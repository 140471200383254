// PolygonAnnotator
import React, { Component } from 'react';
import { Image, Button, Icon, Form } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { convertKeyToString, displaySettingsByRole, ROLES_TYPES,TOO_LARGE_ENTITY } from '../../helpers/Utils';
import Mousetrap from 'mousetrap';
import Panner from '../../helpers/PinchZoomPan/CenteredPanZoom';
import Modal from 'react-bootstrap/lib/Modal';
import { HIT_STATE_SKIPPED, HIT_STATE_DONE, HIT_STATE_DELETED, HIT_STATE_PRE_TAGGED, HIT_STATE_REQUEUED, IMAGE_MASKING, IMAGE_SEGMENTATION, getGroupingLabels, getByValue } from "../../helpers/Utils";
import { Modal as AntdModal, Icon as AntIcon, Tag, Select } from 'antd';
import { DRAW_TOOLS, POLY_ANNOTATOR_CONSTANTS, MESSAGES, ACTIONS, ALERT_TYPES } from '../../helpers/Constants';
import { showAlert, getUidToken } from '../../helpers/commonFunctions';
import { moveAllToDone, autoAnnotate } from '../../Services/TaggerApis';
import domtoimage from 'dom-to-image';
import ColorContrastBar from '../ColorContrastBar/ColorContrastBar';
import ToolsBar from '../ToolsBar/ToolsBar';
import Lables from '../LablesData/Lables';

export default class ImageAnnotator extends Component {
  constructor(props) {
    super(props);
    console.log('PolygonAnnotator props', props);
    const { taskType } = props;
    const opasityVal = taskType === IMAGE_MASKING ? 1 : 0.4
    this.selectedColor = null
    this.state = {
      rects: props.rects,
      rectCatMap: props.rectCatMap,
      rectShapeMap: props.rectShapeMap,
      hideLabelsMap: {},
      imageHoverMap: {},
      hideRectMap: {},
      menuOpenMap: {},
      imgLoaded: {},
      currentPolyPoint: [],
      opacity: opasityVal,
      openNote: false,
      disableZoomAnim: false,
      entities: Object.keys(props.entityColorMap),
      canvasSet: false,
      searchQuery: '',
      translate: {
        x: 0,
        y: 0
      },
      notes: props.notes,
      attributesOption: props.attributesOption,
      imgWidth: undefined,
      imgHeight: undefined,
      imgLoad: false,
      contrast: 1.0,
      brightness: 1.0,
      saturation: 1.0,
      dx: 0,
      dy: 0,
      scale: 1,
      image: props.image,
      imageNiri: props.imageNiri,
      mouseHoverMap: {},
      toolName: this.props.defaultShape,
      drawButton: true,
      toolbarHidden: false,
      currentRect: [],
      hoverPoint:[],
      deleteLabelAnno: '',
      enableDeleteClearAll: false,
      currentCircle: [],
      divToggle: false,
      currentLine: [],
      freeHandSketch: [],
      zoomCircle: false,
      selectedItem: undefined,
      rotateImagAngle: 0,
      openHitLabel: false,
      hitLabel: '',
      hitKey: '',
      pickerColor: '#ffffff',
      dynamicLabelButtonAction: '',
      selectedHitLabel: '',
      colorPickerTop: false,
      selectedHitLabelIndex: [],
      lockAnnotation: false,
      base64Content: '',
      magnifierOption: false,
      deletePointContent: '',
      deleteConfirm: false,
      deleteConfirmPoint: false,
      addlabelHidden: false,
      defaultClass: '',
      qaCheck: false,
      refCheck: false,
      labelGroupMap: props.labelGroupMap,
      keepEntitySelected: false,
      mergeAnnotation: false,
      groupCount: 0,
      annotationMerge: false,
      imagePropsChanged: false,
      lockedHits: [],
      lockHits: false,
      selectedLockedHitsIndex: [],
      enableDragEnableIcon: true,
      annotationToBeErasedId: undefined,
      openAttributeOption: false,
      attributeIndex: '',
      attributes: props.attributes,
      dragEnable: false,
      showDropdown: {},
      activeErasePolygon: false,
      mergeGroupLabel: props.mergeGroupLabel,
      toggleEyeStatusAll: true,
      annotationToBeMerged: '',
      mergestarted: false,
      lock: null,
      removeAnnotation: false,
      removeAnnotationKey: null,
      remAnnotation : false,
      remAnnotationKey : null,
      minValue : undefined,
      deleteValue : undefined,
      reverseDeletePoint : undefined,
      hideImg: false,
      storeRefImages: [],
      isAnnotationSelected : false,
      canvasData:null,
      selectedOptions: {},
      inputValues: [],
      fileLoad : false,
    };
    this.loadImages = this.loadImages.bind(this);
    this.mousedownHandle = this.mousedownHandle.bind(this);
    this.mousemoveHandle = this.mousemoveHandle.bind(this);
    this.mouseupHandle = this.mouseupHandle.bind(this);
    this.savePolygon = this.savePolygon.bind(this);
    this.clearPolygons = this.clearPolygons.bind(this);
    this.undoLast = this.undoLast.bind(this);
    this.resizeWindow = this.resizeWindow.bind(this);
    this._onWheel = this._onWheel.bind(this);
    this.onMouseOutHandler = this.onMouseOutHandler.bind(this);
    this.zoom = this.zoom.bind(this);
    this.resetScale = this.resetScale.bind(this);
    this.removeShape = this.removeShape.bind(this);
    this.getPoints = this.getPoints.bind(this);
    this.moveAllCallback = this.moveAllCallback.bind(this);
    this.autoAnnotationCallback = this.autoAnnotationCallback.bind(this);
    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  componentDidMount() {
    if (this.props.drawHandle) {
      let combo = '';
      if (this.props.shortcuts && 'undo' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.undo);
        Mousetrap.bind(combo, this.undoLast);
      }
      if (this.props.shortcuts && 'clearAll' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.clearAll);
        Mousetrap.bind(combo, this.clearPolygons);
      }
      if (this.props.shortcuts && 'delete' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.delete);
        Mousetrap.bind(combo, this.removeShape.bind(this, undefined));
      }
    }
    Mousetrap.bind('del', this.deleteSelectedPoint)
    window.addEventListener('resize', this.resizeWindow);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rects && nextProps.rectCatMap && nextProps.labelGroupMap && (this.props.rects !== nextProps.rects)) {
      let arr = nextProps.rects
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
          for (let k = 0; k < arr[i][j].length; k++) {
            if (arr[i][j][k] === 1) {
              arr[i][j][k] = 0.9
            }
          }
        }
      }
      this.setState({ rects: arr, notes: nextProps.notes, rectShapeMap: nextProps.rectShapeMap, rectCatMap: nextProps.rectCatMap, labelGroupMap: nextProps.labelGroupMap, image: nextProps.image, imageNiri: nextProps.imageNiri, mergeGroupLabel: nextProps.mergeGroupLabel });
    }
    if (this.props.image !== nextProps.image) {
      this.setState({ imgLoad: false, canvasSet: false, imgWidth: undefined, imgHeight: undefined });
      this.setState({ scale: 1.0, translate: { x: 0, y: 0 }, contrast: 1.0, hideRectMap: {}, brightness: 1.0, saturation: 1.0 });
    }
    if (nextProps.attributesOption) {
      this.setState({ attributesOption: nextProps.attributesOption });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
    if (this.props.drawHandle) {
      let combo = '';
      if (this.props.shortcuts && 'undo' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.undo);
        Mousetrap.unbind(combo);
      }
      if (this.props.shortcuts && 'clearAll' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.clearAll);
        Mousetrap.unbind(combo);
      }
      if (this.props.shortcuts && 'delete' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.delete);
        Mousetrap.unbind(combo);
      }
    }
    Mousetrap.unbind('del', this.deleteSelectedPoint)
  }

  onMouseOutHandler(event) {
    event.stopPropagation();
    if (this.state.magnifierOption) {
      this.zoomOut();
      return false;
    }
    this.setState({
      svgDrag: false,
      mouseDown: false
    });
    return false;
  }

  getPoint(point) {
    if (point < 0.0) { return 0.0; }
    if (point > 1.0) { return 1.0; }
    return point;
  }

  getOffsets(e) {
    return [e.offsetX, e.offsetY];
  }

  getPoints(currentRect) {
    let points = '';
    for (let index = 0; index < currentRect.length; index++) {
      points = points + currentRect[index][0] + ',' + currentRect[index][1] + ' ';
    }
    return points.trim();
  }

  getDecPoints(currentRect, shape) {
    let points = '';
    if (!shape || shape === DRAW_TOOLS.POLYGON.NAME || shape === DRAW_TOOLS.ERASERPOLYGON.NAME) {
      for (let index = 0; index < currentRect.length; index++) {
        points = points +
          (currentRect[index][0] * this.state.imgWidth) + ',' +
          ((currentRect[index][1] * (this.state.imgHeight))) + ' ';
      }
    } else if (shape && shape === DRAW_TOOLS.RECTANGLE.NAME || shape === DRAW_TOOLS.MAGICTOOL.NAME) {
      if (currentRect.length === 4) {
        let x = currentRect[0][0];
        let y = currentRect[0][1];
        let xlg = currentRect[3][0];
        let ylg = currentRect[3][1];
        for (let jindex = 0; jindex < currentRect.length; jindex++) {
          const currentPoint = currentRect[jindex];
          if (x > currentPoint[0]) {
            x = currentPoint[0];
          }
          if (y > currentPoint[1]) {
            y = currentPoint[1];
          }
          if (currentPoint[0] > xlg) {
            xlg = currentPoint[0];
          }
          if (currentPoint[1] > ylg) {
            ylg = currentPoint[1];
          }
        }
        x = x * this.state.imgWidth;
        y = y * this.state.imgHeight;
        xlg = xlg * this.state.imgWidth;
        ylg = ylg * this.state.imgHeight;
        points = x + "," + ylg + " " + xlg + "," + ylg + " " + xlg + "," + y + " " + x + "," + y;
      }
    }
    if (shape === DRAW_TOOLS.LINE.NAME) {
      const currentLine = currentRect;
      let x1 = currentLine[0][0] * this.state.imgWidth;
      let y1 = currentLine[0][1] * this.state.imgHeight;
      let x2 = currentLine[1][0] * this.state.imgWidth;
      let y2 = currentLine[1][1] * this.state.imgHeight;
      points = x1 + " " + y1 + "," + x2 + " " + y2;
    }
    return points.trim();
  }

  handleTextBoxChange = (event, idx, name) => {
    const { attributesOption } = this.state;
    const inputValues = [...this.state.inputValues];
    inputValues[idx] = event;
    if (event != "") {
      attributesOption[this.state.attributeIndex][name] = event;
    }
    this.setState({ inputValues, attributesOption });
  }

  handleAttributeChangeDrop(event, idx, name) {
    const { attributesOption } = this.state;
    const selectedOptions = { ...this.state.selectedOptions };
    const inputValues = [...this.state.inputValues];
    selectedOptions[idx] = event;
    if (event === "custom") {
      inputValues[idx] = "";
    } else {
      attributesOption[this.state.attributeIndex][name] = event;
    }
    this.setState({ selectedOptions, attributesOption });
  }

  handleDropdownFocus = (idx) => {
    this.setState({ selectValue : idx})
  }

  getPopupContainer = (triggerNode) => {
    console.log("popup here")
    return triggerNode.parentNode;
  };


  getAttributesOptions() {
    const { attributes, attributeIndex,attributesOption } = this.state;
    let renderArr = [];
    if (attributes) {
      const dropdown = document.querySelector('.ant-select-dropdown');
      if (dropdown) {
        dropdown.style.position = 'fixed'; // Hide the dropdown when scrolling
      }
      let response = attributes.split(",")
      response.map((element, idx) => {
        let res = this.props.editClassAttribute[idx].classes
        if(typeof(res)=== "string")
        {
          res = res.split(",")
        }
        attributesOption[this.state.attributeIndex][element] = !attributesOption[attributeIndex][element] ? "undefined" : attributesOption[attributeIndex][element];
        renderArr.push(
          <Form size="mini" key={idx}>
            <label style={{margin: "10px", padding: "8px"}}>{element}</label>
            <Select
            key={idx}
              style={{ width: '100%', padding: "10px" }}
              getPopupContainer={this.getPopupContainer}
              placeholder="Choose options"
              onFocus={() => this.handleDropdownFocus(idx)}
              onChange={(event) => this.handleAttributeChangeDrop(event, idx, element)}>
              {res.map(option => (
                <option key={option} value={option} style={{ width: "470px", padding : "15px 8px"  }}>
                  {option}
                </option>
              ))}
              <option value="custom" style={{ width: "470px", padding : "15px 8px" }} >Enter value</option>
            </Select>
            {this.state.selectedOptions[idx] === "custom" && 
            <input
              type="text"
              autoFocus
              style={{ width: '96%', padding: "10px", margin : "10px" }}
              value={this.state.inputValues[idx] || ''} 
              onChange={(event) => this.handleTextBoxChange(event.target.value, idx, element)} 
            />}
          </Form>
        );
      });
    }

    return (
      <div>
        {renderArr}
      </div>
    )
  }

  handleAttributeChange(name, event) {
    const { attributesOption } = this.state;
    if (event.target.value === "") {
      attributesOption[this.state.attributeIndex][name] = "undefined"
    } else {
      attributesOption[this.state.attributeIndex][name] = event.target.value;
    }
  }

  saveAttributeDetails() {
    this.setState({ openAttributeOption: false, attributeIndex: -1 , selectedOptions : {}});
    if (this.props.drawHandle) {
      this.props.drawHandle(this.state);
    }
  }

  handleNoteChange=(event)=> {
    const { notes } = this.state;
    notes[this.state.noteIndex] = event.target.value;
    this.setState({ notes });
  }

  resetImage() {
    console.log('resize resetImage');
    if (this.state.imgObject) {
      this.setState({
        imgHeight: this.state.imgObject.offsetHeight,
        imgWidth: this.state.imgObject.offsetWidth, translate: { x: 0, y: 0 }
      });
      this._panner = new Panner({
        screenWidth: this.state.imgObject.offsetWidth,
        screenHeight: this.state.imgObject.offsetHeight
      });
    }
    clearTimeout(this.resizeTo);
    this.setState({ imgLoad: true });
  }

  resizeWindow(e1, e2) {
    console.log('resize ', e1, e2);
    if (this.state.imgObject) {
      this.setState({ imgLoad: false, canvasSet: false });
    }
    this.resizeTo = setTimeout(this.resetImage.bind(this), 2000);
  }

  checkMerge = () => {
    const { selectedItem, mouseHoverMap } = this.state;
    if (selectedItem) {
      let index = parseInt(selectedItem, 10);
      if (mouseHoverMap[index]) {
        return true;
      }
    }
    return false;
  }

  getGroupCountFromMerge = (groupCount) => {
    const { selectedItem, labelGroupMap } = this.state;
    let index = parseInt(selectedItem, 10);
    if (labelGroupMap[index]) {
      if (labelGroupMap[index].startsWith("#")) {
        let groupLabels = getGroupingLabels(labelGroupMap);
        let indexArr = getByValue(groupLabels, labelGroupMap[index])
        this.setState({ keepEntitySelected: true, groupCount: indexArr[0] });
      } else {
        this.setState({ keepEntitySelected: true, groupCount });
      }
    }
  }

  toggleEntitySelected = () => {
    const { keepEntitySelected, rects } = this.state;
    let groupCount = 0;
    if (keepEntitySelected) {
      this.setState({ defaultClass: '', keepEntitySelected: false });
      this.props.mergingGroupLabelButtonSwitch(false)
    } else {
      groupCount = (rects && rects.length > 0) ? rects.length : 0;
      if (this.checkMerge()) {
        this.getGroupCountFromMerge(groupCount);
      } else {
        this.setState({ keepEntitySelected: true, groupCount });

      }
    }
  };

  toggleMergeOldAnnotation = () => {
    const {mouseHoverMap } = this.state;
    if (mouseHoverMap[this.state.selectedItem] && !this.state.mergestarted) {
      this.setState({
        annotationToBeMerged: this.state.selectedItem,
        mergeOldAnnotation: true,
        mergestarted: true
      })
      showAlert('Select the next annotation to be merged', 'error');
    }
    if (mouseHoverMap[this.state.selectedItem] && this.state.mergestarted) {
      const index = this.state.annotationToBeMerged;
      const labelGroupMaps = this.state.labelGroupMap;
      if (labelGroupMaps[index].startsWith('#')) {
        labelGroupMaps[this.state.selectedItem] = labelGroupMaps[index];
        showAlert("Selected annotations were merged successfully", "success");
        mouseHoverMap[this.state.selectedItem] = false;
        this.setState({
          labelGroupMap: labelGroupMaps,
          annotationToBeMerged: '',
          selectedItem: '',
          mouseHoverMap,
          mergestarted: false,
          mergeOldAnnotation: false
        }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        })
      } else {
        labelGroupMaps[index] = "#" + this.state.labelGroupMap[index] + "_" + index;
        labelGroupMaps[this.state.selectedItem] = labelGroupMaps[index];
        showAlert("Selected annotations were merged successfully", "success");
        mouseHoverMap[this.state.selectedItem] = false;
        this.setState({
          labelGroupMap: labelGroupMaps,
          annotationToBeMerged: '',
          selectedItem: '',
          mouseHoverMap,
          mergestarted: false,
          mergeOldAnnotation: false
        }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        })
      }
    }
  };


  changeSelectedAnnotationAsGroup = () => {
    const { rectCatMap, selectedItem, mergeGroupLabel } = this.state;
    if ((!mergeGroupLabel && (selectedItem === 'svgContainer' || selectedItem === 'x-0')) || (!mergeGroupLabel && selectedItem === undefined)) {
      this.props.mergingGroupLabelButtonSwitch(false)
      showAlert('Select the annotation to be grouped', 'error');
    } else if (!mergeGroupLabel && (selectedItem !== 'svgContainer' || selectedItem !== 'x-0' || selectedItem !== undefined)) {
      this.props.mergingGroupLabelButtonSwitch(true)
    } else {
      this.props.mergingGroupLabelButtonSwitch(false)
    }
    const rectColorMap = this.props.hitEntities;
    rectColorMap[selectedItem] = this.selectedColor ? this.selectedColor : this.props.entityColorMap[rectCatMap[selectedItem]]
    if (this.state.labelGroupMap[selectedItem]) {
      if (!this.state.labelGroupMap[selectedItem].startsWith("#")) {
        this.state.labelGroupMap[selectedItem] = "#" + this.state.labelGroupMap[selectedItem] + "_" + selectedItem;
        this.setState({ rectColorMap })
        this.props.drawHandle(this.state)
      } else {
        showAlert('This annotation is already selected for merging', 'error');
        this.props.mergingGroupLabelButtonSwitch(false)
      }
    }
  };

  mergeFromPoints = () => {
    const { labelGroupMap } = this.state;
    this.setState({mergeFrom:getByValue(labelGroupMap, labelGroupMap[0])})

    console.log('mergeFrom', this.state.mergeFrom)
  }

  mergeToPoints = () => {
    const { mergeFrom, labelGroupMap } = this.state;
    this.setState({mergeTo:getByValue(labelGroupMap, labelGroupMap[1])})
    this.state.mergeTo.push(mergeFrom)
    console.log('After push merge to issss', this.state.mergeTo)
    this.props.drawHandle()
    this.props.showPolygonV2BoundingImages()
    this.setState({ mergeFrom: [] })
  }

  handleKeyDown(event) {
    if (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) {
      this.setState({activeErasePolygon:false})
      if (event.keyCode === 90) {
        if (!this.state.zoomCircle)
          this.setState({ zoomCircle: true })
      }
      if (this.state.openNote) {
        if (event.keyCode === 13) {
          // enter
          this.saveModal();
        } else if (event.keyCode === 27) {
          // escape
          this.closeModal();
        }
      }
      else {
        if (!this.props.isDynamicModelOpen && !this.props.isCommentTyping && event.keyCode === 83) {
          // down
          this.pan(0, 5)
        }
        if (!this.props.isDynamicModelOpen && !this.props.isCommentTyping && event.keyCode === 87) {
          // up
          this.pan(0, -5)
        }
        if (!this.props.isDynamicModelOpen && !this.props.isCommentTyping && event.keyCode === 65) {
          // left
          this.pan(-5, 0)
        }
        if (!this.props.isDynamicModelOpen && !this.props.isCommentTyping && event.keyCode === 68) {
          // right
          this.pan(5, 0)
        }
      }
      return false;
    }
  }
  validPoints = (points) => {
    for (let index = 0; index < points.length; index++) {
      if ((points[index][0] > 1.0 || points[index][1] > 1.0) ||
        (points[index][0] <= 0.0 || points[index][1] < 0.0))
        return false;
    }
    return true;
  }

  enableLabelSelection = (selectedIndex) => {
    const { labelGroupMap, mouseHoverMap } = this.state;
    if (labelGroupMap[selectedIndex])
      if (labelGroupMap[selectedIndex].startsWith("#")) {
        let selectArr = getByValue(labelGroupMap, labelGroupMap[selectedIndex])
        selectArr.map(element => {
          mouseHoverMap[element] = true;
        })
        this.setState({ mouseHoverMap });
      }
  }

  selectHitsList = (id) => {
    if (id !== 'selectedItem') {
      let hoverMap = {};
      const { selectedLockedHitsIndex } = this.state
      if (selectedLockedHitsIndex.includes(id)) {
        var index = selectedLockedHitsIndex.indexOf(id)
        if (index > -1) {
          selectedLockedHitsIndex.splice(index, 1);
        }
      } else {
        selectedLockedHitsIndex.push(id)
      }
      selectedLockedHitsIndex.map(index => {
        hoverMap[index] = true;
      })
      this.setState({ mouseHoverMap: hoverMap, lockAnnotation: true });
    }
  }

  mousedownHandle(event) {
    console.log("Entered in mousedown handle")
    event.preventDefault();
    const [offsetX, offsetY] = this.getOffsets(event);
    console.log('mousedown polygon drag', event.offsetX, event.offsetY, offsetX, offsetY, this.state, event.target.nodeName, event.target.id);
    const { selectedItem, toolName, annotationToBeErasedId, rectShapeMap,  lockHits } = this.state;
    const mouseHoverMap = this.state.mouseHoverMap;
    const targetId = event.target.id;
    if (targetId) {
        this.setState({ selectedItem: targetId })
        if (lockHits) {
          this.selectHitsList(selectedItem)
        }
    }
    if (event.target.nodeName === "svg" && toolName === DRAW_TOOLS.ERASERPOLYGON) {
      this.setState({defaultClass : ''})
      this.undoLabelSelection();
      this.toggleTool(DRAW_TOOLS.POLYGON.NAME, event);
    }

    let eventData = ((event.target.nodeName.includes('circle') || event.target.nodeName.includes('polygon') || event.target.nodeName.includes('polyline') || event.target.nodeName.includes('polygon')) && false);
    this.setState({ enableDragEnableIcon: eventData })
    if (this.state.defaultClass) {
      if (!this.state.lockAnnotation) {
        for (const k in mouseHoverMap) {
          if (mouseHoverMap.hasOwnProperty(k)) {
            mouseHoverMap[k] = false;
          }
        }
      }
      const currentRect = this.state.currentRect;
      this.selectDrawTool(event)
      if (toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && selectedItem !== "svgContainer" && selectedItem === annotationToBeErasedId) {
        if (event.target.nodeName === DRAW_TOOLS.CIRCLE.NAME
          && targetId.length === 3 && targetId[0] === 'x' && targetId[2] === '0') {
            this.EraserPolygon();
        } else {
          currentRect.push([offsetX, offsetY]);
          this.setState({
            currentPolyPoint: []
          });
        }
      } 
    } else if (event.target.nodeName === DRAW_TOOLS.CIRCLE.NAME && this.props.drawHandle) {
      const splits = targetId.split('--');
      if (splits.length === 2) {
        const rectIndex = parseInt(splits[0], 10);
        const rectShape = this.state.rectShapeMap[rectIndex];
        if (rectShape === DRAW_TOOLS.POINT.NAME) {
          if (!this.state.lockAnnotation) {
            for (const k in mouseHoverMap) {
              if (mouseHoverMap.hasOwnProperty(k)) {
                mouseHoverMap[k] = false;
              }
            }
          }
          mouseHoverMap[rectIndex] = true;
        }
        this.setState({ pointDrag: true, dragRect: rectIndex, mouseHoverMap, dragPoint: parseInt(splits[1], 10) });
      } else if (splits.length === 3) {
        const rects = this.state.rects;
        const rect = this.state.rects[parseInt(splits[0], 10)];
        const firstIndex = parseInt(splits[1], 10);
        const nextIndex = parseInt(splits[2], 10);
        const middleX = (rect[firstIndex][0] + rect[nextIndex][0]) / 2;
        const middleY = (rect[firstIndex][1] + rect[nextIndex][1]) / 2;
        rect.splice(firstIndex + 1, 0, [middleX, middleY]);
        rects[parseInt(splits[0], 10)] = rect;
        this.setState({ rects: rects, pointDrag: true, dragRect: parseInt(splits[0], 10), dragPoint: parseInt(splits[1], 10) + 1 });
      } else {
        if (mouseHoverMap[targetId] && mouseHoverMap[targetId] === true) {
          this.setState({ circleDrag: true, rectDrag: true, dragRect: targetId, dragPoint: [offsetX, offsetY] });
        } else {
          if (!this.state.lockAnnotation) {
            for (const k in mouseHoverMap) {
              if (mouseHoverMap.hasOwnProperty(k)) {
                if (k !== targetId) {
                  mouseHoverMap[k] = false;
                }
              }
            }
          }
          if (mouseHoverMap[targetId]) {
            mouseHoverMap[targetId] = false;
          } else {
            mouseHoverMap[targetId] = true;
          }
          this.setState({ mouseHoverMap });
        }
      }
    } else if(event.ctrlKey && rectShapeMap[targetId] && rectShapeMap[targetId] !== "line" && rectShapeMap[targetId] !== "rectangle") {
      const rects = this.state.rects;
      let freeHandSketch = []
      freeHandSketch.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
       const newAreaPoints = [...rects];
      const distance = (point) => {
        return Math.sqrt(
          Math.pow(freeHandSketch[0][0]- point.x, 2) + Math.pow(freeHandSketch[0][1] - point.y, 2)
        );
      };
      const interpolate = (xVal, yVal, f) => {
        var nx = xVal[0] + (yVal[0] - xVal[0]) * f;
        var ny = xVal[1] + (yVal[1] - xVal[1]) * f;
        return { x: nx, y: ny };
      };
      let closestPoint = 0;
      let closestDistance = Number.MAX_VALUE;
      let numPoints = newAreaPoints[targetId].length;
      for (let i = 0; i < numPoints; i++) {
        let curr = newAreaPoints[targetId][i];
        let next = newAreaPoints[targetId][i + 1 === numPoints ? 0 : i + 1];
        for (let n = 0; n < 1; n += 0.05) {
          let interpolatedDist = distance(interpolate(curr, next, n));
          if (interpolatedDist < closestDistance) {
            closestPoint = i;
            closestDistance = interpolatedDist;
          }
        }
      }
      newAreaPoints[targetId].splice(closestPoint + 1, 0, [freeHandSketch[0][0], freeHandSketch[0][1]]);
      this.setState({
        rects : newAreaPoints
      }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
        });
    } else if (this.props.drawHandle && (event.target.nodeName === DRAW_TOOLS.POLYGON.NAME || (event.target.nodeName === DRAW_TOOLS.ERASERPOLYGON.NAME && selectedItem !== "svgContainer" &&
      (selectedItem === annotationToBeErasedId || (selectedItem.length === 3 && selectedItem[0] === 'x' && this.state.selectedItem[2] === '0')))
      || event.target.nodeName === 'rect')) {
      if (mouseHoverMap[targetId] && mouseHoverMap[targetId] === true) {
        this.setState({ rectDrag: true, dragRect: targetId, dragPoint: [offsetX, offsetY] });
      } else {
        if (!this.state.lockAnnotation) {
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              if (k !== targetId) {
                mouseHoverMap[k] = false;
              }
            }
          }
        }
        if (mouseHoverMap[targetId]) {
          mouseHoverMap[targetId] = false;
        } else {
          mouseHoverMap[targetId] = true;
          this.setState({activeErasePolygon:true})
        }
        this.setState({ mouseHoverMap, activeErasePolygon: this.state.activeErasePolygon });
      }
    } else if (event.target.nodeName === DRAW_TOOLS.LINE.NAME && this.props.drawHandle) {
      if (mouseHoverMap[targetId] && mouseHoverMap[targetId] === true) {
        this.setState({ rectDrag: true, dragRect: targetId, dragPoint: [offsetX, offsetY] });
      } else {
        if (!this.state.lockAnnotation) {
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              if (k !== targetId) {
                mouseHoverMap[k] = false;
              }
            }
          }
        }
        if (mouseHoverMap[targetId]) {
          mouseHoverMap[targetId] = false;
        } else {
          mouseHoverMap[targetId] = true;
        }
        this.setState({ mouseHoverMap });
      }
    } else if (event.target.nodeName === DRAW_TOOLS.POLYLINE.NAME && this.props.drawHandle) {
      if (mouseHoverMap[targetId] && mouseHoverMap[targetId] === true) {
        console.log('inside else', mouseHoverMap, targetId, mouseHoverMap[targetId])
        this.setState({ rectDrag: true, dragRect: targetId, dragPoint: [offsetX, offsetY] });
      } else {
        if (!this.state.lockAnnotation) {
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              if (k !== targetId) {
                mouseHoverMap[k] = false;
              }
            }
          }
        }
        if (mouseHoverMap[targetId]) {
          mouseHoverMap[targetId] = false;
        } else {
          mouseHoverMap[targetId] = true;
        }
        this.setState({ mouseHoverMap });
      }
    } else if (event.target.nodeName === 'svg') {
      this.setState({ svgDrag: true });
      this._startX = event.pageX;
      this._startY = event.pageY;
      this.setState({ dragPoint: [event.pageX, event.pageY] });
    }
    this.enableLabelSelection(targetId);
    return false;
  }

  denormalizePoints(normalizedPointsArray, maxWidth, maxHeight) {
    let denormalizedPoints = normalizedPointsArray.map(point => {
        let [normalizedX, normalizedY] = point;
        let denormalizedX = Math.round(normalizedX * maxWidth);
        let denormalizedY = Math.round(normalizedY * maxHeight);
        return [
            [denormalizedX, denormalizedY]
        ];
    });
    const flattened = denormalizedPoints.flat(1);
    const uniquePoints = Array.from(new Set(flattened.map(JSON.stringify))).map(JSON.parse);
    const [x1, y1] = uniquePoints[0];
    const [x2, y2] = uniquePoints[2];
    const result = [x1, y1, x2, y2];
    console.log("denormalizedPoints", result)
    return result;
}

  selectDrawTool = (event) => {
    const { toolName, rectShapeMap, keepEntitySelected, hoverPoint, 
            rectCatMap, labelGroupMap, groupCount } = this.state;
    const { hitEntities, entityColorMap, continuousTagging } = this.props;
    const [offsetX, offsetY] = this.getOffsets(event);
    const currentRect = this.state.currentRect;
    const rectColorMap = hitEntities;
    let defaultClass = this.state.defaultClass;
    const mouseHoverMap = this.state.mouseHoverMap;
    const targetId = event.target.id;
    switch(toolName) {
      case DRAW_TOOLS.POLYGON.NAME:
        if (event.target.nodeName === DRAW_TOOLS.CIRCLE.NAME
          && targetId.length === 3
          && targetId[0] === 'x'
          && targetId[2] === '0') {
          if(!defaultClass) {
            this.savePolygon(this.state.entities[0]);
          }
        } else {
          if (hoverPoint && hoverPoint.length > 0) {
            currentRect.push([hoverPoint[0], hoverPoint[1]]);
            this.setState({
              currentPolyPoint: []
            });
          } else {
            currentRect.push([offsetX, offsetY]);
            this.setState({
              currentRect,
              currentPolyPoint: []
            });
          }
        }
        break;
      case DRAW_TOOLS.POINT.NAME:
        if (event.target.nodeName === DRAW_TOOLS.CIRCLE.NAME) {
          defaultClass ? this.savePolygon(this.state.defaultClass) : this.savePolygon(this.state.entities[0]);
        } else {
          const currentRect = [];
          currentRect.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
          const numberOfRects = Object.keys(rectCatMap).length;
          rectShapeMap[numberOfRects] = 'point';
          if (this.state.defaultClass) {
            rectCatMap[numberOfRects] = [this.state.defaultClass];
          }
          rectColorMap[numberOfRects] = this.selectedColor ? this.selectedColor : entityColorMap[rectCatMap[numberOfRects]];
          mouseHoverMap[numberOfRects] = true;
          this.selectedColor = null;  
          if ((!keepEntitySelected && !continuousTagging)) {
            labelGroupMap[numberOfRects] = this.state.defaultClass;
            defaultClass = '';
          } else {
            if (keepEntitySelected) {
              labelGroupMap[numberOfRects] = "#" + this.state.defaultClass + "_" + groupCount;
            } else if (continuousTagging) {
              labelGroupMap[numberOfRects] = this.state.defaultClass;
            }
          }

          this.setState({
            rects: [
              ...this.state.rects,
              currentRect
            ],
            rectCatMap,
            labelGroupMap,
            mouseHoverMap,
            currentRect: [],
            rectShapeMap,
            defaultClass,
            openMenuTool: true,
            mouseDown: false,
            showList: true,
            rectColorMap
          }, () => {
            if (this.props.drawHandle) {
              console.log('calling drawhandle', this.state);
              this.props.drawHandle(this.state);
            }
          });
        }
        break;
      case DRAW_TOOLS.RECTANGLE.NAME:
        console.log('starting rectangle', event.offsetX, event.offsetY, event.target.nodeName, this.state.currentRect);
        if (this.state.currentRect.length === 0) {
          console.log('starting point of rectangle');
          currentRect.push([offsetX, offsetY]);
          this.setState({
            currentRect,
          });
        } else {
          console.log('rectangle draw ends...');
        let currentRectangle = this.state.currentRect;
        if (event.target.nodeName === 'svg') {
          const currentPoint = currentRectangle[0];
          currentRectangle = [];
          currentRectangle.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRectangle.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRectangle.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
          currentRectangle.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
        } else {
          const currentPoint = currentRectangle[0];
          const nextPoint = currentRectangle[3];
          currentRectangle = [];
          currentRectangle.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRectangle.push([this.getPoint(nextPoint[0] / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRectangle.push([this.getPoint(nextPoint[0] / this.state.imgWidth), this.getPoint(nextPoint[1] / this.state.imgHeight)]);
          currentRectangle.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(nextPoint[1] / this.state.imgHeight)]);
        }
          const numberOfRects = Object.keys(rectCatMap).length;
          rectShapeMap[numberOfRects] = DRAW_TOOLS.RECTANGLE.NAME;
          if (this.state.defaultClass) {
            rectCatMap[numberOfRects] = [this.state.defaultClass];
          }
          if (this.selectedColor === null) {
            rectColorMap[numberOfRects] = entityColorMap[this.state.defaultClass]
          }
          this.selectedColor = null;  
          if ((!keepEntitySelected && !continuousTagging)) {
            labelGroupMap[numberOfRects] = this.state.defaultClass;
            defaultClass = '';
          } else {
            if (keepEntitySelected) {
              labelGroupMap[numberOfRects] = "#" + this.state.defaultClass + "_" + groupCount;
            } else if (continuousTagging) {
              labelGroupMap[numberOfRects] = this.state.defaultClass;
            }
          }

          mouseHoverMap[numberOfRects] = true;
          this.setState({
            rects: [
              ...this.state.rects,
              currentRectangle
            ],
            rectCatMap,
            labelGroupMap,
            mouseHoverMap,
            currentRect: [],
            rectShapeMap,
            defaultClass,
            openMenuTool: true,
            mouseDown: false,
            showList: true,
            rectColorMap
          }, () => {
            if (this.props.drawHandle) {
              this.props.drawHandle(this.state);
            }
          });
        }
        break;
      case DRAW_TOOLS.MAGICTOOL.NAME:
        console.log('starting magictool', event.offsetX, event.offsetY, event.target.nodeName, this.state.currentRect);
        if (this.state.currentRect.length === 0) {
          console.log('starting point of magictool');
          currentRect.push([offsetX, offsetY]);
          this.setState({
            currentRect,
          });
        } else {
          console.log('magictool draw ends...');
        let currentRect = this.state.currentRect;
        if (event.target.nodeName === 'svg') {
          const currentPoint = currentRect[0];
          currentRect = [];
          currentRect.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRect.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRect.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
          currentRect.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
        } else {
          const currentPoint = currentRect[0];
          const nextPoint = currentRect[3];
          currentRect = [];
          currentRect.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRect.push([this.getPoint(nextPoint[0] / this.state.imgWidth), this.getPoint(currentPoint[1] / this.state.imgHeight)]);
          currentRect.push([this.getPoint(nextPoint[0] / this.state.imgWidth), this.getPoint(nextPoint[1] / this.state.imgHeight)]);
          currentRect.push([this.getPoint(currentPoint[0] / this.state.imgWidth), this.getPoint(nextPoint[1] / this.state.imgHeight)]);
        }
          const numberOfRects = Object.keys(rectCatMap).length;
          rectShapeMap[numberOfRects] = DRAW_TOOLS.MAGICTOOL.NAME;
          if (this.state.defaultClass) {
            rectCatMap[numberOfRects] = [this.state.defaultClass];
          }
          if (this.selectedColor === null) {
            rectColorMap[numberOfRects] = entityColorMap[this.state.defaultClass]
          }
          this.selectedColor = null;  
          if ((!keepEntitySelected && !continuousTagging)) {
            labelGroupMap[numberOfRects] = this.state.defaultClass;
            defaultClass = '';
          } else {
            if (keepEntitySelected) {
              labelGroupMap[numberOfRects] = "#" + this.state.defaultClass + "_" + groupCount;
            } else if (continuousTagging) {
              labelGroupMap[numberOfRects] = this.state.defaultClass;
            }
          }

          mouseHoverMap[numberOfRects] = true;
          const { imageNaturalHeight, imageNaturalWidth } = this.state;
          const denormalizedPoints = this.denormalizePoints(currentRect, imageNaturalWidth, imageNaturalHeight);
          const projectId = this.props.params.projectId;
          const boxCoordinates = '"' + '[' + denormalizedPoints.join(',') + ']"';
          console.log('Denormalized Points:', currentRect, boxCoordinates, this.state.image);
          this.setState({ fileLoad : true, currentRect : [] });
          autoAnnotate(
            projectId, 
            boxCoordinates,
            this.state.image,
            this.autoAnnotationCallback.bind(this, rectCatMap, labelGroupMap, mouseHoverMap, rectShapeMap,defaultClass, rectColorMap)
          );
          this.setState({ defaultClass : ""});
        }
        break;
      case DRAW_TOOLS.CIRCLE.NAME:
          if (this.state.currentCircle.length === 0) {
            const { currentCircle } = this.state;
            currentCircle.push([offsetX, offsetY]);
            this.setState({
              currentCircle,
            });
          } else {
            let { currentCircle } = this.state;
  
            const circleNormalPoints = [];
              circleNormalPoints.push([this.getPoint(currentCircle[0][0] / this.state.imgWidth), this.getPoint(currentCircle[0][1] / this.state.imgHeight)]);
              circleNormalPoints.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
            const numberOfCircles = Object.keys(rectCatMap).length;
            rectShapeMap[numberOfCircles] = DRAW_TOOLS.CIRCLE.NAME;
            if (this.state.defaultClass) {
              rectCatMap[numberOfCircles] = [this.state.defaultClass];
            }
            rectColorMap[numberOfCircles] = this.selectedColor ? this.selectedColor : entityColorMap[rectCatMap[numberOfCircles]];
  
            if ((!keepEntitySelected && !continuousTagging)) {
              labelGroupMap[numberOfCircles] = this.state.defaultClass;
              defaultClass = '';
            } else {
              if (keepEntitySelected) {
                labelGroupMap[numberOfCircles] = "#" + this.state.defaultClass + "_" + groupCount;
              } else if (continuousTagging) {
                labelGroupMap[numberOfCircles] = this.state.defaultClass;
              }
            }
  
            mouseHoverMap[numberOfCircles] = true;
            this.setState({
              rects: [
                ...this.state.rects,
                circleNormalPoints
              ],
              rectCatMap,
              labelGroupMap,
              rectShapeMap,
              rectColorMap,
              mouseHoverMap,
              currentCircle: [],
              defaultClass,
              openMenuTool: true,
              mouseDown: false,
              showList: true
            }, () => {
              if (this.props.drawHandle) {
                this.props.drawHandle(this.state);
              }
            });
          }
          break;
      case DRAW_TOOLS.LINE.NAME:
          if (this.state.currentLine.length === 0) {
            const { currentLine } = this.state;
            currentLine.push([offsetX, offsetY]);
            this.setState({
              currentLine,
            });
          } else {
            const { currentLine } = this.state;
            const lineVertices = [];
            lineVertices.push([this.getPoint(currentLine[0][0] / this.state.imgWidth), this.getPoint(currentLine[0][1] / this.state.imgHeight)]);
            lineVertices.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
            const lineVertex = Object.keys(rectCatMap).length;
            rectShapeMap[lineVertex] = DRAW_TOOLS.LINE.NAME;
            if (this.state.defaultClass) {
              rectCatMap[lineVertex] = [this.state.defaultClass];
            }
            rectColorMap[lineVertex] = this.selectedColor ? this.selectedColor : entityColorMap[rectCatMap[lineVertex]];
            this.selectedColor = null;    
            if ((!keepEntitySelected && !continuousTagging)) {
              labelGroupMap[lineVertex] = this.state.defaultClass;
              defaultClass = '';
            } else {
              if (keepEntitySelected) {
                labelGroupMap[lineVertex] = "#" + this.state.defaultClass + "_" + groupCount;
              } else if (continuousTagging) {
                labelGroupMap[lineVertex] = this.state.defaultClass;
              }
            }
  
            mouseHoverMap[lineVertex] = true;
            this.setState({
              rects: [
                ...this.state.rects,
                lineVertices
              ],
              rectCatMap,
              labelGroupMap,
              rectShapeMap,
              mouseHoverMap,
              currentLine: [],
              defaultClass,
              openMenuTool: true,
              mouseDown: false,
              showList: true,
              rectColorMap
            }, () => {
              if (this.props.drawHandle) {
                this.props.drawHandle(this.state);
              }
            });
          }
          break;
      case DRAW_TOOLS.POLYLINE.NAME:
          this._drawing = true;
          const { freeHandSketch } = this.state;
          if (freeHandSketch.length === 0) {
            freeHandSketch.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
            this.setState({
              freeHandSketch,
            });
          } else {
              freeHandSketch.push([this.getPoint(offsetX / this.state.imgWidth), this.getPoint(offsetY / this.state.imgHeight)]);
            const freeHandVertex = Object.keys(rectCatMap).length;
            rectShapeMap[freeHandVertex] = DRAW_TOOLS.POLYLINE.NAME;
            if (this.state.defaultClass) {
              rectCatMap[freeHandVertex] = [this.state.defaultClass];
            }
            rectColorMap[freeHandVertex] = this.selectedColor ? this.selectedColor : entityColorMap[rectCatMap[freeHandVertex]];
            this.selectedColor = null;    
            if ((!keepEntitySelected && !continuousTagging)) {
              labelGroupMap[freeHandVertex] = this.state.defaultClass;
              defaultClass = '';
            } else {
              if (keepEntitySelected) {
                labelGroupMap[freeHandVertex] = "#" + this.state.defaultClass + "_" + groupCount;
              } else if (continuousTagging) {
                labelGroupMap[freeHandVertex] = this.state.defaultClass;
              }
            }
  
            mouseHoverMap[freeHandVertex] = true;
            let freeHandSketchPolyLine = []
            freeHandSketch.map((point, jindex) => {
                if((jindex %2 == 0 && jindex %3 == 0 && jindex %4 == 0) || (jindex == freeHandSketch.length - 1)){
                freeHandSketchPolyLine.push(point)
              }
              });
              let xyPointEqual = (p1, p2, linePoly = 0.01) => Math.abs(p1 - p2) <= linePoly;
              let xPoint = xyPointEqual(freeHandSketchPolyLine[freeHandSketchPolyLine.length - 1][1], freeHandSketchPolyLine[0][1])
              let yPoint = xyPointEqual(freeHandSketchPolyLine[freeHandSketchPolyLine.length - 1][0], freeHandSketchPolyLine[0][0])
              if(xPoint && yPoint) {
                freeHandSketchPolyLine.push(freeHandSketchPolyLine[0])
                this.setState({
                  rects: [
                    ...this.state.rects,
                    freeHandSketchPolyLine
                  ],
                  rectCatMap,
                  labelGroupMap,
                  rectShapeMap,
                  mouseHoverMap,
                  freeHandSketch: [],
                  defaultClass,
                  openMenuTool: true,
                  mouseDown: false,
                  showList: true,
                  rectColorMap
                }, () => {
                  if (this.props.drawHandle) {
                    this.props.drawHandle(this.state);
                  }
                });
              } 
              else {
              freeHandSketchPolyLine = []
              this.setState({
                rects: [
                  ...this.state.rects,
                  freeHandSketchPolyLine
                ],
              });
            }
          }
          break;
      default:
        console.log("Not with the cases")
    }
  }

  convertCurrentRectToData(currentRect) {
    const { x1, y1, width, height } = currentRect;
    const dataRect = {};
    if (width > 0) {
      dataRect.x1 = (x1);
      dataRect.x2 = ((x1 + width));
    } else {
      dataRect.x2 = ((x1));
      dataRect.x1 = ((x1 + width));
    }
    if (height > 0) {
      dataRect.y1 = (y1);
      dataRect.y2 = ((y1 + height));
    } else {
      dataRect.y2 = ((y1));
      dataRect.y1 = ((y1 + height));
    }
    return dataRect;
  }

  getIds = () => {
    let idArr = [];
    let children = document.getElementById("svgContainer").children;
    for (var i = 0; i < children.length; i++) {
      if (children[i].id !== "")
        idArr.push(children[i].id);
      else if (children[i].id === "" && children[i].nodeName === "g") {
        let childNodes = children[i].childNodes;
        const keys = Object.keys(childNodes)
        keys.map(element => {
          idArr.push(childNodes[element].id);
        })
      }
    }
    return idArr;
  }

  setMagnifier = (checked) => {
    let idArr = this.getIds();
    const { lockAnnotation } = this.state;
    if (!checked) {
      this.setState({ magnifierOption: false }, () => {
        if (!lockAnnotation) {
          if (idArr.length > 0) {
            idArr.map(id => {
              if (document.getElementById(id)) {
                document.getElementById(id).removeAttribute("pointer-events");
              }
            })
          }
        }
      })
    } else {
      const node = document.getElementById('myImage');
      console.log("dometoImage", node)
      domtoimage.toBlob(node, {
        cacheBust: true
      }).then((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64data = reader.result;
          console.log("base64data ", base64data);
          this.setState({ magnifierOption: true, base64Content: base64data }, () => {
            if (idArr.length > 0) {
              idArr.map(id => {
                document.getElementById(id).setAttribute("pointer-events", "none");
              })
            }
          })
        }
      });
    }
  }

  mouseupHandle(event) {
    this._drawing = false;
    console.log('mouseupHandle polygon', event.target.nodeName, event.offsetX, event.offsetY);
    const [offsetX, offsetY] = this.getOffsets(event);
    this.setState({ zoomCircle: false, deletePointContent: '' })
    const shape = this.state.rectShapeMap[this.state.dragRect];


    if (this.state.pointDrag) {
      if (shape === DRAW_TOOLS.RECTANGLE.NAME) {
        this.setState({ pointDrag: false }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        });
      } else {
        event.preventDefault();
        const rects = this.state.rects;
        const currentRect = rects[this.state.dragRect];
        const shape = this.state.rectShapeMap[this.state.dragRect];
        const newx = this.getPoint(offsetX / this.state.imgWidth);
        const newy = this.getPoint(offsetY / this.state.imgHeight);
        currentRect[this.state.dragPoint][0] = newx;
        currentRect[this.state.dragPoint][1] = newy;
        const rectColorMap = this.props.hitEntities;
        rectColorMap[this.state.dragRect] = this.selectedColor ? this.selectedColor : this.props.hitEntities[this.state.dragRect];
        rects[this.state.dragRect] = currentRect;

        // Tracking polygon delete points
        let deletePoint = shape + "_" + this.state.dragRect + "_" + this.state.dragPoint;
        let rect = rects[this.state.dragRect]
        if (rect[1] !== undefined) {
          let minValue = rect[1][0] - rect[0][0];
          if (minValue > 0) {
            this.setState({minValue:false})
          } else if (minValue < 0) {
            this.setState({minValue:true})
          }
        }
        this.setState({ rects, rectColorMap, deletePointContent: deletePoint , deleteValue : this.state.dragPoint, reverseDeletePoint : undefined});
        this.setState({ pointDrag: false }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        });
      }

    } else if (this.state.rectDrag) {
      event.preventDefault();
      const { circleDrag, lineDrag } = this.state
      if (lineDrag) {
        this.setState({ lineDrag: false, rectDrag: false }, () => {
          if (this.props.drawHandle) {
            console.log('calling drawhandle after line rerender', this.state);
            this.props.drawHandle(this.state);
          }
        });
      } else if (circleDrag) {
        this.setState({ circleDrag: false, zoomCircle: false, rectDrag: false }, () => {
          if (this.props.drawHandle) {
            console.log('calling drawhandle after circle rerender', this.state);
            this.props.drawHandle(this.state);
          }
        });
      } else if (this.state.dragging) {
        const dx = (offsetX - this.state.dragPoint[0]) / this.state.imgWidth;
        const dy = (offsetY - this.state.dragPoint[1]) / this.state.imgHeight;
        const currentRect = this.state.rects[this.state.dragRect];
        for (let jindex = 0; jindex < currentRect.length; jindex++) {
          currentRect[jindex][0] = currentRect[jindex][0] + dx;
          currentRect[jindex][1] = currentRect[jindex][1] + dy;
        }
        const rects = this.state.rects;
        rects[this.state.dragRect] = currentRect;
        console.log('rectdrag', event.clientX, event.clientY, event.offsetX, event.offsetY, event.target.id, this.state);
        this.setState({ rects, dragging: false, rectDrag: false, dragPoint: undefined, dragRect: undefined, zoomCircle: false },
          () => {
            if (this.props.drawHandle) {
              console.log('calling drawhandle', this.state);
              this.props.drawHandle(this.state);
            }
          });
      } else {
        event.preventDefault();
        const mouseHoverMap = this.state.mouseHoverMap;
        if (!this.state.lockAnnotation) {
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              if (k !== event.target.id) {
                mouseHoverMap[k] = false;
              }
            }
          }
        }
        if (mouseHoverMap[event.target.id]) {
          mouseHoverMap[event.target.id] = false;
        } else {
          mouseHoverMap[event.target.id] = true;
        }
        this.setState({ mouseHoverMap, dragging: false, rectDrag: false, dragRect: undefined });
      }
    } else if (this.state.svgDrag) {
      event.preventDefault();
      this.setState({ svgDrag: false });
    }
  }

  zoomIn = (e) => {
    let thisContext = document.getElementById('svgContainer');
    thisContext.style.cursor = "zoom-in";
    thisContext.style.display = "block";
    let original = document.getElementById('myImageZoom');
    let magnified = document.getElementById('overlay');
    let style = magnified.style;
    let x = e.offsetX ? (e.offsetX) : e.pageX - thisContext.offsetLeft;
    let y = e.offsetY ? (e.offsetY) : e.pageY - thisContext.offsetTop;
    let imgWidth = original.width;
    let imgHeight = original.height;
    let xperc = ((x / imgWidth) * 100);
    let yperc = ((y / imgHeight) * 100);

    if (x > (0.01 * imgWidth)) {
      xperc += (0.15 * xperc);
    }// lets user scroll past right edge of image

    if (y >= (0.01 * imgHeight)) {
      yperc += (0.15 * yperc);
    }// lets user scroll past bottom edge of image

    style.backgroundPositionX = (xperc - 9) + '%';
    style.backgroundPositionY = (yperc - 9) + '%';

    style.left = (x - 100) + 'px';
    style.top = (y - 100) + 'px';

    let element = document.getElementById("overlay");
    // overlay css
    element.style.border = '4px solid whitesmoke';
    element.style.width = "100px";
    element.style.height = "100px";
    element.style.backgroundImage = 'url(' + this.state.base64Content + ')'
    element.style.backgroundRepeat = 'no-repeat';
    element.style.boxShadow = "0 5px 10px -2px rgba(0,0,0,0.3)";
    element.style.pointerEvents = "none";
    element.style.position = "absolute";
    element.style.opacity = 1;
    element.style.zIndex = 99;
    element.style.borderRadius = "100%";
    element.style.display = "block";
    element.style.transition = "opacity .2s !important";
    element.style.transform = "scale(2.5)";
  }

  zoomOut = () => {
    var element = document.getElementById("overlay");
    element.style.display = "none";
    let thisContext = document.getElementById('svgContainer');
    thisContext.style.cursor = "move";
    thisContext.style.display = "block";
  }

  pointLineDistance = (x, y, x1, y1, x2, y2) => {
    // x, y is your target point and x1, y1 to x2, y2 is line segment.
    let A = x - x1;
    let B = y - y1;
    let C = x2 - x1;
    let D = y2 - y1;
  
    let dot = A * C + B * D;
    let len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) 
        param = dot / len_sq;
  
    let xx, yy;
  
    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }
    let dx = x - xx;
    let dy = y - yy;
    return {distance: Math.sqrt(dx * dx + dy * dy), coOrdinate: {x:xx,y:yy}};
  }

  // handles the mouse move after starting any annotation
  mousemoveHandle(event) {
    console.log("Entered in mousemove handle")
    if((this.state.toolName === DRAW_TOOLS.POLYGON.NAME || this.state.toolName === DRAW_TOOLS.POLYLINE.NAME) && this.state.defaultClass !== "" && event.target.id !== "x-0"){
      const polygonRects = []
      const polygonNodes = this.state.toolName === DRAW_TOOLS.POLYGON.NAME ? document.getElementsByTagName("polygon") : document.getElementsByTagName("polyline");
      if (polygonNodes.length > 0){
        for (let i = 0; i < polygonNodes.length; i++) {
          if(polygonNodes[i].id !== "SvgjsPolyline1003" && polygonNodes[i].id) {
            polygonRects.push([...polygonNodes[i].points]);
          }
        }
      }
      let pointerCoOrdinates = [];
      polygonRects.forEach((rect, index) =>{
        const linesInPolygon = rect.map((arr,i,arrs)=> [arr,arrs[(i+1) >= arrs.length ? 0 : (i+1)]]);
        pointerCoOrdinates.push(...linesInPolygon);
      })
  
      const lineNearHoverPoint = pointerCoOrdinates.reduce((acc,arr,i)=>{
        const distanceBwPointLine = this.pointLineDistance(event.offsetX, event.offsetY, arr[0].x, arr[0].y, arr[1].x , arr[1].y);
        if (distanceBwPointLine.distance <= 3) {
          return [...acc,{line:distanceBwPointLine.coOrdinate,distance:distanceBwPointLine.distance}]
        }
        return acc;
      },[])
      if (lineNearHoverPoint.length > 0){
        const nearByPoint = lineNearHoverPoint[0].line;
        this.setState({
          hoverPoint:[nearByPoint.x, nearByPoint.y],
        });
      }else{
        this.setState({hoverPoint:[]});
      }
    }else{
      this.setState({hoverPoint:[]});
    }

    if (this.state.defaultClass) {
      var cH = document.getElementById("crosshair-h");
      var cV = document.getElementById("crosshair-v");
      if (cH && cV) {
        cH.style.top = event.offsetY + "px";
        cV.style.left = event.offsetX + "px";
      }
    }

    if (event) event.preventDefault();

    if (this.state.magnifierOption) {
      this.zoomIn(event);
      return false;
    }

    const [offsetX, offsetY] = this.getOffsets(event);
    const { toolName, rects, svgDrag, pointDrag, dragPoint, rectDrag, dragRect, circleDrag, lineDrag } = this.state;
    let { currentRect: currentRectangle, currentCircle, currentLine, freeHandSketch, selectedItem, annotationToBeErasedId } = this.state;
    const shape = this.state.rectShapeMap[this.state.dragRect];

    if (lineDrag || circleDrag) {
      if (this.state.dragEnable) {
        const rects = this.state.rects;
        const currentLine = rects[this.state.dragRect];
        currentLine.forEach((coord) => {
          coord[0] = this.getPoint(coord[0] + ((offsetX - dragPoint[0]) / this.state.imgWidth))
          coord[1] = this.getPoint(coord[1] + ((offsetY - dragPoint[1]) / this.state.imgHeight))
        })
        this.setState({ rects, dragPoint: [offsetX, offsetY] });
      }
    }
    else if (pointDrag) {
      if ((!this.state.dragEnable) || (this.state.dragEnable && shape === DRAW_TOOLS.RECTANGLE.NAME)) {
        const rects = this.state.rects;
        const currentRect = rects[this.state.dragRect];
        const newx = this.getPoint(offsetX / this.state.imgWidth);
        const newy = this.getPoint(offsetY / this.state.imgHeight);
        let oldx = currentRect[this.state.dragPoint][0];
        let oldy = currentRect[this.state.dragPoint][1];
        currentRect[this.state.dragPoint][0] = newx;
        currentRect[this.state.dragPoint][1] = newy;
        const shape = this.state.rectShapeMap[this.state.dragRect];
        if(shape === DRAW_TOOLS.POLYLINE.NAME) {
          currentRect[0][0] = currentRect[currentRect.length - 1][0];
          currentRect[0][1] = currentRect[currentRect.length - 1][1];
        }
        if (shape === DRAW_TOOLS.RECTANGLE.NAME) {
          for (let jindex = 0; jindex < currentRect.length; jindex++) {
            if (currentRect[jindex][0] === oldx) {
              currentRect[jindex][0] = newx
            }
            if (currentRect[jindex][1] === oldy) {
              currentRect[jindex][1] = newy
            }
          }
          rects[this.state.dragRect] = currentRect;
        }

        this.setState({ rects });
      }
    } else if (rectDrag && dragRect) {
      if (this.state.dragEnable) {
        const dx = (offsetX - dragPoint[0]) / this.state.imgWidth;
        const dy = (offsetY - dragPoint[1]) / this.state.imgHeight;
        const currentRect = rects[dragRect];
        for (let jindex = 0; jindex < currentRect.length; jindex++) {
          currentRect[jindex][0] = currentRect[jindex][0] + dx;
          currentRect[jindex][1] = currentRect[jindex][1] + dy;
        }
        if (!this.validPoints(currentRect)) {
          for (let jindex = 0; jindex < currentRect.length; jindex++) {
            currentRect[jindex][0] = currentRect[jindex][0] - dx;
            currentRect[jindex][1] = currentRect[jindex][1] - dy;
          }
        }
        rects[dragRect] = currentRect;
        this.setState({ rects, dragging: true, dragPoint: [offsetX, offsetY] });
      }
    } else if (
      (toolName === DRAW_TOOLS.RECTANGLE.NAME || toolName === DRAW_TOOLS.MAGICTOOL.NAME)
      && currentRectangle.length > 0
      && event.target.nodeName !== DRAW_TOOLS.CIRCLE.NAME) {

      const currentPoint = currentRectangle[0];
      currentRectangle = [];
      currentRectangle.push([currentPoint[0], currentPoint[1]]);
      currentRectangle.push([offsetX, currentPoint[1]]);
      currentRectangle.push([currentPoint[0], offsetY]);
      currentRectangle.push([offsetX, offsetY]);
      this.setState({ currentRect: currentRectangle });
    } else if (toolName === DRAW_TOOLS.POLYGON.NAME && this.state.currentRect.length > 0) {
      if (this.state.hoverPoint && this.state.hoverPoint.length > 0) {
        this.setState({
          currentPolyPoint: [this.state.hoverPoint[0], this.state.hoverPoint[1]]
        });
      } else {
        this.setState({
          currentPolyPoint: [offsetX, offsetY]
        });
      }
    } else if (toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && this.state.currentRect.length > 0 && selectedItem !== "svgContainer" && (selectedItem === annotationToBeErasedId ||
      (selectedItem.length === 3 && selectedItem[0] === 'x'
        && selectedItem[2] === '0'))) {
      this.setState({
        currentPolyPoint: [offsetX, offsetY]
      });
    } else if (toolName === DRAW_TOOLS.CIRCLE.NAME && currentCircle.length > 0) {
      let { currentCircle } = this.state;
      const circlePoints = []
      circlePoints.push([currentCircle[0][0], currentCircle[0][1]]);
      circlePoints.push([offsetX, offsetY]);
      this.setState({
        currentCircle: circlePoints
      });
    } else if (toolName === DRAW_TOOLS.LINE.NAME && currentLine.length > 0) {
      const linePoints = [];
      linePoints.push([currentLine[0][0], currentLine[0][1]]);
      linePoints.push([offsetX, offsetY]);
      this.setState({
        currentLine: linePoints
      });
    } else if (toolName === DRAW_TOOLS.POLYLINE.NAME && freeHandSketch.length > 0) {
      if (!this._drawing) {
        return;
      }
      freeHandSketch.push([offsetX / this.state.imgWidth, offsetY / this.state.imgHeight]);
      this.setState({
        freeHandSketch
      });
    } else if (svgDrag) {
      this._panner.panFrom(
        {
          x: this._startX,
          y: this._startY
        },
        {
          x: event.pageX,
          y: event.pageY
        });
      this._startX = event.pageX;
      this._startY = event.pageY;
      this.setState({
        translate: {
          x: this._panner.viewport.x,
          y: this._panner.viewport.y
        },
      });
    }
    return false;
  }

  pan(distancex, distancey) {
    console.log("Pan value: " + distancex, distancey);
    const degrees = [90, 180, 270, 360]
    let angle = degrees[90];
    var distanceX, distanceY;
    if (distancex === 0 && distancey === 5) {
      if (angle === 180) {
        distanceX = 5;
        distanceY = 0;
      } else if (angle === 270) {
        distanceX = 0;
        distanceY = -5;
      } else if (angle === 360) {
        distanceX = -5;
        distanceY = 0;
      } else {
        distanceX = 0;
        distanceY = 5;
      }
    } else if (distancex === 0 && distancey === -5) {
      if (angle === 180) {
        distanceX = -5;
        distanceY = 0;
      } else if (angle === 270) {
        distanceX = 0;
        distanceY = 5;
      } else if (angle === 360) {
        distanceX = 5;
        distanceY = 0;
      } else {
        distanceX = 0;
        distanceY = -5;
      }
    } else if (distancex === -5 && distancey === 0) {
      if (angle === 180) {
        distanceX = 0;
        distanceY = 5;
      } else if (angle === 270) {
        distanceX = 5;
        distanceY = 0;
      } else if (angle === 360) {
        distanceX = 0;
        distanceY = -5;
      } else {
        distanceX = -5;
        distanceY = 0;
      }
    } else {
      if (angle === 180) {
        distanceX = 0;
        distanceY = -5;
      } else if (angle === 270) {
        distanceX = -5;
        distanceY = 0;
      } else if (angle === 360) {
        distanceX = 0;
        distanceY = 5;
      } else {
        distanceX = 5;
        distanceY = 0;
      }
    }
    this._panner.panFrom(
      {
        x: 0,
        y: 0
      },
      {
        x: distanceX,
        y: distanceY
      });
    this._startX = 0;
    this._startY = 0;
    this.setState({
      translate: {
        x: this._panner.viewport.x,
        y: this._panner.viewport.y
      },
    });
  }

  scrollStop = (callback) => {
    if (!callback || typeof callback !== 'function') return;
    var isScrolling = -1;
    const canvas = this.canvas;
    if (canvas) {
      canvas.addEventListener('wheel', (event) => {
        if (isScrolling !== -1) {
          clearTimeout(isScrolling);
        }
        isScrolling = setTimeout(() => {
          callback(event);
        }, 66);
      }, false);
    }
  };

  _onWheel(event) {
    event.preventDefault();
    const [offsetX, offsetY] = this.getOffsets(event);
    console.log('_onWheel', event, offsetX, offsetY, event.deltaY);
    console.log('viewport onwheel', event.offsetX, event.offsetY, this._panner.viewport.x, this._panner.viewport.y, this.state.translate.x, this.state.translate.y);
    if (!event.deltaY) {
      this.zoom(offsetX, offsetY, -5, 1.20);
    } else {
      this.zoom(offsetX, offsetY, event.deltaY);
    }
  }

  loadImages() {
    const imgLoaded = this.state.imgLoaded;
    for (let index =
      this.props.currentIndex + 1;
      index < this.props.currentIndex + 2 &&
      index < this.props.hits.length; index++) {
      if (!(this.props.hits[index].data in imgLoaded) && this.timeout) {
        this.timeOut = null;
        const image1 = new Image(); // eslint-disable-line no-undef
        image1.src = this.props.hits[index].data;
        imgLoaded[this.props.hits[index].data] = true;
      }
    }
    this.setState({imgLoaded:imgLoaded})
  }

  resetScale() {
    console.log('reset scale');
    this.setState({ disableZoomAnim: false });
  }

  zoom(event_offsetX, event_offsetY, deltaY, zoomF) {
    console.log("Inside zoom function")
    let zoomFactor;
    if (deltaY < 0 && !zoomF) {
      zoomFactor = 1.07;
    } else if (deltaY > 0 && !zoomF) {
      zoomFactor = 0.93;
    } else if (zoomF) {
      zoomFactor = zoomF;
    }
    const mouseHoverMap = this.state.mouseHoverMap;
    if (!this.state.lockAnnotation)
      for (const k in mouseHoverMap) {
        if (mouseHoverMap.hasOwnProperty(k))
          mouseHoverMap[k] = false;
      }
    this._panner.zoomScale(zoomFactor, { x: event_offsetX, y: event_offsetY });
    this._startX = event_offsetX;
    this._startY = event_offsetY;
    let newZoomF = this._panner.scale / this.state.scale;
    this.state.translate = {
      x: this._panner.viewport.x,
      y: this._panner.viewport.y
    };
    let { currentRect } = this.state;
    if (currentRect && currentRect.length > 0) {
      for (let jindex = 0; jindex < currentRect.length; jindex++) {
        currentRect[jindex][0] = currentRect[jindex][0] * zoomFactor;
        currentRect[jindex][1] = currentRect[jindex][1] * zoomFactor;
      }
    }
    let zoomValue = 0.7440000000000001;
    newZoomF = newZoomF.toString()
    newZoomF = newZoomF <= zoomValue ? newZoomF.slice(0,3) : newZoomF;
    this.setState({ zoomFactor: newZoomF, disableZoomAnim: true })
    this.setState({
      imgWidth: this.state.imgWidth * zoomFactor,
      imgHeight: this.state.imgHeight * zoomFactor,
      scale: 1,
      currentRect
    });
    clearTimeout(this.resetScale);
    setTimeout(this.resetScale, 10);
  }

  renderCurrentFreeHandSketch() {
    const { entityColorMap } = this.props;
    const { defaultClass, freeHandSketch, opacity } = this.state;
    const color = this.selectedColor ? this.selectedColor : entityColorMap[defaultClass];
    if (freeHandSketch.length > 0) {
      let points = ``
      let pointArr = []
      const sw = 2;
      const radius = 0.5;
      freeHandSketch.map(point => {
        points = `${points} ${(point[0] * this.state.imgWidth)} ${(point[1] * this.state.imgHeight)},`
        pointArr.push(points);
      });
      let xPoint = pointArr[0].split(" ");
      let yPoint = xPoint[2].split(",");
      return (
        <g>
          <polyline
            points={points}
            style={{ fill: `transparent`, fillOpacity: `${opacity}`, stroke: color, strokeWidth: `${2 / this.state.scale}` }}
          />
          <circle
            cx={xPoint[1]}
            cy={yPoint[0]}
            r={(radius + 3) / this.state.scale}
            title="Click to close"
            onClick={this.savePolygon.bind(this, this.state.defaultClass)}
            style={{ cursor: this.props.isSampleData ? 'default' : 'pointer' }}
            stroke="white"
            strokeWidth={sw / this.state.scale}
            fill={color}
            >
          </circle>
        </g>
      );
    }
    return null
  }

  svgRender(currentRect) {
    const { entityColorMap } = this.props;
    const { defaultClass, selectedItem, annotationToBeErasedId, toolName } = this.state;
    const color = (!this.props.entityColorMap[this.state.defaultClass]) ? '#000000' : entityColorMap[defaultClass]
    const canvas = this.canvas;
    if (!canvas) {
      return (<div />);
    }
    if (toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && selectedItem !== "svgContainer" && (selectedItem === annotationToBeErasedId ||
      (selectedItem.length === 3 && selectedItem[0] === 'x'
        && selectedItem[2] === '0'))
      && currentRect.length === 1) {
      return (<circle cx={currentRect[0][0]} cy={currentRect[0][1]} r={2} stroke="white" fill="lightblue" strokeWidth={2} style={{ fill: `${this.props.entityColorMap[this.state.defaultClass]}`, fillOpacity: `${this.state.opacity}`, stroke: '#1ae04e', strokeWidth: `${1 / this.state.scale}` }} />);
    } else if (toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && selectedItem !== "svgContainer" && (selectedItem === annotationToBeErasedId ||
      selectedItem.length === 3 && selectedItem[0] === 'x'
      && selectedItem[2] !== '0') && currentRect.length > 1) {
      return (<polyline points={this.getPoints(currentRect)} style={{ fill: `transparent`, fillOpacity: `${this.state.opacity}`, stroke: color, strokeWidth: `${1 / this.state.scale}` }} />);
    }
    if ((toolName === DRAW_TOOLS.POLYGON.NAME) && currentRect.length === 1) {
      return (<circle cx={currentRect[0][0]} cy={currentRect[0][1]} r={2} stroke="white" fill="lightblue" strokeWidth={2} style={{ fill: `${this.props.entityColorMap[this.state.defaultClass]}`, fillOpacity: `${this.state.opacity}`, stroke: '#1ae04e', strokeWidth: `${1 / this.state.scale}` }} />);
    } else if ((toolName === DRAW_TOOLS.POLYGON.NAME) && (currentRect.length > 1 || this.state.currentRect.length > 1)) {
      return (<polyline points={this.getPoints(currentRect)} style={{ fill: `transparent`, fillOpacity: `${this.state.opacity}`, stroke: color, strokeWidth: `${1 / this.state.scale}` }} />);
    } else if (this.state.toolName === DRAW_TOOLS.RECTANGLE.NAME || this.state.toolName === DRAW_TOOLS.MAGICTOOL.NAME) {
      return this.renderCurrentRectangle();
    } else if (this.state.toolName === DRAW_TOOLS.CIRCLE.NAME) {
      return this.renderCurrentCircle();
    } else if (this.state.toolName === DRAW_TOOLS.LINE.NAME) {
      return this.renderCurrentLine();
    } else if (this.state.toolName === DRAW_TOOLS.POLYLINE.NAME) {
      return this.renderCurrentFreeHandSketch();
    }
  }

  EraserPolygon() {
    const { annotationToBeErasedId, rectShapeMap, rectColorMap } = this.state;
    const currentRect = this.state.currentRect;
    if (currentRect.length > 0) {
      const normPoints = [];
      for (let index = 0; index < currentRect.length; index++) {
        let xCord = currentRect[index][0];
        let yCord = currentRect[index][1];
        xCord = this.getPoint(xCord / this.state.imgWidth);
        yCord = this.getPoint(yCord / (this.state.imgHeight));
        normPoints.push([xCord, yCord]);
      }
      const annotationToBeErased = this.state.rects[annotationToBeErasedId];
      if (normPoints[1][0] < normPoints[0][0]) {
        normPoints.map((normPoint, index) => (annotationToBeErased.splice(index + 1, 0, normPoint)));
      }
      else if (normPoints[1][0] > normPoints[0][0]) {
        normPoints.reverse().map((normPoint, index) => (annotationToBeErased.splice(index + 1, 0, normPoint)));
      }
      annotationToBeErased.splice(normPoints.length + 1, 0, [normPoints[0][0], normPoints[0][1]]);
      annotationToBeErased.splice(normPoints.length + 2, 0, [annotationToBeErased[0][0], annotationToBeErased[0][1]]);
      rectShapeMap[annotationToBeErasedId] = DRAW_TOOLS.POLYGON.NAME;
      this.setState({
        currentRect: [],
        currentPolyPoint: [],

        selectedItem: annotationToBeErasedId,
        rectColorMap,
        rectShapeMap
      }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      });
    }
  }

  savePolygon(category) {
    const currentRect = this.state.currentRect;
    console.log("===========================>", this.state)
    if (currentRect.length > 1) {
      const rects = this.state.rects;
      const rectColorMap = this.props.hitEntities;
      const len = Object.keys(rects).length;
      const normPoints = [];
      for (let index = 0; index < currentRect.length; index++) {
        let xCord = currentRect[index][0];
        let yCord = currentRect[index][1];
        xCord = this.getPoint(xCord / this.state.imgWidth);
        yCord = this.getPoint(yCord / (this.state.imgHeight));
        normPoints.push([xCord, yCord]);
      }
      rects[len] = normPoints;
      const rectCatMap = this.state.rectCatMap;
      const rectShapeMap = this.state.rectShapeMap;
      const { labelGroupMap } = this.state;
      rectCatMap[len] = [category];
      rectShapeMap[len] = this.state.toolName;
      const hideLabelsMap = this.state.hideLabelsMap;
      hideLabelsMap[len] = false;
      rectColorMap[len] = this.selectedColor ? this.selectedColor : this.props.entityColorMap[rectCatMap[len]];
      this.selectedColor = null;
      const mouseHoverMap = this.state.mouseHoverMap;
      let defaultClass = this.state.defaultClass;

      if ((!this.state.keepEntitySelected && !this.props.continuousTagging)) {
        labelGroupMap[len] = this.state.defaultClass;
        defaultClass = '';
      } else {
        if (this.state.keepEntitySelected) {
          labelGroupMap[len] = "#" + this.state.defaultClass + "_" + this.state.groupCount;
        } else if (this.props.continuousTagging) {
          labelGroupMap[len] = this.state.defaultClass;
        }
      }
      this.setState({
        currentRect: [],
        currentPolyPoint: [],
        defaultClass,
        rects: rects,
        mouseHoverMap,
        rectCatMap,
        labelGroupMap,
        hideLabelsMap,
        rectColorMap
      },
        () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        });
    } else {
      let index = undefined;
      const mouseHoverMap = this.state.mouseHoverMap;
      for (const k in mouseHoverMap) {
        if (mouseHoverMap.hasOwnProperty(k) && mouseHoverMap[k] === true) {
          index = k;
        }
      }
      if (index) {
        const rectCatMap = this.state.rectCatMap;
        const rectColorMap = this.props.hitEntities;
        rectColorMap[index] = this.selectedColor ? this.selectedColor : this.props.entityColorMap[rectCatMap[index]];
        rectCatMap[index] = [category];
        this.setState({ rectCatMap, openMenuTool: false, rectColorMap }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        });
      }
    }
  }

  undoLast() {
    if (this.state.currentRect && this.state.currentRect.length > 0) {
      this.resetBackupState();
    }
    if (this.previous_rects && this.previous_rects.length > 0) {
      let lastIndex = Object.keys(this.state.rects).length;
      this.state.rectCatMap[lastIndex] = this.previous_rectCatMap;
      this.state.rects[lastIndex] = this.previous_rects;
      this.state.rectShapeMap[lastIndex] = this.previous_rectShapeMap;
      this.state.hideLabelsMap[lastIndex] = this.previous_hideLabelsMap;
      this.state.hideRectMap[lastIndex] = this.previous_hideRectMap;
      this.state.rectColorMap[lastIndex] = this.previous_rectColorMap;
      this.state.labelGroupMap[lastIndex] = this.previous_labelGroupMap;
      if (this.props.drawHandle) {
        this.props.drawHandle(this.state);
        this.resetBackupState();
      }
    }

    if (this.state.currentRect.length > 0) {
      const currentRect = this.state.currentRect;
      currentRect.splice(-1, 1);
      this.setState({ currentRect });
    }
    return false;
  }

  resetBackupState = () => {
    this.previous_rectCatMap = {};
    this.previous_rects = {};
    this.previous_rectShapeMap = {};
    this.previous_hideLabelsMap = {};
    this.previous_hideRectMap = {};
    this.previous_rectColorMap = {};
    this.previous_labelGroupMap = {};
  }

  undoLabelSelection = () => {
    if (this.props.continuousTagging) {
      this.props.continuousTaggingSelected();
    }
    this.setState({ defaultClass: '', keepEntitySelected: false });
  }

  getDeletePointContent = () => {
    const { deletePointContent } = this.state;
    if (deletePointContent !== '') {
      let pointArr = deletePointContent.split("_");
      return pointArr;
    }
  }

  deletePoint = () => {
    const { rects } = this.state;
    let pointArr = this.getDeletePointContent();
    let rectIndex = pointArr[1];
    let pointIndex = pointArr[2];
    let currentRect = rects[rectIndex];
    currentRect.splice(pointIndex, 1);
    rects[rectIndex] = currentRect;
    this.setState({ rects, deletePointContent: '', deleteConfirmPoint: false }, () => {
      if (this.props.drawHandle) {
        this.props.drawHandle(this.state);
      }
    });
  }

  deleteSelectedPoint = () => {
    const { deletePointContent, dragPoint, dragRect, rects, rectShapeMap } = this.state;
    const rect = rects[dragRect];
    let rectPoint = dragPoint - 1;
    let rectsLength = rect.length - 2;
    rectPoint = rectPoint < 0 ? rectsLength : rectPoint;
    let dragDeletePoint = this.state.deleteValue - 1;
    if (dragDeletePoint === rect.length - 2) {
      this.setState({reverseDeletePoint : 1})
    }
    let deletePoint = rectShapeMap[dragRect] + "_" + dragRect + "_" + rectPoint;
    if (deletePointContent !== '') {
      let pointArr = this.getDeletePointContent();
      if (pointArr[0] === "polygon" || 
        pointArr[0] === "polyline" || 
        pointArr[0] === "polygonml") {
        this.deletePoint()
        this.setState({deletePointContent: deletePoint, dragPoint : rectPoint})
      }
    }
  }

  clearPolygons = () => {
    if (this.props.fullScreen) {
      this.setState({ divToggle: true })
    } else {
      const that = this
      that.props.mergingGroupLabelButtonSwitch(false)
      const confirm = AntdModal.confirm;
      const content = POLY_ANNOTATOR_CONSTANTS.TAGGED_ITEM_MESSAGE
      confirm({
        title: 'Confirmation',
        content: content,
        okText: 'OK',
        cancelText: 'CANCEL',
        onOk() { that.confirmAlertOkTapped() },
        onCancel() { return false },
      });
    }
  }

  confirmAlertOkTapped = () => {
    const { saveRow } = this.props;
    const { rects } = this.state;
    if (Object.keys(rects).length > 0) {
      this.resetBackupState();
      this.setState({ currentRect: [], rects: [] });
      this.props.type === "notDone" ? (saveRow('clearAllImageAnnotations')) : (saveRow('clearAllDoneImageAnnotations'))
    }

    if (this.props.fullScreen) {
      this.setState({ divToggle: false })
    }
  }

  moveAllToDoneAction() {
    let path = window.location.pathname;
    let pid = path.split('/')
    moveAllToDone(pid[4], this.moveAllCallback)
  }

  normalizePoints(pointsArray, maxWidth, maxHeight) {
    let flattenedPoints = pointsArray.map(point => point[0]);
    let normalizedPoints = flattenedPoints.map(point => {
        let digitsX = this.countDigits(point[0]);
        let digitsY = this.countDigits(point[1]);
        let normalizedX = point[0] / maxWidth;
        let normalizedY = point[1] / maxHeight;
        return [Math.round(normalizedX * (Math.pow(10, digitsX))) / (Math.pow(10, digitsX)), Math.round(normalizedY * (Math.pow(10, digitsY))) / (Math.pow(10, digitsY))];
    });
    return normalizedPoints;
  }

  countDigits(value) {
      return Math.floor(Math.log10(Math.abs(value))) + 1;
  }

  convert4DTo3D(pointsArray1) {
    let arr3D = [];
    pointsArray1.forEach(point => {
      arr3D = arr3D.concat(point);
    });
    return arr3D;
  }

  removeMinimalDifferencePoints(points, threshold) {
    function calculateDistance(point1, point2) {
        const dx = point1[0] - point2[0];
        const dy = point1[1] - point2[1];
        return Math.sqrt(dx * dx + dy * dy);
    }
    let filteredPoints = [points[0]];
    points.forEach((point, i) => {
      if (i > 0) {
        const distance = calculateDistance(point, points[i - 1]);
        if (distance > threshold) {
          filteredPoints.push(point);
        }
      }
    });
    return filteredPoints;
}

  autoAnnotationCallback = (rectCatMap, labelGroupMap, mouseHoverMap, rectShapeMap, defaultClass, rectColorMap, error, response) => {
    console.log("called autoAnnotationCallback", response, error);
    if (error) {
      let errorMessage = JSON.parse(response.text).error;
      let jsonPart = errorMessage.substring(errorMessage.indexOf("{"));
      try {
        const parsedError = JSON.parse(jsonPart);
        const detail = parsedError.detail;
        console.log("detail", detail);
        this.setState({ fileLoad: false });
        showAlert(detail, ALERT_TYPES.ERROR);
      } catch (e) {
        console.error("Failed to parse JSON:", e);
        this.setState({ fileLoad: false });
        showAlert(jsonPart, "error");
      }
    } else {
      let pointsArrayContours = response.body.pythonResponse.contours;
      let pointsArray = this.convert4DTo3D(pointsArrayContours);
      const normalizedPoints = this.normalizePoints(pointsArray, this.state.imageNaturalWidth, this.state.imageNaturalHeight);
      const threshold = 0.0009;
      const filteredPoints = this.removeMinimalDifferencePoints(normalizedPoints, threshold);
      console.log("filteredPoints", filteredPoints, normalizedPoints);
      this.setState({ fileLoad: false });
      this.setState({
        rects: [
          ...this.state.rects,
          filteredPoints
        ],
        rectCatMap,
        labelGroupMap,
        mouseHoverMap,
        currentRect: [],
        rectShapeMap,
        defaultClass,
        openMenuTool: true,
        mouseDown: false,
        showList: true,
        rectColorMap,
      }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      });
    }
  }

  moveAllCallback(error, response) {
    console.log("called moveAllCallback")
    //  Redirects to overview page after move all to done is clicked
    if(response.statusCode === 200) {
      this.props.pushState({
        pathname:
          "/projects/" +
          this.props.params.orgName +
          "/" +
          this.props.params.projectName +
          "/" +
          this.props.params.projectId
      });
    } else {
      showAlert(TOO_LARGE_ENTITY, ALERT_TYPES.ERROR);
    }
  }

  showActionButtons = () => {
    let nextButton = 'Next';
    let prevButton = 'Previous';
    let skipButton = 'Move To Skip';
    let moveButton = 'Move To Done';
    let reQueue = 'Move To Requeue';
    let m_delete = 'Move To Delete';

    let nextButtonDisabled =
      this.props.currentIndex < 0 ||
      (this.props.hitScrollCompleted &&
        this.props.currentIndex > this.props.hits.length - 2)
      || (this.props.totalHitsCompleted &&
        this.props.currentIndex > this.props.hits.length - 2)
      || (this.props.currentIndex === this.props.totalFilteredHits - 1);

    if ("shortcuts" in this.props) {
      const shortcuts = this.props.shortcuts;
      if ("next" in shortcuts) {
        const combo = convertKeyToString(shortcuts.next);
        if (!nextButtonDisabled) {
          Mousetrap.bind(combo, this.props.saveTagAndNextRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ("previous" in shortcuts) {
        const combo = convertKeyToString(shortcuts.previous);
        if (this.props.currentIndex > 0) {
          Mousetrap.bind(combo, this.props.getBackTopreviousRow);
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('moveToDone' in shortcuts) {
        const combo = convertKeyToString(shortcuts.moveToDone);
        moveButton = 'Move To Done (' + combo + ')';
        Mousetrap.bind(combo, this.props.saveRow.bind(this, 'saveToDone'));
      }
    }
    const {totalFilteredHits, currentIndex, type} = this.props;
     let nextButtonValue;
    if(totalFilteredHits - 1 === currentIndex || (!type)){
      nextButtonValue = false;
    }
    else{
      nextButtonValue = true;
    }
    if(this.props.imageNiri) {
      let totalLastIndexval = this.props.totalFilteredHits / 2;
      if((type === "done") && currentIndex === (totalLastIndexval - 1) || this.props.hits.length === 1) {
        nextButtonDisabled = true;
        nextButtonValue = false
      }
      else if((type === "all" || type === "skipped" || type.includes("correct")) && currentIndex === (totalLastIndexval - 1) || this.props.hits.length === 1) {
        nextButtonDisabled = true;
        nextButtonValue = false
      }
    }

    return (
      <div className="e_buttons">
        <div title={prevButton} className={this.props.currentIndex <= 0 || this.props.buttonDisabled ? 'eventnone' : ''}>
          <Button icon size="mini" color="blue" onClick={this.props.getBackTopreviousRow} disabled={this.props.currentIndex <= 0 || this.props.buttonDisabled}>
            <Icon name="left arrow" />
          </Button>
        </div>
        {nextButtonDisabled === false && <div title={nextButton} className={nextButtonDisabled ? 'eventnone' : ''}>
          <Button icon size="mini" color="blue" onClick={this.props.saveTagAndNextRow} disabled={nextButtonValue === true ? nextButtonDisabled : !nextButtonDisabled}>
            <Icon name="right arrow" />
          </Button>
        </div>}
        {nextButtonDisabled === true && <div title={nextButton} className={!nextButtonDisabled ? 'eventnone' : ''}>
          <Button icon size="mini" color="blue" onClick={this.props.saveTagAndNextRow} disabled={nextButtonValue == true ? !nextButtonDisabled : nextButtonDisabled}>
            <Icon name="right arrow" />
          </Button>
        </div>}
        {(this.props.type === HIT_STATE_SKIPPED ||
          this.props.type === HIT_STATE_PRE_TAGGED ||
          this.props.type === HIT_STATE_REQUEUED) && (
            <div title={moveButton} className={"text-center " + (this.props.buttonDisabled ? 'eventnone' : '')} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
              <Button icon size="mini" color="grey" onClick={this.props.saveRow.bind(this, 'saveToDone')} disabled={this.props.buttonDisabled}>
                <Icon name="check" />
              </Button>
            </div>
          )}
        {(this.props.type === HIT_STATE_DONE ||
          this.props.type === HIT_STATE_PRE_TAGGED ||
          this.props.type === HIT_STATE_REQUEUED ||
          this.props.type === HIT_STATE_DELETED) && (
            <div title={skipButton} className={"text-center " + (this.props.buttonDisabled ? 'eventnone' : '')} style={{ display: displaySettingsByRole(getUidToken().roleId) }}>
              <Button icon size="mini" color="grey" onClick={this.props.moveToSkip.bind(this)} disabled={this.props.buttonDisabled}>
                <Icon name="share" />
              </Button>
            </div>
          )}

        {(this.props.type === HIT_STATE_SKIPPED ||
          this.props.type === HIT_STATE_DONE ||
          this.props.type === HIT_STATE_PRE_TAGGED ||
          this.props.type === HIT_STATE_REQUEUED) && (
            <div title={m_delete} className={"text-center " + (this.props.buttonDisabled ? 'eventnone' : '')}>
              <Button icon size="mini" color="grey" onClick={this.props.deleteItem.bind(this)} disabled={this.props.buttonDisabled}>
                <Icon name="trash alternate icon" />
              </Button>
            </div>
          )}
        {(this.props.type === HIT_STATE_DONE ||
          this.props.type === HIT_STATE_SKIPPED ||
          this.props.type === HIT_STATE_PRE_TAGGED ||
          this.props.type === HIT_STATE_DELETED) && (
            <div title={reQueue} className={"text-center " + (this.props.buttonDisabled ? 'eventnone' : '')}>
              <Button icon size="mini" color="grey" onClick={this.props.retagHit.bind(this)} disabled={this.props.buttonDisabled}>
                <Icon name="redo" />
              </Button>
            </div>
          )}
      </div>
    )
  }

  saveAnnotationMasking = () => {
    this.setState({ imagePropsChanged: false }, () => {
      this.props.saveAnnotation();
    })
  }

  toggleTool = (event, event1) => {
    console.log("IN Toogle Tool", event,event1)
    this.props.checkNewLabel();
    if (event1) event1.preventDefault();
    let value = event;
    if (event === 'shift') {
      if (this.props.taskType === IMAGE_SEGMENTATION) {
        if (this.state.toolName === 'polygon') value = 'line';
        else if (this.state.toolName === 'line') value = 'polyline';
        else if (this.state.toolName === 'polyline') value = 'rectangle';
        else if (this.state.toolName === 'rectangle') value = 'circle';
        else if (this.state.toolName === 'point') value = 'polygon';
      }
      if (this.props.taskType === IMAGE_MASKING) {
        if (this.state.toolName === 'polygon') value = 'line';
        else if (this.state.toolName === 'line') value = 'polyline';
        else if (this.state.toolName === 'polyline') value = 'rectangle';
        else if (this.state.toolName === 'rectangle') value = 'circle';
        else if (this.state.toolName === 'circle') value = 'polygon';
        else if (this.state.toolName === 'polygonml') value = 'polygon'
      }
    }
    if (value === 'move') {
      this.setState({ drawButton: false, canvasSet: false });
      console.log("Canvas", this.canvas)
      this.canvas.removeEventListener('mousedown', this.mousedownHandle);
      document.removeEventListener('mouseup', this.mouseupHandle);
    } else {
      this.setState({ drawButton: true, canvasSet: false, toolName: value });
    }
    return false;
  }

  getSize(obj) {
    let size = 0;
    let key = null;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  deSelectLabelAfterDelete = () => {
    let mouseHoverMap = this.state.mouseHoverMap;
    let keys = Object.keys(mouseHoverMap);
    keys.map((element) => {
      mouseHoverMap[element] = false;
    })
    this.setState({ mouseHoverMap })
  }

  removeShape(index) {
    const that = this
    const { selectedItem, mouseHoverMap, isAnnotationSelected } = this.state;
    const { fullScreen } = this.props;
    let selectedValue = mouseHoverMap[selectedItem]
    const confirm = AntdModal.confirm;
    if(!isAnnotationSelected && selectedValue) {
      if(!fullScreen){
        this.setState({ isAnnotationSelected : true })
        confirm({
          title: 'Confirmation',
          content: 'Are you sure you want to delete this annotation ?',
          okText: 'OK',
          cancelText: 'CANCEL',
          onOk() { that.removeAnnotationShape(index) },
          onCancel() { that.cancelDeletion() },
        });
      }
      else{
        this.setState({ remAnnotation: true, remAnnotationKey: index })
      }
    }
  }

  cancelDeletion() {
    this.setState({ isAnnotationSelected : false })
    return false
   }

  removeAnnotationShape(index) {
    let deleteIndex = index;
    const mouseHoverMap = this.state.mouseHoverMap;
    if (!deleteIndex) {
      deleteIndex = parseInt(this.state.selectedItem, 10);
    }
    if (deleteIndex === undefined) return;
    const rectCatMap = this.state.rectCatMap;
    const rectShapeMap = this.state.rectShapeMap;
    const hideLabelsMap = this.state.hideLabelsMap;
    const hideRectMap = this.state.hideRectMap;
    const rectColorMap = this.props.hitEntities;
    const rects = this.state.rects;
    const labelGroupMap = this.state.labelGroupMap;

    if (Array.isArray(deleteIndex)) {
      deleteIndex.map(index => {
        delete rectCatMap[Number(index)]
        delete rects[Number(index)];
        delete rectShapeMap[Number(index)];
        delete hideLabelsMap[Number(index)];
        delete hideRectMap[Number(index)];
        delete rectColorMap[Number(index)];
        delete labelGroupMap[Number(index)];
      })
    } else {
      this.previous_rectCatMap = rectCatMap[Number(deleteIndex)];
      delete rectCatMap[Number(deleteIndex)]

      this.previous_rects = rects[Number(deleteIndex)];
      delete rects[Number(deleteIndex)]

      this.previous_rectShapeMap = rectShapeMap[Number(deleteIndex)];
      delete rectShapeMap[Number(deleteIndex)];

      this.previous_hideLabelsMap = hideLabelsMap[Number(deleteIndex)];
      delete hideLabelsMap[Number(deleteIndex)];

      this.previous_hideRectMap = hideRectMap[Number(deleteIndex)];
      delete hideRectMap[Number(deleteIndex)];

      this.previous_rectColorMap = rectColorMap[Number(deleteIndex)];
      delete rectColorMap[Number(deleteIndex)];

      this.previous_labelGroupMap = labelGroupMap[Number(deleteIndex)];
      delete labelGroupMap[Number(deleteIndex)];
    }

    this.setState({
      rects, rectCatMap, mouseHoverMap, rectShapeMap, labelGroupMap, hideLabelsMap, hideRectMap, openMenuTool: false, rectColorMap,
      remAnnotation : false, removeAnnotation : false, isAnnotationSelected : false
    }, () => {
      this.deSelectLabelAfterDelete();
      if (this.props.drawHandle) {
        console.log("call drawhandle callback");
        this.props.resetHitLabelAction();
        this.props.drawHandle(this.state);
      }
    });
  }

  renderCurrentRectangle() {
    let x = this.state.imgWidth;
    let y = this.state.imgHeight;
    let xlg = 0;
    let ylg = 0;
    const color = this.selectedColor ? this.selectedColor : this.props.entityColorMap[this.state.defaultClass];
    for (let index = 0; index < this.state.currentRect.length; index++) {
      const currentPoint = this.state.currentRect[index];
      if (x > currentPoint[0]) {
        x = currentPoint[0];
      }
      if (y > currentPoint[1]) {
        y = currentPoint[1];
      }
      if (currentPoint[0] > xlg) {
        xlg = currentPoint[0];
      }
      if (currentPoint[1] > ylg) {
        ylg = currentPoint[1];
      }
    }
    let points = x + "," + ylg + " " + xlg + "," + ylg + " " + xlg + "," + y + " " + x + "," + y;
    return (
      <polygon points={points} style={{ fill: `${color}`, fillOpacity: `${this.state.opacity}`, stroke: color, strokeWidth: `${2 / this.state.scale}` }} />
    );
  }

  renderCurrentCircle() {
    const { taskType, entityColorMap } = this.props;
    const { defaultClass, currentCircle, opacity } = this.state;
    const color = this.selectedColor ? this.selectedColor : entityColorMap[defaultClass];
    const strokeColor = taskType === IMAGE_MASKING ? color : '#1ae04e'
    if (currentCircle.length > 0) {
      const x = currentCircle[0][0];
      const y = currentCircle[0][1];
      const rx = Math.abs(currentCircle[currentCircle.length - 1][0] - currentCircle[0][0]);
      const ry = Math.abs(currentCircle[currentCircle.length - 1][1] - currentCircle[0][1]);
      return (
        <circle
          cx={x}
          cy={y}
          r={rx > ry ? rx : ry}
          style={{ fill: `${color}`, fillOpacity: `${opacity}`, stroke: strokeColor, strokeWidth: `${2 / this.state.scale}` }}
        />
      );
    }
    return null
  }

  closeModal(index) {
    console.log('closemodal', this.state, index);
    this.setState({ openNote: false, noteIndex: -1, openHitLabel: false, deleteConfirm: false, openAttributeOption: false, attributeIndex: -1, selectedOptions : {} });
  }

  saveModal(index) {
    console.log('closemodal', this.state, index);
    this.setState({ openNote: false, noteIndex: -1 });
    if (this.props.drawHandle) {
      this.props.drawHandle(this.state);
    }
  }

  renderCurrentLine() {
    const { entityColorMap } = this.props;
    const { defaultClass, currentLine } = this.state;
    const color = this.selectedColor ? this.selectedColor : entityColorMap[defaultClass];
    if (currentLine.length > 1) {
      const x1 = currentLine[0][0];
      const y1 = currentLine[0][1];
      const x2 = currentLine[1][0];
      const y2 = currentLine[1][1];
      const points = `${x1} ${y1} , ${x2} ${y2}`;
      return (
        <polyline
          points={points}
          style={{ stroke: color, strokeWidth: `${2 / this.state.scale}` }}
        />
      );
    }
    return null
  }

  drawRegionsOnload = () => {
    let value = this.state.toolName
    this.setState({ drawButton: true, canvasSet: false, toolName: value });
    this.setState({ defaultClass: "Default" })
  }

  updateNewLabel = (type) => {
    if (type !== 'masking') {
      this.setState({ defaultClass: this.props.labelValue })
    }
    const { entities, entityColorMap, shortcuts } = this.props
    this.setState({ entities, entityColorMap, shortcuts })
  }

  showLabelPopup = () => {
    if (this.state.defaultClass === "" || this.state.defaultClass === undefined) {
      this.props.showLabelPopup();
    }
  }
  getKey = (map, val) => {
    return Object.keys(map).find(key => map[key] === val);
  }

  getAnnotationTitle = (id) => {
    const { rectCatMap } = this.state;
    if (rectCatMap.hasOwnProperty(id)) {
      return rectCatMap[id].join(", ");
    }
    return "";
  }

  resetRectCatMap = (selectedHitLabel, action) => {
    let indices = [];
    let updatedRectCatMap = { ...this.state.rectCatMap }; // Create a copy of the state

    Object.keys(updatedRectCatMap).forEach(index => {
        let currentData = [...updatedRectCatMap[index]]; // Create a copy of the current data array
        const labelIndex = currentData.indexOf(selectedHitLabel);

        if (labelIndex > -1) {
            currentData.splice(labelIndex, 1); // Remove the selected hit label
            updatedRectCatMap[index] = currentData; // Update the copy

            if (action === ACTIONS.UPDATE && !indices.includes(index)) {
                indices.push(index);
            }
        }
    });

    this.setState({
        rectCatMap: updatedRectCatMap, // Update the state with the new copy
        selectedHitLabelIndex: indices
    });
}

  addDynamicHitLabel = (action) => {
    this.setState({ selectedHitLabelIndex: [] })
    const { hitLabel, hitKey, selectedHitLabel } = this.state;
    if (hitLabel && hitLabel.length > 0) {
      switch (action) {
        case ACTIONS.UPDATE:
          this.resetRectCatMap(selectedHitLabel, ACTIONS.UPDATE);
          this.props.saveHitLabel(selectedHitLabel, hitLabel, hitKey, action);
          break;
        case ACTIONS.DELETE:
          this.resetRectCatMap(hitLabel, ACTIONS.DELETE);
          this.props.saveHitLabel(hitLabel, null, hitKey, action);
          break;
        case ACTIONS.ADD:
          this.props.saveHitLabel(hitLabel, null, hitKey, action);
          break;
        default:
          console.log("Action not match")
      }
      const rectColorMap = this.props.hitEntities;
      this.setState({ openHitLabel: false, hitLabel: '', rectColorMap, deleteConfirm: false }, () => {
        setTimeout(() => {
          this.props.drawHandle(this.state);
        }, 1000)
      })
    } else {
      showAlert(MESSAGES.HIT_LABEL_EMPTY_MESSAGE, ALERT_TYPES.ERROR);
    }
  }

  render() {
    console.log('this.state=====>', this.state)
    console.log('this.props=====>', this.props)
    let hideLoadedItems = false
    const { disableZoomAnim,
      lineDrag,
      circleDrag,
      pointDrag,
      rectDrag, dragRect,
      svgDrag, defaultClass, qaCheck } = this.state;

    if (qaCheck || svgDrag || lineDrag || pointDrag || circleDrag || (rectDrag && dragRect) || disableZoomAnim || defaultClass !== '') {
      hideLoadedItems = true;
    }

    console.log("default class",this.state.defaultClass)
    const canvasStyles = {
      zIndex: this.state.mouseDown ? 4 : 2,
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      width: this.state.imgWidth,
      height: this.state.imgHeight,
      transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`,
      cursor: this.state.defaultClass ? 'crosshair' : 'move',
    };

    const selectCategory = (event1) => {
      if (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) {
        const mouseHoverMap = this.state.mouseHoverMap;
        if (this.state.currentRect && this.state.currentRect.length > 0) {
          this.savePolygon(event1);
        } else {
          if (this.state.keepEntitySelected && this.state.defaultClass !== '' && this.state.defaultClass !== event1) {
            this.setState({ defaultClass: event1, keepEntitySelected: false });
            this.toggleTool(this.state.toolName,event1);
          } else if (this.state.defaultClass !== event1) {
            this.setState({ defaultClass: event1 });
            this.toggleTool(this.state.toolName);
          } else {
            this.setState({ defaultClass: '' });
          }
        }
        if (!this.state.lockAnnotation) {
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              mouseHoverMap[k] = false;
            }
          }
        }
        this.setMagnifier(false);
        return false;
      }
    };

    const removeRect = (event) => {
      console.log('remove rect', event.target.id, this.state);
      const index = event.target.id;
      const { labelGroupMap } = this.state;
      const that = this
      if (labelGroupMap[index]) {
        if (labelGroupMap[index].startsWith("#")) {
          let deleteArr = getByValue(labelGroupMap, labelGroupMap[index])
          this.removeShape(deleteArr);
        } else {
          for (const data of this.state.rectCatMap[index]) {
            for (const elem of this.props["hitLabels"]) {
              if (data === elem) {
                that.setState({ hitLabel: data }, () => { that.addDynamicHitLabel("delete"); })
              }
            }
          }
          const confirm = AntdModal.confirm;
          if(!this.props.fullScreen){
            confirm({
              title: 'Confirmation',
              content: 'Are you sure you want to delete this annotation ?',
              okText: 'OK',
              cancelText: 'CANCEL',
              onOk() { that.removeAnnotationShape(index) },
              onCancel() { return false },
            });
          }
          else{
            that.removeAnnotationShape(index)
            delete labelGroupMap[index]
            this.setState({ removeAnnotation: false })
          }
        }
      }
    };

    // using a generator function
    function* entries(obj) {
      for (const key of Object.keys(obj)) {
        yield [key, obj[key]];
      }
    }

    const renderRects = () => (
      this.state.rects.map((rect, index) => {
        const { opacity, hideRectMap, selectedItem, annotationToBeErasedId } = this.state;
        if (index in hideRectMap && hideRectMap[index] === true) {
          return (<div />);
        }
        const entity = this.props.rectCatMap[index];
        if ((!(entity in this.state.hideLabelsMap)) || (entity in this.state.hideLabelsMap && this.state.hideLabelsMap[entity] === false)) {
          const lineColor = this.props.entityColorMap[entity];
          const strokeColor = lineColor
          const shape = this.state.rectShapeMap[index];
          let sw = 1 / this.state.scale;
          let cursor = '';
          if (this.state.currentRect.length === 0) {
            cursor = this.props.isSampleData ? 'default' : 'pointer';
          }
          if (this.state.mouseHoverMap && index in this.state.mouseHoverMap && this.state.mouseHoverMap[index]) {
            sw = 3 / this.state.scale;
            cursor = 'alias';
          }
          if (this.state.defaultClass) {
            cursor = 'crosshair';
          }
          if (!this.state.dragEnable) {
            cursor = "pointer";
          }
          if (!shape || shape === DRAW_TOOLS.POLYGON.NAME || shape === DRAW_TOOLS.MAGICTOOL.NAME || (shape === DRAW_TOOLS.ERASERPOLYGON.NAME && selectedItem !== "svgContainer" && selectedItem === annotationToBeErasedId)) {
            let points = this.getDecPoints(rect, DRAW_TOOLS.POLYGON.NAME);
            if (!this.canvas) {
              this.state.canvasSet = false;
              return (<div />);
            }
            if (rect.length === 1) {
              const pointSplits = points.split(',');
              return (
                <circle
                  cx={pointSplits[0]}
                  cy={pointSplits[1]}
                  r="2"
                  stroke={lineColor}
                  strokeWidth={sw}
                  fill={lineColor}
                />
              );
            }
            let pointArray = points.split(" ");
            let pointSubArray = pointArray.map((e) => {
              return e.split(",").map(e1 => {
                return parseInt(e1)
              })
            })
            let x = pointSubArray.map(e => {
              return e[0]
            })
            let y = pointSubArray.map(e => {
              return e[1]
            })
            let xP = Math.min(...x) + (Math.max(...x) - Math.min(...x)) / 2;
            let yP = Math.min(...y) + (Math.max(...y) - Math.min(...y)) / 2;

            return (
              (this.props.opacityValue && this.props.taskType === IMAGE_SEGMENTATION) ?
                <g>
                  <polygon
                    id={index}
                    key={index}
                    points={points}
                    style={{ fill: `${lineColor}`, cursor: `${cursor}`, fillOpacity: `${opacity}`, stroke: strokeColor, strokeWidth: `${sw}` }}>
                    <title>{this.getAnnotationTitle(index)}</title>
                  </polygon>
                  {
                    this.state.qaCheck &&
                    <rect x={((xP * 2) - (this.getAnnotationTitle(index).length === 1 ? 20 : this.getAnnotationTitle(index).length * 10)) / 2} y={yP} width={this.getAnnotationTitle(index).length == 1 ? 20 : this.getAnnotationTitle(index).length * 10} height="20" fill="#FFFFFF" />
                  }
                  {
                    this.state.qaCheck &&
                    <text x={xP + 5} y={yP + 15} dominant-baseline="middle" text-anchor="middle" fill="#000000">{this.getAnnotationTitle(index)}</text>
                  }
                </g> :
                <g>
                  <polygon
                    id={index}
                    key={index}
                    className="polygon-p"
                    points={points}
                    style={{ fill: `${lineColor}`, cursor: `${cursor}`, fillOpacity: `${opacity}`, stroke: strokeColor, strokeWidth: `${sw}` }}>
                    <title>{this.getAnnotationTitle(index)}</title>
                  </polygon>
                  {
                    this.state.qaCheck &&
                    <rect x={((xP * 2) - (this.getAnnotationTitle(index).length === 1 ? 20 : this.getAnnotationTitle(index).length * 10)) / 2} y={(((yP * 2) - 20) / 2)} width={this.getAnnotationTitle(index).length == 1 ? 20 : this.getAnnotationTitle(index).length * 10} height="20" fill="#FFFFFF" />
                  }
                  {
                    this.state.qaCheck &&
                    <text x={(((xP * 2) - (this.getAnnotationTitle(index).length === 1 ? 20 : this.getAnnotationTitle(index).length * 10)) / 2) + 5} y={(((yP * 2) - 20) / 2) + 15} dominant-baseline="middle" text-anchor="middle" fill="#000000">{this.getAnnotationTitle(index)}</text>
                  }
                </g>
            );
          } else if (shape === DRAW_TOOLS.RECTANGLE.NAME) {
            const points = this.getDecPoints(rect, DRAW_TOOLS.RECTANGLE.NAME);
            let pointArray = points.split(" ");
            let pointSubArray = pointArray.map((e) => {
              return e.split(",").map(e1 => {
                return parseInt(e1)
              })
            })
            let x = pointSubArray.map(e => {
              return e[0]
            })
            let y = pointSubArray.map(e => {
              return e[1]
            })
            let xP = (Math.max(...x) - Math.min(...x)) / 2 + Math.min(...x);
            let yP = (Math.max(...y) - Math.min(...y)) / 2 + Math.min(...y);
            return (
              (this.props.opacityValue && this.props.taskType === IMAGE_SEGMENTATION) ?
                <g>
                  <polygon
                    id={index}
                    key={index}
                    className="polygon-p"
                    points={points}
                    style={{ fill: `${lineColor}`, cursor: `${cursor}`, fillOpacity: `${0.40}`, stroke: strokeColor, strokeWidth: `${sw}` }}>
                    <title>{this.getAnnotationTitle(index)}</title>
                  </polygon>
                  {
                    this.state.qaCheck &&
                    <rect x={xP} y={yP} width={this.getAnnotationTitle(index).length == 1 ? 20 : this.getAnnotationTitle(index).length * 10} height="20" fill="#FFFFFF" />
                  }
                  {
                    this.state.qaCheck &&
                    <text x={xP + 5} y={yP + 15} dominant-baseline="middle" text-anchor="middle" fill="#000000">{this.getAnnotationTitle(index)}</text>
                  }</g> :
                <g>
                  <polygon
                    id={index}
                    key={index}
                    points={points}
                    className="polygon-p"
                    style={{ fill: `${lineColor}`, cursor: `${cursor}`, fillOpacity: `${opacity}`, stroke: strokeColor, strokeWidth: `${sw}` }}>
                    <title>{this.getAnnotationTitle(index)}</title>
                  </polygon>
                  {
                    this.state.qaCheck &&
                    <rect x={((xP * 2) - (this.getAnnotationTitle(index).length === 1 ? 20 : this.getAnnotationTitle(index).length * 10)) / 2} y={(((yP * 2) - 20) / 2)} width={this.getAnnotationTitle(index).length == 1 ? 20 : this.getAnnotationTitle(index).length * 10} height="20" fill="#FFFFFF" />
                  }
                  {
                    this.state.qaCheck &&
                    <text x={(((xP * 2) - (this.getAnnotationTitle(index).length === 1 ? 20 : this.getAnnotationTitle(index).length * 10)) / 2) + 5} y={(((yP * 2) - 20) / 2) + 15} dominant-baseline="middle" text-anchor="middle" fill="#000000">{this.getAnnotationTitle(index)}</text>
                  }
                </g>
            );
          } else if (shape === DRAW_TOOLS.CIRCLE.NAME) {
            const currentCircle = rect;
            const x = currentCircle[0][0] * this.state.imgWidth;
            const y = currentCircle[0][1] * this.state.imgHeight;
            const rx = Math.abs((currentCircle[currentCircle.length - 1][0] - currentCircle[0][0]) * this.state.imgWidth);
            const ry = Math.abs((currentCircle[currentCircle.length - 1][1] - currentCircle[0][1]) * this.state.imgHeight);
            let xP = (x + rx / 2)
            let yP = (y + ry / 2)
            return (
              (this.props.opacityValue && this.props.taskType === IMAGE_SEGMENTATION) ?
                <g>
                  <circle
                    id={index}
                    key={index}
                    cx={x}
                    cy={y}
                    r={(currentCircle[2]) ? currentCircle[2].radius : rx > ry ? rx : ry}
                    style={{ fill: `${lineColor}`, cursor: `${cursor}`, fillOpacity: `${0.40}`, stroke: strokeColor, strokeWidth: `${2 / this.state.scale}` }}>
                    <title>{this.getAnnotationTitle(index)}</title>
                  </circle>
                  {
                    this.state.qaCheck &&
                    <rect x={xP} y={yP} width={this.getAnnotationTitle(index).length == 1 ? 20 : this.getAnnotationTitle(index).length * 10} height="20" fill="#FFFFFF" />
                  }
                  {
                    this.state.qaCheck &&
                    <text x={xP + 5} y={yP + 15} dominant-baseline="middle" text-anchor="middle" fill="#000000">{this.getAnnotationTitle(index)}</text>
                  }
                </g> :
                <g>
                  <circle
                    id={index}
                    key={index}
                    cx={x}
                    cy={y}
                    r={(currentCircle[2]) ? currentCircle[2].radius : rx > ry ? rx : ry}
                    style={{ fill: `${lineColor}`, cursor: `${cursor}`, fillOpacity: `${opacity}`, stroke: strokeColor, strokeWidth: `${2 / this.state.scale}` }}>
                    <title>{this.getAnnotationTitle(index)}</title>
                  </circle>
                  {
                    this.state.qaCheck &&
                    <rect x={xP} y={yP} width={this.getAnnotationTitle(index).length == 1 ? 20 : this.getAnnotationTitle(index).length * 10} height="20" fill="#FFFFFF" />
                  }
                  {
                    this.state.qaCheck &&
                    <text x={xP + 5} y={yP + 15} dominant-baseline="middle" text-anchor="middle" fill="#000000">{this.getAnnotationTitle(index)}</text>
                  }
                </g>
            );
          } else if (shape === DRAW_TOOLS.LINE.NAME) {
            const lineVertex = this.getDecPoints(rect, DRAW_TOOLS.LINE.NAME);
            let pointArray = lineVertex.split(" ");
            let pointSubArray = pointArray.map((e) => {
              return e.split(",").map(e1 => {
                return parseInt(e1)
              })
            })

            let xP = Math.abs(pointSubArray[0][0] - pointSubArray[1][0])
            let yP = Math.abs(pointSubArray[0][1] - pointSubArray[1][1])

            return (
              <g>
                <polyline
                  id={index}
                  key={index}
                  points={lineVertex}
                  style={{ cursor: `${cursor}`, stroke: strokeColor, strokeWidth: `${2 / this.state.scale}` }}
                >
                  <title>{this.getAnnotationTitle(index)}</title>
                </polyline>
              </g>
            );
          } else if (shape === DRAW_TOOLS.POLYLINE.NAME) {
            const freeHandSketch = rect;
            let lineVertex = ``
            freeHandSketch.map(point => {
              lineVertex = `${lineVertex} ${(point[0] * this.state.imgWidth)} ${(point[1] * this.state.imgHeight)},`
            });
            let pointArray = lineVertex.split(" ");
            let pointSubArray = pointArray.map((e) => {
              return e.split(",").map(e1 => {
                return parseInt(e1)
              })
            })
            let x = pointSubArray.map(e => {
              return e[0]
            })
            let y = pointSubArray.map(e => {
              return e[1]
            })
            let xP = Math.max(...x)
            let yP = Math.max(...y)
            return (
              <g>
                <polyline
                  id={index}
                  key={index}
                  points={lineVertex}
                  style={{ fill: `${lineColor}`, fillOpacity: `${opacity}`, cursor: `${cursor}`, stroke: strokeColor, strokeWidth: `${2 / this.state.scale}` }}>
                  <title>{this.getAnnotationTitle(index)}</title>
                </polyline>
              </g>
            );
          }
        }
      })
    );

    const renderPoints = () => (
      this.state.rects.map((rect, index) => {
        console.log('render rects renderPoints', rect);
        if (index in this.state.hideRectMap && this.state.hideRectMap[index] === true) {
          return (<div />);
        }
        const pointArrs = [];
        const entity = this.props.rectCatMap[index];
        const lineColor = this.props.entityColorMap[entity];
        const shape = this.state.rectShapeMap[index];
        let sw = 1;
        let radius = 0.5;
        let style = {};
        if (shape === 'point') {
          radius = 5;
          sw = 0;
        }
        let circle_c = '';
        if ((!(entity in this.state.hideLabelsMap))
          || (entity in this.state.hideLabelsMap
            && this.state.hideLabelsMap[entity] === false)) {
          if (this.state.currentRect.length === 0) {
            circle_c = 'circle_grab';
          }
          if ((this.state.mouseHoverMap
            && index in this.state.mouseHoverMap
            && this.state.mouseHoverMap[index])
            || shape === 'point') {
            if (shape === 'point') {
              radius = 4;
            } else {
              radius = 3;
            }
            for (let jindex = 0; jindex < rect.length; jindex++) {
              const id = index + '--' + jindex;
              const cx = (rect[jindex][0] * (this.state.imgWidth));
              const cy = (rect[jindex][1] * (this.state.imgHeight));
              pointArrs.push(
                <circle
                  id={id}
                  className={circle_c}
                  style={style}
                  cx={cx}
                  cy={cy}
                  r={radius}
                  stroke="white"
                  strokeWidth={sw}
                  fill={lineColor}>
                  {shape === 'point' && <title>{this.getAnnotationTitle(id.split("--")[0])}</title>}
                </circle>
              );
              if (rect.length == 4) {
                let x1c,x2c,y1c,y2c;
                if (jindex == 0) {
                  x1c = ((rect[0][0] * (this.state.imgWidth)) + (rect[1][0] * (this.state.imgWidth))) / 2
                  x2c = ((rect[2][0] * (this.state.imgWidth)) + (rect[3][0] * (this.state.imgWidth))) / 2
                  y1c = ((rect[0][1] * (this.state.imgHeight)) + (rect[1][1] * (this.state.imgHeight))) / 2
                  y2c = ((rect[2][1] * (this.state.imgHeight)) + (rect[3][1] * (this.state.imgHeight))) / 2
                } else if (jindex == 1) {
                  x1c = ((rect[0][0] * (this.state.imgWidth)) + (rect[3][0] * (this.state.imgWidth))) / 2
                  x2c = ((rect[2][0] * (this.state.imgWidth)) + (rect[1][0] * (this.state.imgWidth))) / 2
                  y1c = ((rect[0][1] * (this.state.imgHeight)) + (rect[3][1] * (this.state.imgHeight))) / 2
                  y2c = ((rect[2][1] * (this.state.imgHeight)) + (rect[1][1] * (this.state.imgHeight))) / 2
                }
                if (rect[0][0] == rect[3][0] && rect[0][1] == rect[1][1]) {
                  const points = `${x1c} ${y1c},${x2c} ${y2c}`;
                  const color = "#FFFFFF"
                  pointArrs.push(
                    <polyline
                      points={points}
                      style={{ stroke: color, strokeWidth: this.props.stroke_width }}
                    />
                  )
                }
              }
            }
          }
          return (
            <g>
              {pointArrs}
            </g>
          );
        }
      })
    );

    const getDistance = (point1, point2) => {
      console.log('getDistance', point1, point2);
      return Math.sqrt(Math.pow((point1[0] * this.state.imgWidth - point2[0] * this.state.imgWidth), 2) + Math.pow((point1[1] * this.state.imgHeight - point2[1] * this.state.imgHeight), 2)) * this.state.scale;
    };

    const renderHalfPoints = () => (
      this.state.rects.map((rect, index) => {
        console.log('render rects renderHalfPoints ', rect);
        if (index in this.state.hideRectMap && this.state.hideRectMap[index] === true) {
          return (<div />);
        }
        const pointArrs = [];
        const entity = this.props.rectCatMap[index];
        const mouseHoverMap = this.state.mouseHoverMap;
        const shape = this.state.rectShapeMap[index];
        let radius = 0.5;
        let circle_c = '';
        if ((!shape || shape === DRAW_TOOLS.POLYGON.NAME|| shape === DRAW_TOOLS.MAGICTOOL.NAME  || shape === DRAW_TOOLS.POLYLINE.NAME) &&
          (!this.state.pointDrag && (!this.state.rectDrag || index !== this.state.dragRect) && mouseHoverMap[index])) {
          if ((!(entity in this.state.hideLabelsMap)) || (entity in this.state.hideLabelsMap && this.state.hideLabelsMap[entity] === false)) {
            if (this.state.currentRect.length === 0) {
              circle_c = 'circle_grab';
            }
            if (this.state.mouseHoverMap && index in this.state.mouseHoverMap && this.state.mouseHoverMap[index]) {
              radius = 4 / this.state.scale;
            }
            for (let jindex = 0; jindex < rect.length; jindex++) {
              let nextIndex = jindex + 1;
              if (jindex === rect.length - 1) {
                nextIndex = 0;
              }
              const distance = getDistance(rect[jindex], rect[nextIndex]);
              const id = index + '--' + jindex + '--' + nextIndex;
              if (distance.toFixed(2) >= 30) {
                const x = (rect[jindex][0] + rect[nextIndex][0]) / 2;
                const y = (rect[jindex][1] + rect[nextIndex][1]) / 2;
                pointArrs.push(
                  <circle
                    style={{ fillOpacity: '0.7' }}
                    id={id}
                    className={circle_c}
                    cx={(x * this.state.imgWidth)}
                    cy={(y * (this.state.imgHeight))}
                    r={radius} stroke="white" fill="white"
                  />
                );
              }
            }
            return (
              <g>
                {pointArrs}
              </g>
            );
          }
        }
      })
    );

    const renderCurrentPoints = (vertices) => {
      const pointArrs = [];
      let lineColor = 'lightblue';
      if (this.state.defaultClass) {
        lineColor = this.selectedColor ? this.selectedColor : this.props.entityColorMap[this.state.defaultClass];
      }
      const sw = 2;
      const radius = 0.5;
      const rect = vertices;
      for (let jindex = 0; jindex < rect.length; jindex++) {
        const id = 'x' + '-' + jindex;
        if (jindex === 0 &&
          (this.state.toolName === DRAW_TOOLS.POLYGON.NAME || this.state.toolName === DRAW_TOOLS.LINE.NAME)) {
          pointArrs.push(
            <circle
              id={id}
              cx={(rect[jindex][0])}
              cy={(rect[jindex][1])}
              title="Click to close"
              onClick={this.savePolygon.bind(this, this.state.defaultClass)}
              style={{ cursor: this.props.isSampleData ? 'default' : 'pointer' }}
              r={(radius + 3) / this.state.scale}
              stroke="white"
              strokeWidth={sw / this.state.scale}
              fill={lineColor}
            />
          );
        } else if (jindex === 0 && this.state.toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && this.state.selectedItem !== "svgContainer" && (this.state.selectedItem === this.state.annotationToBeErasedId ||
          (this.state.selectedItem.length === 3 && this.state.selectedItem[0] === 'x'
            && this.state.selectedItem[2] === '0'))) {
          pointArrs.push(
            <circle
              id={id}
              cx={(rect[jindex][0])}
              cy={(rect[jindex][1])}
              title="Click to close"
              onClick={this.EraserPolygon.bind()}
              style={{ cursor: this.props.isSampleData ? 'default' : 'pointer' }}
              r={(radius + 3) / this.state.scale}
              stroke="white"
              strokeWidth={sw / this.state.scale}
              fill={lineColor}
            />
          );
        } else if (this.state.toolName === DRAW_TOOLS.POLYGON.NAME || (this.state.toolName === DRAW_TOOLS.ERASERPOLYGON.NAME && this.state.selectedItem !== "svgContainer" && (this.state.selectedItem === this.state.annotationToBeErasedId ||
          (this.state.selectedItem.length === 3 && this.state.selectedItem[0] === 'x'
            && this.state.selectedItem[2] === '0')))
          || this.state.toolName === DRAW_TOOLS.LINE.NAME) {
          pointArrs.push(
            <circle
              id={id}
              cx={(rect[jindex][0])}
              cy={(rect[jindex][1])}
              r={radius / this.state.scale}
              stroke="white"
              strokeWidth={sw / this.state.scale}
              fill={lineColor}
            />
          );
        }
      }
      return (
        <g>
          {pointArrs}
        </g>
      );
    };
    const toggleEyeAllStatus = (value) => {
      const handler = event => {
        event.stopPropagation();
        event.preventDefault();
        return false;
      };
      const hideRectMap = this.state.hideRectMap;
      const { labelGroupMap } = this.state;
      for (let property in labelGroupMap) {
        const elementData = labelGroupMap[property];
        let hideLabelsMap;
        if (value === true) {
          document.addEventListener(elementData, handler, true)
          hideLabelsMap = this.state.hideLabelsMap;
          hideLabelsMap[elementData] = value;
          this.setState({ hideLabelsMap });
        } else {
          document.removeEventListener(elementData, handler, true);
          hideLabelsMap = this.state.hideLabelsMap;
          hideLabelsMap[elementData] = value;
          this.setState({ hideLabelsMap });
        }

        if (labelGroupMap[property]) {
          if (labelGroupMap[property].startsWith("#")) {
            let hideArr = getByValue(labelGroupMap, labelGroupMap[property])
            hideArr.map(element => {
              hideRectMap[element] = value;
            })
          } else {
            hideRectMap[property] = value;
          }
        }
        this.setState({ hideRectMap });
      }
      if (value == true) {
        this.setState({ toggleEyeStatusAll: false })
      } else {
        this.setState({ toggleEyeStatusAll: true })
      }
    }

    const toggleEyeStatus = (key, value, event) => {
      event.stopPropagation();
      const hideLabelsMap = this.state.hideLabelsMap;
      hideLabelsMap[key] = value;
      this.setState({ hideLabelsMap });
    };

    const setImageHover = (key, value, event) => {
      console.log("setImageHover", key, value, event)
      event.stopPropagation();
      const imageHoverMap = this.state.imageHoverMap;
      imageHoverMap[key] = value;
      this.setState({ imageHoverMap });
    };

    function dynamicSorting(a, b) {
      console.log("dynamic sorting",a,b)
      if (a && b) {
        a = a.toString()
        b = b.toString()
        if (a.startsWith("#")) {
          a = a.substring(1)
        } else if (b.startsWith("#")) {
          b = b.substring(1)
        }
        let isNumA = /^\d+$/.test(a);
        let isNumB = /^\d+$/.test(b);
        if (isNumA === true && isNumB === true) {
          a = parseInt(a)
          b = parseInt(b)
          return a - b;
        } else {
          a = a.toString();
          b = b.toString();
          return a.localeCompare(b);
        }
      }
    }

    const getEntitiesWithoutHitLabel = () => {
      let labelMap = [];
      let entityColorMap = {};
      if (this.props.entityColorMap) {
        if (this.props.entities > 0 && this.props.hitEntities > 0) {
          entityColorMap = { ...this.props.hitEntities }
        } else {
          entityColorMap = { ...this.props.entityColorMap };
        }
        labelMap = Object.keys(entityColorMap);
      }
      let entities = [...this.props.entities];
      labelMap.map((element) => {
        if (!entities.includes(element)) {
          delete entityColorMap[element];
        }
      })
      return entityColorMap;
    }

    function dynamicSort(property) {
      console.log("dynamic sort", property)
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return (a, b) => {
        if (a[property] && b[property]) {
          a[property] = a[property].toString()
          b[property] = b[property].toString()
          if (a[property].startsWith("#")) {
            a[property] = a[property].substring(1)
          } else if (b[property].startsWith("#")) {
            b[property] = b[property].substring(1)
          }
          let isNumA = /^\d+$/.test(a[property]);
          let isNumB = /^\d+$/.test(b[property]);
          if (isNumA === true && isNumB === true) {
            a[property] = parseInt(a[property])
            b[property] = parseInt(b[property])
            return a[property] - b[property];
          } else {
            a[property] = a[property].toString();
            b[property] = b[property].toString();
            if (sortOrder === -1) {
              return b[property].localeCompare(a[property]);
            } else {
              return a[property].localeCompare(b[property]);
            }
          }
        }
      }
    }

    // ChangeLabel function helps to change the label and select the annotation on image
    const changeLabel = (key, index, event) => {
      console.log('changeLabel', key, index, event.target.checked, event.target);
      const { rectCatMap } = this.state;
      let entitiesLab = this.props.entities ? [...this.props.entities] : [...this.state.entities]
      const currentData = rectCatMap[key];
      const jindex = currentData.indexOf(entitiesLab[index]);
      if (jindex !== -1) currentData.splice(jindex, 1);
      else currentData.push(entitiesLab[index]);
      entitiesLab.sort(dynamicSorting)
      if (this.props.hitLabels && this.props.hitLabels.length > 0) {
        this.props.hitLabels.map(elem => {
          entitiesLab.push(elem)
        })
      }
      if (currentData.length === 0) {
        this.removeShape(key);
        return;
      }
      rectCatMap[key] = currentData;
      this.setState({ rectCatMap });
      this.setState({rectCatMap:rectCatMap})
      let rectColorMap = this.props.hitEntities;
      if (rectCatMap[key].length === 1) {
        rectColorMap[key] = this.props.entityColorMap[rectCatMap[key][0]]
      }
      this.setState({
        rectCatMap, rectColorMap
      }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      });
    };

    const toggleEyeRectStatus = (key, value, event) => {
      event.stopPropagation();
      const hideRectMap = this.state.hideRectMap;
      const { labelGroupMap } = this.state;
      if (labelGroupMap[key]) {
        if (labelGroupMap[key].startsWith("#")) {
          let hideArr = getByValue(labelGroupMap, labelGroupMap[key])
          hideArr.map(element => {
            hideRectMap[element] = value;
          })
        } else {
          hideRectMap[key] = value;
        }
      }


      this.setState({ hideRectMap });
    };

    const addDynamicLabel = (key) => {
      this.setState({ openHitLabel: true, hitKey: key, hitLabel: '', dynamicLabelButtonAction: 'add' })
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown })
    }

    const handleColorChange = (color) => {
      this.selectedColor = color.hex
      let mouseHoverMap = this.state.mouseHoverMap;
      let rectColorMap = this.props.hitEntities;
      let selectedIndex = this.getKey(mouseHoverMap, true);
      rectColorMap[selectedIndex] = this.selectedColor
      this.setState({ openMenuTool: false, rectColorMap, pickerColor: color })
    }

    const handleClose = (color) => {
      this.selectedColor = color.hex
      let selectedIndex = this.getKey(this.state.mouseHoverMap, true);
      if (this.selectedColor !== undefined && this.selectedColor !== null && selectedIndex !== undefined && selectedIndex !== null) {
        let rectColorMap = this.props.hitEntities;
        rectColorMap[selectedIndex] = this.selectedColor
        this.setState({ openMenuTool: false, rectColorMap }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
            this.selectedColor = null;
          }
        });
      }
    }

    const closeDropdown = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown })
    }

    const closeDropdownFromNotes = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown, openNote: true, noteIndex: key })
    }

    const openDropdown = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = true
      this.setState({ showDropdown })
    }

    const closeDropdownFromAttributes = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown, openAttributeOption: true, attributeIndex: key })
    }

    const addDynamicHitLabelChange = (e, action) => {
      e.preventDefault();
      var code = (e.keyCode ? e.keyCode : e.which);
      this.eventObjectCode = code;
      if (e.target.value && e.target.value.length > 50) {
        showAlert("Label name exceeds maximum length.", "error");
        this.setState({ hitLabel: e.target.value, addlabelHidden: true })
      } else {
        this.setState({ hitLabel: e.target.value, addlabelHidden: false }, () => {
          if (this.eventObjectCode === 13) { //Enter keycode
            this.addDynamicHitLabel(action);
          }
        });
      }
    }

    const isDarkColor = (color) => {
      if (color) {
        let tinycolor = require("tinycolor2");
        let colorValue = tinycolor(color);
        return colorValue.isDark();
      }
      return true;
    }
    const setQACheck = (checked) => {
      this.setState({ qaCheck: checked })
    }

    const setRefCheck = (checked) => {
      this.setState({refCheck: checked})
    }

    const setretainAnnotations = (checked) => {
      this.props.setretainAnnotations(checked);
      this.props.setretainIndex(this.props.currentIndex)
      this.props.setSlicedHits(this.props.currentIndex)
    }

    const dragAnnotation = (event) => {
      this.setState({ dragEnable: event.target.checked });
    }

    const selectAnnotations = (event) => {
      this.setState({ lockHits: event.target.checked });
    }

    const lockSingleAnonotation = (checked) => {
      console.log("lockSingleAnonotation",checked)
      this.setState({ lock: checked })
      let idArr = this.getIds()
      console.log("lockSingleAnonotation idArr",idArr)
      const { rects, selectedItem, selectedLockedHitsIndex, lockedHits } = this.state
      if (this.state.lockHits) {
        document.getElementById("circle outline").checked = false;
        this.setState({ lockHits: false })
        if (idArr.length > 0) {
          selectedLockedHitsIndex.map(id => {
            idArr.map(val => {
              if (id === val || val.startsWith(id + '-')) {
                document.getElementById(val).setAttribute("pointer-events", "none");
              }
            })
          })
        }
      } else {
        selectedLockedHitsIndex.push(selectedItem)
        let hoverMap = {};
        const rectKeys = Object.keys(rects)
        if (checked) {
          selectedLockedHitsIndex.map(index => {
            hoverMap[index] = true;
          })
        } else {
          for (const k in rectKeys) {
            if (hoverMap.hasOwnProperty(k)) {
              hoverMap[k] = false;
            }
          }
        }
        if (checked) {
          if (idArr.length > 0) {
            idArr.map(id => {
              if (id === selectedItem || id.startsWith(selectedItem + '-')) {
                lockedHits.push(id)
              }
            })
          }
        } else {
          this.setState({ lockedHits: [], selectedLockedHitsIndex: [] })
        }
        this.setState({ mouseHoverMap: hoverMap, lockAnnotation: checked, }, () => {
          const { lockAnnotation } = this.state;
          if (lockAnnotation) {
            lockedHits.map(id => {
              if (document.getElementById(id)) {
                document.getElementById(id).setAttribute("pointer-events", "none");
              }
            })
          } else {
            console.log("IDARR",idArr)
            idArr.map(id => {
              // document.getElementById(id).removeAttribute("pointer-events");
              if (document.getElementById(id)) {
                document.getElementById(id).removeAttribute("pointer-events");
            }
            })
          }
          this.setMagnifier(false);
        })
      }
    }


    const lockAnnotationChange = (checked) => {
      const { rects } = this.state;
      let hoverMap = {};
      const rectKeys = Object.keys(rects)
      for (const k in rectKeys) {
        if (checked) {
          hoverMap[k] = true;
        } else {
          if (hoverMap.hasOwnProperty(k)) {
            hoverMap[k] = false;
          }
        }
      }
      this.setState({ mouseHoverMap: hoverMap, lockAnnotation: checked }, () => {
        const { lockAnnotation } = this.state;
        let idArr = this.getIds();
        if (idArr.length > 0) {
          idArr.map(id => {
            if (lockAnnotation) {
              if (document.getElementById(id)) {
                document.getElementById(id).setAttribute("pointer-events", "none");
              }
            } else {
              if (document.getElementById(id)) {
                document.getElementById(id).removeAttribute("pointer-events");
              }
            }
          })
        }
        this.setMagnifier(false);
      })
    }

    const deleteAnnotation = (key) => {
      this.setState({ removeAnnotation: true, removeAnnotationKey: key })
    }

    const removePolygon = (key) => {
      return(
        <div className="custom_confirm">
        <div className="custom_close" onClick={() => this.setState({ removeAnnotation: false })}><AntIcon type="close" /></div>
        <h4>Confirmation</h4>
        <p>Are you sure you want to delete this annotation ?</p>
        <div className="btn_end m-t-10">
          <button className="btn_cancel" onClick={() => this.setState({ removeAnnotation: false })}>Cancel</button>
          <button className="btn_ok m-l-5" name="delete" id={key} onClick={() => this.removeAnnotationShape(key)}>Okay</button>
        </div>
      </div>
      )
  }

  const removeShapeAnnotation = (key) => {
    return(
      <div className="custom_confirm">
         <h4>Confirmation</h4>
         <div className="custom_close" onClick={() => this.setState({ remAnnotation  :false })}><AntIcon type="close" /></div> 
         <p>Are you sure you want to delete this annotation ?</p>
         <div className="btn_end m-t-10">
         <button className="btn_cancel" onClick={() => this.setState({ remAnnotation: false })}>Cancel</button>
        <button className="btn_ok m-l-5" name="delete" id={key} onClick={() => this.removeAnnotationShape(key)}>Okay</button>
        </div>
        </div>
    )
}

    const onImgLoad = ({ target: img }) => {
      console.log('image loaded', img.offsetWidth, img.offsetHeight, img.width, img.naturalWidth, img.height, img.naturalHeight);
      let imgWidth = img.offsetWidth;
      let imgHeight = img.offsetHeight;
      this._panner = new Panner({
        screenWidth: imgWidth,
        screenHeight: imgHeight
      });
      let angle = 0;
      document.getElementsByClassName('content-container')[0].style.transform = 'rotate(' + angle + 'deg)';
      this.setState({
        imgHeight,
        imgWidth,
        imageNaturalWidth: img.naturalWidth,
        imageNaturalHeight: img.naturalHeight,
        imgObject: img,
        imgLoad: true,
        rotateImagAngle: 0,
        lockAnnotation: false,
        magnifierOption: false
      }, () => {
        lockAnnotationChange(false);
        if (document.getElementById("annotcheck") !== null)
          document.getElementById("annotcheck").checked = false;
      });
    };

    const setFunctions = () => {
      console.log("Entered in setFunctions");
      if (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) {
        const canvas = this.canvas;
        console.log("Canvas",canvas)
        console.log("DDDDDDDDDD",!this.state.canvasSet && canvas)
        if (!this.state.canvasSet && canvas) {
          if (this.state.imgLoad) {
            console.log("Entered inside the if in setFunction")
            canvas.addEventListener('mousedown', this.mousedownHandle);
            canvas.addEventListener('mousemove', this.mousemoveHandle);
            canvas.addEventListener('mouseup', this.mouseupHandle);
            canvas.addEventListener('wheel', this._onWheel);
            canvas.addEventListener('mouseleave', this.onMouseOutHandler, false);
            this.setState({ canvasSet: true });
          }
        }
      }
    };
    let clearButton = 'Clear All';
    if (this.props.shortcuts && 'clearAll' in this.props.shortcuts) {
      clearButton = clearButton + ' (' + convertKeyToString(this.props.shortcuts.clearAll) + ' )';
    }

    let undoButton = 'Undo';
    if (this.props.shortcuts && 'undo' in this.props.shortcuts) {
      undoButton = undoButton + ' (' + convertKeyToString(this.props.shortcuts.undo) + ' )';
    }
    let saveChanged = 'Save';
    const changeImageProp = (name, event) => {
      console.log('changeImageProp', name, event.pageX, event.offsetY, event.target.value, event.offsetX, event.offsetY, event.deltaY);
      if (name === 'scale') {
        this.zoom(250, 250, this._panner.scale - event.target.value);
      } else {
        this.setState({ [name]: event.target.value, imagePropsChanged: true });
      }
    };

    const imageRotate = () => {
      console.log("imgrotate")
      const degrees = [90, 180, 270, 360]
      const { rotateImagAngle } = this.state
      let angle = degrees[rotateImagAngle];
      document.getElementsByClassName('content-container')[0].style.transform = 'rotate(' + angle + 'deg)';
      if (rotateImagAngle === 3) {
        this.setState({ rotateImagAngle: 0 })
      } else {
        this.setState({ rotateImagAngle: rotateImagAngle + 1 })
      }
    }

    const hideImage = () => {
      console.log("x.style.display")
      var x = document.getElementById("myImageZoom1");
      var y = document.getElementById("myImageu");
      var z = document.getElementById("btnnu");
      if(x !== null){
        console.log("x.style.display", x.style.display)
        if (x.style.display === "none") {
          x.style.display = "block";
          y.style.alignContent = "flex-end";
        } else {
          x.style.display = "none";
          y.style.alignContent = "center";
        }
      } else {
        y.style.alignContent = "flex-end";
      }
    }

    const editDynamicLabel = (value) => {
      this.setState({ hitLabel: value, openHitLabel: true, dynamicLabelButtonAction: 'update', selectedHitLabel: value })
    }

    const deleteDynamicLabel = (value) => {
      if (this.props.fullScreen) {
        this.setState({ hitLabel: value, deleteConfirm: true })
        return false;
      }
      const that = this
      const confirm = AntdModal.confirm;
      confirm({
        title: 'Confirmation',
        content: 'Are you sure you want to delete this label ?',
        okText: 'OK',
        cancelText: 'CANCEL',
        onOk() { that.setState({ hitLabel: value }, () => { that.addDynamicHitLabel("delete"); }) },
        onCancel() { return false },
      });
    }

    const setDeleteConfirm =(val)=>{
      this.setState({ deleteConfirm: val })
    }

    const setDivToggle = (val) =>{
      this.setState({ divToggle: val })
    }

    const setSearchQuery = (val) =>{
      console.log("Search entity val",val)
      this.setState({searchQuery : val})
    }


    const getHitLabels = () => {
      const arrs = [];
      if (this.props.hitLabels && this.props.hitLabels.length > 0) {
        this.props.hitLabels.map(elem => {
          arrs.push(<Tag className="hitTag" color="rgba(0,0,0,.65)"> <span className="hitTagspan" title={elem}>{elem}</span> <Icon name="edit" title="Edit" onClick={() => editDynamicLabel(elem)} /><Icon name="delete" title="Delete" onClick={() => deleteDynamicLabel(elem)} /></Tag>)
        })
      }
      arrs.sort(dynamicSorting);
      return (
        <div className="hitLabelTag">
          {arrs}
        </div>
      )
    }

    const disableEraserPolygon = (event) => {
      if (this.state.toolName === DRAW_TOOLS.ERASERPOLYGON.NAME) {
        this.setState({activeErasePolygon:false,defaultClass:""})
        this.undoLabelSelection();
        this.toggleTool(DRAW_TOOLS.POLYGON.NAME, event);
      } else {
        if (this.state.mouseHoverMap[this.state.selectedItem]) {
          this.toggleTool(DRAW_TOOLS.ERASERPOLYGON.NAME, event);
          this.setState({ 
            annotationToBeErasedId: this.state.selectedItem,
            defaultClass:this.state.entities[0],
            activeErasePolygon:true
           })
        } else {
          showAlert('Please select the annotation', 'error');
        }
      }
    }

    let windowHeight = (window.innerHeight * 75) / 100;
    let windowWidth = (window.innerWidth * 80) / 100;
    if (!this.props.space) {
      windowHeight = (window.innerHeight * 70) / 100;
      windowWidth = (window.innerWidth * 60) / 100;
    }
    if (this.props.fullScreen) {
      windowHeight = (window.innerHeight * 95) / 100;
    }
    if (this.state.toolbarHidden && this.props.fullScreen) {
      windowWidth = (window.innerWidth * 80) / 100;
    }
    let toolCombo = undefined;
    if (this.props.drawHandle && this.props.shortcuts && 'tool' in this.props.shortcuts) {
      if ('tool' in this.props.shortcuts) {
        toolCombo = convertKeyToString(this.props.shortcuts.tool);
        Mousetrap.bind(toolCombo, this.toggleTool.bind(this, 'shift'));
      }
      if (this.props.space) {
        let combo = undefined;
        const shortcuts = this.props.shortcuts;
        if ('next' in shortcuts) {
          combo = convertKeyToString(shortcuts.next);
          if (this.props.currentIndex >= 0) {
            Mousetrap.bind(combo, this.props.saveTagAndNextRow);
          } else {
            Mousetrap.unbind(combo);
          }
        }
        if ('previous' in shortcuts) {
          combo = convertKeyToString(shortcuts.previous);
          if (this.props.currentIndex > 0) {
            Mousetrap.bind(combo, this.props.getBackTopreviousRow);
          } else {
            Mousetrap.unbind(combo);
          }
        }
        if ('skip' in shortcuts && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)) {
          combo = convertKeyToString(shortcuts.skip);
          if (this.props.currentIndex >= 0) {
            Mousetrap.bind(combo, this.props.skipRow);
          } else {
            Mousetrap.unbind(combo);
          }
        }
      }
    }

    let classes = "content-container noselect";
    if (this.state.disableZoomAnim) {
      classes = "content-container noselect leaflet-zoom-anim";
    }
    let classes2 = "no-flickr leaflet-zoom-animated";
    if (this.state.disableZoomAnim) {
      classes2 = "no-flickr leaflet-zoom-animated1";
    }

    console.log('session value=====>', this.props.changesInSession)
    console.log("zoomy", this.state.zoomFactor)
    console.log("findhei", this.state.imgHeight, this.state.imgWidth, this.state, this.props)

    console.log("this.state.refCheck", this.state.refCheck)
    let topValue = '0px';
    topValue = this.props.imageNiri ? '-150px' : this.props.fullScreen ? '0px' : '-90px';
    return (
      <div className="pr_img_annotate w-100">
        <div className="w-80 work_sec">
          <ColorContrastBar
            contrast={this.state.contrast}
            saturation = {this.state.saturation}
            brightness = {this.state.brightness}
            zoomFactor = {this.state.zoomFactor}
            opacity = {this.state.opacity}
            fullScreen = {this.props.fullScreen}
            _panner = {this._panner}
            hits = {this.props.hits}
            annotatedLabelCount = {this.props.annotatedLabelCount}
            rects={this.state.rects}
            retainAnnotations ={this.props.retainAnnotations}
            lock ={this.state.lock}
            imageNiri= {this.state.imageNiri}
            qaCheck={this.state.qaCheck}
            refCheck={this.state.refCheck}
            magnifierOption={this.state.magnifierOption}
            lockAnnotation={this.state.lockAnnotation}
            drawHandle = {this.props.drawHandle}
            currentIndex = {this.props.currentIndex}
            taskType = {this.props.taskType}
            setMagnifier={this.setMagnifier}
            setretainAnnotations={setretainAnnotations}
            dragAnnotation={dragAnnotation}
            selectAnnotations={selectAnnotations}
            lockSingleAnonotation={lockSingleAnonotation}
            lockAnnotationChange={lockAnnotationChange}
            setQACheck={setQACheck}
            hideImage={hideImage}
            setRefCheck={setRefCheck}
            imageRotate={imageRotate}
            changeImageProp={changeImageProp}
          />

        <div className="w_img-sec">
            <div className="w_holder" ref={(parentDiv) => this.parentDiv = parentDiv} style={{ lineHeight: 0, display: 'block', position: 'relative' }}>
              <div ref={(parentDiv) => this.parentDiv2 = parentDiv} id="myImageu" className={(this.state.imageNiri !== null && this.state.imageNiri !== undefined && this.state.refCheck === false) ? "pan-zoom-element1" : "pan-zoom-element"}>
                <div id="myImage" ref={(parentDiv) => this.parentDiv3 = parentDiv} className={classes} style={{ 
                  position: 'relative', 
                  top: topValue,
                  width: '100% !important', 
                  height: '100% !important',
                  filter: `contrast(${this.state.contrast})
                           brightness(${this.state.brightness})
                           saturate(${this.state.saturation})`
                           }}>
                  {this.state.defaultClass && (this.state.toolName === DRAW_TOOLS.RECTANGLE.NAME || this.state.toolName === DRAW_TOOLS.MAGICTOOL.NAME) &&
                    <div>
                      <div id="crosshair-h" style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        background: 'transparent',
                        borderTop: '2px dotted #000',
                        borderLeft: '2px dotted #000',
                        pointerEvents: 'none',
                        zIndex: 2,
                        width: '100%',
                        transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`,
                      }}>
                      </div>

                      <div id="crosshair-v" style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        background: 'transparent',
                        borderTop: '2px dotted #000',
                        borderLeft: '2px dotted #000',
                        pointerEvents: 'none',
                        zIndex: 2,
                        height: '100%',
                        transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`,
                      }}>
                      </div>
                    </div>
                  }
                  {(this.state.imageNiri !== null) ? <div className={this.state.refCheck === true ? '' : 'normalImg'}>
                    <img
                     alt='img'
                      id="myImageZoom"
                      className={classes2}
                      style={{
                        maxHeight: this.state.imgLoad ? 'none' : `${windowHeight}`,
                        maxWidth: this.state.imgLoad ? 'none' : `${windowWidth}`,
                        transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`,
                        width: this.state.imgLoad ? this.state.imgWidth : 'auto',
                        height: this.state.imgLoad ? this.state.imgHeight : 'auto',
                        display: 'block',
                        objectFit: 'cover'
                      }}
                      draggable="false"
                      onLoad={onImgLoad}
                      src={this.state.image}
                    />
                    </div> :
                    <img
                    alt='img'
                      id="myImageZoom"
                      className={classes2}
                      style={{
                        maxHeight: this.state.imgLoad ? 'none' : `${windowHeight}`,
                        maxWidth: this.state.imgLoad ? 'none' : `${windowWidth}`,
                        transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`,
                        width: this.state.imgLoad ? this.state.imgWidth : 'auto',
                        height: this.state.imgLoad ? this.state.imgHeight : 'auto',
                        display: 'block',
                        objectFit: 'cover'
                      }}
                      draggable="false"
                      onLoad={onImgLoad}
                      src={this.state.image}
                    />}
                                {(this.state.imageNiri !== null && this.state.imageNiri !== undefined && this.state.refCheck === false) && <div style={{width: '400px', height: '400px'}} className='myZoomyimg'>
                                  <img 
                    alt='img'
                    id="myImageZoom1" 
                    style={{
                      maxHeight: '470px',
                      maxWidth: '470px',
                      transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`,
                      boxShadow: '1px 1px 2px 2px rgba(94,93,102,.08)',
                      height: this.state.imgLoad ? this.state.imgHeight : 'auto',
                    }}
                    draggable="false"
                    onLoad={onImgLoad}
                    src={this.state.imageNiri}
                  /> </div>}


                  {(!this.state.imgLoad || this.props.loading || this.props.imagesLoad) && <div className="overlay_dom" >
                    <div className="cssload-wraper">
                      <div className="cssload-dots"></div>
                    </div>
                  </div>}
                  {this.state.imgLoad &&
                    <svg id="svgContainer" className={classes2} ref={(canv) => { 
                      this.canvas = canv
                      setFunctions()
                    }} style={canvasStyles}>
                      <defs>
                        <filter x="0" y="0" width="1" height="1" id="solid">
                          <feFlood
                            flood-color="green" flood-opacity="1" />
                          <feComposite in="SourceGraphic" operator="xor" />
                        </filter>
                      </defs>
                      {Object.keys(this.state.rects).length >= 0 && renderRects()}
                      {Object.keys(this.state.rects).length >= 0 && renderPoints()}
                      {Object.keys(this.state.rects).length >= 0 && renderHalfPoints()}
                      {this.state.hoverPoint && this.state.hoverPoint.length > 0 && 
                        <circle
                          id="renderHoverPoint"
                          cx={this.state.hoverPoint[0]}
                          cy={this.state.hoverPoint[1]}
                          title="Click to close"
                          style={{ cursor: 'pointer' }}
                          r={3 / this.state.scale}
                          stroke="white"
                          strokeWidth={10 / this.state.scale}
                          fill='lightblue'
                        />
                      }
                      {this.state.currentRect && this.state.currentRect.length > 0 && this.svgRender(this.state.currentRect)}
                      {this.state.currentRect && this.state.currentRect.length > 0 && renderCurrentPoints(this.state.currentRect)}
                      {this.state.currentCircle && this.state.currentCircle.length > 0 && this.svgRender(this.state.currentCircle)}
                      {this.state.currentLine && this.state.currentLine.length > 1 && this.svgRender(this.state.currentLine)}
                      {this.state.currentLine && this.state.currentLine.length > 0 && renderCurrentPoints(this.state.currentLine)}
                      {this.state.freeHandSketch && this.state.freeHandSketch.length > 1 && this.svgRender(this.state.freeHandSketch)}
                      {this.state.freeHandSketch && this.state.freeHandSketch.length > 0 && renderCurrentPoints(this.state.freeHandSketch)}
                    </svg>
                  }
                </div>
                <div id="overlay" style={{ display: "flex" }} onMouseMove={(event) => this.zoomIn(event)}></div>
                {this.props.drawHandle && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) &&
                  <div style={{ position: 'absolute', bottom: 0, right: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 'large' }}>
                    <Button size="mini" icon onClick={this.pan.bind(this, -5, 0)}>
                      <Icon name="angle left" />
                    </Button>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button size="mini" icon onClick={this.pan.bind(this, 0, -5)}>
                        <Icon name="angle up" />
                      </Button>
                      <Button size="mini" icon onClick={this.pan.bind(this, 0, 5)}>
                        <Icon name="angle down" />
                      </Button>
                    </div>
                    <Button size="mini" icon onClick={this.pan.bind(this, 5, 0)}>
                      <Icon name="angle right" />
                    </Button>
                  </div>
                }
              </div>
            </div>
          </div>
          
          {((this.props.taskType === IMAGE_SEGMENTATION && !hideLoadedItems)
            || (this.props.taskType === IMAGE_MASKING && !hideLoadedItems)
            || ((this.props.taskType !== IMAGE_SEGMENTATION) && (this.props.taskType !== IMAGE_MASKING)))
            &&
             <Lables
            propsState={this.state}
            propsData={this.props}
            entries={entries}
            dynamicSort={dynamicSort}
            isDarkColor={isDarkColor}
            taskType={this.props.taskType}
            addDynamicLabel={addDynamicLabel} 
            changeLabel={changeLabel}
            closeDropdownFromNotes={closeDropdownFromNotes}
            closeDropdownFromAttributes={closeDropdownFromAttributes}
            toggleEyeRectStatus={toggleEyeRectStatus}
            openDropdown={openDropdown}
            closeDropdown={closeDropdown}
            deleteAnnotation={deleteAnnotation}
            removeRect={removeRect}
            handleColorChange={handleColorChange}
            handleClose={handleClose}
            />
          }

        </div>
        {this.props.drawHandle && this.state.imgLoad &&
        <div className="w-20 label_sec m-l-0">

      <ToolsBar
        propsState ={this.state}
        propsData ={this.props}
        addDynamicHitLabel={this.addDynamicHitLabel}
        deletePoint={this.deletePoint}
        removePolygon={removePolygon}
        removeShapeAnnotation={removeShapeAnnotation}
        disableEraserPolygon={disableEraserPolygon}
        getEntitiesWithoutHitLabel={getEntitiesWithoutHitLabel}
        entries={entries}
        selectCategory={selectCategory}
        toggleEyeStatus={toggleEyeStatus}
        setImageHover={setImageHover}
        dynamicSort={dynamicSort}
        toggleEyeAllStatus={toggleEyeAllStatus}
        hits={this.state.hits}
        currentIndex={this.props.currentIndex}
        saveChanged={saveChanged}
        undoButton={undoButton}
        clearButton={clearButton}
        getHitLabels={getHitLabels}
        showButtons={this.showButtons}
        toggleTool = {this.toggleTool}
        showLabelPopup={this.showLabelPopup}
        toggleMergeOldAnnotation={this.toggleMergeOldAnnotation}
        changeSelectedAnnotationAsGroup={this.changeSelectedAnnotationAsGroup}
        toggleEntitySelected={this.toggleEntitySelected}
        confirmAlertOkTapped={this.confirmAlertOkTapped}
        undoLabelSelection={this.undoLabelSelection}
        clearPolygons={this.clearPolygons}
        undoLast={this.undoLast}
        saveAnnotationMasking={this.saveAnnotationMasking}
        showActionButtons={this.showActionButtons}
        setDeleteConfirm={setDeleteConfirm}
        setDivToggle={setDivToggle}
        setSearchQuery={setSearchQuery}
        handleColorChange={handleColorChange}
        handleClose={handleClose}
      />
          </div>
        }

         {this.state.openNote && this.props.drawHandle &&
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Add a Note
                  <Icon onClick={this.closeModal.bind(this)} className="pull-right cursor_pointer"
                  style={{marginLeft : '350px'}}
                   name="close" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <input type="textarea" value={this.state.notes[this.state.noteIndex]} onChange={this.handleNoteChange.bind(this)} autoFocus className="form-control" id="note" />
              </Modal.Body>

              <Modal.Footer>
                <Button type="submit" positive onClick={this.saveModal.bind(this)}>Save</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        }
        {this.state.openHitLabel && this.props.drawHandle &&
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>{this.state.dynamicLabelButtonAction === 'update' ? "Edit Label" : "Add Label"}
                  <Icon onClick={this.closeModal.bind(this)} className="pull-right cursor_pointer"
                   style={{marginLeft : '350px'}}
                  name="close" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <input type="textarea" defaultValue={this.state.hitLabel !== '' ? this.state.hitLabel : ''} placeholder="Add Label" onKeyUp={(e) => addDynamicHitLabelChange(e, this.state.dynamicLabelButtonAction === 'update' ? "update" : "add")} autoFocus className="form-control" id="label" />
              </Modal.Body>

              <Modal.Footer>
                <Button disabled={this.state.addlabelHidden} type="submit" positive onClick={() => this.addDynamicHitLabel(this.state.dynamicLabelButtonAction === 'update' ? "update" : "add")}>{this.state.dynamicLabelButtonAction === 'update' ? "UPDATE" : "ADD"}</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        }

        {this.state.openAttributeOption && this.props.drawHandle &&
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Edit Attributes
                  <Icon onClick={this.closeModal.bind(this)} className="pull-right cursor_pointer"
                   style={{marginLeft : '350px'}}
                  name="close" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {this.getAttributesOptions()}
              </Modal.Body>

              <Modal.Footer>
                <Button type="submit" positive onClick={() => this.saveAttributeDetails()}>Save</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        } 

        {this.state.fileLoad &&
          <div className="overlay_doms">
            <div className="cssload-wraper">
              <div className="cssload-dots"></div>
            </div>
          </div>}
      </div>
    );
  }
}
