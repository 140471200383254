import React from 'react';
import {
    Checkbox,
    Icon,
    Form,
    Divider,
    Accordion,
  } from "semantic-ui-react";

  import {
    Icon as AntIcon,
    Collapse,
  } from "antd";

  import {
    VIDEO_CLASSIFICATION,
    VIDEO_BOUNDING_BOX,
    IMAGE_CLASSIFICATION,
    DOCUMENT_ANNOTATION,
    IMAGE_POLYGON_BOUNDING_BOX,
    IMAGE_POLYGON_BOUNDING_BOX_V2,
    POS_TAGGING,
    POS_TAGGING_GENERIC,
    TEXT_CLASSIFICATION,
    IMAGE_BOUNDING_BOX,
    DICOM_CLASSIFICATION,
    DICOM_SEGMENTATION,
    IMAGE_MASKING,
    IMAGE_SEGMENTATION,
  } from "../../../helpers/Utils";
  import ColorLabelForm from "./ColorLabelForm";
  import BasicEntityForm from "./BasicEntityForm";
  import ImageAttributeForm from "./ImageAttributeForm";
  import AdvanceEntitiesForm from "./AdvanceEntitiesForm";
  import "../ImportProjects.css";
  import helper from "../ImportProjectHelpers";
  const { Panel } = Collapse;
class TaggerImportProjectForms extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  render() {
    const {showUserDefinedColor,type,advancedTags,advTagsPanels,tagPlaceHolder,
        namePlaceHolder,coloredTags,loading,isShowAdvanceLabelTop,basicEntities,
        advanceEntities,submitDisabled,placeholder,propsState,handleChange,
        renderWorkspace,compressionHandle,handleObjectAttributeChange,
        addAttribute,addAttributeList,removeAttribute,removeAttributeList,handleAttributeChange,handleEditAttributeChange,addColorTag,removeColorTag,
        handleColorChange,handleColorLabelChange,dynamicLabelOptionChange,advancedOptionChangeData,
        NEREntityOptionChange,handleSubmit,resetClassification,getTagsInput,
        updateBasicFormInput,removeBasicFormEntity,updateAvanceFormInput,removeAdvanceFormLabel,addAdvanceFormEntity,
        removeAdvanceFormEntity,addParent,getClassificationTypes
    } = this.props

    return (
        <div className="project_view project_new advance">
        <div className="w-100 box_shodow m-h-content box_area">
          <div className="main_box flex-colum m-t-40">
            <div className="base_root w-100">
              <div className="m-b-8 w-50 p-r-10 p-l-10">
                <Form>
                  <Form.Input
                    id="project_name"
                    size="small"
                    color="teal"
                    compact
                    onChange={(e)=>handleChange("project_name",e)}
                    label="Project Name"
                    control="input"
                    type="text"
                    value={propsState.fields.project_name}
                    autoFocus
                    placeholder={namePlaceHolder}
                  />
                </Form>
              </div>
              <div className="m-b-8 w-50 p-l-10 p-r-10">
                <div>
                  <label>Workspace Name</label>
                  {renderWorkspace()}
                </div>
              </div>
            </div>
            <div className="base_root w-100">
              {(type === IMAGE_CLASSIFICATION ||
                type === VIDEO_CLASSIFICATION ||
                type === POS_TAGGING ||
                type === POS_TAGGING_GENERIC ||
                type === TEXT_CLASSIFICATION ||
                type === IMAGE_BOUNDING_BOX ||
                type === IMAGE_SEGMENTATION ||
                type === IMAGE_POLYGON_BOUNDING_BOX ||
                type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                (type === IMAGE_MASKING && !showUserDefinedColor) ||
                type === VIDEO_BOUNDING_BOX ||
                type === DICOM_CLASSIFICATION ||
                type === DICOM_SEGMENTATION) && (
                <div className="m-b-8 w-100 p-r-10 p-l-10">
                  <Form>
                    <div>
                      {!advancedTags && (
                        <div>
                          {type === POS_TAGGING_GENERIC && (
                            <div className="checkOption">
                              <Form.Checkbox
                                label="Enable Auto Tagging"
                                name="autoTag"
                                checked={propsState.modelEntity}
                                value="Enable Auto Tagging"
                                onChange={(e)=>NEREntityOptionChange("modelEntity",e)}
                              />
                              {propsState.modelEntity && (
                                <Form.Checkbox
                                  label="Use Trained Model"
                                  name="useTrainedModel"
                                  checked={propsState.isModelUpload}
                                  value="Use Trained Model"
                                  onChange={(e)=>NEREntityOptionChange("isModelUpload",e)}
                                />
                              )}
                            </div>
                          )}
                          <Form.Input
                            id="tags"
                            size="small"
                            compact
                            onChange={(e)=>handleChange("tags",e)}
                            label={`List of Entities/ Categories`}
                            control="input"
                            type="text"
                            value={propsState.fields.tags}
                            placeholder={tagPlaceHolder}
                          />
                          {/* show ner tags */}
                          <div className="tagSet">
                            {propsState.modelEntity &&
                              !propsState.isModelUpload &&
                              helper.listEntities(this)}
                            <div className="tagSetEdit">
                              {propsState.modelEntity &&
                                !propsState.isModelUpload && (
                                  <AntIcon
                                    type="edit"
                                    title="Edit"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        nermodelVisible: true,
                                      })
                                    }
                                  />
                                )}
                            </div>
                          </div>
                          {propsState.newEntityForm}
                        </div>
                      )}
                      {advancedTags && (
                        <div>
                          <Accordion
                            as={Form.Field}
                            panels={advTagsPanels}
                            style={{ fontSize: "xx-small" }}
                          />
                          {getTagsInput()}
                        </div>
                      )}
                      {(type === IMAGE_CLASSIFICATION ||
                        type === POS_TAGGING ||
                        type === TEXT_CLASSIFICATION) && (
                        <div>
                          <Form.Checkbox
                            label={
                              type === POS_TAGGING ||
                              type === TEXT_CLASSIFICATION
                                ? "Enable Auto Tagging"
                                : "Upload Model"
                            }
                            name="isModelUpload"
                            checked={propsState.isModelUpload}
                            onChange={(e, data) => advancedOptionChangeData("uploadModel", data)}
                          />
                        </div>
                      )}
                      {type === TEXT_CLASSIFICATION && (
                        <div>{getClassificationTypes()}</div>
                      )}
                    </div>
                  </Form>
                </div>
              )}
              
              {type === DOCUMENT_ANNOTATION && (
                <div className="m-b-8 w-50 p-l-10 p-r-10">
                  <Collapse
                    className="c_collapse"
                    bordered={true}
                    expandIcon={({ isActive }) => (
                      <Icon
                        type="caret-right"
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                  >
                    <Panel header="Dynamic Label">
                      <div>
                        <Checkbox
                          size="mini"
                          checked={propsState.allowDynamiclabelling}
                          onClick={dynamicLabelOptionChange}
                          label="Allow Dynamic Labelling"
                        />
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              )}
            </div>
            {/* Color picker display section for image masking  <ColorLabelForm/>*/}
            {type === IMAGE_MASKING &&
              showUserDefinedColor &&
              coloredTags.length > 0 && (
                <div className="w-100">
                  <label className="cval_label">
                    List of Entities/ Categories
                  </label>
                  <div className="column-reverse">
                    {coloredTags.map((coloredTag, index) => {
                      return (
                        <ColorLabelForm
                          coloredTag={coloredTag}
                          handleColorLabelChange={handleColorLabelChange}
                          handleColorChange={handleColorChange}
                          addColorTag={addColorTag}
                          removeColorTag={removeColorTag}
                          index={index}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            {!advancedTags && type === DOCUMENT_ANNOTATION && (
              <div className="base_root w-100">
                <div className="p-l-10 p-r-10 w-100">
                  <h5 className="c_label">List of Labels</h5>
                  <Form
                    size="small"
                    key="import1"
                    loading={loading}
                    compact
                    className="box_fill w-100"
                  >
                    <div
                      className={
                        isShowAdvanceLabelTop ? "column-reverse" : ""
                      }
                    >
                      {!advancedTags && basicEntities.length > 0 && (
                        // adds basic entity form for document annotation
                        <div className="column-reverse">
                          {basicEntities.map((basiclabel,i) => {
                            return (
                              <div key={i}>
                              <BasicEntityForm
                                list={basiclabel}
                                updateInput={(e, id, key) =>
                                  updateBasicFormInput(e,id,key)
                                }
                                removeLabel={(id) => removeBasicFormEntity(id)
                                }
                              />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {!advancedTags && advanceEntities.length > 0 && (
                        <div className="column-reverse">
                          {advanceEntities.map((label,i) => {
                            return (
                            <div key={i}>
                              <AdvanceEntitiesForm
                                formId={label.id}
                                label={label.name}
                                list={label.entities}
                                serNum={label.count}
                                addParent={(id) => addParent(id)}
                                addChild={(entityId) =>
                                  addAdvanceFormEntity(
                                    label.id,
                                    entityId,
                                    label.entities
                                  )
                                } 
                                updateInput={(e, entityId, key) =>
                                  updateAvanceFormInput(
                                    label.id,
                                    e,
                                    entityId,
                                    key,
                                    label.entities
                                  )
                                }
                                removeLabel={(id) =>
                                  removeAdvanceFormLabel(
                                    label.id,
                                    label.entities
                                  )
                                }
                                removeChild={(entityId) =>
                                  removeAdvanceFormEntity(
                                    label.id,
                                    entityId,
                                    label.entities
                                  )
                                }
                              />
                            </div>
                            );
                          })}
                        </div>
                      )}
                      {advancedTags && (
                        <div>
                          <Accordion
                            as={Form.Field}
                            panels={advTagsPanels}
                            style={{ fontSize: "xx-small" }}
                          />
                          {getTagsInput()}
                        </div>
                      )}
                    </div>
                    <br />
                    <Divider hidden />
                  </Form>
                </div>
              </div>
            )}
            {(type === POS_TAGGING_GENERIC ||
              type === DOCUMENT_ANNOTATION) && (
              <div className="base_root w-100">
                <div className="p-l-10 p-r-10 w-100">
                  <Collapse
                    className="c_collapse"
                    bordered={true}
                    expandIcon={({ isActive }) => (
                      <Icon
                        type="caret-right"
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                  >
                    <Panel header="Classifications">
                      <div className="view_holder custom_textarea w-100">
                        <div className="w-100">
                          <div className="remove_btn">
                            {propsState.fields.classification_name
                              .length > 0 &&
                              propsState.fields.classification_classes
                                .length > 0 && (
                                <div
                                  className="btn_clear"
                                  onClick={resetClassification}
                                >
                                  Remove
                                </div>
                              )}
                          </div>
                          {
                            <Form className="div-classify">
                              <Form.Input
                                className="w-33 p-r-5"
                                onChange={(e)=>handleChange("classification_name",e)}
                                id="classification_name"
                                compact
                                value={
                                  propsState.fields
                                    .classification_name
                                }
                                placeholder="Theme Name"
                              />
                              <Form.Input
                                className="w-33 p-r-5"
                                onChange={(e)=>handleChange("classification_disp_name",e)}
                                id="classification_disp_name"
                                value={
                                  propsState.fields
                                    .classification_disp_name
                                }
                                placeholder="Select the article theme"
                              />
                              <Form.Input
                                className="w-33"
                                onChange={(e)=>handleChange("classification_classes",e)}
                                id="classification_classes"
                                compact
                                value={
                                  propsState.fields
                                    .classification_classes
                                }
                                placeholder="Enter the classes"
                              />
                            </Form>
                          }
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            )}
            {(type === IMAGE_SEGMENTATION ||
              type === IMAGE_MASKING) && (
              <div className="base_root w-100 m-t-5">
                <div className="view_holder custom_textarea w-100 p-l-10 p-r-10 ">
                  <Form className="w-100">
                    <Form.Field>
                      <Checkbox
                        label="Image Compression"
                        onChange={(e)=>compressionHandle(e)}
                      />
                    </Form.Field>
                  </Form>
                </div>
              </div>
            )}
            {(type === IMAGE_SEGMENTATION ||
              type === VIDEO_BOUNDING_BOX ||
              type === IMAGE_MASKING) && (
              <div className="base_root w-100">
                <div className="p-l-10 p-r-10 w-100 m-b-20">
                  <Collapse
                    className="c_collapse"
                    bordered={true}
                    expandIcon={({ isActive }) => (
                      <Icon
                        type="caret-right"
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                  >
                    <Panel header="IMAGE ATTRIBUTES">
                      <div className="view_holder custom_textarea w-100">
                        <div className="w-100">
                          {propsState.imageAttributes.map(
                            (value, index) => {
                              return (
                                <div key={index}>
                                <ImageAttributeForm
                                  handleAttributeChange={handleAttributeChange}
                                  addAttribute={addAttribute}
                                  removeAttribute={removeAttribute}
                                  value={value}
                                  index={index}
                                  total={
                                    propsState.imageAttributes.length
                                  }
                                />
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            )}
            {(type === IMAGE_SEGMENTATION || 
              type === VIDEO_BOUNDING_BOX ||
              type === IMAGE_MASKING) && (
              <div className="base_root w-100">
                <div className="p-l-10 p-r-10 w-100 m-b-10">
                  <Collapse
                    className="c_collapse"
                    bordered={true}
                    expandIcon={({ isActive }) => (
                      <Icon
                        type="caret-right"
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                  >
                    <Panel header="OBJECT/Annotation ATTRIBUTES">
                      <div className="view_holder custom_textarea w-100">
                        <div className="w-100">
                          {
                            <Form className="div-classify">
                              <Form.Input
                                className="w-33 p-r-5"
                                onChange={(e)=>handleObjectAttributeChange(e)}
                                id="objectAttribute"
                                compact
                                value={propsState.objectAttribute}
                                placeholder="List of comma separated object attribute names"
                              />
                            </Form>
                          }
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            )}
            {(type === VIDEO_BOUNDING_BOX ||
              type === IMAGE_MASKING ||
              type === IMAGE_SEGMENTATION) && (
                <div className="base_root w-100">
                  <div className="p-l-10 p-r-10 w-100 m-b-20">
                    <Collapse className="c_collapse" bordered={true} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}>
                      <Panel header="ATTRIBUTES VALUES">
                       {
                          propsState.objectAttribute && (
                            <div style={{textTransform:"none"}}>
                              {`Please create each respective text boxes for: `}
                              <b>
                              {propsState.objectAttribute.split(",").join(", ")}
                              </b>
                              {` and enter the respective values in each:`}
                            </div>
                          )
                        }
                        <div className="view_holder custom_textarea w-100">
                          <div className="w-100">
                            {
                              propsState.dropDownAttributes.map((value, index) => {
                                return (<ImageAttributeForm
                                  handleAttributeChange={handleEditAttributeChange}
                                  addAttribute={addAttributeList}
                                  removeAttribute={removeAttributeList}
                                  value={value}
                                  index={index}
                                  role={"objectAttribute"}
                                  total={propsState.dropDownAttributes.length} />)
                              })
                            }
                          </div>
                        </div>

                      </Panel>
                    </Collapse>
                  </div>
                </div>
              )}
            <div className="base_root w-100 m-t-5">
              <div className="view_holder custom_textarea w-100 p-l-10 p-r-10 ">
                <Form className="w-100">
                  <Form.Input
                    size="small"
                    id="instruction"
                    type="textarea"
                    onChange={(e)=>handleChange("instructions",e)}
                    value={propsState.fields.instructions}
                    label="Tagging Instruction"
                    control="TextArea"
                    placeholder={placeholder}
                  />
                </Form>
              </div>
            </div>

            <div className="base_root w-100">
              <div className="txt-center m-t-40 p-l-10 p-r-10 w-100">
                <button
                  className="r_submit"
                  disabled={submitDisabled}
                  onClick={handleSubmit}
                >
                  {" "}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TaggerImportProjectForms