import { getUidToken } from './LoginApis';
import {  SESSION_VARIABLES, BOOLEAN_TYPES } from "../helpers/Constants";
import { BASE_URL,BASE_URL_WITHOUT_SUFFIX_SLASH } from '../helpers/Constants';
const superagent = require("superagent");

export const middleCallback = (err, res, callback, type) => {
    if (res) {
      if (res.status === 401) {
        window.sessionStorage.clear();
        setTimeout(() => {
          let url = window.location.protocol + "//" + window.location.host + "/projects/login";
          window.location.replace(url);
          window.sessionStorage.setItem(SESSION_VARIABLES.TOKEN_EXPIRY, BOOLEAN_TYPES.TRUE);
        }, 100);
      } else {
        window.sessionStorage.setItem(SESSION_VARIABLES.LAST_ACCESS_TIME_API, new Date().getTime());
        window.sessionStorage.setItem(SESSION_VARIABLES.TIME_EXPIRY_POPUP, BOOLEAN_TYPES.FALSE);
        if (type === "body") {
          callback(err, res.body);
        } else {
          callback(err, res);
        }
      }
    }
  }

export const downloadImageUrl = (callback) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL_WITHOUT_SUFFIX_SLASH ;
    console.log('baseURL=====>',url)
    callback (url, uid, token)
  }

export const createIndex = (pid) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/CreateIndex";
    console.log("url", url);
    superagent
      .post(url)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        console.log("createdIndex");
      });
  };


  export const downloadfile = (pid, option, callback, format, filterInputparam, downloadfile) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/download?";
    if (null !== filterInputparam && filterInputparam.length > 0) {
      url = url + filterInputparam;
    }
    if (format && format.toUpperCase() === "YOLO"){
      url = url + "&format=YOLO";  
    }
    if (format && format.toUpperCase() === "SINGLEJSON"){
      url = url + "&format=SINGLEJSON";
    }
    if (format && format.toUpperCase() === "RAW"){
      url = url + "&format=RAW";
    }
    if(format && format.toUpperCase() === "EVALUATION") {
      url = url + "&items=CORRECT";
    }
    if (option.toUpperCase() === "ALL") {
      url = url + "&items=ALL";
    } else if (option.toUpperCase() === "TAGGED") {
      url = url + "&items=DONE";
    } else if (option.toUpperCase() === "NONTAGGED") {
      url = url + "&items=NOT_DONE";
    }
    if (format && format.toUpperCase() === "DICOM_JSON")
      url = url + "&format=DICOM_JSON";
    else if (format && format.toUpperCase() === "CSV")
      url = url + "&format=CSV";
    if (format && format.toUpperCase() === "STANFORD") {
      url = url + "&format=STANFORD_NER";
      superagent
        .post(url)
        .responseType('blob')
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    } else {
      superagent
        .post(url)
        .responseType('blob')
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    }
  };


  export const downloadfilecsv = (pid, option, callback, format, filterInputparam, downloadfile) => {
    const { uid, token } = getUidToken();
    let url = BASE_URL + pid + "/download/csv?";
    if (null !== filterInputparam && filterInputparam.length > 0) {
      url = url + filterInputparam;
    }
    if (option.toUpperCase() === "ALL") {
      url = url + "&items=ALL";
    } else if (option.toUpperCase() === "TAGGED") {
      url = url + "&items=DONE";
    } else if (option.toUpperCase() === "NONTAGGED") {
      url = url + "&items=NOT_DONE";
    }
    if (format && format.toUpperCase() === "DICOM_JSON")
      url = url + "&format=DICOM_JSON";
    else if (format && format.toUpperCase() === "CSV")
      url = url + "&format=CSV";
    else if (format && format.toUpperCase() === "yolo")
      url = url + "&format=YOLO";
    if (format && format.toUpperCase() === "STANFORD") {
      url = url + "&format=STANFORD_NER";
      superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    } else {
      superagent
        .post(url)
        .set("uid", uid)
        .set("token", token)
        .end((err, res) => {
          middleCallback(err, res, callback)
        });
    }
  };
  

  export const clearMachinePrediction = (pid, hitId , type, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + pid + "/clearMachinePrediction?"+ (type === "clear" ? "hitId="+ hitId : "type=clearAll"))
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
};

export const saveComments = (pid, hitId, data, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + pid + "/saveComments/" + hitId)
      .set("uid", uid)
      .set("token", token)
      .send(data)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const getCommentsDetails = (hitId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .get(BASE_URL + hitId + "/getComments")
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const updateComments = (hitId, commentId, data, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + hitId + "/updateComments/" + commentId)
      .set("uid", uid)
      .set("token", token)
      .send(data)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const deleteComments = (hitId, commentId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + hitId + "/deleteComments/" + commentId)
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const getComments = (hitId, callback) => {
    const { uid, token } = getUidToken();
    superagent
      .get(BASE_URL + hitId + "/getComments")
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const sendInvite = (pid, email, isOwner, callback) => {
    console.log("sending invite ", pid, encodeURIComponent(email));
    const { uid, token } = getUidToken();
    let role = "CONTRIBUTOR";
    if (isOwner) {
      role = "ADMIN_CONTRIBUTOR";
    }
    superagent
      .post(
        BASE_URL +
        pid +
        "/addContributor?userEmail=" +
        encodeURIComponent(email) +
        "&role=" +
        role
      )
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        middleCallback(err, res, callback)
      });
  };

  export const extendTimeout = () => {
    const { uid, token } = getUidToken();
    superagent
      .post(BASE_URL + "extendTimeout")
      .set("uid", uid)
      .set("token", token)
      .end((err, res) => {
        window.sessionStorage.setItem(SESSION_VARIABLES.LAST_ACCESS_TIME_API, new Date().getTime());
        window.sessionStorage.setItem(SESSION_VARIABLES.TIME_EXPIRY_POPUP, BOOLEAN_TYPES.FALSE);
      });
  };

  export const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}