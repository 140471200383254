// HitsOverviewBox.js
import React from "react";
import {
  ROLES_TYPES,
  TEXT_MODERATION,
  POS_TAGGING_GENERIC,
  POS_TAGGING,
  hitStateNameMap,
  HIT_STATE_DONE,
  DOCUMENT_ANNOTATION
} from "../../helpers/Utils";
import { Icon, Button } from "semantic-ui-react";
import { MentionsInput, Mention } from "react-mentions";
import { getUidToken } from "../../helpers/commonFunctions.js";
import { message, Icon as AntIcon } from "antd";
import { Select,LocaleProvider } from "antd";
import { DOC_ENTITY_COLORS } from "../../helpers/Utils";
import "../AnnotationLableModel/AnnotationLableModel.css"
import en_US from "antd/lib/locale-provider/en_US";

class HitsOverviewBox extends React.Component {

  constructor()
  {
    super()
    this.state={
      totalLabelCount:0
    }
  }



  copyConvId(copyText) {
    var textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    message.info("Conversation Id copied to clipboard.", 2);
  }

  getHitDetails(hit) {
    console.log("Hits in hitsdeta",hit)
    const { propsState } = this.props;
    const { task_type } = this.props.projectDetails;
    if (hit && hit.hitResults 
    ) {
      console.log("Entered in the if of getHitDetails")
      return (
        <div className="status_area m-b-10">
          <h5>Annotator Info</h5>
          <p><b>Name: </b>{propsState.hitUserName ? propsState.hitUserName : hit.hitUserName}</p>
          <p><b>Email:</b> {propsState.hitUserEmail ? propsState.hitUserEmail : hit.hitUserEmail}</p>
          {propsState.hitFileName &&
            task_type !== TEXT_MODERATION &&
            task_type !== POS_TAGGING_GENERIC &&
            task_type !== POS_TAGGING && (
              <p>
                <b> File Name: </b>
                {propsState.hitFileName.replaceAll("__-__-", " ")}{" "}
              </p>
            )}
          <p>
            <b>Last Updated: </b>
            {propsState.hitUpdatedTime}
          </p>
          {propsState.hitEvaluatedBy !== "" && (
            <p>
              <b>Evaluated By:</b> {propsState.hitEvaluatedBy}
            </p>
          )}
          <div className="text-center m-t-5">
            {propsState.hitConvId && (
              <div
              >
                <p>
                  <b>ConversationId:</b>
                </p>
                <div className="linkn_copy">
                  <Icon
                    name="copy"
                    title="Copy Conversation Id to clipboard"
                    onClick={() => this.copyConvId(propsState.hitConvId)}
                  />
                  <p>{propsState.hitConvId} </p>
                </div>
              </div>
            )}
            {propsState.hitStatus && (
              <span className="status_btn m-b-5">
                {hitStateNameMap[propsState.hitStatus]}
              </span>
            )}
            {propsState.hitStatus &&
              propsState.hitEvaluation &&
              propsState.hitEvaluation.toLowerCase() === "correct" && (
                <span
                  className="status_btn m-b-5"
                  style={{ backgroundColor: "#12c4fc", color: "white" }}
                >
                  {propsState.hitEvaluation}
                </span>
              )}
            {propsState.hitStatus &&
              propsState.hitEvaluation &&
              propsState.hitEvaluation.toLowerCase() === "incorrect" && (
                <span
                  className="status_btn m-b-5"
                  style={{ backgroundColor: "#e0595d", color: "white" }}
                >
                  {propsState.hitEvaluation}
                </span>
              )}
          </div>
        </div>
      );
    }
  }

  getComments = () => {
    const { propsState, saveComments, handleChange } = this.props;

    const userMentionData = propsState.contributorDetails.map((myUser) => ({
      id: myUser.userDetails.uid,
      display: `${myUser.userDetails.firstName} ${myUser.userDetails.secondName}`,
    }));

    return (
      <div className="CommentSec">
        <MentionsInput
          value={propsState.comments}
          onChange={handleChange}
          markup="@{{__type__||__id__||__display__}}"
          placeholder="Write your comments..."
          className="mentions"
        >
          <Mention
            type="user"
            trigger="@"
            data={userMentionData}
            className="mentions__mention"
          />
        </MentionsInput>
        <Button className="btn_base btn_correct" onClick={saveComments}>
          {" "}
          Save{" "}
        </Button>
      </div>
    );
  };

  getHitDetailsAll(hit) {
    const { task_type } = this.props.projectDetails;
    const { propsState, propsData } = this.props;
    const { user } = propsData;
    const {
      hitUpdatedTime,
      hitEvaluatedBy,
      hitConvId,
      hitStatus,
      hitEvaluation,
    } = propsState;

    return (
      <div className="status_area m-b-10">
        <h5>Annotator Info</h5>
        <p>
          <b>Name: </b>
          {user?.firstName}
        </p>
        <p>
          <b>Email:</b> {user?.email}
        </p>
        {hit.fileName &&
          task_type !== TEXT_MODERATION &&
          task_type !== POS_TAGGING_GENERIC &&
          task_type !== POS_TAGGING && (
            <p>
              <b> File Name: </b>
              {hit.fileName.replaceAll("__-__-", " ")}{" "}
            </p>
          )}
        {hit.hitResults && (
          <p>
            <b>Last Updated: </b>
            {hitUpdatedTime}
          </p>
        )}
        {hitEvaluatedBy !== "" && hit.hitResults && (
          <p>
            <b>Evaluated By:</b> {hitEvaluatedBy}
          </p>
        )}
        <div className="text-center m-t-5">
          {hit.hitResults && hitConvId && (
            <div>
              <p>
                <b>ConversationId:</b>
              </p>
              <div className="linkn_copy">
                <Icon
                  name="copy"
                  title="Copy Conversation Id to clipboard"
                  onClick={() => this.copyConvId(hitConvId)}
                />
                <p>{hitConvId} </p>
              </div>
            </div>
          )}
          {hit.hitResults && hitStatus && (
            <span className="status_btn m-b-5">
              {hitStateNameMap[hitStatus]}
            </span>
          )}
          {hit.hitResults &&
            hitStatus &&
            hitEvaluation &&
            hitEvaluation.toLowerCase() === "correct" && (
              <span
                className="status_btn m-b-5"
                style={{ backgroundColor: "#12c4fc", color: "white" }}
              >
                {hitEvaluation}
              </span>
            )}
          {hit.hitResults &&
            hitStatus &&
            hitEvaluation &&
            hitEvaluation.toLowerCase() === "incorrect" && (
              <span
                className="status_btn m-b-5"
                style={{ backgroundColor: "#e0595d", color: "white" }}
              >
                {hitEvaluation}
              </span>
            )}
        </div>
      </div>
    );
  }


  getAnnotatedLabels = () => {
    let result = "";
    let labelList = [];
    const { currentHit,annotateModalOpen,changesInSession,selectedLabel,onlyUnique,
      sortEntities,handleLabelChange } = this.props;
    console.log("check currentHit.hitResults[0].result", currentHit);
    result = currentHit.hitResults[0].result;
    if (result !== "") {
      let jsonObj = JSON.parse(result);
      if (Array.isArray(jsonObj.annotationResult)) {
        jsonObj.annotationResult.forEach((element) => {
          element.label.map((result) => {
            labelList.push(result.value);
          });
        });

        this.labelCountMap = new Map(
          [...new Set(labelList)].map((x) => [
            x,
            labelList.filter((y) => y === x).length,
          ])
        );

        if (labelList.length > 1) {
          labelList = labelList.filter(onlyUnique);
          labelList = labelList.sort(sortEntities);
        }
        this.state.totalLabelCount = labelList.length;
      }
    }
    console.log("labl added");
    const options = labelList.map((element, index) => (
      <option
        disabled={
          changesInSession > 0 || annotateModalOpen
            ? true
            : false
        }
        className="option_label"
        style={{ color: DOC_ENTITY_COLORS[index],display:"flex",gap:"10px",width:"fit-content" }}
        label={element}
        value={element + ":" + DOC_ENTITY_COLORS[index]}
      >
        {element}
        <div style={{width:"20px",height:"20px",borderRadius:"50%",backgroundColor:"grey",
          display:"flex",justifyContent:"center",alignItems:"center",color:"white",
        }}>
        {this.labelCountMap.get(element)}
        </div>
      </option>
    ));
    return (
      <div className="w-100 tags_data_annotate">
        <LocaleProvider locale={en_US}>
          <Select
            className="select_width"
            dropdownClassName="m_list"
            mode="multiple"
            defaultOpen
            showSearch
            placeholder="Select a label"
            optionLabelProp="label"
            onChange={handleLabelChange}
            value={
                  selectedLabel === ""
                ? undefined
                : selectedLabel
            }
            disabled={
              changesInSession > 0 || annotateModalOpen
                ? true
                : false
            }
            filterOption={(input, option) =>
              option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options}
          </Select>
        </LocaleProvider>
      </div>
    );
  };

  render() {
    const {
      currentHit,
      stateType,
      contributorDetails,
      showButtonsMini,
      showEvaluationButtons,
      showActionButtons,
      hits,
      type,
      displaySettingsByRole,
      changesInSession,
      handleLabelChange,
    } = this.props;
    const { totalLabelCount } = this.state;

    return (
      <div className="right_box w-20 m-l-20 box_shodow m-h-720">
        <div className="sec_top">
          <div className="r_box">
            <h4 className="sec_tilte">
              <span>HITs Overview</span>
            </h4>
            {showButtonsMini}
          </div>
          {currentHit &&
            currentHit.hitResults &&
            this.getHitDetails(currentHit)}
          {currentHit &&
            !currentHit.hitResults &&
            stateType === "all" &&
            this.getHitDetailsAll(currentHit)}

          {currentHit &&
            currentHit.hitResults &&
            (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.QA) && (
              <div className="sec_bottom  m-t-0 m-b-10">
                <h4 className="m-b-10 txt-center">Evaluate Tagging</h4>
                {showEvaluationButtons}
              </div>
            )}
          {currentHit &&
            !currentHit.hitResults &&
            stateType === "all" &&
            (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.QA) && (
              <div className="sec_bottom  m-t-0 m-b-10">
                <h4 className="m-b-10 txt-center">Evaluate Tagging</h4>
                {showEvaluationButtons}
              </div>
            )}
          {currentHit && showActionButtons}
          {contributorDetails && this.getComments()}

          {hits &&
            currentHit &&
            currentHit.hitResults &&
            currentHit.hitResults.length > 0 &&
            type === HIT_STATE_DONE &&
            this.props.projectDetails.task_type === DOCUMENT_ANNOTATION && (
              <div className="w-100 m-t-15 m-b-30" id="custom_labelselect">
                <div
                className="customLab"
                >
                  <h5 className="m-b-10">
                    LABELS TAGGED :{" "}
                    <span 
                    className="labelTagg"
                    >
                      {totalLabelCount}</span>
                  </h5>
                  <a
                    className="btn_base btn_active btn_default clr_btn"
                    style={{
                      cursor: changesInSession > 0 ? "not-allowed" : "pointer",
                    }}
                    onClick={(e)=>handleLabelChange(e,"clearAll")}
                    title={changesInSession > 0 ? "" : "Reset"}
                  >
                    <AntIcon type="rollback" />
                  </a>
                </div>
                <div
                  className="annotateCon"
                  style={{
                    display: displaySettingsByRole(getUidToken().roleId),
                  }}
                >
                  {this.getAnnotatedLabels()}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default HitsOverviewBox
