import React from "react";
import { Button, Label, Dropdown, Checkbox, Icon } from "semantic-ui-react";
import { ROLES_TYPES } from "../../helpers/Utils";
import { getUidToken } from "../../helpers/commonFunctions";
import ColorPicker from '../../Components/ColorPicker/ColorPicker';
import {
  IMAGE_SEGMENTATION,
  IMAGE_MASKING,
  getGroupingLabels,
  displaySettingsByRole,
} from "../../helpers/Utils";
class LablesData extends React.Component {
  render() {
    const {
      propsState,
      propsData,
      entries,
      isDarkColor,
      addDynamicLabel,
      changeLabel,
      closeDropdownFromNotes,
      closeDropdownFromAttributes,
      toggleEyeRectStatus,
      taskType,
      openDropdown,
      closeDropdown,
      deleteAnnotation,
      handleColorChange,
      handleClose,
      removeRect,
    } = this.props;

    const cursorType = propsData.isSampleData ? "default" : "pointer";
    let arrs = [];
    const { rectCatMap, notes, labelGroupMap } = propsState;
    let entitiesLab = [];
    entitiesLab = propsData.entities
      ? [...propsData.entities]
      : [...propsState.entities];
    if (propsData.hitLabels && propsData.hitLabels.length > 0) {
      propsData.hitLabels.map((elem) => {
        if (!entitiesLab.includes(elem)) {
          entitiesLab.push(elem);
        }
      });
    }
    let groupLabels = getGroupingLabels(labelGroupMap);
    let index = 0;
    let width = "20%";

    for (const [key, value] of entries(groupLabels)) {
      let boxShadow = "";
      let transform = "";
      let position = "";
      let zIndex = "";
      let border = "";
      if (propsState.mouseHoverMap[key]) {
        boxShadow = "0px 0px 10px 2px #000";
        transform = "scaleY(1.2)";
        border = "2px dashed rgb(255, 255, 255)";
      }
      if (rectCatMap[key] && rectCatMap[key].length > 0) {
        const entitySet = new Set(rectCatMap[key]);
        const options = [];
        position = "absolute";
        zIndex = "-1";
        options.push({
          key: "addLabel",
          text: (
            <Button size="mini" onClick={() => addDynamicLabel(key)}>
              Add Label
            </Button>
          ),
        });
        for (let jindex = 0; jindex < entitiesLab.length; jindex++) {
          position = "relative";
          zIndex = "1";
          if (entitySet.has(entitiesLab[jindex])) {
            options.push({
              key: entitiesLab[jindex],
              text: (
                <Checkbox
                  checked
                  onChange={changeLabel.bind(this, key, jindex)}
                  label={entitiesLab[jindex]}
                  title={
                    entitiesLab[jindex].length > 10 ? entitiesLab[jindex] : ""
                  }
                />
              ),
            });
          } else {
            options.push({
              key: entitiesLab[jindex],
              text: (
                <Checkbox
                  onChange={changeLabel.bind(this, key, jindex)}
                  label={entitiesLab[jindex]}
                  title={
                    entitiesLab[jindex].length > 10 ? entitiesLab[jindex] : ""
                  }
                />
              ),
            });
          }
        }
        if (!propsData.drawHandle) {
          if (notes && key in notes && notes[key].length > 0) {
            options.push({ key: "note", text: notes[key] });
          }
        } else {
          if (
            notes &&
            key in notes &&
            notes[key].length > 0 &&
            (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)
          ) {
            options.push({
              key: "note",
              text: (
                <Button size="mini" onClick={() => closeDropdownFromNotes(key)}>
                  {" "}
                  Edit Note{" "}
                </Button>
              ),
            });
          } else if (
            parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
            parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR
          ) {
            options.push({
              key: "note",
              text: (
                <Button size="mini" onClick={() => closeDropdownFromNotes(key)}>
                  {" "}
                  Add Note{" "}
                </Button>
              ),
            });
          }
        }

        if (propsData.attributes) {
          options.push({
            key: "Attribute",
            text: (
              <Button
                size="mini"
                onClick={() => closeDropdownFromAttributes(key)}
              >
                {" "}
                Edit Attributes{" "}
              </Button>
            ),
          });
        }
        arrs.push(
          <div
            className="l_blabel"
            disabled
            key={key}
            id={key}
            ref={value}
            style={{
              maxWidth: `${width}`,
              wordBreak: "break-word",
              borderRadius: "15px",
              boxShadow: `${boxShadow}`,
              transform: `${transform}`,
              position: `${position}`,
              zIndex: `${zIndex}`,
              border: `${border}`,
              display: "flex",
              marginRight: "2px",
              marginBottom: "5px",
              color: "white",
              backgroundColor: `${ propsData.entityColorMap[propsData.rectCatMap[key]]}`,
            }}
          >
            {key in propsState.hideRectMap &&
              propsState.hideRectMap[key] === true &&
              propsData.drawHandle && (
                <Icon
                  size="small"
                  title="Show Label"
                  name="low vision"
                  style={{
                    cursor: `${cursorType}`,
                    color: `${
                      isDarkColor(propsData.hitEntities[key])
                        ? "white"
                        : "darkslategrey"
                    }`,
                    fontSize: "10px",
                    display: displaySettingsByRole(getUidToken().roleId),
                  }}
                  onClick={(event) => {
                    toggleEyeRectStatus(key, false, event);
                  }}
                />
              )}
            {(!(key in propsState.hideRectMap) ||
              (propsState.hideRectMap &&
                propsState.hideRectMap[key] === false)) &&
              propsData.drawHandle && (
                <Icon
                  name="eye"
                  title="Hide Label"
                  style={{
                    cursor: `${cursorType}`,
                    color: `${
                      isDarkColor(propsData.hitEntities[key])
                        ? "white"
                        : "darkslategrey"
                    }`,
                    fontSize: "10px",
                    display: displaySettingsByRole(getUidToken().roleId),
                  }}
                  onClick={(event) => {
                    toggleEyeRectStatus(key, true, event);
                  }}
                />
              )}
            {!propsData.fullScreen
              ? propsData.drawHandle && (
                  <Icon
                    title="Delete"
                    style={{
                      cursor: `${cursorType}`,
                      fontSize: "10px",
                      display: displaySettingsByRole(getUidToken().roleId),
                      color: `${
                        isDarkColor(propsData.hitEntities[key])
                          ? "white"
                          : "darkslategrey"
                      }`,
                    }}
                    name="delete"
                    id={key}
                    onClick={removeRect.bind(this)}
                  />
                )
              : propsData.drawHandle && (
                  <Icon
                    title="Delete"
                    style={{
                      cursor: `${cursorType}`,
                      fontSize: "10px",
                      display: displaySettingsByRole(getUidToken().roleId),
                      color: `${
                        isDarkColor(propsData.hitEntities[key])
                          ? "white"
                          : "darkslategrey"
                      }`,
                    }}
                    name="delete"
                    id={key}
                    onClick={() => deleteAnnotation(key)}
                  />
                )}
            {propsData.drawHandle &&
            (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN ||
              parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) ? (
              <Dropdown
                text={rectCatMap[key].join(",")}
                floating
                inline
                upward
                labeled
                button
                compact
                open={propsState.showDropdown[key]}
                closeOnChange={!propsState.showDropdown[key]}
                closeOnBlur={false}
                onOpen={() => openDropdown(key)}
                className={
                  "icon " +
                  (rectCatMap[key].join(",").length > 10 ? "b_labeloption" : "")
                }
                style={{
                  fontSize: "xx-small",
                  color: `${
                    isDarkColor(propsData.hitEntities[key])
                      ? "white"
                      : "darkslategrey"
                  }`,
                  backgroundColor: `${ propsData.entityColorMap[propsData.rectCatMap[key]]}`,
                }}
                disabled={propsData.noTagSelection ? true : false}
              >
                <Dropdown.Menu>
                  <Dropdown.Header
                    content={
                      <div style={{ width: "100%", float: "right" }}>
                        <Icon
                          size="large"
                          onClick={() => closeDropdown(key)}
                          className="pull-right"
                          name="close"
                        />
                      </div>
                    }
                  />
                  <div style={{ maxHeight: "20rem", overflow: "scroll" }}>
                    {options.map((optionItem) => (
                      <div style={{ margin: "10px" }}>{optionItem.text}</div>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown
                open={propsState.showDropdown[key]}
                closeOnChange={!propsState.showDropdown[key]}
                closeOnBlur={false}
                onOpen={() => openDropdown(key)}
                className={
                  "icon " +
                  (rectCatMap[key].join(",").length > 10 ? "b_labeloption" : "")
                }
                style={{
                  fontSize: "xx-small",
                  color: `${
                    isDarkColor(propsData.hitEntities[key])
                      ? "white"
                      : "darkslategrey"
                  }`,
                  backgroundColor: `${ propsData.entityColorMap[propsData.rectCatMap[key]]}`,
                }}
                labeled
                text={rectCatMap[key].join(",")}
                disabled={propsData.noTagSelection ? true : false}
              />
            )}
            {propsData.drawHandle &&
              (taskType === IMAGE_SEGMENTATION || IMAGE_MASKING) && (
                <div className="colorPickerCustom">
                  <ColorPicker className="ts" pickerColor={propsData.hitEntities[key]} 
                  handleColorChange={handleColorChange}
                  handleClose={handleClose} />
                </div>
              )}
            {!propsData.drawHandle && (
              <Label
                disabled
                className={
                  rectCatMap[key].join(",").length > 10 ? "lblwrap" : ""
                }
                style={{
                  fontSize: "xx-small",
                  color: `${
                    isDarkColor(propsData.hitEntities[key])
                      ? "white"
                      : "darkslategrey"
                  }`,
                  backgroundColor: `${propsData.hitEntities[key]}`,
                }}
                title={rectCatMap[key].join(",")}
              />
            )}
          </div>
        );
      }
      index = index + 1;
    }

    return (
      <div
        style={{
          marginTop: "5px",
          alignContent: "flex-start",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {" "}
        {arrs}{" "}
      </div>
    );
  }
}

export default LablesData