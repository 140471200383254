// Videoannotator.js
import React, { Component, PropTypes } from 'react';
import { Modal as AntdModal, Select } from 'antd';
import 'semantic-ui-css/semantic.min.css';
import { Button, Label, Icon, Dimmer, Input, Dropdown, Form, Checkbox  } from 'semantic-ui-react';
import Modal from 'react-bootstrap/lib/Modal';
import ReactSlider from 'react-slider';

import { convertKeyToString, displaySettingsByRole, ROLES_TYPES } from '../../helpers/Utils';
import Panner from '../../helpers/PinchZoomPan/CenteredPanZoom';
import { TOOLTIP } from '../../helpers/Constants';
import ColorPicker from '../../Components/ColorPicker/ColorPicker';
import { getUidToken,showAlert } from '../../helpers/commonFunctions';
import { POLY_ANNOTATOR_CONSTANTS, VIDEO_ANNOTATOR_CONSTANTS, ACTIONS, MESSAGES, ALERT_TYPES } from '../../helpers/Constants';

const Mousetrap = require('mousetrap');
const SVG = require('svg.js');
export default class VideoAnnotator extends Component {
  constructor(props) {
    super(props);
    console.log('VideoAnnotator props', props);
    this.contentRef = null;
    this.state = {
      rects: props.rects,
      lock: null,
      selectedItem: undefined,
      selectedLockedHitsIndex: [],
      mouseHoverMap: {},
      lockAnnotation: false,
      lockedHits: [],
      lockHits: false,
      skippedFrames: 0,
      rectCatMap: props.rectCatMap,
      rectShapeMap: props.rectShapeMap,
      rectTimeMap: props.rectTimeMap,
      endTimeMap: props.endTimeMap,
      animationStartTime: undefined,
      hideLabelsMap: {},
      imgLoaded: {},
      entities: Object.keys(props.entityColorMap),
      canvasSet: false,
      frameTime: 1 / 25,  // can put frame rate here
      rfaMap: {},
      searchQuery: '',
      translate: {
        x: 0,
        y: 0
      },
      marginTop: 0,
      marginLeft: 0,
      currentStartTime: undefined,
      imgLoad: false,
      opacity: 0.25,
      brightness: 1.0,
      saturation: 1.0,
      defaultPlaybackRate: this.props.space ? 0.4 : 0.8,
      defaultVolume: 0.0,
      dx: 0,
      dy: 0,
      scale: 1,
      video: props.video,
      mouseHoverMap: {},
      toolName: this.props.defaultShape,
      drawButton: true,
      toolbarHidden: false,
      currentRect: [],
      hideRectMap: {},
      endTimeValue: 0,
      isEditEndTime: false,
      deletePointContent : '',
      reverseDeletePoint : undefined,
      deleteValue : undefined,
      minValue : undefined,
      attributes: props.attributes,
      showDropdown: {},
      openNote: false,
      notes: props.notes,
      openAttributeOption: false,
      attributesOption: props.attributesOption,
      attributeIndex: '',
      openHitLabel: false,
      labelGroupMap: props.labelGroupMap,
      selectedHitLabelIndex: [],
      hitLabel: '',
      hitKey: '',
      selectedHitLabel: '',
      deleteConfirm: false,
      addlabelHidden: false,
      dropdownVal : 0,
      labelKey: "",
      selectColor: true,
      selectColorClose: 0,
      activeElementType: "dropdown",
      targetVal : undefined,
      selectedOptions: {},
      inputValues: [],
      videoDuration: 0, // Set video duration here
      selectedTime: null,
      dragging: false,
      offsetX: 0,
      offsetY: 0,
      isDragging: false,
      value: [0.1, 0.3],
      trackWidth: 0,
      selectValue : null,
      slidevalues : props.slidevalues,
      setvals : 0,
      buttonPosition: 0,
      containerHeight: '200px',
      activeSliderIndex: null, 
      frameRate: 0,
      fpsSet: false,
      duration: null,
      zoomLevel: 1,
      buttonClicked : false,
    };
    this.loadImages = this.loadImages.bind(this);
    this.mousedownHandle = this.mousedownHandle.bind(this);
    this.mousemoveHandle = this.mousemoveHandle.bind(this);
    this.mouseupHandle = this.mouseupHandle.bind(this);
    this.savePolygon = this.savePolygon.bind(this);
    this.saveRectangle = this.saveRectangle.bind(this);
    this.clearPolygons = this.clearPolygons.bind(this);
    this.undoLast = this.undoLast.bind(this);
    this.resizeWindow = this.resizeWindow.bind(this);
    this._onWheel = this._onWheel.bind(this);
    this.zoom = this.zoom.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.changeCurrentTime = this.changeCurrentTime.bind(this);
    this.seek = this.seek.bind(this);
    this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.moveToNext = this.moveToNext.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleWheelZoom = this.handleWheelZoom.bind(this);
    this.setupEventListener = this.setupEventListener.bind(this);
  }


  componentDidMount() {
    console.log('component did mount', this.canvas);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleDragEnd);
    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleDragEnd);
    if (this.props.drawHandle) {
      let combo = '';
      if (this.props.shortcuts && 'undo' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.undo);
        Mousetrap.bind(combo, this.undoLast);
      }
      if (this.props.shortcuts && 'clearAll' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.clearAll);
        Mousetrap.bind(combo, this.clearPolygonnps);
      }
    }
    if (this.props.entityColorMap && this.state.slidevalues) {
      const newValues = [...this.state.slidevalues];
      for (let idx = 0; idx < Object.keys(this.props.entityColorMap).length; idx++) {
        if (newValues[idx] == undefined) {
          newValues[idx] = []
        }
      }
      this.setState({ slidevalues: newValues }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      })
    }
    this.adjustContainerHeight();
    Mousetrap.bind('del', this.deleteSelectedPoint)
    window.addEventListener('resize', this.resizeWindow);

    var vid = document.querySelector("video");
    if (vid && !this.props.fromSample) {
      vid.addEventListener('loadedmetadata', this.handleMetaData);
      vid.addEventListener('loadedmetadata', () => {
        this.setState({ duration: vid.duration });
        vid.play().then(() => {
          console.log('Video is playing');
        }).catch(error => {
          console.log('Error playing video:', error);
        });
        
        window.requestAnimationFrame((ts) => {
          this.setState({ animationStartTime: ts });
          this.increasePlayerTime(ts);
        });
      });
  
      vid.addEventListener('error', (e) => {
        console.error('Error loading video:', e);
      });
    }
  }

  setupEventListener() {
    if (this.contentRef) {
      this.contentRef.addEventListener('wheel', this.handleWheelZoom);
    }
  }

  handleWheelZoom = (e) => {
    e.preventDefault();
    const { zoomLevel } = this.state;
    const newZoomLevel = e.deltaY < 0 ? zoomLevel + 0.1 : zoomLevel - 0.1;
    this.setState({ zoomLevel: Math.max(newZoomLevel, 0.1) });
  };

  adjustContainerHeight = () => {
    if (this.contentRef) {
      const contentHeight = this.contentRef.offsetHeight;
      this.setState({ containerHeight: contentHeight });
    }
  };

  componentWillReceiveProps(nextProps) {
    console.log('VideoAnnotator nextprops', nextProps);
    if (nextProps.rects && nextProps.rectCatMap && (this.props.rects !== nextProps.rects)) {
      this.setState({ rects: nextProps.rects, endTimeMap: nextProps.endTimeMap, rectTimeMap: nextProps.rectTimeMap, rectShapeMap: nextProps.rectShapeMap, rectCatMap: nextProps.rectCatMap, image: nextProps.image});
    }
    if (this.props.video !== nextProps.video) {
      this.setState({imgLoad: false, canvasSet: false, videoLoad: false, hideRectMap: {}, video: nextProps.video });
      this.player.source = nextProps.video;
      this.DrawBoard = null;
    }
    if (nextProps.attributesOption) {
      this.setState({ attributesOption: nextProps.attributesOption });
    }
  }

  componentWillUnmount() {
    console.log('VideoAnnotator unmount');
    Mousetrap.bind('del', this.deleteSelectedPoint)
    window.removeEventListener('resize', this.resizeWindow);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleDragEnd);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleDragEnd);
    if (this.contentRef) {
      this.contentRef.removeEventListener('wheel', this.handleWheelZoom);
    }
  }

  handleMouseMove = (e) => {
    this.lastMousePosition = e.clientX;
  }
  
  handleTouchMove = (e) => {
    this.lastMousePosition = e.touches[0].clientX;
  }

  handleDragEnd = () => {
    this.activeThumbIndex = null;
  }

  handleLineClick = (time) => {
    this.setState({ selectedTime: time });
    const video = document.getElementsByTagName("video");
    const seekTime = time * 1;
    video[0].currentTime = seekTime;
    const buttonPosition = (seekTime / video[0].duration) * 100;
    this.setState({ buttonPosition });
  };

  formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours < 10 ? '0' + hours : hours}.${minutes < 10 ? '0' + minutes : minutes}.${seconds < 10 ? '0' + seconds : seconds}`;
  };

  handleLineParentClick = (event) => {
    const video = document.getElementsByTagName("video");
    const clickPositionX = event.clientX - event.currentTarget.getBoundingClientRect().left;
    const clickedTime = (clickPositionX / event.currentTarget.offsetWidth) * video[0].duration;
    this.setState({ selectedTime: clickedTime });
    const seekTime = clickedTime * 1;
    video[0].currentTime = seekTime;
    this.setupEventListener();
  };

  handleTimelineChange = (event) => {
    const video = document.getElementsByTagName("video");
    const clickPositionX = event.clientX - event.currentTarget.getBoundingClientRect().left;
    const clickedTime = (clickPositionX / event.currentTarget.offsetWidth) * video[0].duration;
    this.setState({ selectedTime: clickedTime });
    const seekTime = clickedTime * 1;
    video[0].currentTime = seekTime;
  }

  handleSliderChange = (newValues, index, idx) => {
    const video = document.getElementsByTagName("video");
    const fixedTime = newValues[index] * video[0].duration;
    if(!isNaN(fixedTime)){
      video[0].currentTime = fixedTime;
    }
    const { slidevalues } = this.state;
    slidevalues[idx] = newValues
    this.setState({ slidevalues : slidevalues, activeSliderIndex: this.state.setvals })
  };

  handleVideoTimeUpdate = () => {
    const video = document.getElementsByTagName("video");
    const currentTime = video[0].currentTime;
    const buttonPosition = (currentTime / video[0].duration) * 100;
    this.setState({ buttonPosition });
  };

  handleButtonClick = (idx) => {
    this.props.drawHandle(this.state);
    const newValues = [...this.state.slidevalues];
    if(newValues[idx].length == 0) {
        newValues[idx] = [0.1, 0.3]
    } else {
        newValues[idx].push(newValues[idx][newValues[idx].length - 1] + 0.01, newValues[idx][newValues[idx].length - 1] + 0.2);
    }
    this.setState({ slidevalues : newValues}, () => {
                if (this.props.drawHandle) {
                  this.props.drawHandle(this.state).then(() => {
                    this.setState({ buttonClicked : true})
                  });
        }
    })
  };

  handleDelete = (id) => {
    this.props.drawHandle(this.state);
    const newValues = [...this.state.slidevalues];
    if (newValues[id].length > 1) {
      newValues[id].splice(-2, 2);
    }
  this.setState({ slidevalues: newValues}, () => {
    if (this.props.drawHandle) {
    this.props.drawHandle(this.state);
    }
  })
  }

  getPoint(point) {
    console.log('point is', point);
    if (point < 0.0) { return 0.0; }
    if (point > 1.0) { return 1.0; }
    return point;
  }

  getPoints(currentRect) {
    let points = '';
    for (let index = 0; index < currentRect.length; index ++) {
      points = points + currentRect[index][0] + ',' + currentRect[index][1] + ' ';
    }
    return points.trim();
  }

  getDecPoints(currentRect) {
    let points = '';
    if (currentRect) {
      for (let index = 0; index < currentRect.length; index ++) {
          points = points + Math.ceil(currentRect[index][0] * this.state.videoWidth) + ',' + (Math.ceil(currentRect[index][1] * (this.state.videoHeight))) + ' ';
      }
    }
    return points.trim();
  }

  setMuted(muted) {
    return () => {
      this.state.player.muted = muted;
    };
  }

  getWindowDimeshions() {
    let windowHeight = (window.innerHeight * 70) / 100;
    let windowWidth = (window.innerWidth * 80) / 100;
    if (!this.props.space) {
      windowHeight = (window.innerHeight * 70) / 100;
      windowWidth = (window.innerWidth * 60) / 100;
    }
    if (this.props.fullScreen) {
      windowHeight = (window.innerHeight * 95) / 100;
    }
    if (this.state.toolbarHidden && this.props.fullScreen) {
      windowWidth = (window.innerWidth * 85) / 100;
    }
    return { windowWidth, windowHeight };
  }
  setVolume(steps) {
    this.state.player.volume = steps;
    this.setState({ defaultVolume: steps });
  }

  getCoords = (rect) => {
    let x = this.state.videoWidth;
    let y = this.state.videoHeight;
    let xlg = 0;
    let ylg = 0;
    if (rect) {
      for (let jindex = 0; jindex < rect.length; jindex ++) {
        let currentPoint = rect[jindex];
        let currentx = currentPoint[0];
        let currenty = currentPoint[1];
        if (x > currentx) {
          x = currentx;
        }
        if (y > currenty) {
          y = currenty;
        }
        if (currentx > xlg) {
          xlg = currentx;
        }
        if (currenty > ylg) {
          ylg = currenty;
        }
      }
    }
    xlg = Math.ceil(xlg * this.state.videoWidth);
    ylg = Math.ceil(ylg * this.state.videoHeight);

    x = Math.ceil(x * this.state.videoWidth);
    y = Math.ceil(y * this.state.videoHeight);

    let width = Math.abs(xlg - x);
    let height = Math.abs(ylg - y);
    return { x, y, width, height };
  }

  convertToPoint(x, y) {
    if (x < 0) {
      x = 0;
    }
    if (y < 0) {
      y = 0;
    }
    if (x > this.state.videoWidth) {
      x = this.state.videoWidth;
    }
    if (y > this.state.videoHeight) {
      y = this.state.videoHeight
    }
    return {x, y};
  }


  increasePlayerTime(ts) {
    if (this.state.player === null || this.state.player === undefined || this.state.player.currentTime === undefined) {
      console.log('increasePlayerTime undefined');
      window.cancelAnimationFrame(this.increasePlayerTime);
      this.setState({ rfa: false });
      return;
    }
    let timeTraveled = (ts - (this.state.animationStartTime ? this.state.animationStartTime : window.performance.now())) / 1000;
    console.log('increasePlayerTime timeNote 1', timeTraveled, this.state.player.currentTime, ts, window.performance.now());
    let flag = false;
    let threshold = ( 0.9 / (25 * this.state.player.playbackRate));
    if (!this.DrawBoard) this.DrawBoard = SVG(this.svgId).size(this.state.videoWidth, this.state.videoHeight);
    console.log('increasePlayerTime 2 elapsed', timeTraveled, threshold, this.DrawBoard);
    if ((Math.floor(threshold * 100) > Math.floor(timeTraveled * 100))) {
      if (!this.state.player.paused && this.state.player.currentTime !== this.state.player.duration) {
        window.requestAnimationFrame((tse) => { this.increasePlayerTime(tse); });
        console.log('increasePlayerTime false-start restart');
        return;
      }
    }
      this.state.rects.map((rect, index) => {
        const entity = this.props.rectCatMap[index];
        const timeMap = this.props.rectTimeMap[index];
        let startTime = 0.0;
        let id = index;
        id = index + '--node';
        let endTime = this.state.player.duration;
        let shape = this.props.rectShapeMap[index];
        if (timeMap) {
          startTime = timeMap[0];
          endTime = this.props.endTimeMap[index] ? this.props.endTimeMap[index] : this.state.player.duration;
        }
        console.log('increasePlayerTime looping', this.state.player.paused, this.state.player.currentTime, startTime, endTime, entity in this.state.hideLabelsMap, ( entity in this.state.hideLabelsMap && this.state.hideLabelsMap[entity] === false))
        if (!this.state.player.paused && (this.state.player.currentTime >= startTime && this.state.player.currentTime <= endTime) && ((!(entity in this.state.hideLabelsMap)) ||
            ( entity in this.state.hideLabelsMap && this.state.hideLabelsMap[entity] === false))) {
          const lineColor = this.props.entityColorMap[entity];
        //   let labelColor = index in this.props.hitEntities ? this.props.hitEntities[index] : this.props.entityColorMap[this.props.rectCatMap[index]] || this.props.entityColorMap[this.props.rectCatMap[index][0]]
        let labelColor;
        if (index in this.props.hitEntities) {
            labelColor = this.props.hitEntities[index];
        } else {
            const rectCat = this.props.rectCatMap[index];
            if (rectCat !== undefined) {
                labelColor = this.props.entityColorMap[rectCat] || this.props.entityColorMap[rectCat[0]];
            } else {
                labelColor = undefined;
            }
        }
          let timeIndex = 0;
          for (let jindex = 0; jindex < timeMap.length; jindex ++) {
            if (timeMap[jindex] && timeMap[jindex] > this.state.player.currentTime) {
              timeIndex = jindex - 1;
              break;
            } else if (timeMap[jindex]) {
              timeIndex = jindex;
            }
          }
          let currentRectPoints = rect[timeIndex];
          if (shape === 'rectangle' && currentRectPoints !== undefined) {
            let diffX = 0;
            let diffY = 0;
            let diffWidth = 0;
            let diffHeight = 0;

            let currentCoords = this.getCoords(currentRectPoints);
            let element = document.getElementById(id);
            if (element !== null) {
              if (this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )) {
                let nextCoords = this.getCoords(rect[timeIndex + 1]);
                let difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
                diffX = (nextCoords.x - currentCoords.x) / difft;
                diffY = (nextCoords.y - currentCoords.y) / difft;
                diffWidth = (nextCoords.width - currentCoords.width) / difft;
                diffHeight = (nextCoords.height - currentCoords.height) / difft;
              }
              this.setState({animationStartTime:ts});
              console.log('increasePlayerTime 3 repaint', element);
              let el = SVG.adopt(element);
              let currentx = el.attr('x') - 0;
              let currenty = el.attr('y') - 0;
              let currentw = el.attr('width') - 0;
              let currenth = el.attr('height') - 0;
              let dt = timeTraveled * this.state.player.playbackRate;
              let dx = (diffX * dt)
              let dy = (dt * diffY)
              let dw = (dt * diffWidth)
              let dh = (dt * diffHeight)
              currentx = currentx + dx;
              currenty = currenty + dy;
              currentw = currentw + dw;
              currenth = currenth + dh;
              el.attr('x', currentx);
              el.attr('y', currenty);
              el.attr('width', currentw);
              el.attr('height', currenth);
              flag = true;
            } else {
              let x = this.state.videoWidth;
              let y = this.state.videoHeight;
              let xlg = 0;
              let ylg = 0;
              for (let jindex = 0; jindex < currentRectPoints.length; jindex ++) {
                let currentPoint = currentRectPoints[jindex];
                let currentx = currentPoint[0];
                let currenty = currentPoint[1];
                let diffx = 0;
                let diffy = 0;
                let difft = 1;
                if (this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )
                      && timeMap[timeIndex] !== undefined && ( rect[timeIndex] !== undefined && rect[timeIndex + 1] !== undefined && rect[timeIndex].length === rect[timeIndex + 1].length)) {
                  diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                  diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
                  difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
                }

                currentx = currentx + (this.state.player.currentTime - timeMap[timeIndex] + (timeTraveled * this.state.player.playbackRate )) * (diffx / difft);
                currenty = currenty + (this.state.player.currentTime - timeMap[timeIndex] + (timeTraveled * this.state.player.playbackRate )) * (diffy / difft);
                if (x > currentx) {
                  x = currentx;
                }
                if (y > currenty) {
                  y = currenty;
                }
                if (currentx > xlg) {
                  xlg = currentx;
                }
                if (currenty > ylg) {
                  ylg = currenty;
                }
              }
                let width = Math.abs(xlg - x);
                let height = Math.abs(ylg - y);
                x = x * this.canvas.offsetWidth;
                y = y * this.canvas.offsetHeight;
                flag = true;
                width = width * this.canvas.offsetWidth;
                height = height * this.canvas.offsetHeight;
                let rectResponse = this.DrawBoard.rect(width, height).id(id).attr({ x: x, y: y, fill: `${labelColor}`, cursor: 'pointer', 'fill-opacity': `${this.state.opacity}`, stroke: '#1ae04e' });
                console.log('increasePlayerTime drawTime', id, x, y, width, height, rectResponse);
              }
            } else  if (currentRectPoints !== undefined) {
              let element = document.getElementById(id);
                  // get dec points
                if (element !== null) {
                  let el = SVG.get(id)
                  if (el !== null) {
                    console.log('increasePlayerTime 1 remove node', id, el);
                    try {
                      el.remove();
                    } catch (ex) {
                      console.log('increasePlayerTime 1 remove node exception', ex);
                    }
                  }
                }
                  let newPoints = [];
                let difft = 1;

                if (this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )) {
                  difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
                }
                for (let jindex = 0; jindex < currentRectPoints.length; jindex ++) {
                  let currentPoint = currentRectPoints[jindex];
                  let currentx = currentPoint[0];
                  let currenty = currentPoint[1];
                  let diffx = 0;
                  let diffy = 0;
                  if (rect[timeIndex + 1] && rect[timeIndex + 1].length >=  currentRectPoints.length &&
                      this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime &&
                      timeMap[timeIndex + 1] !== undefined ) && rect[timeIndex + 1] !== undefined && rect[timeIndex] !== undefined) {
                    diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                    diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
                  }
                  if (rect[timeIndex + 1] && rect[timeIndex + 1].length <  currentRectPoints.length &&
                    this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime &&
                    timeMap[timeIndex + 1] !== undefined ) && rect[timeIndex + 1] !== undefined && rect[timeIndex] !== undefined) {
                      if(jindex < rect[timeIndex + 1].length){
                        diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                        diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
                      }
}
                  currentx = currentx + (this.state.player.currentTime - timeMap[timeIndex] + (timeTraveled * this.state.player.playbackRate )) * (diffx / difft);
                  currenty = currenty + (this.state.player.currentTime - timeMap[timeIndex] + (timeTraveled * this.state.player.playbackRate )) * (diffy / difft);
                  newPoints.push([ currentx, currenty ]);
                }
                if (rect[timeIndex + 1] !== undefined &&  rect[timeIndex + 1].length <  currentRectPoints.length && 
                  this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && 
                  timeMap[timeIndex + 1] !== undefined )) { 
                    for(let kindex = this.state.rects[index][timeIndex].length -1; kindex >= this.state.rects[index][this.state.rects[index].length - 1].length ; kindex--){
                        newPoints.splice(kindex, 1)
                      }
                  }
                const points = this.getDecPoints(newPoints);
                flag = true;
                let pol = this.DrawBoard.polygon(points).id(id).attr({fill: `${labelColor}`, cursor: 'pointer', 'fill-opacity': `${this.state.opacity}`, stroke: '#1ae04e' });
                console.log('increasePlayerTime drawing polyon', pol);
            }
        } else {
          let el = SVG.get(id)
          if (el !== null) {
            console.log('increasePlayerTime 2 remove node', id,  el);
            try {
              el.remove();
            } catch (ex) {
              console.log('increasePlayerTime 2 remove node exception', ex);
            }
          }
        }
      })

  const scheduleTime = () => {
    window.requestAnimationFrame((tse) => { this.increasePlayerTime(tse); });
  }

  if (!this.state.player.paused && this.state.player.currentTime !== this.state.player.duration && this.state.rects.length > 0) {
    this.setState({animationStartTime:ts});
    console.log('increasePlayerTime setting timeout');
    console.log('threshold timeout'+ threshold,scheduleTime);
    setTimeout(scheduleTime, (threshold * 100));
    console.log('increasePlayerTime 6 restart');
  } else {
    window.cancelAnimationFrame(this.increasePlayerTime);
    clearTimeout(scheduleTime);
    this.setState({ rfa: false });
    console.log('increasePlayerTime 8 stop', flag);
  }
  console.log('increasePlayerTime timeNote final', window.performance.now());
}

  isSameRect(id, rect) {
    let element = document.getElementById(id);
    let ex = element.getAttribute('x') - 0;
    let ey = element.getAttribute('y') - 0;
    let ew = element.getAttribute('width') - 0;
    let eh = element.getAttribute('height') - 0;

    let coords = this.getCoords(rect);

    if (ex === coords.x && ey === coords.y && ew === coords.width && eh === coords.height) return true;
    return false;
  }

  initRect( rect ) {
    const newRect = [];
    for (let index = 0; index < rect.length; index ++) {
      newRect.push(rect[index].slice());
    }
    return newRect;
  }

  play() {
    this.state.player.play();
    if (!this.state.rfa) {
      window.requestAnimationFrame((ts) => {
        this.setState({animationStartTime : ts});
        this.increasePlayerTime(ts);
       });
      this.state.rfa = true;
      this.setState({ rfa: true });
    }
    return false;
  }

  pause() {
    console.log('pausing');
    window.cancelAnimationFrame(this.increasePlayerTime);
    this.setState({rfa:false});
    this.state.player.pause();
    return false;
  }

  load() {
    this.state.player.load();
  }
  handleTextBoxChange = (event, idx, name) => {
    const { attributesOption } = this.state;
    const inputValues = [...this.state.inputValues];
    inputValues[idx] = event;
    if (event != "") {
      attributesOption[this.state.attributeIndex][name] = event;
    }
    this.setState({ inputValues, attributesOption });
  }

  handleAttributeChangeDrop(event, idx, name) {
    const { attributesOption } = this.state;
    const selectedOptions = { ...this.state.selectedOptions };
    const inputValues = [...this.state.inputValues];
    selectedOptions[idx] = event;
    if (event === "custom") {
      inputValues[idx] = "";
    } else {
      attributesOption[this.state.attributeIndex][name] = event;
    }
    this.setState({ selectedOptions, attributesOption });
  }

  handleDropdownFocus = (idx) => {
    this.setState({ selectValue : idx})
  }

  getPopupContainer = (triggerNode) => {
    console.log("popup here")
    return triggerNode.parentNode;
  };

  getAttributesOptions() {
    const { attributes, activeElementType, attributesOption, attributeIndex } = this.state;
    let renderArr = [];
    if (attributes) {
      const dropdown = document.querySelector('.ant-select-dropdown');
      if (dropdown) {
        dropdown.style.position = 'fixed'; // Hide the dropdown when scrolling
      }
      let response = attributes.split(",")
      response.map((element, idx) => {
        let res;
        if(!(Array.isArray(this.props.editClassAttribute[idx].classes)))
        {
          res = this.props.editClassAttribute[idx].classes.split(','); 
        }
        else{
          res = this.props.editClassAttribute[idx].classes
        }
        attributesOption[this.state.attributeIndex][element] = !attributesOption[attributeIndex][element] ? "undefined" : attributesOption[attributeIndex][element];
        renderArr.push(
          <Form size="mini" key={idx}>
            <label style={{margin: "10px", padding: "8px"}}>{element}</label>
            <Select
              key={idx}
              style={{ width: '100%', padding: "10px" }}
              getPopupContainer={this.getPopupContainer}
              placeholder="Choose options"
              onFocus={() => this.handleDropdownFocus(idx)}
              onChange={(event) => this.handleAttributeChangeDrop(event, idx, element)}>
              {res.map(option => (
                <option key={option} value={option} style={{ width: "470px", padding : "15px 8px" }}>
                  {option}
                </option>
              ))}
              <option value="custom" style={{ width: "470px", padding : "15px 8px" }} >Enter value</option>
            </Select>
            {this.state.selectedOptions[idx] === "custom" && 
            <input
              type="text"
              autoFocus
              style={{ width: '96%', padding: "10px", margin : "10px" }}
              value={this.state.inputValues[idx] || ''} 
              onChange={(event) => this.handleTextBoxChange(event.target.value, idx, element)} 
            />}
          </Form>
        );
      });
    }

    return (
      <div>
        {renderArr}
      </div>
    )
  }

  saveAttributeDetails() {
    this.setState({ openAttributeOption: false, attributeIndex: -1, selectedOptions : {} });
    if (this.props.drawHandle) {
      this.props.drawHandle(this.state);
    }
  }

  handleNoteChange(event) {
    const { notes } = this.state;
    notes[this.state.noteIndex] = event.target.value;
    this.setState({ notes });
  }

  closeModal(index) {
    this.setState({ openNote: false, noteIndex: -1, openHitLabel: false, deleteConfirm: false, openAttributeOption: false, attributeIndex: -1, selectedOptions : {} });
  }

  saveModal(index) {
    this.setState({ openNote: false, noteIndex: -1 });
    if (this.props.drawHandle) {
      this.props.drawHandle(this.state);
    }
  }

  changeCurrentTime(frames, previousTime) {
    const player = this.state.player;
    const currentTime = player.currentTime;
    let seconds = frames / this.state.frameRate
    let previousFrame = previousTime * this.state.frameRate
    let totalFrame = this.state.frameRate * this.state.player.duration
    let val = ((currentTime + parseFloat(seconds)) * this.state.frameRate)
    let nextFrame = (val > totalFrame) ? totalFrame : val < 0 ? 0 : val
    let frameDifference = nextFrame - previousFrame
    this.setState({skippedFrames: frameDifference})
    this.seek((currentTime + parseFloat(seconds)).toFixed(6));
    return false;
}

  seek(seconds) {
    console.log('seek', seconds);
    const player = this.state.player;
    player.currentTime = seconds;
    if (!this.state.player.paused) {
      this.pause();
    }
    this.setState({ player });
  }

  changePlaybackRateRate(steps) {
    this.state.player.playbackRate = steps;
    this.setState({ defaultPlaybackRate: steps });
  }

  videoDimensions(video) {
    // Ratio of the video's intrisic dimensions
    var videoRatio = video.videoWidth / video.videoHeight;
    // The width and height of the video element
    var { windowWidth, windowHeight } = this.getWindowDimeshions();
    // The ratio of the element's width to its height
    var elementRatio = windowWidth / windowHeight;
    // If the video element is short and wide
    if (elementRatio > videoRatio) windowWidth = windowHeight * videoRatio;
    // It must be tall and thin, or exactly equal to the original ratio
    else windowHeight = windowWidth / videoRatio;
    return {
      width: windowWidth,
      height: windowHeight
    };
  }

  changeVolume(steps) {
    return () => {
      const player = this.state.player;
      const volume = player.volume;
      this.state.player.volume = volume + steps;
      this.setState({ defaultVolume: volume + steps });
    };
  }

  handleStateChange(eventName, event) {
    // copy player state to this component's state
    console.log('videostatechange', event.target.currentTime, event, event.target, eventName);
    this.state.player = event.target;
    if (!this.state.rfa || this.state.player.paused || eventName !== 'onTimeUpdate') {
      this.setState({
        player: event.target
      });
    } else if (eventName === 'onTimeUpdate' && !this.state.player.paused) {
      let pBar = document.getElementById("progressBar");
      console.log('update time', pBar);
      let htmlElementId = this.state.player.paused ? "progressBarTextPlay" : "progressBarText"; 
      let ptextDoc = document.getElementById(htmlElementId)
      if (ptextDoc !== null && this.state.rfa) {
    	  console.log('update time inside condition ', ptextDoc);
    	let nowTime = `${this.state.player.currentTime} / ${this.state.player.duration}`;
        nowTime = nowTime + ' ';
        console.log("nowTimeTest" + nowTime);
        var element = document.getElementById(htmlElementId);
        element.textContent =  nowTime;
        pBar.value = this.state.player.currentTime;
      }
    }
  }

  loadImages() {
    console.log('loading image');
    const imgLoaded = this.state.imgLoaded;
    for ( let index = this.props.currentIndex + 1; index < this.props.currentIndex + 2 && index < this.props.hits.length; index ++) {
      if (!(this.props.hits[index].data in imgLoaded) && this.timeout) {
        console.log('loading image', index);
        this.timeOut = null;
        const image1 = new Image(); // eslint-disable-line no-undef
        image1.src = this.props.hits[index].data;
        imgLoaded[this.props.hits[index].data] = true;
      }
    }
    this.state.imgLoaded = imgLoaded;
  }

  zoom(event_offsetX, event_offsetY, deltaY) {
    let zoomFactor;
    if (deltaY < 0) {
      zoomFactor = this.state.scale * 1.03;
    } else {
      zoomFactor = this.state.scale * 0.97;
    }
    if (zoomFactor > 100) zoomFactor = 100.0;
    if (zoomFactor < -10) zoomFactor = -10;
    this._panner.zoomVideo(zoomFactor, {x: event_offsetX, y: event_offsetY});
    this._startX = event_offsetX;
    this._startY = event_offsetY;
    this.setState({
      translate: {
        x: this._panner.viewport.x + 0 * (this.canvas.offsetWidth * this._panner.scale),
        y: this._panner.viewport.y + 0 * (this.canvas.offsetHeight * this._panner.scale)
      },
      scale: this._panner.scale
    });
  }

  resetImage() {
    console.log('resize resetImage');
    clearTimeout(this.resizeTo);
  }

  getKey = (map, val) => {
    return Object.keys(map).find(key => map[key] === val);
  }

  handleColorChange = (color) => {
    this.selectedColor = color.hex
    let mouseHoverMap = this.state.mouseHoverMap;
    let rectColorMap = this.props.hitEntities;
    let selectedIndex = this.getKey(mouseHoverMap, true);
    rectColorMap[selectedIndex] = this.selectedColor
    this.setState({ openMenuTool: false, rectColorMap, pickerColor: color })
  }
  handleClose = (color) => {
    this.state.selectColorClose = 1;
    this.selectedColor = color.hex
    let selectedIndex = this.getKey(this.state.mouseHoverMap, true);
    if (this.selectedColor !== undefined && this.selectedColor !== null && selectedIndex !== undefined && selectedIndex !== null) {
      let rectColorMap = this.props.hitEntities;
      rectColorMap[selectedIndex] = this.selectedColor
      this.setState({ openMenuTool: false, rectColorMap }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
          this.selectedColor = null;
        }
      });
    }
  }

  resizeWindow() {
    if (this.state.videoLoad) {
      this.setState({ canvasSet: false, videoLoad: false, video: this.state.video + '?ts=' + new Date() });
    }
    if (this.resizeTo) clearTimeout(this.resizeTo);
    this.resizeTo = setTimeout(this.resetImage.bind(this), 2000);
  }

  getOffsets(e) {
    return [e.offsetX, e.offsetY];
  }


  selectHitsList = (id) => {
    if (id !== 'selectedItem') {
      let hoverMap = {};
      const { selectedLockedHitsIndex } = this.state
      if (selectedLockedHitsIndex.includes(id)) {
        var index = selectedLockedHitsIndex.indexOf(id)
        if (index > -1) {
          selectedLockedHitsIndex.splice(index, 1);
        }
      } else {
        selectedLockedHitsIndex.push(id)
      }
      selectedLockedHitsIndex.map(index => {
        hoverMap[index] = true;
      })
      this.setState({ mouseHoverMap: hoverMap, lockAnnotation: true });
    }
  }

sort2DArrayAcrossRows(arr) {
  arr.forEach(row => {
    row.forEach((_, j) => {
      row.forEach((_, k) => {
        if (k < row.length - j - 1 && row[k] > row[k + 1]) {
          [row[k], row[k + 1]] = [row[k + 1], row[k]];
        }
      });
    });
  });
  return arr;
}

  mousedownHandle(event) {
    console.log('mousedown polygon drag', this.state, this.ctx, event.target.nodeName);
    const { rectShapeMap } = this.state;
    const mouseHoverMap = this.state.mouseHoverMap;
    const targetId = event.target.id.split("--")[0];
    let targetId1 = event.target.id.split("--")[1];
    const [offsetX, offsetY] = this.getOffsets(event);
    const targetId3 = event.target.id;
    if (targetId3) {
      console.log("Target id video", targetId3)
      this.setState({ selectedItem: targetId3 })
      if (this.state.lockHits) {
        this.selectHitsList(this.state.selectedItem)
      }
  }
    if (this.state.defaultClass) {
      for (const k in mouseHoverMap) {
        if (mouseHoverMap.hasOwnProperty(k)) {
          mouseHoverMap[k] = false;
        }
      }
      if (this.state.toolName === 'rectangle') {
        console.log('starting rectangle');
        if (this.state.currentRect.length === 0) {
          const currentRect = this.state.currentRect;
          currentRect.push([event.offsetX, event.offsetY]);
          this.setState({
            currentRect,
          });
        } else {
          let currentRectangle = this.state.currentRect;
          const currentPoint = currentRectangle[0];
          let newPoint = this.convertToPoint(event.offsetX, event.offsetY);
          currentRectangle = [];
          currentRectangle.push([ this.getPoint(currentPoint[0] / this.state.videoWidth), this.getPoint(currentPoint[1] / this.state.videoHeight)]);
          currentRectangle.push([ this.getPoint(currentPoint[0] / this.state.videoWidth), this.getPoint(newPoint.y / this.state.videoHeight)]);
          currentRectangle.push([ this.getPoint(newPoint.x / this.state.videoWidth), this.getPoint(currentPoint[1] / this.state.videoHeight)]);
          currentRectangle.push([ this.getPoint(newPoint.x / this.state.videoWidth), this.getPoint(newPoint.y / this.state.videoHeight)]);
          const { rectCatMap, rectShapeMap, rectTimeMap  } = this.state;
          const numberOfRects = Object.keys(rectCatMap).length;
          rectShapeMap[numberOfRects] = 'rectangle';
          rectTimeMap[numberOfRects] = [this.state.player.currentTime, undefined];
          if (this.state.defaultClass) {
            rectCatMap[numberOfRects] = this.state.defaultClass;
          }
          const mouseHoverMap = this.state.mouseHoverMap;
          mouseHoverMap[numberOfRects] = true;
          this.setState({
            rects: [
              ...this.state.rects,
              [currentRectangle]
            ],
            rectCatMap,
            rectTimeMap,
            mouseHoverMap,
            currentRect: [],
            rectShapeMap,
            defaultClass: '',
            openMenuTool: true,
            mouseDown: false,
            showList: true
          }, () => {
            if (this.props.drawHandle) {
              console.log('calling drawhandle', this.state);
              this.props.drawHandle(this.state);
            }
          });
        }
      } else if (this.state.toolName === 'polygon') {
        let newPoint = this.convertToPoint(event.offsetX, event.offsetY);
        if (event.target.nodeName === 'circle' &&  event.target.id.length === 3 && event.target.id[0] === 'x' && event.target.id[2] === '0') {
          if (this.state.defaultClass) {
            this.savePolygon(this.state.defaultClass);
          } else {
            this.savePolygon(this.state.entities[0]);
          }
        } else {
          const currentRect = this.state.currentRect;
          currentRect.push([newPoint.x, newPoint.y]);
          this.setState({
            currentRect,
            currentPolyPoint: []
          });
        }
      }
    } else if (event.target.nodeName === 'circle' && this.props.drawHandle) {
      console.log('drag start', event.offsetX, event.offsetY, event.target.id);
      const splits = event.target.id.split('--');
      if (splits.length >= 3) {
        let timeIndex = parseInt(splits[2], 10);
        let rectIndex = parseInt(splits[0], 10);
        let pointIndex = parseInt(splits[1], 10);
        if (mouseHoverMap[rectIndex] && mouseHoverMap[rectIndex] === true) {
          console.log('rectdrag start', event.clientX, event.clientY);
          if (event.target.id.endsWith('temp')) {
            let rectId = rectIndex + '--' + timeIndex + '--' + 'temp';
            let element = document.getElementById(rectId);
            let rects = this.state.rects;
            let rectTimeMap = this.state.rectTimeMap;
            let newRectangle = [];
            let ex = element.getAttribute('x') - 0;
            let ey = element.getAttribute('y') - 0;
            let ew = element.getAttribute('width') - 0;
            let eh = element.getAttribute('height') - 0;
            newRectangle.push([ex / this.canvas.offsetWidth, ey / this.canvas.offsetHeight]);
            newRectangle.push([ex / this.canvas.offsetWidth, (ey + eh) / this.canvas.offsetHeight]);
            newRectangle.push([ (ex + ew) / this.canvas.offsetWidth, ey / this.canvas.offsetHeight]);
            newRectangle.push([ (ex + ew) / this.canvas.offsetWidth, (ey + eh) / this.canvas.offsetHeight]);
            console.log('rectdrag start', this.state.rects);
            // let timeMap = this.state.rectTimeMap[rectIndex];
            // let newIndex = -1;
            // for (let index = 0; index < timeMap.length; index ++) {
            //   if (timeMap[index] && timeMap[index] > this.state.player.currentTime) {
            //     newIndex = index - 1;
            //     break;
            //   } else if (timeMap[index]) {
            //     newIndex = index;
            //   }
            // }
            rects[rectIndex].splice(timeIndex + 1, 0, newRectangle);
            rectTimeMap[rectIndex].splice(timeIndex + 1, 0, this.state.player.currentTime);
            this.setState({dragTimeIndex:timeIndex+1})
            this.setState({ rects, rectTimeMap});
            console.log('rectdrag start', event.clientX, event.clientY);
            this.setState({ pointDrag: true, dragRect: rectIndex, dragPoint: pointIndex, dragTimeIndex: timeIndex + 1});
            } else {
            console.log('drag point is', this.state.rects[rectIndex][timeIndex][pointIndex][0] * this.state.imgWidth);
            console.log('drag point is', this.state.rects[rectIndex][timeIndex][pointIndex][1] * this.state.imgHeight);
            this.setState({ pointDrag: true, dragRect: rectIndex, dragPoint: pointIndex, dragTimeIndex: timeIndex });
          }
        }
      }
      if (splits.length === 2) {
        const rectIndex = parseInt(splits[0], 10);
        const rectShape = this.state.rectShapeMap[rectIndex];
        if (rectShape === "point") {
          if (!this.state.lockAnnotation) {
            for (const k in mouseHoverMap) {
              if (mouseHoverMap.hasOwnProperty(k)) {
                mouseHoverMap[k] = false;
              }
            }
          }
          mouseHoverMap[rectIndex] = true;
        }
        this.setState({ pointDrag: true, dragRect: rectIndex, mouseHoverMap, dragPoint: parseInt(splits[1], 10) });
      }
    }
      else if(event.ctrlKey &&  rectShapeMap[targetId] !== "line" && rectShapeMap[targetId] !== "rectangle") {
      const rects = this.state.rects[targetId];
      let freeHandSketch = []
      freeHandSketch.push([this.getPoint(offsetX / this.state.videoWidth), this.getPoint(offsetY / this.state.videoHeight)]);
       const newAreaPoints = [...rects];
      const distance = (point) => {
        return Math.sqrt(
          Math.pow(freeHandSketch[0][0]- point.x, 2) + Math.pow(freeHandSketch[0][1] - point.y, 2)
        );
      };
      const interpolate = (xVal, yVal, f) => {
        var nx = xVal[0] + (yVal[0] - xVal[0]) * f;
        var ny = xVal[1] + (yVal[1] - xVal[1]) * f;
        return { x: nx, y: ny };
      };
      let closestPoint = 0;
      let closestDistance = Number.MAX_VALUE;
      let numPoints = newAreaPoints[targetId1].length;
      for (let i = 0; i < numPoints; i++) {
        let curr = newAreaPoints[targetId1][i];
        let next = newAreaPoints[targetId1][i + 1 === numPoints ? 0 : i + 1];
        for (let n = 0; n < 1; n += 0.05) {
          let interpolatedDist = distance(interpolate(curr, next, n));
          if (interpolatedDist < closestDistance) {
            closestPoint = i;
            closestDistance = interpolatedDist;
          }
        }
      }
      let data;
      newAreaPoints[targetId1].splice(closestPoint + 1, 0, [freeHandSketch[0][0], freeHandSketch[0][1]]);
      this.state.rects[targetId] = newAreaPoints;
      data = this.state.rects
      this.setState({
        rects : data
      }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
        });
    } else if( this.props.type === "done" &&  this.state.player.paused === true && event.shiftKey && rectShapeMap[targetId] !== "line" && rectShapeMap[targetId] !== "rectangle") {      
      this.state.rects.map((rect, index) => {
        const timeMap = this.props.rectTimeMap[index];
        let timeIndex = 0;
        for (let jindex = 0; jindex < timeMap.length; jindex ++) {
          if (timeMap[jindex] !== undefined) {
            if (timeMap[jindex] && timeMap[jindex] > this.state.player.currentTime) {
              timeIndex = jindex - 1;
              break;
            } else if (timeMap[jindex]) {
              timeIndex = jindex;
            }
          }
        }
      let currentRectPoints = rect[timeIndex];
      let timeTraveled = this.state.player.currentTime - timeMap[timeIndex];
      let newPoints = [];
      let difft = 1;
      if (this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )) {
        difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
      }
      for (let jindex = 0; jindex < currentRectPoints.length; jindex ++) {
        let currentPoint = currentRectPoints[jindex];
        let currentx = currentPoint[0];
        let currenty = currentPoint[1];
        let diffx = 0;
        let diffy = 0;
        if (rect[timeIndex + 1] !== undefined && rect[timeIndex + 1].length >=  currentRectPoints.length && 
            this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && 
            timeMap[timeIndex + 1] !== undefined )) {
          diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
          diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
        }

        if (rect[timeIndex + 1] && rect[timeIndex + 1].length <  currentRectPoints.length &&
          this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime &&
          timeMap[timeIndex + 1] !== undefined ) && rect[timeIndex + 1] !== undefined && rect[timeIndex] !== undefined) {
            if(jindex < rect[timeIndex + 1].length){
              diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
              diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
            }
        }
        currentx = currentx + (timeTraveled * (diffx / difft));
        currenty = currenty + (timeTraveled * (diffy / difft));
        newPoints.push([ currentx, currenty ]);
      }
      if (rect[timeIndex + 1] !== undefined &&  rect[timeIndex + 1].length <  currentRectPoints.length && 
        this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && 
        timeMap[timeIndex + 1] !== undefined )) { 
          for(let kindex = this.state.rects[index][timeIndex].length -1; kindex >= this.state.rects[index][this.state.rects[index].length - 1].length ; kindex--){
            newPoints.splice(kindex, 1)
            }
        }
      const splits = event.target.id.split('--');
      const rects = this.state.rects;
      const rectTimeMap = this.state.rectTimeMap;
      let data;
      let rectIndex = parseInt(splits[0], 10);
      if(index == rectIndex) {
      rects[rectIndex].push(newPoints)
        rectTimeMap[rectIndex][rectTimeMap[rectIndex].length] = this.state.player.currentTime
        rectTimeMap[rectIndex].sort((a, b) => a - b);
      }
      data = this.state.rects;
      const sortedArray = this.sort2DArrayAcrossRows(rects);
      this.setState({ rects: data, rectTimeMap : rectTimeMap }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      })
    })
  } else if ( this.props.drawHandle && (event.target.nodeName === 'polygon' || event.target.nodeName === 'rect')) {
      const splits = event.target.id.split('--');
      if (splits.length >= 2) {
        let timeIndex = parseInt(splits[1], 10);
        let rectIndex = parseInt(splits[0], 10);
        if (mouseHoverMap[rectIndex] && mouseHoverMap[rectIndex] === true) {
          console.log('rectdrag start', event.clientX, event.clientY);
          if (event.target.nodeName === 'rect') {
            let sameRect = this.isSameRect(event.target.id, this.state.rects[rectIndex][timeIndex]);
            if (!sameRect) {
              let element = document.getElementById(event.target.id);
              let rects = this.state.rects;
              let rectTimeMap = this.state.rectTimeMap;
              let newRectangle = [];
              let ex = element.getAttribute('x') - 0;
              let ey = element.getAttribute('y') - 0;
              let ew = element.getAttribute('width') - 0;
              let eh = element.getAttribute('height') - 0;
              newRectangle.push([ex / this.canvas.offsetWidth, ey / this.canvas.offsetHeight]);
              newRectangle.push([ex / this.canvas.offsetWidth, (ey + eh) / this.canvas.offsetHeight]);
              newRectangle.push([ (ex + ew) / this.canvas.offsetWidth, ey / this.canvas.offsetHeight]);
              newRectangle.push([ (ex + ew) / this.canvas.offsetWidth, (ey + eh) / this.canvas.offsetHeight]);
              console.log('rectdrag start', this.state.rects);
              rects[rectIndex].splice(timeIndex + 1, 0, newRectangle);
              rectTimeMap[rectIndex].splice(timeIndex + 1, 0, this.state.player.currentTime);
              this.setState({dragTimeIndex:timeIndex+1})
              this.setState({ rects, rectTimeMap});
              console.log('rectdrag start', event.clientX, event.clientY);
              this.setState({ rectDrag: true, dragRect: rectIndex, dragTimeIndex: timeIndex, dragPoint: [event.offsetX, event.offsetY] });
            } else {
              this.setState({ rectDrag: true, dragRect: rectIndex, dragTimeIndex: timeIndex, dragPoint: [event.offsetX, event.offsetY] });
            }
          } else {
            this.setState({ rectDrag: true, dragRect: rectIndex, dragTimeIndex: timeIndex, dragPoint: [event.offsetX, event.offsetY] });
          }
        } else {
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              if (k !== rectIndex) {
                mouseHoverMap[k] = false;
              }
            }
          }
          if (mouseHoverMap[rectIndex]) {
            mouseHoverMap[rectIndex] = false;
          } else {
            mouseHoverMap[rectIndex] = true;
          }
          this.setState({ mouseHoverMap });
        }
      }
    } else if (event.target.nodeName === 'svg') {
      console.log('svgdrag start');
      this.setState({ svgDrag: true });
      this._startX = event.pageX;
      this._startY = event.pageY;
      this.setState({ dragPoint: [event.pageX, event.pageY]})
    }
    if(!this.props.fromSample)
    {
    this.props.drawHandle(this.state);
    }
  }
  
  getIds = () => {
    let idArr = [];
    let children = document.getElementById("drawing").children;
    for (var i = 0; i < children.length; i++) {
      if (children[i].id !== "")
        idArr.push(children[i].id);
      else if (children[i].id === "" && children[i].nodeName === "g") {
        let childNodes = children[i].childNodes;
        const keys = Object.keys(childNodes)
        keys.map(element => {
          idArr.push(childNodes[element].id);
        })
      }
    }
    return idArr;
  }

  resetRectCatMap = (selectedHitLabel, action) => {
    let indices = [];
    var keys = Object.keys(this.state.rectCatMap);
    keys.map(index => {
      let currentData = this.state.rectCatMap[index];
      if (currentData.indexOf(selectedHitLabel) > -1) {
        currentData.splice(currentData.indexOf(selectedHitLabel), 1);
        if (action === ACTIONS.UPDATE && !indices.includes(index)) {
          indices.push(index)
        }
        this.state.rectCatMap[index] = currentData;
      }
    });

    this.setState({ selectedHitLabelIndex: indices })
  }


  addDynamicHitLabel = (action) => {
    this.setState({ selectedHitLabelIndex: [] })
    const { hitLabel, hitKey, selectedHitLabel } = this.state;
    if (hitLabel && hitLabel.length > 0) {
      switch (action) {
        case ACTIONS.UPDATE:
          this.resetRectCatMap(selectedHitLabel, ACTIONS.UPDATE);
          this.props.saveHitLabel(selectedHitLabel, hitLabel, hitKey, action);
          break;
        case ACTIONS.DELETE:
          this.resetRectCatMap(hitLabel, ACTIONS.DELETE);
          this.props.saveHitLabel(hitLabel, null, hitKey, action);
          break;
        case ACTIONS.ADD:
          this.props.saveHitLabel(hitLabel, null, hitKey, action);
          break;
        default:
          console.log("default")
      }
      const rectColorMap = this.props.hitEntities;
      const rectCatMap = this.state.rectCatMap;
      rectCatMap[Number(this.state.labelKey)][rectCatMap[Number(this.state.labelKey)].length] = this.state.hitLabel
      this.setState({ openHitLabel: false, hitLabel: '', rectColorMap, rectCatMap, deleteConfirm: false }, () => {
        setTimeout(() => {
          this.props.drawHandle(this.state);
        }, 1000)
      })
    } else {
      showAlert(MESSAGES.HIT_LABEL_EMPTY_MESSAGE, ALERT_TYPES.ERROR);
    }
  }
  convertCurrentRectToData(currentRect) {
    console.log('convertCurrentRectToData ', this.canvas);
    const { x1, y1, width, height } = currentRect;
    const dataRect = {};
    if (width > 0) {
      dataRect.x1 = (x1);
      dataRect.x2 = ((x1 + width));
    } else {
      dataRect.x2 = ((x1));
      dataRect.x1 = ((x1 + width));
    }
    if (height > 0) {
      dataRect.y1 = (y1);
      dataRect.y2 = ((y1 + height));
    } else {
      dataRect.y2 = ((y1));
      dataRect.y1 = ((y1 + height));
    }

    return dataRect;
  }

  mouseupHandle(event) {
    if (this.state.pointDrag) {
      console.log('pointdrag', event.offsetX, event.offsetY, event.target.id);
      const rects = this.state.rects;
      const currentRect = rects[this.state.dragRect][this.state.dragTimeIndex];
      let newRectangle = this.initRect(currentRect);
      console.log('pointdrag', currentRect, this.state);
      const shape = this.state.rectShapeMap[this.state.dragRect];
      let newPoint = this.convertToPoint(event.offsetX, event.offsetY);
      const newx = this.getPoint(newPoint.x / this.state.videoWidth);
      const newy = this.getPoint(newPoint.y / this.state.videoHeight);
      newRectangle[this.state.dragPoint][0] = newx;
      newRectangle[this.state.dragPoint][1] = newy;
      if (shape === 'rectangle') {
        if (this.state.dragPoint === 0) {
          newRectangle[2][1] = newy;
          newRectangle[1][0] = newx;
        } else if (this.state.dragPoint === 2) {
          newRectangle[0][1] = newy;
          newRectangle[3][0] = newx;
        } else if (this.state.dragPoint === 1) {
          newRectangle[0][0] = newx;
          newRectangle[3][1] = newy;
        } else if (this.state.dragPoint === 3) {
          console.log('pointdrag changing 3rd');
          newRectangle[2][0] = newx;
          newRectangle[1][1] = newy;
        }
        console.log('pointdrag', shape, currentRect, newx, newy);
      }
      this.setState({ rects,  deletePointContent: '' });
      let deletePoint = shape + "_" + this.state.dragRect + "_" + this.state.dragPoint;
      let rect = rects[this.state.dragRect][0]
      if (rect[1] !== undefined) {
        let minValue = rect[1][0] - rect[0][0];
        if (minValue > 0) {
          this.state.minValue = false;
        } else if (minValue < 0) {
          this.state.minValue = true;
        }
      }
      this.setState({ pointDrag: false ,deletePointContent: deletePoint, reverseDeletePoint : undefined,  deleteValue : this.state.dragPoint, targetVal : event.target.id}, () => {
        if (this.props.drawHandle) {
          console.log('calling drawhandle', this.state);
          this.props.drawHandle(this.state);
        }
      });
    } else if (this.state.rectDrag) {
      if (this.state.dragging) {
        const dx = (event.offsetX - this.state.dragPoint[0]) / this.state.videoWidth;
        const dy = (event.offsetY - this.state.dragPoint[1]) / this.state.videoHeight;
        const currentRect = this.state.rects[this.state.dragRect][this.state.dragTimeIndex];


        let newRectangle = this.initRect(currentRect);
        console.log('rectdrag dx', dx, dy, currentRect);
        for (let jindex = 0; jindex < newRectangle.length; jindex ++) {
          newRectangle[jindex][0] = this.getPoint(newRectangle[jindex][0] + dx);
          newRectangle[jindex][1] = this.getPoint(newRectangle[jindex][1] + dy);
        }
        const rects = this.state.rects;
        const rectTimeMap = this.state.rectTimeMap[this.state.dragRect];
        if (rectTimeMap) {
          if (rectTimeMap[this.state.dragTimeIndex] === this.state.player.currentTime) {
            rects[this.state.dragRect][this.state.dragTimeIndex] = newRectangle;
          } else {
            rects[this.state.dragRect].splice(this.state.dragTimeIndex + 1, 0, newRectangle);
            rectTimeMap.splice(this.state.dragTimeIndex + 1, 0, this.state.player.currentTime);
            this.state.dragTimeIndex = this.state.dragTimeIndex + 1;
          }
        }
        this.setState({ rects, dragging: false, rectDrag: false, dragPoint: undefined, dragRect: undefined, dragTimeIndex: undefined  });
      } else {
        const splits = event.target.id.split('--');
        if (splits.length >= 2) {
          let rectIndex = parseInt(splits[0], 10);
          const mouseHoverMap = this.state.mouseHoverMap;
          for (const k in mouseHoverMap) {
            if (mouseHoverMap.hasOwnProperty(k)) {
              if (parseInt(k, 10) !== rectIndex) {
                mouseHoverMap[k] = false;
              }
            }
          }
          if (mouseHoverMap[rectIndex]) {
            mouseHoverMap[rectIndex] = false;
          } else {
            mouseHoverMap[rectIndex] = true;
          }
          this.setState({ mouseHoverMap, dragging: false, rectDrag: false, dragRect: undefined });
        }
      }
    } else if (this.state.svgDrag) {
      this.setState({ svgDrag: false });
    }
  }

  mousemoveHandle(event) {
    if (this.state.pointDrag) {
      const rects = this.state.rects;
      const rectTimeMap = this.state.rectTimeMap[this.state.dragRect];
      const currentRect = rects[this.state.dragRect][this.state.dragTimeIndex];
      let newRectangle = this.initRect(currentRect);
      const newx = this.getPoint(event.offsetX / this.state.videoWidth);
      const newy = this.getPoint(event.offsetY / this.state.videoHeight);
      newRectangle[this.state.dragPoint][0] = newx;
      newRectangle[this.state.dragPoint][1] = newy;
      const shape = this.state.rectShapeMap[this.state.dragRect];
      if (shape === 'rectangle') {
        if (this.state.dragPoint === 0) {
          newRectangle[2][1] = newy;
          newRectangle[1][0] = newx;
        } else if (this.state.dragPoint === 2) {
          newRectangle[0][1] = newy;
          newRectangle[3][0] = newx;
        } else if (this.state.dragPoint === 1) {
          newRectangle[0][0] = newx;
          newRectangle[3][1] = newy;
        } else if (this.state.dragPoint === 3) {
          console.log('rectdrag changing 3rd');
          newRectangle[2][0] = newx;
          newRectangle[1][1] = newy;
        }
      }
      console.log('mousemove recttime', rectTimeMap, this.state.rects, this.state.dragTimeIndex, newRectangle);
      if (rectTimeMap) {
        if (rectTimeMap[this.state.dragTimeIndex] === this.state.player.currentTime) {
          rects[this.state.dragRect][this.state.dragTimeIndex] = newRectangle;
        } else {
          rects[this.state.dragRect].splice(this.state.dragTimeIndex + 1, 0, newRectangle);
          rectTimeMap.splice(this.state.dragTimeIndex + 1, 0, this.state.player.currentTime);
          this.state.dragTimeIndex = this.state.dragTimeIndex + 1;
        }
      }
      this.setState({ rects });
    } else if (this.state.rectDrag) {
      const dx = (event.offsetX - this.state.dragPoint[0]) / this.state.videoWidth;
      const dy = (event.offsetY - this.state.dragPoint[1]) / this.state.videoHeight;
      const currentRect = this.state.rects[this.state.dragRect][this.state.dragTimeIndex];
      let newRectangle = this.initRect(currentRect);
      console.log('rectdrag dx', dx, dy, currentRect);
      for (let jindex = 0; jindex < newRectangle.length; jindex ++) {
        newRectangle[jindex][0] = this.getPoint(newRectangle[jindex][0] + dx);
        newRectangle[jindex][1] = this.getPoint(newRectangle[jindex][1] + dy);
      }
      const rects = this.state.rects;
      const rectTimeMap = this.state.rectTimeMap[this.state.dragRect];
      if (rectTimeMap) {
        if (rectTimeMap[this.state.dragTimeIndex] === this.state.player.currentTime) {
          rects[this.state.dragRect][this.state.dragTimeIndex] = newRectangle;
        } else {
          rects[this.state.dragRect].splice(this.state.dragTimeIndex + 1, 0, newRectangle);
          rectTimeMap.splice(this.state.dragTimeIndex + 1, 0, this.state.player.currentTime);
          this.state.dragTimeIndex = this.state.dragTimeIndex + 1;
        }
      }
      this.setState({ rects, dragging: true, dragPoint: [event.offsetX, event.offsetY] });
    } else if (this.state.toolName === 'rectangle' && this.state.currentRect.length > 0) {
      let currentRectangle = this.state.currentRect;
      const currentPoint = currentRectangle[0];
      let x = event.offsetX;
      let y = event.offsetY;
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
      if (x > this.state.videoWidth) {
        x = this.state.videoWidth;
      }
      if (y > this.state.videoHeight) {
        y = this.state.videoHeight
      }
      currentRectangle = [];
      currentRectangle.push([ currentPoint[0], currentPoint[1] ]);
      currentRectangle.push([ x, currentPoint[1] ]);
      currentRectangle.push([ currentPoint[0], y]);
      currentRectangle.push([ x, y]);
      this.setState({ currentRect: currentRectangle });
    } else if (this.state.svgDrag) {
      console.log('lets move svg');
      this._panner.panFrom(
        {
          x: this._startX,
          y: this._startY
        },
        {
          x: event.pageX,
          y: event.pageY
        });
      this._startX = event.pageX;
      this._startY = event.pageY;
      this.setState({
        translate: {
          x: this._panner.viewport.x,
          y: this._panner.viewport.y
        },
        scale: this._panner.scale
      });
    }
  }

  _onWheel(event) {
    event.preventDefault();
    console.log('viewport onwheel', event.offsetX, event.offsetY, this._panner.viewport.x, this._panner.viewport.y, this.state.translate.x, this.state.translate.y);
    this.zoom(event.offsetX, event.offsetY, event.deltaY);
  }

  rectToStyles(currentRect) {
	  try{
		    console.log('rectToStyles ', currentRect, this.state.toolName);
		    const canvas = this.canvas;
		    if (!canvas) {
		      return (<div />);
		    }
		    if (this.state.toolName === 'polygon' && currentRect.length === 1) {
		      return (<circle cx={currentRect[0][0]} cy={currentRect[0][1]} r={2 / this.state.scale} stroke="white" fill="lightblue" strokeWidth={1 / this.state.scale} />);
		    } else if (this.state.toolName === 'polygon' && currentRect.length > 1) {
		      return (<polyline points={this.getPoints(currentRect)} style={{fill: `transparent`, opacity: '0.5', stroke: '#1ae04e', strokeWidth: `${1 / this.state.scale}`}} />);
		    } else if (this.state.toolName === 'rectangle') {
		      return this.renderCurrentRectangle();
		    }
	  }catch (ex){
		  console.log("rectToStylesError"+ex);
	  }

  }
  moveToNext(direction) {
    console.log('move to ', direction);
    if (direction === 'next') {
      this.props.saveTagAndNextRow();
    } else if (direction === 'previous') {
      this.props.getBackTopreviousRow();
    } else if (direction === 'skip') {
      this.props.skipRow();
    }
    else if(direction === 'saveToDone'){
      this.props.moveToDone(direction);
    }
    this.setState({skippedFrames: 0})
  }

  moveToDoneConfirmAlert() {
    if (Object.keys(this.state.rects).length > 0) {
      this.moveToDoneConfirmed();
    } else {
      const confirm = AntdModal.confirm;
      const content = VIDEO_ANNOTATOR_CONSTANTS.MOVE_TO_DONE_ANNOTATION_CONFIRM_MESSAGE
      const that = this
      confirm({
        title: 'Confirmation',
        content: content,
        okText: 'OK',
        cancelText: 'CANCEL',
        onOk() { that.moveToDoneConfirmed.bind(that)() },
        onCancel() { return false },
      });
    }
  }

  moveToDoneConfirmed() {
    this.nextElement.bind(this, 'saveToDone')();
  }

  nextElement(direction, event) {
    if(direction === 'save'){
       this.props.handleIsSaveAction()
      direction = 'next'
    }
    const rectTimeMap = this.state.rectTimeMap;
    const endTimeMap = this.state.endTimeMap;
    // debugger;
    for (const key of Object.keys(rectTimeMap)) {
      const timeMap = rectTimeMap[key];
      if (timeMap[1] === undefined) {
        timeMap[1] = this.state.player.duration;
        rectTimeMap[key] = timeMap;
      }
      if (endTimeMap[key] === undefined) {
        endTimeMap[key] = this.state.player.duration;
      }
    }
    this.state.rectTimeMap = rectTimeMap;
    this.state.endTimeMap = endTimeMap;
    this.props.drawHandle(this.state, this.moveToNext, direction);
    return false;
  }

  moveToDone(action) {
	  this.props.moveToDone(action);
  }

  saveRectangle(category) {
      const rectColorMap = this.props.hitEntities;
      const rects = this.state.rects;
      const len = Object.keys(rects).length;
      const rectCatMap = this.state.rectCatMap;
      rectCatMap[len] = category;
      rectColorMap[len] = this.selectedColor ? this.selectedColor : this.props.entityColorMap[rectCatMap[len]];
      this.selectedColor = null;
      this.setState({ defaultClass: category, currentStartTime: this.state.player.currentTime, rectColorMap});
  }

  savePolygon(category) {
    console.log('savePolygon ', category, this.state.player.currentTime);
    const currentRect = this.state.currentRect;
    if (currentRect.length > 0) {
      if (currentRect[0] !== currentRect[currentRect.length - 1]) {
        currentRect.push(currentRect[0]);
      }
      const startTime = this.state.currentStartTime;
      const rectColorMap = this.props.hitEntities;
      const rects = this.state.rects;
      const len = Object.keys(rects).length;
      const normPoints = [];
      console.log('savePolygon ', currentRect);
      for (let index = 0; index < currentRect.length; index ++) {
        let xCord = currentRect[index][0];
        let yCord = currentRect[index][1];
        xCord = this.getPoint(xCord / this.state.videoWidth);
        yCord = this.getPoint(yCord / (this.state.videoHeight));
        normPoints.push([ xCord, yCord]);
      }
      console.log('savePolygon ', normPoints);
      if (rects[len] === undefined) {
        rects[len] = [];
      }
      rects[len].push(normPoints);
      const rectCatMap = this.state.rectCatMap;
      const rectShapeMap = this.state.rectShapeMap;
      const mouseHoverMap = this.state.mouseHoverMap;
      const rectTimeMap = this.state.rectTimeMap;
      const hideLabelsMap = this.state.hideLabelsMap;

      rectCatMap[len] = category;
      rectShapeMap[len] = this.state.toolName;
      hideLabelsMap[len] = false;
      rectTimeMap[len] = [startTime, undefined];
      rectColorMap[len] = this.selectedColor ? this.selectedColor : this.props.entityColorMap[rectCatMap[len]];
      this.selectedColor = null;
      mouseHoverMap[len] = true;
      this.setState({ currentRect: [], defaultClass: '', rects: rects, rectTimeMap, mouseHoverMap, rectCatMap, hideLabelsMap, rectColorMap}, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      });
    } else {
      let index = undefined;
      const mouseHoverMap = this.state.mouseHoverMap;
      for (const k in mouseHoverMap) {
        if (mouseHoverMap.hasOwnProperty(k) && mouseHoverMap[k] === true) {
          index = k;
        }
      }
      if (index) {
        const rectCatMap = this.state.rectCatMap;
        rectCatMap[index] = category;
        this.setState({ rectCatMap, openMenuTool: false }, () => {
          if (this.props.drawHandle) {
            this.props.drawHandle(this.state);
          }
        })
      }
    }
  }

  undoLast() {
    if (this.state.currentRect.length > 0) {
      const currentRect = this.state.currentRect;
      currentRect.splice(-1, 1);
      this.setState({currentRect});
    }
    return false;
  }

  clearPolygons() {
    const confirm = AntdModal.confirm;
    const content = POLY_ANNOTATOR_CONSTANTS.TAGGED_ITEM_MESSAGE
    const that = this
    confirm({
      title: 'Confirmation',
      content: content,
      okText: 'OK',
      cancelText: 'CANCEL',
      onOk() { that.confirmAlertOkTapped() },
      onCancel() { return false },
    });
  }

  confirmAlertOkTapped = () => {
    if (Object.keys(this.state.rects).length > 0) {
      this.setState({ currentRect: [], rects: []});
    }
  }

  getDeletePointContent = () => {
    const { deletePointContent } = this.state;
    if (deletePointContent !== '') {
      let pointArr = deletePointContent.split("_");
      return pointArr;
    }
  }


  videoRefFun =(element) =>{
    console.log("ELEment in video", element)
    this.videoRef = element
  }

  handleMetaData = () => {
    var vid = document.querySelector("video");
    var lastMediaTime
    var lastFrameNum;
    let fps;
    var fpsRounder = [];
    var frameNotSeeked = true;
    function getFPSAverage() {
      return fpsRounder.reduce((acc, val) => acc + val) / fpsRounder.length;
    }
      const ticker = (useless, metadata)=> {
      var mediaTimeDiff = Math.abs(metadata.mediaTime - lastMediaTime);
      var frameNumDiff = Math.abs(metadata.presentedFrames - lastFrameNum);
      var diff = mediaTimeDiff / frameNumDiff;
      if (
        diff &&
        diff < 1 &&
        frameNotSeeked &&
        fpsRounder.length < 50
      ) {
        fpsRounder.push(diff);
        fps = Math.round(1 / getFPSAverage());
        console.log("check frame rate", fps)
        if (!this.state.fpsSet) {
          this.setState({ frameRate: fps, fpsSet: true });
        }
      }
      frameNotSeeked = true;
      lastMediaTime = metadata.mediaTime;
      lastFrameNum = metadata.presentedFrames;
      vid.requestVideoFrameCallback(ticker);
    }
    vid.requestVideoFrameCallback(ticker);
    vid.addEventListener("seeked", function () {
      fpsRounder.pop();
      frameNotSeeked = false;
    });
  }

  deletePoint = () => {
    let targetId = this.state.targetVal.split("--")[2];
    let pointArr = this.getDeletePointContent();
    let data;
    let rectIndex = pointArr[1];
    let pointIndex = pointArr[2];
    const rects = this.state.rects[rectIndex];
    let currentRect = rects[targetId];
    currentRect.splice(pointIndex, 1);
    this.state.rects[rectIndex][targetId] = currentRect;
    data =  this.state.rects
      this.setState({ rects : data, deletePointContent: '', deleteConfirmPoint: false }, () => {
      if (this.props.drawHandle) {
        this.props.drawHandle(this.state);
      }
    });
  }

  deleteSelectedPoint = () => {
    const { deletePointContent, dragPoint, dragRect, rects, rectShapeMap, targetVal } = this.state;
    let targetId = targetVal.split("--")[2];
    const rect = rects[dragRect][targetId];
    let rectPoint = dragPoint - 1;
    let rectsLength = rect.length - 2;
    rectPoint = rectPoint < 0 ? rectsLength : rectPoint;
    let dragDeletePoint = this.state.deleteValue - 1;
    if (dragDeletePoint == rect.length - 2) {
      this.setState({reverseDeletePoint : 1})
    }
    let deletePoint = rectShapeMap[dragRect] + "_" + dragRect + "_" + rectPoint;
    if (deletePointContent !== '') {
      let pointArr = this.getDeletePointContent();
      if (pointArr[0] === "polygon" || pointArr[0] === "polyline") {
        this.deletePoint()
        this.setState({deletePointContent: deletePoint, dragPoint : rectPoint})
      }
    }
  }

  showButtons() {
    let nextButton = 'Next';
    let prevButton = 'Previous';
    let skipButton = 'Skip';
    let moveToDoneButton = "Move to Done";
    const nextButtonDisabled =
    (this.props.hitScrollCompleted && this.props.currentIndex >= this.props.hits.length - 1);

    if ('shortcuts' in this.props) {
      const shortcuts = this.props.shortcuts;
      if ('next' in shortcuts) {
        const combo = convertKeyToString(shortcuts.next);
        nextButton = 'Next (' + combo + ')';
        if (!nextButtonDisabled) {
          Mousetrap.bind(combo, this.nextElement.bind(this, 'next'));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('previous' in shortcuts) {
        const combo = convertKeyToString(shortcuts.previous);
        prevButton = 'Previous (' + combo + ')';
        if (this.props.currentIndex > 0) {
          Mousetrap.bind(combo, this.nextElement.bind(this, 'previous'));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('skip' in shortcuts && parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) {
        const combo = convertKeyToString(shortcuts.skip);
        skipButton = 'Skip (' + combo + ')';
        console.log('setting skip shortcut', combo);
        if (this.props.currentIndex >= 0) {
          Mousetrap.bind(combo, this.nextElement.bind(this, 'skip'));
        } else {
          Mousetrap.unbind(combo);
        }
      }
    }
    return (
            <div style={{display: 'flex'}}>
            <div className="marginTop" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <div title={prevButton} className={this.props.currentIndex <= 0 ? 'eventnone' : ''}>
                    <Button icon size="mini" color="grey" onClick={this.nextElement.bind(this, 'previous')} disabled={this.props.currentIndex <= 0}>
                      <Icon name="left arrow" />
                    </Button>
                  </div>
                  <div title={skipButton} className={this.props.currentIndex < 0 ? 'eventnone' : ''} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
                    <Button icon size="mini" color="grey" onClick={this.nextElement.bind(this, 'skip')} disabled={this.props.currentIndex < 0}>
                      <Icon name="mail forward" />
                    </Button>
                  </div>
                  <div title={nextButton} className={nextButtonDisabled ? 'eventnone' : ''}>
                    <Button icon size="mini" color="blue" onClick={this.nextElement.bind(this, 'next')} disabled={nextButtonDisabled}>
                      <Icon name="right arrow" />
                    </Button>
                  </div>
            </div>


           <div className="marginTop" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
            <div title={moveToDoneButton}>
            <Button
              size="mini"
              color="blue"
              icon
              onClick={this.moveToDoneConfirmAlert.bind(this)}
            >
              <Icon name="check icon" />
            </Button>
          </div>
            </div>

           </div>
        );
  }

  toggleTool(event, event1) {
    if (event1) event1.preventDefault();
    let value = event;
    if (event === 'shift') {
      if (this.state.toolName === 'polygon') value = 'rectangle';
      else if (this.state.toolName === 'rectangle') value = 'polygon';
    }
    if (value === 'move') {
      this.setState({ drawButton: false, canvasSet: false });
      this.canvas.removeEventListener('mousedown', this.mousedownHandle);
      document.removeEventListener('mouseup', this.mouseupHandle);
    } else {
      this.setState({ drawButton: true, canvasSet: false, toolName: value });
    }
  }

  removeAnnotationShape(index) {
    const rectCatMap = this.state.rectCatMap;
    const rectShapeMap = this.state.rectShapeMap;
    const hideLabelsMap = this.state.hideLabelsMap;
    const rectTimeMap = this.state.rectTimeMap;
    const endTimeMap = this.state.endTimeMap;
    const hideRectMap = this.state.hideRectMap;
    delete rectCatMap[index];
    delete endTimeMap[index];
    const rects = this.state.rects;
    delete rects[index];
    delete rectShapeMap[index];
    delete hideLabelsMap[index];
    delete rectTimeMap[index];
    delete hideRectMap[index];
    this.setState({
      rects, rectCatMap, rectShapeMap, rectTimeMap, hideLabelsMap,hideRectMap, openMenuTool: false
    }, () => {
      if (this.props.drawHandle) {
        this.props.drawHandle(this.state);
      }
    });
  }


  renderCurrentRectangle() {
    console.log('rectangleDraw renderCurrentRectangle', this.state.currentRect);
    let x = this.state.videoWidth;
    let y = this.state.videoHeight;
    let xlg = 0;
    let ylg = 0;
    const color = this.props.entityColorMap[this.state.defaultClass];
    for (let index = 0; index < this.state.currentRect.length; index ++) {
      let currentPoint = this.state.currentRect[index];
      if (x > currentPoint[0]) {
        x = currentPoint[0];
      }
      if (y > currentPoint[1]) {
        y = currentPoint[1];
      }
      if (currentPoint[0] > xlg) {
        xlg = currentPoint[0];
      }
      if (currentPoint[1] > ylg) {
        ylg = currentPoint[1];
      }
    }
    const width = Math.abs(xlg - x);
    const height = Math.abs(ylg - y);
    return (<rect x={x} y={y} width={width} height={height} style={{fill: `${color}`, opacity: '0.5', stroke: '#1ae04e', strokeWidth: `${1 / this.state.scale}`}} />);
  }

  render() {
    console.log('BoxAnnotator state', this.state, this.state.player);
    const { selectedTime, player, containerHeight } = this.state;
    if (this.state.player) {
      console.log('dimensions', this.videoDimensions(this.state.player));
    }
    const canvasStyles = {
      zIndex: this.state.mouseDown ? 4 : 2,
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      marginTop: this.state.marginTop,
      marginLeft: this.state.marginLeft,
      width: this.state.player ? this.state.videoWidth : 0,
      height: this.state.player ? this.state.videoHeight : 0,
      cursor: this.state.defaultClass ? 'crosshair' : 'move',
    };
    const cBar = document.getElementsByClassName('video-react-control-bar');
    if (cBar.length > 0) {
      console.log('hide bar', cBar);
      cBar[0].style.display = 'none';
    }
    const selectCategory = (event1, index) => {
      console.log('select category ', event1, index);
      if (this.state.currentRect && this.state.currentRect.length > 0) {
        this.savePolygon(event1);
      } else {
        if (this.state.defaultClass !== event1) {
          this.saveRectangle(event1)
        } else {
          this.setState({ defaultClass: '', currentStartTime: undefined });
        }
      }
      return false;
    };

    const toggleEyeRectStatus = (key, value, event) => {
        event.stopPropagation();
        const hideRectMap = this.state.hideRectMap;
        hideRectMap[key] = value;
        this.setState({ hideRectMap });
      };

    const toggleColorRectStatus = (key, value, event) => {
      event.stopPropagation();
      const hideRectMap = this.state.hideRectMap;
      hideRectMap[key] = value;
      this.setState({ hideRectMap });
      this.setState({ selectColor: false })
      if (this.state.selectColorClose == 1) {
        this.setState({ selectColor: true, selectColorClose: 0 })
      }
    };

    const removeRect = (event) => {
      let index = undefined;
      const that = this
      const confirm = AntdModal.confirm;
      const idx = event.target.id;
      const mouseHoverMap = this.state.mouseHoverMap;
      if (event && event.target && event.target.id) {
        index = event.target.id;
      } else {
        for (const key of Object.keys(mouseHoverMap)) {
          if (mouseHoverMap[key]) { index = parseInt(key, 10); mouseHoverMap[key] = false; break; }
        }
      }
      if(!this.props.fullScreen){
        confirm({
          title: 'Confirmation',
          content: 'Are you sure you want to delete this annotation ?',
          okText: 'OK',
          cancelText: 'CANCEL',
          onOk() { that.removeAnnotationShape(idx) },
          onCancel() { return false },
        });
      }
      else{
        that.removeAnnotationShape(idx)
        this.setState({ removeAnnotation: false })
      }
    };

    const isDarkColor = (color) => {
      if (color) {
        let tinycolor = require("tinycolor2");
        let colorValue = tinycolor(color);
        return colorValue.isDark();
      }
      return true;
    }

    const disablePointerEvents = (lockedIndices, idArr) => {
      if (idArr.length > 0) {
        lockedIndices.forEach(id => {
          idArr.forEach(val => {
            if (id === val || val.startsWith(id + '-')) {
              document.getElementById(val).setAttribute("pointer-events", "none");
            }
          });
        });
      }
    };

    const togglePointerEvents = (lockAnnotation, idArr, lockedHits) => {
      if (lockAnnotation) {
        lockedHits.forEach(id => document.getElementById(id).setAttribute("pointer-events", "none"));
      } else {
        idArr.forEach(id => document.getElementById(id).removeAttribute("pointer-events"));
      }
    };


    const handleLocking = (checked, idArr, selectedItem, selectedLockedHitsIndex, rects, lockedHits) => {
      console.log("handleLocking", checked, idArr, selectedItem, selectedLockedHitsIndex, rects, lockedHits)
      let hoverMap = {};
      const rectKeys = Object.keys(rects);
    
      if (checked && selectedItem !== null) {
        selectedLockedHitsIndex.push(selectedItem);
        selectedLockedHitsIndex.forEach(index => hoverMap[index] = true);
        if (idArr.length > 0) {
          idArr.forEach(id => {
            if (id === selectedItem || id.startsWith(selectedItem + '-')) {
              lockedHits.push(id);
            }
          });
        }
      } else {
        this.setState({ lockedHits: [], selectedLockedHitsIndex: [] });
        rectKeys.forEach(k => hoverMap[k] = false);
      }
      console.log("mouseHoverMap polygonannotator", hoverMap);
      this.setState({ mouseHoverMap: hoverMap, lockAnnotation: checked }, () => {
        togglePointerEvents(checked, idArr, lockedHits);
      });
    };

    const lockAnnotationChange = (checked) => {
      const { rects } = this.state;
      this.setState({ lock: null });
      let hoverMap = {};
      const rectKeys = Object.keys(rects)
      for (const k in rectKeys) {
        if (checked) {
          hoverMap[k] = true;
        } else {
          if (hoverMap.hasOwnProperty(k)) {
            hoverMap[k] = false;
          }
        }
      }
      this.setState({ mouseHoverMap: hoverMap, lockAnnotation: checked }, () => {
        const { lockAnnotation } = this.state;
        let idArr = this.getIds();
        if (idArr.length > 0) {
          idArr.map(id => {
            if (lockAnnotation) {
              document.getElementById(id).setAttribute("pointer-events", "none");
            } else {
              document.getElementById(id).removeAttribute("pointer-events");
            }
          })
        }
      })
    }

    const lockSingleAnonotation = (checked) => {
          const { rects, selectedLockedHitsIndex, lockedHits, lockHits } = this.state;
          let selectedItem2 = null;
          for (let key in this.state.mouseHoverMap) {
            if (this.state.mouseHoverMap[key] === true) {
              selectedItem2 = key;
              break;  // Stop the loop once we find the true value
            }
          }

          this.setState({ lock: checked });
          let lockedHitsIndex = []
          lockedHitsIndex.push(selectedItem2)
          console.log("")
          if (lockedHitsIndex.length > 0) {
              this.setState({lockedHits: lockedHitsIndex})
              let idArr = this.getIds();
              if (lockHits) {
                this.setState({ lockHits: false });
                disablePointerEvents(selectedLockedHitsIndex, idArr);
              } else {
                handleLocking(checked, idArr, selectedItem2, selectedLockedHitsIndex, rects, lockedHits);
              }
            }

    };

    const addDynamicHitLabelChange = (e, action) => {
      e.preventDefault();
      var code = (e.keyCode ? e.keyCode : e.which);
      this.eventObjectCode = code;
      if (e.target.value && e.target.value.length > 50) {
        showAlert("Label name exceeds maximum length.", "error");
        this.setState({ hitLabel: e.target.value, addlabelHidden: true })
      } else {
        this.setState({ hitLabel: e.target.value, addlabelHidden: false }, () => {
          if (this.eventObjectCode === 13) { //Enter keycode
            this.addDynamicHitLabel(action);
          }
        });
      }
    }

    function dynamicSorting(a, b) {
      if (a && b) {
        a = a.toString()
        b = b.toString()
        if (a.startsWith("#")) {
          a = a.substring(1)
        } else if (b.startsWith("#")) {
          b = b.substring(1)
        }
        let isNumA = /^\d+$/.test(a);
        let isNumB = /^\d+$/.test(b);
        if (isNumA == true && isNumB == true) {
          a = parseInt(a)
          b = parseInt(b)
          return a - b;
        } else {
          a = a.toString();
          b = b.toString();
          return a.localeCompare(b);
        }
      }
    }

    const changeLabel = (key, index, event) => {
      const { rectCatMap } = this.state;
      let entitiesLab = this.props.entities ? [...this.props.entities] : [...this.state.entities]
      const currentData = rectCatMap[key];
      const jindex = currentData.indexOf(entitiesLab[index]);
      if (jindex !== -1) currentData.splice(jindex, 1);
      else currentData.push(entitiesLab[index]);
      entitiesLab.sort(dynamicSorting)
      if (this.props.hitLabels && this.props.hitLabels.length > 0) {
        this.props.hitLabels.map(elem => {
          entitiesLab.push(elem)
        })
      }
      if (currentData.length === 0) {
        this.removeShape(key);
        return;
      }
      rectCatMap[key] = currentData;
      this.setState({ rectCatMap });
      this.state.rectCatMap = rectCatMap;
      let rectColorMap = this.props.hitEntities;
      if (rectCatMap[key].length === 1) {
        rectColorMap[key] = this.props.entityColorMap[rectCatMap[key][0]]
      }
      this.setState({
        rectCatMap, rectColorMap
      }, () => {
        if (this.props.drawHandle) {
          this.props.drawHandle(this.state);
        }
      });
    };

    const addDynamicLabel = (key) => {
      this.setState({ openHitLabel: true, hitKey: key, hitLabel: '', dynamicLabelButtonAction: 'add' })
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown })
    }

    const closeDropdownFromNotes = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown, openNote: true, noteIndex: key })
    }

    // using a generator function
    function* entries(obj) {
      for (const key of Object.keys(obj)) {
        yield [key, obj[key]];
      }
    }

    const renderRects = (data, event) => (
      this.state.rects.map((rect, index) => {
        console.log('renderRects rectangle rendering start', data, event, window.performance.now());

        const { hideRectMap } = this.state;
        if ( index in hideRectMap && hideRectMap[index] === true) {
          return (<div />);
        }

        const shape = this.state.rectShapeMap[index];
        if (!this.state.player.paused && shape === 'rectangle') {
          if (!this.state.rfa) {
            this.setState({rfa:true});
            // this.DrawBoard.clear();
            console.log('increasePlayerTime request');
            window.requestAnimationFrame((ts) => {
              this.setState({animationStartTime:ts});
              this.increasePlayerTime(ts);
             });
          }
          return (null);
        }

        const entity = this.props.rectCatMap[index];
        const timeMap = this.props.rectTimeMap[index];
        let startTime = 0.0;
        let endTime = this.state.player.duration;
        if (timeMap) {
          console.log('timeMap', timeMap);
          startTime = timeMap[0];
          endTime = this.props.endTimeMap[index] ? this.props.endTimeMap[index] : this.state.player.duration;
        }
        console.log('render rects', entity, (!(entity in this.state.hideLabelsMap)) || ( entity in this.state.hideLabelsMap && this.state.hideLabelsMap.entity === false))
        if ((this.state.player.currentTime >= startTime && this.state.player.currentTime <= endTime) && ((!(entity in this.state.hideLabelsMap)) ||
            ( entity in this.state.hideLabelsMap && this.state.hideLabelsMap[entity] === false))) {
          const lineColor = this.props.entityColorMap[entity];
          console.log("this.props.hitEntities[index]", this.props.hitEntities)
          let labelColor;
          if(this.props.hitEntities) {
            labelColor = index in this.props.hitEntities ? this.props.hitEntities[index] : this.props.entityColorMap[this.props.rectCatMap[index]] || this.props.entityColorMap[this.props.rectCatMap[index][0]]  
          }
          console.log('renderRects', shape);
          let sw = 1 / this.state.scale;
          let cursor = '';
          if (this.state.mouseHoverMap && index in this.state.mouseHoverMap && this.state.mouseHoverMap[index]) {
            sw = 1 / this.state.scale;
          }
          let timeIndex = 0;
          for (let jindex = 0; jindex < timeMap.length; jindex ++) {
            if (timeMap[jindex] !== undefined) {
              if (timeMap[jindex] && timeMap[jindex] > this.state.player.currentTime) {
                timeIndex = jindex - 1;
                break;
              } else if (timeMap[jindex]) {
                timeIndex = jindex;
              }
            }
          }
          let currentRectPoints = rect[timeIndex];
          let timeTraveled = this.state.player.currentTime - timeMap[timeIndex];
          console.log('renderRects rectangle rendering 2', timeMap, timeIndex, currentRectPoints, this.state.player.currentTime, this.state.rects);
          if (this.state.mouseHoverMap && index in this.state.mouseHoverMap && this.state.mouseHoverMap[index]) {
            sw = 1 / this.state.scale;
            cursor = 'alias';
          }
          if (this.state.defaultClass) {
            cursor = 'crosshair';
          }
          if (currentRectPoints !== undefined && (!shape || shape === 'polygon')) {
            let newPoints = [];
            let difft = 1;

            if (this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )) {
              difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
            }
            let color = '#1ae04e';
            if (this.state.player.currentTime === timeMap[timeIndex] || this.state.player.currentTime === endTime) {
              color = 'rgb(80, 90, 206)';
            }
              for (let jindex = 0; jindex < currentRectPoints.length; jindex ++) {
              let currentPoint = currentRectPoints[jindex];
              let currentx = currentPoint[0];
              let currenty = currentPoint[1];
              let diffx = 0;
              let diffy = 0;
              if (rect[timeIndex + 1] !== undefined && rect[timeIndex + 1].length >=  currentRectPoints.length && 
                  this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && 
                  timeMap[timeIndex + 1] !== undefined )) {
                diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
              }
              if (rect[timeIndex + 1] !== undefined &&  rect[timeIndex + 1].length <  currentRectPoints.length && 
                this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && 
                timeMap[timeIndex + 1] !== undefined )) {
                  if(jindex < rect[timeIndex + 1].length){
                    diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                    diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
                  }
                }
              currentx = currentx + (timeTraveled * (diffx / difft));
              currenty = currenty + (timeTraveled * (diffy / difft));
              newPoints.push([ currentx, currenty ]);
            }
            if (rect[timeIndex + 1] !== undefined &&  rect[timeIndex + 1].length <=  currentRectPoints.length && 
              this.state.player.currentTime && timeTraveled >= 0 && (timeMap[timeIndex] !== this.state.player.currentTime && 
              timeMap[timeIndex + 1] !== undefined )) { 
                for(let kindex = this.state.rects[index][timeIndex].length -1; kindex >= this.state.rects[index][this.state.rects[index].length - 1].length ; kindex--){
                  newPoints.splice(kindex, 1)
                  }
              }
            const points = this.getDecPoints(newPoints);
            console.log('rendering rects', lineColor, points);
            if (!this.canvas ) {
              this.state.canvasSet = false;
              return (<div />);
            }
            const pointSplits = points.split(',');
            if (this.state.player.paused) {
              let id = index + '--' + timeIndex;
              if (pointSplits.length === 2) {
                return (
                  <circle cx={pointSplits[0]} cy={pointSplits[1]} r="2" stroke={lineColor} strokeWidth={sw} fill={lineColor} />
                  );
              }
              return (<polygon
                        id={id} key={index} points={points}
                          style={{ fill: `${labelColor}`, cursor: `${cursor}`, fillOpacity: `${this.state.opacity}`, stroke: `${color}`, strokeWidth: `${sw}` }} />);
            }
          } else if (currentRectPoints !== undefined && shape === 'rectangle') {
            let x = this.state.videoWidth;
            let y = this.state.videoHeight;
            let xlg = 0;
            let ylg = 0;
            let difft = 1;
            let diffX = 0;
            let diffY = 0;
            let diffWidth = 0;
            let diffHeight = 0;

            let currentCoords = this.getCoords(currentRectPoints);
            let nextCoords = {};
            if (this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )) {
              nextCoords = this.getCoords(rect[timeIndex + 1]);
              difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
              diffX = nextCoords.x - currentCoords.x;
              diffY = nextCoords.y - currentCoords.y;
              diffWidth = nextCoords.width - currentCoords.width;
              diffHeight = nextCoords.height - currentCoords.height;
            }
            let newTimeIndex = timeIndex;
            for (let jindex = 0; jindex < currentRectPoints.length; jindex ++) {
              let currentPoint = currentRectPoints[jindex];
              let currentx = currentPoint[0];
              let currenty = currentPoint[1];
              let diffx = 0;
              let diffy = 0;
              if (rect[timeIndex] !== undefined && rect[timeIndex + 1] !== undefined && this.state.player.currentTime && timeTraveled > 0 && (timeMap[timeIndex] !== this.state.player.currentTime && timeMap[timeIndex + 1] !== undefined )) {
                diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
              }
              if (Math.abs(diffx > 0) || Math.abs(diffy > 0)) {
                newTimeIndex = timeIndex + '--' + 'temp';
              }
              console.log('renderRects rectangle rendering 6', currentx, timeIndex, timeTraveled, diffx, diffy, difft);
              currentx = currentx + (timeTraveled * (diffx / difft));
              currenty = currenty + (timeTraveled * (diffy / difft));
              if (x > currentx) {
                x = currentx;
              }
              if (y > currenty) {
                y = currenty;
              }
              if (currentx > xlg) {
                xlg = currentx;
              }
              if (currenty > ylg) {
                ylg = currenty;
              }
            }
            let id = index + '--' + newTimeIndex;
            if (!this.state.player.paused && this.state.player.currentTime !== this.state.player.duration && !this.state.rfaMap[index]) {
              this.state.rfaMap[index] = {id, diffX: (diffX / difft), diffY: (diffY / difft), diffWidth: (diffWidth / difft), diffHeight: (diffHeight / difft)};
              if (!this.state.rfa) {
                this.setState({rfa:true});
                console.log('increasePlayerTime request');
                window.requestAnimationFrame((ts) => {
                  this.setState({animationStartTime:ts});
                  this.increasePlayerTime(ts);
                 });
              }
            } else {
              this.state.rfaMap[index] = undefined;
            }
            console.log('renderRects rectangle rendering 5', x, y, xlg, ylg);
            xlg = Math.ceil(xlg * this.state.videoWidth);
            ylg = Math.ceil(ylg * this.state.videoHeight);

            x = Math.ceil(x * this.state.videoWidth);
            y = Math.ceil(y * this.state.videoHeight);

            let width = Math.abs(xlg - x);
            let height = Math.abs(ylg - y);
            let element = document.getElementById(id);
            console.log('renderRects rectangle rendering 3 increasePlayerTime original 11', currentCoords, element);
            if (element === null || this.state.player.paused || this.state.player.currentTime === timeMap[timeIndex]) {
              let color = '#1ae04e';
              if (this.state.player.currentTime === timeMap[timeIndex] || this.state.player.currentTime === endTime) {
                color = 'rgb(80, 90, 206)';
              }
              console.log('renderRects rectangle rendering 4', x, y, width, height);
              return (
                <rect id={id} x={x} y={y} width={width} height={height} style={{ fill: `${labelColor}`, cursor: 'pointer', fillOpacity: `${this.state.opacity}`, stroke: `${color}`, strokeWidth: `${sw}`, transform: 'rotate(0deg)'  }}
                />
              );
            }
            let el = SVG.adopt(element);
            let currentCoordx = el.attr('x') - 0;
            let currentCoordy = el.attr('y') - 0;
            let currentCoordWidth = el.attr('width') - 0;
            let currentCoordHeight = el.attr('height') - 0;
              return (
                <>Colored </>
                // <rect id={id} x={currentCoordx} y={currentCoordy} width={currentCoordWidth} height={currentCoordHeight} 
                // style={{ fill: `${labelColor}`, cursor: 'pointer', opacity: '0.5', stroke: `${color}`, strokeWidth: `${sw}`  }}
                //  />
              );
          }
        }
      })
    );

    const renderPoints = () => (
      this.state.rects.map((rect, index) => {
        console.log('render rects', rect);

        if ( index in this.state.hideRectMap && this.state.hideRectMap[index] === true) {
            return (<div />);
          }

        const pointArrs = [];
        const entity = this.props.rectCatMap[index];
        const lineColor = this.props.entityColorMap[entity];
        // const shape = this.state.rectShapeMap[index];
        let sw = 1;
        let swid = 0;
        let radius = 0.5;
        let style = {};
        const timeMap = this.props.rectTimeMap[index];
        let startTime = 0.0;
        let endTime = this.state.player.duration;
        // debugger;
        if (timeMap) {
          console.log('timeMap', timeMap);
          startTime = timeMap[0];
          endTime = this.props.endTimeMap[index] ? this.props.endTimeMap[index] : this.state.player.duration;
        }
        if ((this.state.player.currentTime >= startTime && this.state.player.currentTime <= endTime) &&
            ((!(entity in this.state.hideLabelsMap)) || ( entity in this.state.hideLabelsMap && this.state.hideLabelsMap[entity] === false))) {
          if (this.state.currentRect.length === 0) {
            style = { cursor: '-webkit-grabbing'};
          }
          if (this.state.mouseHoverMap && index in this.state.mouseHoverMap && this.state.mouseHoverMap[index]) {
            sw = 4 / this.state.scale;
            radius = 4 / this.state.scale;
          }
          let timeIndex = 0;
          for (let jindex = 0; jindex < timeMap.length; jindex ++) {
            if (timeMap[jindex] !== undefined) {
              if (timeMap[jindex] && timeMap[jindex] > this.state.player.currentTime) {
                timeIndex = jindex - 1;
                break;
              } else if (timeMap[jindex]) {
                timeIndex = jindex;
              }
            }
          }
          if (rect[timeIndex] === undefined) return (<div />);
          let currentRectPoints = rect[timeIndex];
          let newPoints = [];
          let id;
          for (let jindex = 0; jindex < currentRectPoints.length; jindex ++) {
            console.log("iam in forloop", sw, swid)
            id = index + '--' + jindex + '--' + timeIndex;
            let cx = currentRectPoints[jindex][0];
            let cy = currentRectPoints[jindex][1];
            // debugger;
            if (rect[timeIndex + 1] !== undefined && rect[timeIndex + 1].length >=  currentRectPoints.length && 
                this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && 
                timeMap[timeIndex + 1] !== undefined )) {
              id = id + '--' + 'temp';
              let diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
              let diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
              let difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
              let timeTraveled = this.state.player.currentTime - timeMap[timeIndex];
              cx = cx + timeTraveled * (diffx / difft);
              cy = cy + timeTraveled * (diffy / difft);
            }
            if (rect[timeIndex + 1] !== undefined && rect[timeIndex + 1].length <  currentRectPoints.length && 
              this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && 
              timeMap[timeIndex + 1] !== undefined )) {
                if(jindex < rect[timeIndex + 1].length){
                  id = id + '--' + 'temp';
                  let diffx = rect[timeIndex + 1][jindex][0] - rect[timeIndex][jindex][0];
                  let diffy = rect[timeIndex + 1][jindex][1] - rect[timeIndex][jindex][1];
                  let difft = timeMap[timeIndex + 1] - timeMap[timeIndex];
                  let timeTraveled = this.state.player.currentTime - timeMap[timeIndex];
                  cx = cx + timeTraveled * (diffx / difft);
                  cy = cy + timeTraveled * (diffy / difft);
                }
              }
              newPoints.push([ cx, cy ]);
              if (rect[timeIndex + 1] !== undefined &&  rect[timeIndex + 1].length <  currentRectPoints.length && 
                this.state.player.currentTime && (timeMap[timeIndex] !== this.state.player.currentTime && 
                timeMap[timeIndex + 1] !== undefined )) { 
                  for(let kindex = this.state.rects[index][timeIndex].length -1; kindex >= this.state.rects[index][this.state.rects[index].length - 1].length ; kindex--){
                    newPoints.splice(kindex, 1)
                }
                }                
          }
                      let labelColor = index in this.props.hitEntities ? this.props.hitEntities[index] : this.props.entityColorMap[this.props.rectCatMap[index]] || this.props.entityColorMap[this.props.rectCatMap[index][0]]
            if(newPoints.length > 0) {
              for(let jindex2=0;jindex2<newPoints.length;jindex2++){
                id = index + '--' + jindex2 + '--' + timeIndex;
                pointArrs.push(<circle id={id} style={style} 
                  cx={Math.ceil(newPoints[jindex2][0] * (this.state.videoWidth))}
                              cy={Math.ceil(newPoints[jindex2][1] * (this.state.videoHeight))}
                              r={radius} stroke="white" strokeWidth={sw} fill={labelColor} />);
              }
            
            }
          return (
            <g>
              {pointArrs}
            </g>
            );
        }
      })
    );

    const renderCurrentPoints = () => {
    try{
    	console.log('render current points', this.state.currentRect);
        const pointArrs = [];
        let lineColor = 'lightblue';
        if (this.state.defaultClass) {
          lineColor = this.props.entityColorMap[this.state.defaultClass];
        }
        const sw = 2;
        const radius = 1;
        const rect = this.state.currentRect;
        for (let jindex = 0; jindex < rect.length; jindex ++) {
          const id = 'x' + '-' + jindex;
          if (jindex === 0 && this.state.toolName === 'polygon') {
            pointArrs.push(<circle id={id} cx={Math.ceil(rect[jindex][0])}
                          cy={Math.ceil(rect[jindex][1])} title="Click to close"
                          onClick={this.savePolygon.bind(this, this.state.defaultClass)}
                          style={{ cursor: 'pointer' }}
                          r={(radius + 3) / this.state.scale} stroke="white" strokeWidth={sw / this.state.scale} fill={lineColor} />);
          } else {
            pointArrs.push(<circle id={id} cx={Math.ceil(rect[jindex][0])}
                          cy={Math.ceil(rect[jindex][1])}
                          r={radius / this.state.scale} stroke="white" strokeWidth={sw / this.state.scale} fill={lineColor} />);
          }
        }
        return (
          <g>
            {pointArrs}
          </g>
          );

    }catch(ex){
    	console.log("renderCurrentPointsError"+ex);
    }
    };


    const toggleEyeStatus = (key, value, event) => {
      event.stopPropagation();
      const hideLabelsMap = this.state.hideLabelsMap;
      hideLabelsMap[key] = value;
      this.setState({ hideLabelsMap });
    }

    const showTimeLine = () => {
      const arrs = [];
      let zoomedWidth = 800 * this.state.zoomLevel
      arrs.push(
        <div>
        <div
          ref={ref => (this.contentRef = ref)}
          style={{ position: 'relative', width: zoomedWidth, height: '50px', cursor: 'pointer' }}
          onClick={this.handleLineParentClick}>
          <div className="slider-button"
            style={{ left: `${(this.state.player.currentTime / this.state.player.duration) * 100}%` }}
            onMouseDown={this.handleMouseDown}></div>
          <div style={{ left: `${(this.state.player.currentTime / this.state.player.duration) * 100}%`, position: 'absolute', top: '12px', fontSize: '10px' }}>{this.state.player.currentTime}</div>
          <div style={{ position: 'absolute', width: '100%', height: '2px', backgroundColor: 'red', top: '50%', left: '0' }} />
          {intervals.map((second, index) => (
            <div>
              <div
                style={{
                  position: 'absolute',
                  width: '2px',
                  height: '10px',
                  backgroundColor: selectedTime === second ? 'red' : 'blue',
                  top: 'calc(50% + 0px)',
                  left: `${(second / videoDuration) * 100}%`,
                  cursor: 'pointer',
                }}
                onClick={() => this.handleLineClick(second)} />
              <span style={{ position: 'absolute', top: 'calc(50% + 20px)', left: `${(second / videoDuration) * 100}%`, transform: 'translateX(-50%)', fontSize: '10px' }}
              >{this.formatTime(second)}</span>
            </div>
          ))}
        </div>
        <div className='top-nav'>Activity</div>
        </div>
      )
      return (<div>
        {arrs}
      </div>)
    }

    const activityItems = () => {
      const arrs = [];
      let index1 = 0;
      const oddNumberElements = document.querySelectorAll('.example-track[class*="example-track-"]:nth-child(odd)');
      const { slidevalues } = this.props;
      for (let idx = 0; idx < Object.keys(this.props.entityColorMap).length; idx++) {
        let key = Object.keys(this.props.entityColorMap)[idx]
        for (let i = 0; i < oddNumberElements.length; i++) {
          oddNumberElements[i].style.backgroundColor = "white"
        }
        let entitySlider = (slidevalues.length > 0 && slidevalues[idx])
        arrs.push(
          <div style={{display: "flex", gap: "5px"}}>
          <div title="Add slider">
            <Button
              size="mini"
              color="blue"
              icon
              key={idx}
              style={{
                position: 'relative', padding: '10px',
                left: '750px', border: 'none', backgroundColor: '#12c4fc',
                top: '80px', borderRadius: '50%'
              }}
              onClick={() => this.handleButtonClick(idx)}>
              <Icon name="add" /></Button>
          </div>
     {
      entitySlider && 
      slidevalues[idx].length > 1 ?
          <div title="Add slider">
            <Button
              size="mini"
              color="blue"
              icon
              style={{
                position: 'relative', padding: '10px',
                left: '750px', border: 'none', backgroundColor: 'red',
                top: '80px', borderRadius: '50%'
              }}
              onClick={() => this.handleDelete(idx)}>
              <Icon name="delete" /></Button>
          </div> : null
     }
          </div>
        )
        { 
          arrs.push(
          <div className="container" >
            <div className="styled">
              <nav className="left-nav">
                <ul>
                  <li>{index1 + 1}.{key}</li>
                </ul>
              </nav>
            </div>
            <div className="styled"
              ref={(ref) => (this.timelineRef = ref)}
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
              onMouseMove={this.handleMouseMove}>
              <nav className="right-nav">
                <ul>
                  <li >
                    <ReactSlider
                      key={slidevalues[idx]}
                      className="horizontal-slider"
                      thumbClassName="example-thumb"
                      trackClassName="example-track"
                      min={0}
                      max={1}
                      step={0.001}
                      value={entitySlider ? slidevalues[idx] : null}
                      onAfterChange={(newValues, index) => this.handleSliderChange(newValues, index, idx)}
                      style={{ width: this.state.trackWidth }}
                      onBeforeChange={(values, index) => {
                      this.activeThumbIndex = index;
                        }}
                      renderThumb={(props, state) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            cursor: 'e-resize',
                            width: '2px',
                            height: '30px',
                            backgroundColor: 'white',
                            border: '2px solid black',
                          }}
                          onMouseDown={(e) => {
                            props.onMouseDown(e);
                          }}
                          onTouchStart={(e) => {
                            props.onTouchStart(e);
                          }}
                        />
                      )}
                      renderTrack={(props, state) => {
                        console.log("props", props)
                        this.state.setvals = idx
                        return (
                          <div title='abc'
                            {...props}
                            style={{
                              ...props.style,
                              backgroundColor : (entitySlider && slidevalues[idx].length > 0) ? this.props.entityColorMap[key] : "white",
                              border: '2px solid black'
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        );
                      }}
                    />
                    {this.state.activeSliderIndex === idx && (
                    <div style={{ position: 'absolute', bottom: '5px', fontSize: '10px'}} >{this.state.player.currentTime}</div>
                    )}
                  </li>
                </ul></nav></div>
          </div>
        )        
      }
        index1 += 1
      }
      return (<div>
        {arrs}
      </div>)
    }

    const getMenuItems = () => {
      const arrs = [];
      let index1 = 0;
      let jindex = 0;
      for (const [key, value] of entries(this.props.entityColorMap)) {
        if (!this.state.searchQuery || this.state.searchQuery.length === 0 || key.includes(this.state.searchQuery)) {
          let bgC = 'white';
          if (this.state.defaultClass === key) {
            bgC = 'grey';
          }
          let combo = undefined;
          if (key in this.props.shortcuts) {
            combo = convertKeyToString(this.props.shortcuts[key]);
            Mousetrap.bind(combo, selectCategory.bind(this, key));
          }
          arrs.push(
            <div className="disable_text_highlighting text-center" onClick={selectCategory.bind(this, key)} tabIndex={jindex} key={key} id={key} eventKey={value} style={{ cursor: 'pointer', backgroundColor: `${bgC}`, minHeight: '30px', marginBottom: '3px', padding: '3px', marginTop: '3px', display: 'flex', justifyContent: 'space-between' }} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId)  === ROLES_TYPES.QA) ? true : false}>
                   { ((key in this.state.hideLabelsMap) && (this.state.hideLabelsMap[key] === true)) &&
                    <div title="Show Labels" style={{ marginTop: '2px' }}>
                      <Button size="mini" icon style={{ backgroundColor: 'white', cursor: 'pointer' }} onClick={(event) => toggleEyeStatus(key, false, event)}>
                        <Icon name="low vision" style={{ color: `${this.props.entityColorMap[key]}`}} />
                      </Button>
                    </div>
                  }
                    { ((!(key in this.state.hideLabelsMap)) || (key in this.state.hideLabelsMap && this.state.hideLabelsMap[key] === false)) &&
                    <div title="Hide Labels" style={{ marginTop: '2px' }}>
                      <Button size="mini" icon style={{ backgroundColor: 'white', cursor: 'pointer' }} onClick={(event) => toggleEyeStatus(key, true, event)}>
                        <Icon name="eye" style={{ color: `${this.props.entityColorMap[key]}`}} />
                      </Button>
                    </div>
                    }
                <div style={{ cursor: 'pointer',marginTop: '5%' }}>
                  <div>
                    <Label title={key} className={"margin-t-5 " + (key.length > 10 ? 'vlabel_width' : '')} id={key} size="mini" style={{ boxShadow: '1px 1px 1px', color: 'white', backgroundColor: `${this.props.entityColorMap[key]}` }}> {key}</Label>
                  </div>
                  <div>
                    { combo && <p style={{ fontSize: '0.6rem' }}> {combo}</p> }
                  </div>
                </div>
              </div>);
          jindex = jindex + 1;
        }
        index1 = index1 + 1;
      }
      return ( <div
                  style={{ display: 'flex', margin: '0 auto', overflow: 'auto', flexDirection: 'column'}}
                  bsStyle="primary"
                >
                <div className="input_ui_sec">
                  <Input size="mini" value={this.state.searchQuery} onChange={(event) => this.setState({searchQuery: event.target.value })} placeholder="Search..." />
                </div>
                <div>
                  {arrs}
                </div>
                      </div>);
    };

    const toFixedDec = (num) => {
      return num.toFixed(6);
    };
    const onImgLoad = () => {
      let { windowWidth, windowHeight } = this.getWindowDimeshions();
      this._panner = new Panner({
        screenWidth: windowWidth,
        screenHeight: windowHeight
      });
      this.setState({ translate: {
        x: this._panner.viewport.x,
        y: this._panner.viewport.y
      }, scale: this._panner.scale });
      this.setState({imgLoad: true});
    };

    const setFunctions = () => {
      console.log('setting functions', this.canvas);
      const canvas = this.canvas;
      if (!this.state.canvasSet && canvas && this.state.videoLoad) {
        console.log('setting canvas');
        canvas.addEventListener('mousedown', this.mousedownHandle);
        canvas.addEventListener('mousemove', this.mousemoveHandle);
        canvas.addEventListener('mouseup', this.mouseupHandle);
        canvas.addEventListener('wheel', this._onWheel);
        this.setState({ canvasSet: true});
        console.log('playing video');
        onImgLoad();
        this.setVolume(this.state.defaultVolume);
        this.changePlaybackRateRate(this.state.defaultPlaybackRate);
        console.log('state is', this.state);
      }
    };

    let clearButton = 'Clear All';
    if (this.props.shortcuts && 'clearAll' in this.props.shortcuts) {
      clearButton = clearButton + ' (' + convertKeyToString(this.props.shortcuts.clearAll) + ' )';
    }

    let undoButton = 'Undo';
    if (this.props.shortcuts && 'undo' in this.props.shortcuts) {
      undoButton = undoButton + ' (' + convertKeyToString(this.props.shortcuts.undo) + ' )';
    }
    const changeImageProp = (name, event) => {
      console.log('changeImageProp', name, event.pageX, event.offsetY, event.target.value, event.offsetX, event.offsetY, event.deltaY);
      if (name === 'scale') {
        this.zoom(250, 250, this.state.scale - event.target.value);
      } else {
        this.setState({ [name]: event.target.value });
      }
    }

    const saveEndTime = (id) => {
      const endTime = this.state.player.currentTime;
      const endTimeMap = this.state.endTimeMap;
      endTimeMap[id] = endTime;
      this.setState({ endTimeMap });
       if (this.props.drawHandle) this.props.drawHandle(this.state);
    }
    const setTime = (id, time, index) => {
      this.seek(time);
      if (index < 0) {
        const endTimeMap = this.state.endTimeMap;
        endTimeMap[id] = time;
        this.setState({ endTimeMap });
        return;
      }
      const rectTimeMap = this.state.rectTimeMap;
      const timeMap = rectTimeMap[id];
      timeMap[index] = time;
      rectTimeMap[id] = timeMap;
      this.setState({ rectTimeMap });
      if (this.props.drawHandle) this.props.drawHandle(this.state);
    }
    const getTimePoints = (id, timemap) => {
      let renderArrs = [];
      for (let index = 0; index < timemap.length; index ++) {
        let time = timemap[index];
        if (time !== undefined) {
          renderArrs.push(
            (<div className="flex_point_sec">
               <Label style={{ cursor: 'pointer' }} onClick={this.seek.bind(this, time)} size="mini"> {time} </Label>
             </div>)
          )
        }
      }
      return <div className="wrap_points"> {renderArrs} </div>;
    }

    const renderCurrentLabelShape = () => {
      const {notes} = this.state;
      const options = [];
      let entitiesLab = []
      let position = '';
      let zIndex = '';
      entitiesLab = this.props.entities ? [...this.props.entities] : [...this.state.entities]
      if (this.props.hitLabels && this.props.hitLabels.length > 0) {
        this.props.hitLabels.map(elem => {
          if (!entitiesLab.includes(elem)) {
            entitiesLab.push(elem)
          }
        })
      }
        const key = this.state.labelKey;
        const endTime = this.state.endTimeMap[key];
        const entitySet = new Set(this.state.rectCatMap[key]);
        options.push({ key: 'addLabel', text: (<Button size="mini" onClick={() => addDynamicLabel(key)}>Add Label</Button>) });
        for (let jindex = 0; jindex < entitiesLab.length; jindex++) {
          position = 'relative';
          zIndex = '1';
          if (entitySet.has(entitiesLab[jindex])) {
            console.log("entuentity", entitiesLab[jindex])
            options.push({ key: entitiesLab[jindex], text: (<Checkbox checked onChange={changeLabel.bind(this, key, jindex)} label={entitiesLab[jindex]} title={entitiesLab[jindex].length > 10 ? entitiesLab[jindex] : ''} />) });
          } else {
            console.log("entuentity1", entitiesLab[jindex])
            options.push({ key: entitiesLab[jindex], text: (<Checkbox onChange={changeLabel.bind(this, key, jindex)} label={entitiesLab[jindex]} title={entitiesLab[jindex].length > 10 ? entitiesLab[jindex] : ''} />) });
          }
        }
        if (!this.props.drawHandle) {
          if (notes && key in notes && notes[key].length > 0) {
            options.push({ key: 'note', text: notes[key] });
          }
        } 
        else {
          if (notes && key in notes && notes[key].length > 0 && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)) {
            options.push({ key: 'note', text: (<Button size="mini" onClick={() => closeDropdownFromNotes(key)}> Edit Note </Button>) });
          } else if ((parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR)) {
            options.push({ key: 'note', text: (<Button size="mini" onClick={() => closeDropdownFromNotes(key)}> Add Note </Button>) });
          }
        }
        if (this.props.attributes) {
          options.push({ key: 'Attribute', text: (<Button size="mini" onClick={() => closeDropdownFromAttributes(key)}> Edit Attributes </Button>) });
        }
      return (
       (this.state.showDropdown[key] === undefined || this.state.showDropdown[key] === true) && <div className="well compact ui text-center" style={{ position: 'relative' }}>
          <Dropdown.Menu
          style={{backgroundColor : 'white'}}
          >
            <Dropdown.Header
              content={
                <div style={{ width: '100%', float: 'right' }}>
                  <Icon size="large" onClick={() => closeDropdown(key)} className="pull-right" name="close" />
                </div>
              } />
            <div style={{ maxHeight: '20rem', overflow: 'scroll', float:"center" }}>
              {options.map((optionItem) => <div style={{ margin: '10px', padding: '10px' }}>{optionItem.text}</div>)}
            </div>
          </Dropdown.Menu>
          </div>
      )
    }

    const renderCurrentLabel = () => {
      let color = undefined;
      let startTime = undefined;
      let endTime = undefined;
      let tag = this.state.defaultClass;
      let id = undefined;
      let timeMap = undefined;
      let rectTimeMap = undefined;
       for (const key of Object.keys(this.state.mouseHoverMap)) {
        if (this.state.mouseHoverMap[key]) {
          tag = this.state.rectCatMap[key];
          color = this.props.entityColorMap[tag];
          rectTimeMap = this.state.rectTimeMap;
          timeMap = rectTimeMap[key];
          id = key;
          if (timeMap) {
            startTime = timeMap[0];
            endTime = timeMap[timeMap.length - 1]
           // endTime = this.state.endTimeMap[key];
          }
          break;
        }
      }
      if (id !== undefined) {
        return (
          <div className="well compact ui text-center"
           style={{ position: 'relative', backgroundColor: 'white' }}
           >
            <Label size="mini" attached="top left">Current Label</Label>
            <div className="c_labelbox">
              <Label className="text-left" size="mini" style={{ color: 'white',
              backgroundColor: 
              `${((this.props.hitEntities && this.props.hitEntities.length > 0) && (id in this.props.hitEntities)) ?  
              this.props.hitEntities[id] : this.props.entityColorMap[this.props.rectCatMap[id]] ||
               this.props.entityColorMap[this.props.rectCatMap[id][0]]}` 
               }}>
                {tag}
              </Label>
            </div>
            <br />
            <div className="well compact" style={{ display: 'flex', flexDirection: 'column' }}>
              <div id="p1">
                <p style={{ fontSize: 'xx-small' }}> Start Time: </p>
                <div>
                  { this.props.drawHandle && (parseFloat(startTime) - (0.001 * this.state.player.playbackRate)) > 0 && <Icon style={{ cursor: 'pointer' }} onClick={setTime.bind(this, id, toFixedDec(parseFloat(startTime) - (0.001 * this.state.player.playbackRate)), 0)} size="mini" name="minus" /> }
                    <Label style={{ cursor: 'pointer' }} onClick={this.seek.bind(this, startTime)} size="mini"> {startTime} </Label>
                  { this.props.drawHandle && (parseFloat(startTime) + (0.001 * this.state.player.playbackRate)) <= this.state.player.duration && (endTime !== undefined && (parseFloat(startTime) + (0.001 * this.state.player.playbackRate)) <= endTime) &&
                    <Icon style={{ cursor: 'pointer' }} onClick={setTime.bind(this, id, toFixedDec(parseFloat(startTime) + (0.001 * this.state.player.playbackRate)), 0)} size="mini" name="plus" /> }
                </div>
              </div>
              <div id="p2">
              { endTime !== undefined &&
                <div id="p3">
                <p style={{ fontSize: 'xx-small'}}> End Time: </p>
                  {timeMap && <div id="p4">
                  { this.props.drawHandle && ((parseFloat(endTime) - (0.001 * this.state.player.playbackRate)) >= 0 && (parseFloat(endTime) - (0.001 * this.state.player.playbackRate)) >= startTime) && <Icon style={{ cursor: 'pointer' }} onClick={()=>{timeMap[timeMap.length - 1] = toFixedDec(parseFloat(endTime) - (0.001 * this.state.player.playbackRate)); setTime( id, toFixedDec(parseFloat(startTime)), 0);  this.seek(timeMap[timeMap.length - 1]) ; saveEndTime(id)}} size="mini" name="minus" /> }
                        <Label style={{ cursor: 'pointer' }} onClick={()=>{this.seek(endTime); this.setState({isEditEndTime: true,endTimeValue: timeMap[timeMap.length - 1]})}} size="mini"> {endTime} </Label>
                  {(this.state.isEditEndTime && this.props.fromSample === undefined) && <input size="mini" type="text" className="v_input"  value={this.state.endTimeValue} onChange={(e)=>{this.setState({endTimeValue: e.target.value})}} />}
                  {(this.state.isEditEndTime && this.props.fromSample === undefined) && <Button title="OK" icon size="mini" onClick={()=>{timeMap[timeMap.length - 1] = this.state.endTimeValue;  this.setState({isEditEndTime: false , rectTimeMap }); setTime( id, toFixedDec(parseFloat(startTime)), 0); this.seek(timeMap[timeMap.length - 1]); saveEndTime(id)}}> <Icon name="check" /></Button>}

                  { this.props.drawHandle && (parseFloat(endTime) + (0.001 * this.state.player.playbackRate)) <= this.state.player.duration  &&
                       <Icon style={{ cursor: 'pointer' }} onClick={()=>{timeMap[timeMap.length - 1] = toFixedDec(parseFloat(endTime) + (0.001 * this.state.player.playbackRate)); setTime( id, toFixedDec(parseFloat(startTime)), 0); this.seek(timeMap[timeMap.length - 1]) ; saveEndTime(id)}} size="mini" name="plus" /> }
                </div>}
                </div>
              }
                { this.state.endTimeMap[id] === undefined && <Button style={{ cursor: 'pointer' }} disabled={this.state.player.currentTime < startTime } compact onClick={saveEndTime.bind(this, id)} size="mini" color="red"> End </Button> }

              </div>
            </div>
            <br />
            { timeMap && timeMap.length >= 1 &&
              <div className="well compact time_sec_tag" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="timeline_c">
                <p className="timeline_con">Timeline</p>
                {getTimePoints(id, timeMap)}
              </div>
            </div>}
          </div>
          );
      }
    }

    const setDropdown = (key) => {
      this.setState({ dropdownVal : 1, labelKey : key });
    }

    const setHover = (key) => {
      const mouseHoverMap = this.state.mouseHoverMap;
      for (const k in mouseHoverMap) {
        if (mouseHoverMap.hasOwnProperty(k)) {
          if (k !== key) {
            mouseHoverMap[k] = false;
          }
        }
      }
      if (mouseHoverMap[key]) {
        mouseHoverMap[key] = false;
      } else {
        mouseHoverMap[key] = true;
      }
      this.setState({ mouseHoverMap });
    }
    

    const closeDropdownFromAttributes = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = false
      this.setState({ showDropdown, openAttributeOption: true, attributeIndex: key })
    }



    const openDropdown = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = true
      console.log("showDropdown1", showDropdown)
      this.setState({ showDropdown })
    }
    const closeDropdown = (key) => {
      const { showDropdown } = this.state
      showDropdown[key] = false
      console.log("showDropdown", showDropdown)
      this.setState({ showDropdown })
    }

    const renderCompleteLabels = () => {
      const renderarrs = [];
      const { rects, rectCatMap, notes } = this.state;
      let position = '';
      let zIndex = '';
      for (const key of Object.keys(this.state.rectTimeMap)) {
        let boxShadow = '';
        let transform = '';
        let border = '';
        const cat = this.state.rectCatMap[key];
        const mouseHoverMap = this.state.mouseHoverMap;
        if (mouseHoverMap[key]) {
          boxShadow = "0px 0px 10px 2px #000";
          transform = "scaleY(1.2)";
          border = "2px dashed rgb(255, 255, 255)";
        }
        let size = 'mini';
        if (mouseHoverMap[key]) {
          size = 'small';
        }
        {
          this.props.hitEntities &&
            renderarrs.push(
              <div style={{
                cursor: 'pointer', wordBreak: 'break-word', borderRadius: '15px',
                boxShadow: `${boxShadow}`, transform: `${transform}`, position: `${position}`, zIndex: `${zIndex}`, border: `${border}`,
                display: 'flex', marginRight: '2px', marginBottom: '5px', color: 'white', 
                backgroundColor: `${key in this.props.hitEntities ? this.props.hitEntities[key] : this.props.entityColorMap[this.props.rectCatMap[key]] || this.props.entityColorMap[this.props.rectCatMap[key][0]]}`,
                boxShadow: `${boxShadow}`,
                transform: `${transform}`,
                border: `${border}`,
              }} className="l_blabel" disabled key={key} id={key}
                onClick={setHover.bind(this, key)} >
                {((key in this.state.hideRectMap) && (this.state.hideRectMap[key] === true)) &&
                  <Icon size="small" title="Show Label" name="low vision" style={{ cursor: 'pointer', color: `${isDarkColor(this.props.hitEntities[key]) ? 'white' : 'darkslategrey'}`, fontSize: '10px', display: displaySettingsByRole(getUidToken().roleId) }} onClick={(event) => { toggleEyeRectStatus(key, false, event); }} />
                }
                {((!(key in this.state.hideRectMap)) || (key in this.state.hideRectMap && this.state.hideRectMap[key] === false)) &&
                  <Icon name="eye" title="Hide Label" style={{ cursor: 'pointer', color: `${isDarkColor(this.props.hitEntities[key]) ? 'white' : 'darkslategrey'}`, fontSize: '10px', display: displaySettingsByRole(getUidToken().roleId) }} onClick={(event) => { toggleEyeRectStatus(key, true, event); }} />
                }
                <Icon title="Delete" style={{ cursor: 'pointer', fontSize: '10px', color: `${isDarkColor(this.props.hitEntities[key]) ? 'white' : 'darkslategrey'}` }} name="delete" id={key} onClick={removeRect.bind(this)} />
                {cat[0] || cat}
                {this.props.drawHandle && (parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR) &&
                  <Dropdown
                    floating
                    inline
                    backward
                    labeled
                    button compact
                    open={this.state.showDropdown[key]}
                    closeOnChange={!this.state.showDropdown[key]}
                    closeOnBlur={false}
                    onOpen={() => openDropdown(key)}
                    className={"icon " + (rectCatMap[key].length > 10 ? 'b_labeloption' : '')}
                    style={{ fontSize: 'xx-small', color: `${isDarkColor(this.props.hitEntities[key]) ? 'white' : 'darkslategrey'}`, backgroundColor: `${key in this.props.hitEntities ? this.props.hitEntities[key] : this.props.entityColorMap[this.props.rectCatMap[key]] || this.props.entityColorMap[this.props.rectCatMap[key][0]]}` }}
                    disabled={this.props.noTagSelection ? true : false}
                    onClick={setDropdown.bind(this, key)} >
                  </Dropdown>}
                {this.props.drawHandle && 
                  <div className="colorPickerCustom"
                    onClick={(event) => { toggleColorRectStatus(key, false, event); }}>
                    <ColorPicker className="ts"
                    pickerColor={this.props.hitEntities[key] || this.props.entityColorMap[this.props.rectCatMap[key]] || this.props.entityColorMap[this.props.rectCatMap[key][0]]} handleColorChange={this.handleColorChange.bind(this)} pickedTop={false} handleClose={this.handleClose.bind(this)} />
                  </div>
                }
              </div>)
        }
      }
      if (renderarrs.length > 0) {
        return (
          <div className="well" style={{ position: 'relative',padding: '5%' }}>
            <Label size="mini" attached="top left">Completed Labels</Label>
            {renderarrs}
          </div>);
      }
    }

    console.log('polygon annotate. render state', this.state);

    const { windowWidth, windowHeight } = this.getWindowDimeshions();
    this.state.windowWidth = windowWidth;
    this.state.windowHeight = windowHeight;
    // let toolCombo = undefined;
    if (this.props.space && this.props.shortcuts) {
      let combo = undefined;
      const shortcuts = this.props.shortcuts;
      if (this.state.player && this.state.player.paused) {
        Mousetrap.bind('space', this.play.bind(this));
      } else if (this.state.player) {
        Mousetrap.bind('space', this.pause.bind(this));
      }
      if ('forward' in shortcuts) {
        combo = convertKeyToString(shortcuts.forward);
        if (this.state.player && this.state.player.paused) {
          Mousetrap.bind(combo, this.changeCurrentTime.bind(this, 1, this.state.player.currentTime));
        } else if (this.state.player) {
          Mousetrap.unbind(combo);
        }
      }
      if ('backward' in shortcuts) {
        combo = convertKeyToString(shortcuts.backward);
        if (this.state.player && this.state.player.paused) {
          Mousetrap.bind(combo, this.changeCurrentTime.bind(this, - 1, this.state.player.currentTime));
        } else if (this.state.player) {
          Mousetrap.unbind(combo);
        }
      }
      if ('fast_forward' in shortcuts) {
        combo = convertKeyToString(shortcuts.fast_forward);
        if (this.state.player && this.state.player.paused) {
          Mousetrap.bind(combo, this.changeCurrentTime.bind(this, 10, this.state.player.currentTime));
        } else if (this.state.player) {
          Mousetrap.unbind(combo);
        }
      }
      if ('fast_backward' in shortcuts) {
        combo = convertKeyToString(shortcuts.fast_backward);
        if (this.state.player && this.state.player.paused) {
          Mousetrap.bind(combo, this.changeCurrentTime.bind(this, -10, this.state.player.currentTime));
        } else if (this.state.player) {
          Mousetrap.unbind(combo);
        }
      }
      if ('next' in shortcuts) {
        combo = convertKeyToString(shortcuts.next);
        if (this.props.currentIndex >= 0) {
          Mousetrap.bind(combo, this.nextElement.bind(this, 'next'));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('previous' in shortcuts) {
        combo = convertKeyToString(shortcuts.previous);
        if (this.props.currentIndex > 0) {
          Mousetrap.bind(combo, this.nextElement.bind(this, 'previous'));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('skip' in shortcuts) {
        combo = convertKeyToString(shortcuts.skip);
        if (this.props.currentIndex >= 0) {
          Mousetrap.bind(combo, this.nextElement.bind(this, 'skip'));
        } else {
          Mousetrap.unbind(combo);
        }
      }
      if ('delete' in this.props.shortcuts) {
        combo = convertKeyToString(this.props.shortcuts.delete);
        Mousetrap.bind(combo, removeRect.bind(this, undefined));
      }
    }
    let intervalLength;
    let intervals;
    let videoDuration = 0;
    let splitLength;
    if(player){
      if(!isNaN(player.duration)){
        videoDuration = player.duration;
         intervalLength = Math.ceil(videoDuration / 10); // Divide the video duration into 10 equal intervals
         splitLength = videoDuration > 60 ? Math.round(videoDuration / 60) : videoDuration < 10 ? videoDuration : 10;
         intervals = Array.from({ length:splitLength }, (_, index) => index * intervalLength);
         intervals.push(videoDuration)
      }
    }
    return (
    <div className="pr_img_annotate w-100" style={{ lineHeight: 0, display: 'flex', flexDirection: 'row',height: '100%'}}>
     <div className="w-80 work_sec" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#E0E0E0', width: '100%', height: '100%', overflow: 'auto' }}>
        <div className="w_header">
        <label className="m-r-10" style={{ display: 'flex', flexDirection: 'column', height: '20px', justifyContent: 'space-between'}}>
                <span className="l_white">Box Opacity : <b> {Math.round(this.state.opacity * 100)}%</b> </span>
                <input type="range" className="range_slider" step="0.01" min="0" max="1" value={this.state.opacity} onChange={changeImageProp.bind(this, 'opacity')} />
              </label>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px"}} className="menu_strip_con">
        {( this.state.rects.length > 0) &&
                  <button title="Lock Single Annotation" onClick={() => lockSingleAnonotation(true)} style={
                    {
                      backgroundColor: this.state.lock === true ? '#12C4FC' : null,
                    }
                  } className="rotateImg">
                    <div>
                      <Icon style={{height: "10px", width: "10px"}} name="lock" className="rotateImgIcon" />
                    </div>
                    </button>
                }
                {(this.state.rects.length > 0) &&
                  <button style={{display: "flex", justifyContent: "center", alignItems: "center"}} title="UnLock Single Annotations" onClick={() => lockSingleAnonotation(false)} style={
                    {
                      backgroundColor: this.state.lock === false ? '#12C4FC' : null,
                    }
                  } className="rotateImg">
                    <div>
                      <Icon style={{height: "10px", width: "10px"}} name="lock open" className="rotateImgIcon" />
                    </div>
                    </button>
                }

                {(this.state.rects.length > 0) &&
                  <span style={{cursor: "pointer"}} className="annotCheck selectLocked"><input type="checkbox" className="mousetrap" id="shield alternate" onChange={(event) => { lockAnnotationChange(event.target.checked) }} title={!this.state.lockAnnotation ? TOOLTIP.LOCK_ANNOTATIONS : TOOLTIP.UNLOCK_ANNOTATIONS}></input><label></label></span>
                }
        </div>
        </div>
        <div>
          <div style={{ lineHeight: 0, display: 'block', position: 'relative' }}>
                <div className="pan-zoom-element" style={{width: windowWidth, height: windowHeight}}>
                  <div className="content-container noselect" style={{ transform: `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale})`, transformOrigin: '0 0', position: 'relative' }}>
            <video
              ref={(player) => { this.player = player; this.state.player = player; }}
              aspectRatio="16:9"
              onLoadedMetadata={(e1, e2, e3) => {
                        console.log('meta data loaded', e1, e2, e3, this.player, this.player.width, this.player.video, this.state.player );
                        const { width, height } = this.videoDimensions(this.player);
                        let marginLeft = 0;
                        let marginTop = 0;
                        if (height !== this.state.windowHeight) {
                          marginTop = Math.abs(height - this.state.windowHeight) / 2;
                        }
                        if (width !== this.state.windowWidth) {
                          marginLeft = Math.abs(width - this.state.windowWidth) / 2;
                        }
                        this.setState({ loading: false, videoLoad: true, videoHeight: height, marginTop, marginLeft, videoWidth: width });
                      }}
                      onLoadStart={this.handleStateChange.bind(this, 'onLoadStart')}
                      onWaiting={this.handleStateChange.bind(this, 'onWaiting')}
                      onCanPlay={this.handleStateChange.bind(this, 'onCanPlay')}
                      onCanPlayThrough={this.handleStateChange.bind(this, 'onCanPlayThrough')}
                      onPlaying={this.handleStateChange.bind(this, 'onPlaying')}
                      onEnded={this.handleStateChange.bind(this, 'onEnded')}
                      onSeeking={this.handleStateChange.bind(this, 'onSeeking')}
                      onSeeked={this.handleStateChange.bind(this, 'onSeeked')}
                      onPlay={this.handleStateChange.bind(this, 'onPlay')}
                      onPause={this.handleStateChange.bind(this, 'onPause')}
                      onProgress={this.handleStateChange.bind(this, 'onProgress')}
                      onDurationChange={this.handleStateChange.bind(this, 'onDurationChange')}
                      onError={this.handleStateChange.bind(this, 'onError')}
                      onSuspend={this.handleStateChange.bind(this, 'onSuspend')}
                      onAbort={this.handleStateChange.bind(this, 'onAbort')}
                      onEmptied={this.handleStateChange.bind(this, 'onEmptied')}
                      onStalled={this.handleStateChange.bind(this, 'onStalled')}
                      onLoadedData={this.handleStateChange.bind(this, 'onLoadedData')}
                      onTimeUpdate={this.handleStateChange.bind(this, 'onTimeUpdate')}
                      onRateChange={this.handleStateChange.bind(this, 'onRateChange')}
                      onVolumeChange={this.handleStateChange.bind(this, 'onVolumeChange')}
              width={windowWidth} height={windowHeight}
              preload="auto"
              fluid={false}
              src={this.state.video}
            />
                    { (this.props.loading || !this.state.videoLoad) && <Dimmer active>
                      <div className="overlay_dom" >
                        <div className="cssload-wraper">
                          <div className="cssload-dots"></div>
                        </div>
                      </div>
                                            </Dimmer>}
                      { this.state.videoLoad &&
                        <div ref={(canv) => { this.canvas = canv; setFunctions();}} style={canvasStyles}>
                          { this.canvas && this.canvas.offsetWidth &&
                                <svg id="drawing" ref={(id) => {  this.svgId = id }} style={{ width: this.state.videoWidth, height: this.state.videoHeight }}>
                                  {Object.keys(this.state.rects).length >= 0 && renderRects() }
                                  {this.state.player.paused && this.props.drawHandle && Object.keys(this.state.rects).length >= 0 && renderPoints() }
                                  {this.state.currentRect && this.props.drawHandle && this.state.currentRect.length > 0 && this.rectToStyles(this.state.currentRect)}
                                  {this.state.player.paused && this.props.drawHandle && this.state.currentRect && this.state.currentRect.length > 1 && renderCurrentPoints()}
                                </svg>
                          }
                        </div>
                      }
                    </div>
                  </div>
            </div>
      </div>
        {this.state.player && this.state.videoLoad &&
          <div>
            { this.state.player.paused &&
              <p id="progressBarText" className="text-center" style={{ fontSize: 'xx-small'}}>
                {this.state.player.currentTime} / {this.state.player.duration}
              </p>
            }
            { !this.state.player.paused &&
              <p id="progressBarTextPlay" className="text-center" style={{ fontSize: 'xx-small'}}>
                {this.state.player.currentTime} / {this.state.player.duration}
              </p>
            }
             <p className="text-center" style={{ fontSize: 'xx-small'}}>
                {(this.state.player.currentTime * this.state.frameRate).toFixed(2)} / {(this.state.frameRate * this.state.duration).toFixed(2)} Frames
              </p>
              <p className="text-center" style={{ fontSize: 'xx-small'}}>
                Skipped Frames : {(this.state.skippedFrames < 0 ? -(this.state.skippedFrames) : this.state.skippedFrames).toFixed(2)} Frames
              </p>
            <input id="progressBar" title="Progress" style={{ padding: '2px' }} className="ui range" onChange={(event, data) => { console.log('onSeekChange', event.target.value, data); this.seek(event.target.value); }} min={0} step={0.0001} max={this.state.player.duration} type="range" value={this.state.player.currentTime} />

              <Button title="Fast Backward" icon size="mini" onClick={this.changeCurrentTime.bind(this, -10, this.state.player.currentTime)}> <Icon name="fast backward" /></Button>
            <Button title="Backward" icon size="mini" onClick={this.changeCurrentTime.bind(this, -1, this.state.player.currentTime)}> <Icon name="backward" /></Button>
            {this.state.player.paused &&
                        <Button title="Play" icon size="mini" onClick={this.play}> <Icon name="play" /></Button>}
            {!this.state.player.paused &&
              <Button title="Pause" icon size="mini" onClick={this.pause}> <Icon name="pause" /></Button>}
            <Button title="Forward" icon size="mini" onClick={this.changeCurrentTime.bind(this, 1, this.state.player.currentTime)}> <Icon name="forward" /></Button>
            <Button title="Fast Forward" icon size="mini" onClick={this.changeCurrentTime.bind(this, 10, this.state.player.currentTime)}> <Icon name="fast forward" /></Button>
            { this.state.player.volume === 0 && <Icon name="volume off" />}
            { this.state.player.volume !== 0 && <Icon name="volume up" />}
            <input style={{ width: '10%', display: 'inline-block'}} title="Volume" className="range" onChange={(event, data) => { console.log('change volume', event.target.value, data); this.setVolume(parseFloat(event.target.value)); }} type="range" min={0} step={0.1} max={1} value={this.state.player.volume} />

            <p>Playback Speed : <b>{this.state.player.playbackRate}x</b></p>
            <input title="PlayBack Rate" className="ui range" onChange={(event, data) => { console.log('changePlaybackRateRate', event.target.value, data); this.changePlaybackRateRate(parseFloat(event.target.value)); }} min={0} step={0.01} max={2} type="range" value={this.state.player.playbackRate} />

                <div ref={ref => (this.contentRef = ref)}
                  style={{ overflowY: 'auto', height: containerHeight, backgroundColor: 'rgb(220 221 223 / 80%)' }}
                >
                  <div ref={this.contentRef}>
                    {showTimeLine()}
                    <div>
                      {activityItems()}
                    </div>
                  </div></div>
          </div>
        }
      </div>
      {
        !this.props.fromSample ? 
      <div className="w-20 video_anno_sec label_sec m-l-5" style={{marginLeft: '5px' }}>
          { !this.state.toolbarHidden &&
              <div className="label_holder" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                    {
                    <div className="btn_top_card l_header" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', display: displaySettingsByRole(getUidToken().roleId)}}>
                      <div title="Click to draw Polygon">
                        <Button size="mini" color={this.state.toolName === 'polygon' ? 'blue' : 'grey'} icon onClick={this.toggleTool.bind(this, 'polygon')}>
                          <Icon name="star outline" />
                        </Button>
                      </div>
                          <div style={{ height: '10px' }} />
                      <div title="Click to draw Rectangle">
                        <Button size="mini" color={this.state.toolName === 'rectangle' ? 'blue' : 'grey'} icon onClick={this.toggleTool.bind(this, 'rectangle')}>
                          <Icon name="square" style={{ color : "transparent", border : "2px solid white"}}/>
                        </Button>
                      </div>
                    </div>
                      }
                   
                    { this.state.rects.length > 0 &&
                      <div id="currentLabel" style={{ height: 'max-content', overflow: 'auto' }}>
                        {renderCurrentLabel()}
                      </div>
                    }
                     { this.state.dropdownVal === 1 &&
                      <div id="currentLabel" style={{ height: 'max-content', overflow: 'auto' }}>
                        {renderCurrentLabelShape()}
                      </div>
                    }
                    { this.state.rects.length > 0 &&
                      <div id="completeLabel" style={{ height: '100px', overflow: this.state.selectColor === true ? 'auto' : '' }}>
                        {renderCompleteLabels()}
                      </div>
                    }
                        <div style={{ height: '20px' }} />
                    {this.props.drawHandle &&
                      <div style={{ position: 'relative', padding: '5px', boxSizing: 'border-box', display: displaySettingsByRole(getUidToken().roleId)}} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId)  === ROLES_TYPES.QA) ? true : false}>
                        <p style={{ padding: '5px', fontSize: '17px'}}>Select Entity</p>
                        { getMenuItems()}
                    </div>}
                        <div style={{ height: '30px' }} />
                      <div className="l_icon_section m-b-5">
                      { this.props.drawHandle &&
                        <div className="e_buttons">
                        <div title={undoButton} className={this.state.currentRect.length === 0 ? 'eventnone' : ''} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
                          <Button icon size="mini" secondary onClick={this.undoLast} disabled={this.state.currentRect.length === 0}>
                            <Icon name="undo" />
                          </Button>
                        </div>
                        <div title={clearButton} className={Object.keys(this.state.rects).length === 0 ? 'eventnone' : ''} style = {{display: displaySettingsByRole(getUidToken().roleId)}}>
                          <Button icon size="mini" className="btn_danger" secondary onClick={this.clearPolygons} disabled={Object.keys(this.state.rects).length === 0}>
                            <Icon name="remove" />
                          </Button>
                        </div>
                          <div className="text-center">
                            <Button icon size="mini" secondary  onClick={this.nextElement.bind(this, 'save')} style = {{display: displaySettingsByRole(getUidToken().roleId)}} >
                              <Icon name="save"/>
                            </Button>
                          </div>
                      </div>}
                        {this.props.space && this.showButtons()}
                      </div>
                </div>
          }
      </div> : null
      }
        {this.state.openNote && this.props.drawHandle &&
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Add a Note
                  <Icon onClick={this.closeModal.bind(this)} 
                  className="pull-right"
                   style={{marginLeft : '350px'}}
                   name="close" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <input type="textarea" value={this.state.notes[this.state.noteIndex]} onChange={this.handleNoteChange.bind(this)} autoFocus className="form-control" id="note" />
              </Modal.Body>

              <Modal.Footer>
                <Button type="submit" positive onClick={this.saveModal.bind(this)}>Save</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        }
        {this.state.openHitLabel && this.props.drawHandle &&
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>{this.state.dynamicLabelButtonAction === 'update' ? "Edit Label" : "Add Label"}
                  <Icon onClick={this.closeModal.bind(this)} className="pull-right"
                   style={{marginLeft : '350px'}}
                   name="close" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <input type="textarea" defaultValue={this.state.hitLabel !== '' ? this.state.hitLabel : ''} placeholder="Add Label" onKeyUp={(e) => addDynamicHitLabelChange(e, this.state.dynamicLabelButtonAction === 'update' ? "update" : "add")} autoFocus className="form-control" id="label" />
              </Modal.Body>

              <Modal.Footer>
                <Button disabled={this.state.addlabelHidden} type="submit" positive onClick={() => this.addDynamicHitLabel(this.state.dynamicLabelButtonAction === 'update' ? "update" : "add")}>{this.state.dynamicLabelButtonAction === 'update' ? "UPDATE" : "ADD"}</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        }
        {this.state.openAttributeOption && this.props.drawHandle &&
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>Edit Attributes
                  <Icon onClick={this.closeModal.bind(this)} className="pull-right" 
                  style={{marginLeft : '350px'}}
                  name="close" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {this.getAttributesOptions()}
              </Modal.Body>

              <Modal.Footer>
                <Button type="submit" positive onClick={() => this.saveAttributeDetails()}>Save</Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        }
    </div>
    );
  }
}
VideoAnnotator.propTypes = {
  video: PropTypes.string,
  drawHandle: PropTypes.func,
  space: PropTypes.bool,
  entityColorMap: PropTypes.object,
  rectTimeMap: PropTypes.object,
  rectShapeMap: PropTypes.object,
  shortcuts: PropTypes.object,
  rects: PropTypes.array,
  loading: PropTypes.bool,
  defaultShape: PropTypes.string,
  fullScreen: PropTypes.bool,
  menuHidden: PropTypes.bool,
  rectCatMap: PropTypes.object,
  moveToDone: PropTypes.func,
  skipRow: PropTypes.func,
  endTimeMap: PropTypes.object,
  saveTagAndNextRow: PropTypes.func,
  getBackTopreviousRow: PropTypes.func,
  hits: PropTypes.array,
  fromSample: PropTypes.bool,
  handleIsSaveAction : PropTypes.func,
  attributes: PropTypes.string,
  notes: PropTypes.object,
  attributesOption: PropTypes.object,
  hitEntities: PropTypes.object,
  entities: PropTypes.object,
  hitLabels: PropTypes.object,
  labelGroupMap: PropTypes.object,
  saveHitLabel: PropTypes.func,
  slidevalues :  PropTypes.object,
  type: PropTypes.string,
  };
