// UserDetails.js
import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { signIn } from '../../redux/reducer/loginReducer';
import { push, replace } from 'react-router-redux';
import { Button,Icon, Message, Item, Dropdown} from 'semantic-ui-react';
import { getUidToken } from '../../helpers/commonFunctions';
import { APP_CONSTANTS } from '../../helpers/Constants';
import { getUsersById,approveUserByAdmin,getAllOrganizationsWithOutPagination,uploadProfileImage } from '../../Services/usersApis';
import { showAlert } from '../../helpers/commonFunctions';
import BACK from '../../images/Arrow_Simple_left1.svg';
import actionDispatcher from '../../redux/app-actions';
import Profile from "../../images/profile.png"
class UserDetails extends React.Component {
  
    constructor(props) {
        console.log('props are ', props);
        super(props);
        this.updateSelectedOrg = this.updateSelectedOrg.bind(this);
        this.updateOrgSelctionMode = this.updateOrgSelctionMode.bind(this);
        this.approveUser = this.approveUser.bind(this);
        this.redirectToAddOrg = this.redirectToAddOrg.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.afterImageUpload = this.afterImageUpload.bind(this);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let currentPage = params.get('currentpage');
        this.state={
          currentpage:currentPage,
          activeMenu: 'users',
          firstName: '',
          secondName: '',
          profileImage: null,
           email: '',
           role: "0",
           orgName: "0",
           orgId: "0",
           userId: null,
           error: null,
           success: null,
           profileLink: null,
           profilePic: null,
           status: null,
           notificationToken: null,
           notes: null,
           mAuthorized: false,
           passwordChanged: false,
           deleted: false,
           approved: true,
           oauthId: null,
           oauthType: null,
           orgData: [],
           orgOptions: [],
           allOrgInfo: [],
           selectedOrg: null,
           orgModelOpen: false,
           orgSelectionMode: 'select',
           addOrgLoading: false
        }
      }

      componentWillMount() {
        if (this.props.params.userId !== undefined) {
          getUsersById(this.props.params.userId, this.dtgetUsersByIdCallback.bind(this));
          getAllOrganizationsWithOutPagination((err, res) => {
            console.log(res)
            if (!err) {
              const allorgs =
              res.body.map((it, index) =>
                ({
                  key: it.id,
                  text: it.name,
                  value: it.id
                }))
              this.setState({
                allOrgInfo: allorgs
              })
            }
          })
        }
      }
      
      componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps taggeruserdetails : ", nextProps);
        if (nextProps.params.userId !== undefined) {
          getUsersById(nextProps.params.userId, this.dtgetUsersByIdCallback.bind(this));
        }
      }
      dtgetUsersByIdCallback(error, response) {
        if (!error) {
          this.props.updateOrgName(response.body.organizationName ? response.body.organizationName : response.body.orgName)
          this.setState({
            firstName: response.body.firstName,
            orgName: response.body.organizationName ? response.body.organizationName : response.body.orgName,
            secondName: response.body.secondName,
            profileImage: response.body.profileImage,
            email: response.body.email,
            orgId: getUidToken().roleId !== "2" ? "0" : getUidToken().orgId,
            role: response.body.roleId,
            userId: response.body.id,
            profileLink: response.body.profileLink,
            profilePic: response.body.profilePic,
            status: response.body.status,
            notificationToken: response.body.notificationToken,
            notes: response.body.notes,
            mAuthorized: response.body.mAuthorized,
            managerId: response.body.managerId,
            passwordChanged: response.body.passwordChanged,
            deleted: response.body.deleted,
            approved: response.body.approved,
            oauthId: response.body.oauthId,
            oauthType: response.body.oauthType,
          });
        }
      }

      getQueryParam(param) {
        console.log("getquery",this.props.location.search)
        const searchParams = new URLSearchParams(this.props.location.search);
        return searchParams.get(param);
      }

      getOrganizationsCallback(error, response) {
        if (!error) {
          this.setState({ allOrgInfo: response.body });
        } else {
          showAlert('error', 'error');
        }
      }
      listAllorg(){
          this.setState({ orgModelOpen: true});
          getAllOrganizationsWithOutPagination(this.getOrganizationsCallback.bind(this));
      }
      backToUsers(event) {
        const {role} = this.state
        if (role === '3' || role === '5' || role === '6') {
          this.props.replaceState('/projects/' + role + '/list');
        } else {
          this.props.replaceState('/projects/users');
        }
      }
      approveUser = () => {
        const {orgId,userId} = this.state
        if (userId && orgId !== "0") {
        approveUserByAdmin(userId, orgId, this.dtApproveUserByAdminCallback.bind(this))
        } else {
          this.setState({error: 'Please select or Add organization.'});
        }
      }

      dtApproveUserByAdminCallback(error, response) {
        console.log('dtApproveUserByAdminCallback', error, response);
        if (!error) {
            this.setState({loading: false, success: 'User approved successfully', error: null});
            getUsersById(this.props.params.userId, this.dtgetUsersByIdCallback.bind(this));
        } else {
          showAlert(error, 'error');
          this.setState({ loading: false });
        }
      }

      /**
       * to update the selected org state on org list DD change
       * @param value
       */
      handleOrgChange = (e, {value}) => {
        this.setState({orgId: value});
      }
      updateSelectedOrg(value) {
        console.log("sims org", value);
        this.setState({selectedOrg: value, orgId: value})
      }

      updateOrgSelctionMode = (value) => {
        this.setState({ orgSelectionMode: value })
      }

      redirectToAddOrg = () => {
        this.props.updateOrgName(this.state.orgName)
        this.props.updateActiveMenu('')
        this.props.replaceState('/projects/addOrganization');
      }

      handleClick = (e) => {
    	e.stopPropagation();
        this.refs.profileImageUploader.click();
      }
      handleUploadFile(event) {
        const file = event.target.files[0];
        if (file.type.indexOf("image") === -1) {
          showAlert("Please upload image file only", 'error')
        } else if ( file.size > APP_CONSTANTS.PROFILE_IMAGE_MAX_SIZE || file.size < APP_CONSTANTS.PROFILE_IMAGE_MIN_SIZE) {
          showAlert("Please upload image of size between 5kb and 250kb only.", 'error')
        }else {
        uploadProfileImage(file,this.props.params.userId, this.afterImageUpload);
        }
      }

      afterImageUpload(error, response){
        if (!error) {
          showAlert("Profile Image Updated.");
          if (response && response.body) {
            this.setState({profileImage: response.body.profileImage});
            const { uid } = getUidToken();
            if(uid === this.props.params.userId){
                this.props.signIn({firstName: response.body.firstName, lastName: response.body.secondName,  fullName: response.body.firstName + " " + response.body.secondName, uid: response.body.uid, email: response.body.email, profileImage: response.body.profileImage });
            }
          }
        } else {
          showAlert("Unable to update the profile image.", 'error');
        }
      }

  render(){
    console.log(this.state.orgName, this.state.allOrgInfo)
    const currentpage = this.getQueryParam('currentpage');
    const {firstName,profileImage,secondName,role,email,orgName,allOrgInfo,approved,userId,deleted,success,orgId,error} = this.state
    return(
        <div style={{paddingLeft: '6%', paddingRight: '3%'}} className="pr_profile m-t-20 ">
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <div className="f-column">
                <div className="f-center">
                {currentpage === '1' && (
                     <a className="m-r-5">
                      <span className="t_btn_back" onClick={this.backToUsers.bind(this)}>
                        <img alt='back' src={BACK} />
                      </span>
                    </a>
                  )}
                </div>
                <div className="breadcrum-sec">
                  <div className="sec_holder">
                    <span className="tilte_display fw-600">User's Details</span>
                  </div>
                </div>
              </div>
              <div className="project_set"></div>
            </div>
            {
              firstName?
                <Item.Group relaxed>
                <Item>
                  <div className="profile_sec">
                  { profileImage ?
                     <Item.Image size='small' src={`data:image/jpeg;base64,${profileImage}`}/>
                     :
                     <Item.Image size='small' src={Profile}/>
                  }
                    <button className="upload_btn" onClick={this.handleClick} title="Upload profile photo"><Icon name="upload" /></button>
                    <input type="file" id="file" ref="profileImageUploader" accept="image/*" style={{display: "none"}} onChange = {this.handleUploadFile}/>
                  </div>
                  <Item.Content verticalAlign='middle' className="info_sec">
                  <div className="label_set">
                    <label>Name: </label>
                    <p>{firstName +'  '+ secondName }</p>
                  </div>
                  <div className="label_set">
                    <label>Role: </label>
                    <p>{role === 2 ? 'Admin': role === 1 ? 'Super Admin': role === 4 ? 'System Admin': role === 5 ? 'Guest': role === 6 ? 'QA':'Annotator'}</p>
                    </div>

                    <div className="label_set">
                    <label>Email: </label>
                    <p>{email }</p>
                    </div>

                    <div className="label_set">
                    <label>Organization: </label>
                    <p><p>{orgName} {'     '}
                     {allOrgInfo &&
                     !approved &&
                     userId && getUidToken().roleId !== "2" && getUidToken().roleId !== "1" && 
                        <Dropdown
                          style={{
                            marginLeft: 25,
                          }}
                          placeholder='Select Organization'
                          search selection options={allOrgInfo}
                          onChange={this.handleOrgChange.bind(this)}
                        />
                      }
                      {
                        (!approved && getUidToken().roleId !== "2" && getUidToken().roleId !== "1") &&
                        <Button
                          style={{
                            marginLeft: 25
                          }}
                          onClick={this.redirectToAddOrg}>
                          Add Organization

                        </Button>
                      }
                      </p></p>
                    </div>
                    {role > 1 &&
                        <div className="label_set">
                        <label>Status: </label>
                        <p>{approved && !deleted ? <span style={{ color: 'green'}}>Approved</span> : !approved  ? <span style={{ color: 'yellow'}}>Pending</span> : <span style={{ color: 'red'}}>InActive</span>}</p>
                        </div>
                      }

                    <Item.Extra className="model_submit">

                     {!approved && !this.props.params.view && orgId && <Button primary floated='right' onClick={this.approveUser}>Approve</Button>
                     }
                     
                    </Item.Extra>
                  </Item.Content>
                </Item>
                </Item.Group>
                :
                ''
            }
          <Message positive className="msg-fix" hidden={!success}>
            <p>{success}</p>
          </Message>
          <Message negative className="msg-fix" hidden={!error}>
                          <p>{error}</p>
          </Message>
        </div>
    )
}
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user, 
  };
};

const mapDispatchToProps = (dispatch) =>
bindActionCreators(
  {
    signIn, 
    pushState: push, 
    replaceState: replace ,
    updateOrgName: actionDispatcher.updateOrgName,
    updateActiveMenu: actionDispatcher.updateActiveMenu
  },
  dispatch
);

export default connect(
    mapStateToProps,
     mapDispatchToProps
    )(UserDetails);
