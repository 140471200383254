// TaggerExport .js
import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import JSZip from "jszip";
import {
  Button,
  Form,
  Segment,
  Icon,
  Breadcrumb,
} from "semantic-ui-react";
import { goBack, push } from "react-router-redux";
import { TimePicker, DatePicker, LocaleProvider } from "antd";
import moment from "moment";
import {
  TEXT_SUMMARIZATION,
  DOCUMENT_ANNOTATION,
  POS_TAGGING_GENERIC,
  POS_TAGGING,
  TEXT_CLASSIFICATION,
  TEXT_MODERATION,
  IMAGE_POLYGON_BOUNDING_BOX,
  IMAGE_POLYGON_BOUNDING_BOX_V2,
  IMAGE_SEGMENTATION,
  IMAGE_BOUNDING_BOX,
  IMAGE_CLASSIFICATION,
  DICOM_SEGMENTATION,
  NO_RECORDS_AVAILABLE,
  projectTypePath,
  IMAGE_MASKING,
  VIDEO_BOUNDING_BOX,
  VIDEO_CLASSIFICATION,
} from "../../helpers/Utils";
import en_US from "antd/lib/locale-provider/en_US";
import { fromDateTimestamp, toDateTimestamp } from "../../helpers/Utils";
import {
  setloaderMenu,
  setCurrentProject,
  getProjectDetails,
  getHitsDetails,
} from "../../redux/reducer/projectReducer";
import {
  getUidToken,
  logEvent,
  showAlert,
} from "../../helpers/commonFunctions";

import { downloadfile, downloadfilecsv } from "../../Services/commonApis";
import {
  imageMaskDownloadZip,
  checkS3BucketWithDateFilter,
  getRawUrls,
  checkS3BucketS3Download,
  checkS3Bucket,
  fetchS3URLImage,
  evaluationCorrectFilter,
} from "../../Services/DownloadApis";

import { ITEM_OPTIONS } from "../../helpers/Constants";

const FileSaver = require("file-saver");

class ExportProjects extends React.Component {
  state = {
    loading: false,
    downloadFormat: "json",
    color: "greymask",
    itemOption: "ALL", //As per the requirement, by default made all items options as selected one
    fromDate: null,
    toDate: null,
    fromTime: null,
    toTime: null,
    hitsNotAnnotatedCount: null,
    downloadUrlLink: null,
    downloadornot: false,
  };

  componentDidMount() {
    const {projectDetails,hits} = this.state
    console.log(
      "Did mount TaggerVisualize() ",
      projectDetails,
      hits
    );
    this.loadProjectDetails(this.props.currentProject);
    if (
      (this.props.params.orgName &&
        this.props.params.projectName &&
        (!this.props.projectDetails ||
          this.props.projectDetails.name !== this.props.params.projectName ||
          this.props.projectDetails.orgName !== this.props.params.orgName)) ||
      !this.props.currentProject
    ) {
      this.props.currentProject = this.props.params.projectId;
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log("next props in TaggerOveriew", this.props, nextProps);
    if (
      this.props.currentProject !== nextProps.currentProject ||
      !this.props.projectDetails
    ) {
      this.props.getProjectDetails(nextProps.currentProject, getUidToken());
      this.props.setloaderMenu(true);
      this.setState({ loading: true });
    }
    if (!this.props.projectDetails && nextProps.projectDetails) {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  }

  downloadCallback = (error, response) => {
    const {downloadFormat} = this.state
    console.log(" download call back ", error, response, this.state);
    if (!error && response.statusCode !== 204) {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      const blob = response.xhr.response;
      if (this.props.projectDetails) {
        const taskType = this.props.projectDetails.task_type;
        if (
          taskType === TEXT_MODERATION ||
          (taskType === TEXT_SUMMARIZATION &&
            downloadFormat === "json")
        ) {
          FileSaver.saveAs(blob, this.props.projectDetails.name + ".json");
        } else {
          if (
            downloadFormat === "json" ||
            downloadFormat === "DICOM_JSON" ||
            downloadFormat === "evaluation"
          ) {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".json");
          } else if (downloadFormat === "stanford") {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".tsv");
          } else if (downloadFormat === "csv") {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".csv");
          } else if (
            downloadFormat === "yolo" ||
            downloadFormat === "raw" ||
            downloadFormat === "singlejson"
          ) {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".zip");
          } else {
            FileSaver.saveAs(blob, this.props.projectDetails.name + ".txt");
          }
        }
      } else {
        FileSaver.saveAs(blob, this.props.projectDetails.name);
      }
    } else {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      showAlert(NO_RECORDS_AVAILABLE, "error");
    }
  };

  handleChange = (event, { value }) => {
    console.log("handle change", event, value);
    if (value === "MASKED" || value === "MASKEDBKG" || value === "ANNOTATED") {
      if (value === "MASKED") {
        this.setState({ color: "ALL" });
        checkS3BucketWithDateFilter(
          this.props.currentProject,
          "ALL",
          this.props.projectDetails.task_type,
          this.getDateFilterParam(),
          this.downloadZipUrlCallback,
          true
        );
        this.props.setloaderMenu(true);
      } else if (
        value === "MASKEDBKG" &&
        this.props.projectDetails.task_type === IMAGE_SEGMENTATION
      ) {
        this.setState({ color: "greymask" });
        checkS3BucketWithDateFilter(
          this.props.currentProject,
          this.state.color,
          this.props.projectDetails.task_type,
          this.getDateFilterParam(),
          this.downloadZipUrlCallback,
          true
        );
        this.props.setloaderMenu(true);
      } else if (
        value == "MASKEDBKG" &&
        this.props.projectDetails.task_type === IMAGE_MASKING
      ) {
        this.setState({ color: "greymask" });
        checkS3BucketWithDateFilter(
          this.props.currentProject,
          "greymask",
          this.props.projectDetails.task_type,
          this.getDateFilterParam(),
          this.downloadZipUrlCallback,
          true
        );
        this.props.setloaderMenu(true);
      } else if (value === "ANNOTATED") {
        this.setState({ color: "wholemask" });
        checkS3BucketWithDateFilter(
          this.props.currentProject,
          "wholemask",
          this.props.projectDetails.task_type,
          this.getDateFilterParam(),
          this.downloadZipUrlCallback,
          true
        );
        this.props.setloaderMenu(true);
      }
      this.setState({ downloadFormat: "zip" });
    } else {
      this.setState({ downloadFormat: "json" });
    }
    this.setState({ itemOption: value });
    if (value === "ALL") {
      this.setState({
        fromDate: null,
        fromTime: null,
        toDate: null,
        toTime: null,
      });
    }
  };

  handlegrayscale = (event, { value }) => {
    this.setState({ downloadFormat: "zip", itemOption: value });
  };
  downloadCsvCallback = (error, response) => {
    if (!error && response.statusCode !== 204) {
      let fileDownload = require("react-file-download");
      let jsonData = JSON.parse(response.text);
      fileDownload(jsonData.data, jsonData.fileName + ".csv");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    } else {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      showAlert(NO_RECORDS_AVAILABLE, "error");
    }
  };
  handleJsonChange = (event, { value }) => {
    const {itemOption,color} = this.state
    this.setState({ downloadFormat: value });
    if (value === "raw") {
      this.props.setloaderMenu(true);
      getRawUrls(
        this.props.currentProject,
        itemOption,
        this.downloadRawUrlCallback,
        value,
        this.getDateFilterParam()
      );
    }
    if (value === "zip") {
      this.props.setloaderMenu(true);
      checkS3BucketWithDateFilter(
        this.props.currentProject,
        color,
        this.props.projectDetails.task_type,
        this.getDateFilterParam(),
        this.downloadZipUrlCallback,
        true
      );
    }
  };

  addTimeToDate = (dateInput, timeInput, isFromDate) => {
    if (null !== dateInput) {
      dateInput = new Date(dateInput);
      if (undefined !== timeInput && null !== timeInput) {
        dateInput.setHours(timeInput.hour());
        dateInput.setMinutes(timeInput.minutes());
        dateInput.setSeconds(isFromDate ? 0 : 59);
        dateInput = dateInput.getTime();
      } else {
        if (isFromDate) {
          dateInput = fromDateTimestamp(dateInput);
        } else {
          dateInput = toDateTimestamp(dateInput);
        }
      }
    }
    return dateInput;
  };

  showvalidationMessage = (message) => {
    this.props.setloaderMenu(false);
    this.setState({ loading: false });
    showAlert(message, "error");
  };

  disabledDate(current) {
    return current > moment().endOf("day");
  }

  validateDateSelection = () => {
    const {itemOption,fromDate,fromTime,toDate,toTime} = this.state
    if (
      itemOption === ITEM_OPTIONS.TAGGED ||
      itemOption === ITEM_OPTIONS.MASKED
    ) {
      if (
        fromDate !== null &&
        fromDate.length > 0 &&
        toDate !== null &&
        toDate.length > 0
      ) {
        if (fromDate >= toDate) {
          if (fromDate === toDate) {
            if (fromTime > toTime) {
              this.showvalidationMessage("Please select valid date range");
              return false;
            }
          } else if (fromDate > toDate) {
            this.showvalidationMessage("Please select valid date range");
            return false;
          }
        }
      }
    }
    return true;
  };

  getDateFilterParam = () => {
    let param = "";
    let fromDate = "";
    let toDate = "";
    if (
      this.state.itemOption === ITEM_OPTIONS.TAGGED ||
      this.state.itemOption === ITEM_OPTIONS.NON_TAGGED ||
      this.state.itemOption === ITEM_OPTIONS.MASKED
    ) {
      if (
        undefined !== this.state.fromDate &&
        null !== this.state.fromDate &&
        this.state.fromDate.length > 0
      ) {
        fromDate = this.addTimeToDate(
          this.state.fromDate,
          this.state.fromTime,
          true
        );
        param += "&fromDate=" + fromDate;
      }
      if (
        undefined !== this.state.toDate &&
        null !== this.state.toDate &&
        this.state.toDate.length > 0
      ) {
        toDate = this.addTimeToDate(
          this.state.toDate,
          this.state.toTime,
          false
        );
        param += "&toDate=" + toDate;
      }
      console.log(param);
    }
    return param;
  };

  downloadFile = () => {
    const {downloadFormat,itemOption,downloadUrlLink,color} = this.state
    console.log("downloadfile ", this.state);
    this.props.setloaderMenu(true);
    logEvent("download", this.props.projectDetails.name);
    if (this.validateDateSelection()) {
      if (
        (this.props.projectDetails.task_type === IMAGE_MASKING ||
          this.props.projectDetails.task_type === IMAGE_SEGMENTATION) &&
        downloadFormat === "zip"
      ) {
        if (
          this.props.projectDetails.task_type === IMAGE_SEGMENTATION &&
          downloadUrlLink != null
        ) {
          const containsSegImages = downloadUrlLink.some((url) =>
            url.includes("seg_images")
          );
          if (containsSegImages && downloadUrlLink.length === 1) {
            console.log("Entered in the zip download");
            checkS3BucketS3Download(downloadUrlLink);
            this.props.setloaderMenu(false);
          } else if (!containsSegImages) {
            console.log("Entered in the folder download");
            if (
              downloadUrlLink[0] !== "" ||
              downloadUrlLink == null
            ) {
              this.downloadS3UrlZip(downloadUrlLink);
            } else {
              showAlert(NO_RECORDS_AVAILABLE, "error");
              this.setState({ downloadUrlLink: [""] });
              this.props.setloaderMenu(false);
              this.setState({ loading: false });
            }
          } else if (
            containsSegImages &&
            downloadUrlLink.length > 1
          ) {
            console.log("inside the non zip file download");
            checkS3BucketWithDateFilter(
              this.props.currentProject,
              color,
              this.props.projectDetails.task_type,
              this.getDateFilterParam(),
              this.downloadZipCallback,
              false
            );
          }
        } else if (
          this.props.projectDetails.task_type === IMAGE_SEGMENTATION &&
          downloadUrlLink == null
        ) {
          console.log("No Data in S3 Bucket");
          this.props.setloaderMenu(false);
          this.setState({ loading: false });
          showAlert(NO_RECORDS_AVAILABLE, "error");
          this.setState({ downloadornot: false });
        } else {
          console.log("masking zip file download");
          if (
            downloadUrlLink[0] !== "" ||
            downloadUrlLink == null
          ) {
            this.downloadS3UrlZip(downloadUrlLink);
          } else {
            this.props.setloaderMenu(false);
            this.setState({ loading: false, downloadornot: false });
            showAlert(NO_RECORDS_AVAILABLE, "error");
          }
        }
      } else if (
        this.props.projectDetails.task_type === IMAGE_MASKING &&
        downloadFormat === "zip"
      ) {
        checkS3Bucket(this.props.currentProject, this.checkS3BucketCallBack);
      } else if (downloadFormat === "csv") {
        downloadfilecsv(
          this.props.currentProject,
          itemOption,
          this.downloadCsvCallback,
          downloadFormat,
          this.getDateFilterParam()
        );
      } else if (
        downloadFormat === "yolo" ||
        downloadFormat === "singlejson"
      ) {
        downloadfile(
          this.props.currentProject,
          itemOption,
          this.downloadCallback,
          downloadFormat,
          this.getDateFilterParam()
        );
      } else if (downloadFormat === "raw") {
        if (
          downloadUrlLink[0] !== "" ||
          downloadUrlLink == null
        ) {
          this.downloadS3UrlZip(downloadUrlLink);
        } else {
          showAlert(NO_RECORDS_AVAILABLE, "error");
          this.props.setloaderMenu(false);
          this.setState({ loading: false });
          this.setState({ downloadUrlLink: [""] });
        }
      } else if (downloadFormat === "evaluation") {
        if (this.props.projectDetails.totalEvaluationCorrect !== 0) {
          downloadfile(
            this.props.currentProject, 
            itemOption, 
            this.downloadCallback, 
            downloadFormat, 
            this.getDateFilterParam());
        } else {
          showAlert(NO_RECORDS_AVAILABLE, "error");
          this.props.setloaderMenu(false);
          this.setState({ loading: false });
        }
      } else {
        downloadfile(
          this.props.currentProject,
          itemOption,
          this.downloadCallback,
          downloadFormat,
          this.getDateFilterParam()
        );
      }
    }
  };

  loadProjectDetails(pid) {
    if (pid) {
      getHitsDetails(pid, getUidToken(), 0, 10, this.hitsFetched).then(
        (response) => {
          if (response.body.hitsNotAnnotatedCount === 0) {
            this.setState({ hitsNotAnnotatedCount: 0 });
          }
        }
      );
    }
  }

  hitsFetched(error, response) {
    console.log("hitsFetched ", error, response);
  }

  downloadZipCallback = (error, response) => {
    console.log(" download call back ", error, response, this.state);

    if (!error && response.statusCode !== 204) {
      if (response.xhr.response) {
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
        console.log(response);
        FileSaver.saveAs(
          response.xhr.response,
          this.props.projectDetails.name + ".zip"
        );
      } else {
        showAlert(NO_RECORDS_AVAILABLE, "error");
        console.log("No Data in S3 Bucket");
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
      }
    } else {
      showAlert(NO_RECORDS_AVAILABLE, "error");
      console.log("No Data in S3 Bucket");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  };

  downloadZipUrlCallback = (error, response) => {
    console.log(" download call back url", error, response, this.state);

    if (!error) {
      if (response.xhr.response) {
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
        console.log("url response Data", response.body.data[0]);
        this.setState({ downloadUrlLink: response.body.data });
      } else {
        console.log("No Data in S3 Bucket");
        this.props.setloaderMenu(false);
        this.setState({
          downloadUrlLink: [""],
          loading: false,
          downloadornot: true,
        });
      }
    } else {
      showAlert(NO_RECORDS_AVAILABLE, "error");
      console.log("No Data in S3 Bucket");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  };

  downloadRawUrlCallback = (error, response) => {
    console.log(" download call back raw url", error, response, this.state);

    if (!error) {
      if (response.xhr.response) {
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
        let url = response.text;
        let parsedData = JSON.parse(url);
        // Extract the array from the parsed data
        let urlArrayString = parsedData.message;
        // Convert the string representation of array to an actual array
        let urlArray = urlArrayString
          .substring(1, urlArrayString.length - 1)
          .split(", ");
        console.log("urlArray", urlArray);
        this.setState({ downloadUrlLink: urlArray });
      } else {
        showAlert(NO_RECORDS_AVAILABLE, "error");
        console.log("No Data in S3 Bucket");
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
      }
    } else {
      showAlert(NO_RECORDS_AVAILABLE, "error");
      console.log("No Data in S3 Bucket");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  };

  downloadS3UrlZip = async (urls) => {
    const zip = new JSZip();
    const addFileToZip = async (url) => {
      try {
        const arrayBuffer = await fetchS3URLImage(url);
        const urlObject = new URL(url);
        const fileName = urlObject.pathname.split("/").pop();
        zip.file(fileName, arrayBuffer, { binary: true });
      } catch (error) {
        console.error("Error adding file to zip:", error);
        this.props.setloaderMenu(false);
      }
    };

    // Use Promise.all with map to fetch and add files concurrently
    function chunkArray(urls, chunkSize) {
      const chunks = [];
      for (let idx = 0; idx < urls.length; idx += chunkSize) {
        chunks.push(urls.slice(idx, idx + chunkSize));
      }
      return chunks;
    }

    if (urls.length > 1000) {
      const chunkSize = 1000;
      const chunks = chunkArray(urls, chunkSize);
      console.log("chunks length", chunks, chunks.length);
      for (let index = 0; index < chunks.length; index++) {
        await Promise.all(chunks[index].map(addFileToZip));
      }
    } else {
      await Promise.all(urls.map(addFileToZip));
    }

    try {
      // Generate the zip file
      const content = await zip.generateAsync({ type: "blob" });
      // Trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = `${this.props.projectDetails.name}.zip`;
      link.click();
      this.props.setloaderMenu(false);
    } catch (error) {
      console.error("Error generating zip file:", error);
      this.props.setloaderMenu(false);
    }
  };

  downloadZipCallbackForMasking = (error, response) => {
    console.log(" download call back ", error, response, this.state);
    if (!error) {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      console.log(response);
      FileSaver.saveAs(
        response.xhr.response,
        this.props.projectDetails.name + ".zip"
      );
    } else {
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
      showAlert(NO_RECORDS_AVAILABLE, "error");
    }
  };

  checkS3BucketCallBack = (error, response) => {
    console.log(" download call back ", error, response, this.state);
    if (!error) {
      if (response.xhr.response) {
        imageMaskDownloadZip(
          this.props.currentProject,
          this.downloadZipCallback
        );
      } else {
        showAlert("There is no image in completed status", "error");
        console.log("No Data in S3 Bucket");
        this.props.setloaderMenu(false);
        this.setState({ loading: false });
      }
    } else {
      showAlert("There is no image in completed status", "error");
      console.log("No Data in S3 Bucket");
      this.props.setloaderMenu(false);
      this.setState({ loading: false });
    }
  };

  setSelectedFromDate = (date, dateString) => {
    this.setState({ fromDate: dateString });
  };

  setSelectedToDate = (date, dateString) => {
    this.setState({ toDate: dateString });
  };

  fromTimeChange = (time, timeString) => {
    this.setState({ fromTime: time });
  };

  toTimeChange = (time, timeString) => {
    this.setState({ toTime: time });
  };

  isShowDateFilter = (taskType) => {
    if (
      taskType === IMAGE_CLASSIFICATION ||
      taskType === IMAGE_BOUNDING_BOX ||
      taskType === IMAGE_POLYGON_BOUNDING_BOX ||
      taskType === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
      taskType === DOCUMENT_ANNOTATION ||
      taskType === IMAGE_SEGMENTATION ||
      taskType === DICOM_SEGMENTATION
    ) {
      if (
        taskType === DOCUMENT_ANNOTATION &&
        this.state.downloadFormat !== "json"
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  handlemask = (event, { value }) => {
    this.setState({ color: value });
    checkS3BucketWithDateFilter(
      this.props.currentProject,
      value,
      this.props.projectDetails.task_type,
      this.getDateFilterParam(),
      this.downloadZipUrlCallback,
      true
    );
    this.props.setloaderMenu(true);
  };

  render() {
    const { itemOption, downloadFormat, color,toDate,fromDate,loading } = this.state;
    const format = "hh:mm A";
    return (
      <div className="taggerPages">
        <Helmet title="Export Data" />
        {
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            {
              <div>
                <Segment basic size="large">
                  <Button
                    className="pull-left"
                    onClick={() =>
                      this.props.pushState(
                        projectTypePath(this.props.projectDetails) +
                          this.props.params.orgName +
                          "/" +
                          this.props.params.projectName +
                          "/" +
                          this.props.params.projectId
                      )
                    }
                    compact
                  >
                    <Icon name="arrow left" />
                    Project
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  ></div>
                </Segment>
              </div>
            }
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  width: "80%",
                  height: "80%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  padding: "30px",
                  borderRadius: "18px",
                }}
              >
                <Breadcrumb size="big">
                  <Breadcrumb.Section
                    link
                  >
                    {this.props.params.orgName}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    active
                    link
                    onClick={() =>
                      this.props.pushState(
                        projectTypePath(this.props.projectDetails) +
                          this.props.params.orgName +
                          "/" +
                          this.props.params.projectName +
                          "/" +
                          this.props.params.projectId
                      )
                    }
                  >
                    {this.props.params.projectName}
                  </Breadcrumb.Section>
                </Breadcrumb>
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Export Data
                </h1>
                <div>
                  <Form
                    size="small"
                    className="box_fix"
                    key="import1"
                    loading={loading}
                    compact
                  >
                    <Form.Group
                      inline
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Form.Radio
                        label="All Items"
                        value="ALL"
                        checked={itemOption === "ALL"}
                        onChange={this.handleChange}
                      />
                      <Form.Radio
                        label="Completed Items"
                        value="TAGGED"
                        checked={itemOption === "TAGGED"}
                        onChange={this.handleChange}
                      />
                      <Form.Radio
                        label="Not Done Items"
                        value="NONTAGGED"
                        checked={itemOption === "NONTAGGED"}
                        onChange={this.handleChange}
                      />
                      {this.props.projectDetails.task_type ===
                        IMAGE_MASKING && (
                        <Form.Radio
                          label={"Annotated Images"}
                          value="ANNOTATED"
                          checked={itemOption === "ANNOTATED"}
                          onChange={this.handleChange}
                        />
                      )}
                      {this.props.projectDetails.task_type === IMAGE_MASKING ? (
                        <Form.Radio
                          label="Colored Mask Images"
                          value="MASKEDBKG"
                          checked={itemOption === "MASKEDBKG"}
                          onChange={this.handleChange}
                        />
                      ) : (
                        <Form.Radio
                          label="Annotated Images"
                          value="MASKEDBKG"
                          checked={itemOption === "MASKEDBKG"}
                          onChange={this.handleChange}
                        />
                      )}
                      {this.props.projectDetails.task_type ===
                        IMAGE_MASKING && (
                        <Form.Radio
                          label={"Mask Output"}
                          value="MASKED"
                          checked={itemOption === "MASKED"}
                          onChange={this.handleChange}
                        />
                      )}
                    </Form.Group>
                    <div>
                      {(itemOption === ITEM_OPTIONS.TAGGED ||
                        itemOption === ITEM_OPTIONS.NON_TAGGED ||
                        itemOption === ITEM_OPTIONS.MASKED) &&
                        this.props.projectDetails &&
                        this.isShowDateFilter(
                          this.props.projectDetails.task_type
                        ) && (
                          <LocaleProvider locale={en_US}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <DatePicker
                                className="date_time_select"
                                placeholder="From Date"
                                onChange={this.setSelectedFromDate}
                                disabledDate={this.disabledDate}
                              />
                              {fromDate && (
                                <TimePicker
                                  className="date_time_select"
                                  placeholder="Select Time"
                                  onChange={this.fromTimeChange}
                                  format={format}
                                  use12Hours={true}
                                  defaultOpenValue={moment("00:00", format)}
                                />
                              )}

                              <DatePicker
                                className="date_time_select"
                                placeholder="To Date"
                                onChange={this.setSelectedToDate}
                                disabledDate={this.disabledDate}
                              />
                              {toDate && (
                                <TimePicker
                                  placeholder="Select Time"
                                  onChange={this.toTimeChange}
                                  format={format}
                                  use12Hours={true}
                                  defaultOpenValue={moment("00:00", format)}
                                />
                              )}
                            </div>
                          </LocaleProvider>
                        )}
                    </div>

                    {this.props.projectDetails &&
                      this.props.projectDetails.task_type ===
                        DICOM_SEGMENTATION && (
                        <Form.Group
                          inline
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Form.Radio
                            label="Normal json"
                            value="json"
                            checked={downloadFormat === "json"}
                            onChange={this.handleJsonChange}
                          />
                          <Form.Radio
                            label="DICOM json"
                            value="DICOM_JSON"
                            checked={downloadFormat === "DICOM_JSON"}
                            onChange={this.handleJsonChange}
                          />
                          <Form.Radio
                            label="CSV"
                            value="csv"
                            checked={downloadFormat === "csv"}
                            onChange={this.handleJsonChange}
                          />
                        </Form.Group>
                      )}

                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.props.projectDetails &&
                        this.props.projectDetails.task_type ===
                          IMAGE_CLASSIFICATION && (
                          <p>
                            Download file would be a text file where each line
                            is a JSON containing the image URL and the classes
                            marked for the image.
                          </p>
                        )}
                      {this.props.projectDetails &&
                        this.props.projectDetails.task_type ===
                          IMAGE_BOUNDING_BOX && (
                          <div>
                            <p>
                              Download file would be a text file where each line
                              is a JSON containing the image URL and the
                              coordinates of the bounded objects in the image
                            </p>
                          </div>
                        )}
                      {this.props.projectDetails &&
                        (this.props.projectDetails.task_type ===
                          IMAGE_POLYGON_BOUNDING_BOX ||
                          this.props.projectDetails.task_type ===
                            IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                          this.props.projectDetails.task_type ===
                            IMAGE_SEGMENTATION) && (
                          <p>
                            Download file would be a text file where each line
                            is a JSON containing the image URL and the
                            coordinates of the bounded objects in the image
                          </p>
                        )}
                      {this.props.projectDetails &&
                        (this.props.projectDetails.task_type ===
                          DOCUMENT_ANNOTATION ||
                          this.props.projectDetails.task_type ===
                            POS_TAGGING_GENERIC) && (
                          <p>
                            Download file would be a text file where each line
                            is a JSON containing the selected text, start index,
                            end index and marked category
                          </p>
                        )}
                      {this.props.projectDetails &&
                        this.props.projectDetails.task_type ===
                          TEXT_SUMMARIZATION && (
                          <p>
                            Download file would be a tab seperated file with
                            input in first column and output row in second
                            column.
                          </p>
                        )}
                      {this.props.projectDetails &&
                        this.props.projectDetails.task_type ===
                          TEXT_MODERATION && (
                          <p>
                            Download file would be a tab seperated file with
                            input in first column and output row in second
                            column.
                          </p>
                        )}
                      {this.props.projectDetails &&
                        this.props.projectDetails.task_type === POS_TAGGING && (
                          <p>
                            Download file would be a text file where each line
                            is a JSON containing the input text and annotated
                            text.
                          </p>
                        )}
                      {this.props.projectDetails &&
                        this.props.projectDetails.task_type ===
                          TEXT_CLASSIFICATION && (
                          <p>
                            Download file would be a text file where each line
                            is a JSON containing the input text, associated
                            label and notes.
                          </p>
                        )}
                    </div>
                    <div>
                    {this.props.projectDetails &&
                      (this.props.projectDetails.task_type === POS_TAGGING ||
                        this.props.projectDetails.task_type ===
                          DOCUMENT_ANNOTATION ||
                        this.props.projectDetails.task_type ===
                          POS_TAGGING_GENERIC) && (
                        <div>
                          <Form.Group
                            inline
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Form.Radio
                              label="Json Format"
                              value="json"
                              checked={downloadFormat === "json"}
                              onChange={this.handleJsonChange}
                            />
                            <Form.Radio
                              label="Stanford NER Format"
                              value="stanford"
                              checked={downloadFormat === "stanford"}
                              onChange={this.handleJsonChange}
                            />
                            <Form.Radio
                              label="CSV"
                              value="csv"
                              checked={downloadFormat === "csv"}
                              onChange={this.handleJsonChange}
                            />
                          </Form.Group>
                        </div>
                      )}
                    {this.props.projectDetails &&
                      (this.props.projectDetails.task_type ===
                        IMAGE_CLASSIFICATION ||
                        this.props.projectDetails.task_type ===
                          TEXT_CLASSIFICATION ||
                        this.props.projectDetails.task_type ===
                          IMAGE_SEGMENTATION ||
                        this.props.projectDetails.task_type ===
                          IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                        this.props.projectDetails.task_type ===
                          IMAGE_SEGMENTATION ||
                        this.props.projectDetails.task_type === IMAGE_MASKING ||
                        this.props.projectDetails.task_type ===
                          VIDEO_BOUNDING_BOX ||
                        this.props.projectDetails.task_type ===
                          VIDEO_CLASSIFICATION) && (
                        <Form.Group
                          inline
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {itemOption === "MASKEDBKG" ||
                          itemOption === "MASKED" ||
                          itemOption === "ANNOTATED" ? (
                            ""
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <Form.Radio
                                label="JSON"
                                value="json"
                                checked={downloadFormat === "json"}
                                onChange={this.handleJsonChange}
                              />
                              <Form.Radio
                                label="CSV"
                                value="csv"
                                checked={downloadFormat === "csv"}
                                onChange={this.handleJsonChange}
                              />
                              <Form.Radio
                                label="Single Json for Each Image"
                                value="singlejson"
                                checked={downloadFormat === "singlejson"}
                                onChange={this.handleJsonChange}
                              />
                            </div>
                          )}
                          {this.props.projectDetails.task_type ===
                            IMAGE_SEGMENTATION &&
                            itemOption === "MASKEDBKG" && (
                              <Form.Radio
                                label="ZIP"
                                value="zip"
                                checked={downloadFormat === "zip"}
                                onChange={this.handleJsonChange}
                              />
                            )}
                          {(this.props.projectDetails.task_type ===
                            IMAGE_SEGMENTATION ||
                            this.props.projectDetails.task_type ===
                              IMAGE_POLYGON_BOUNDING_BOX_V2) &&
                            itemOption !== "ALL" &&
                            itemOption != "NONTAGGED" && (
                              <Form.Radio
                                label="YOLO"
                                value="yolo"
                                checked={downloadFormat === "yolo"}
                                onChange={this.handleJsonChange}
                              />
                            )}
                          {this.props.projectDetails.task_type ===
                            IMAGE_SEGMENTATION &&
                            (itemOption === "NONTAGGED" ||
                              itemOption === "TAGGED") && (
                              <Form.Radio
                                label="RAW"
                                value="raw"
                                checked={downloadFormat === "raw"}
                                onChange={this.handleJsonChange}
                              />
                            )}
                          {this.props.projectDetails.task_type ===
                            IMAGE_SEGMENTATION &&
                            itemOption === "MASKEDBKG" && (
                              <Form.Radio
                                label="Evaluation Status - Correct"
                                value="evaluation"
                                checked={downloadFormat === "evaluation"}
                                onChange={this.handleJsonChange}
                              />
                            )}
                        </Form.Group>
                      )}
                    {this.props.projectDetails &&
                      (this.props.projectDetails.task_type ===
                        TEXT_SUMMARIZATION ||
                        this.props.projectDetails.task_type ===
                          TEXT_MODERATION) && (
                        <Form.Group
                          inline
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Form.Radio
                            label="JSON"
                            value="json"
                            checked={downloadFormat === "json"}
                            onChange={this.handleJsonChange}
                          />
                          <Form.Radio
                            label="CSV"
                            value="csv"
                            checked={downloadFormat === "csv"}
                            onChange={this.handleJsonChange}
                          />
                        </Form.Group>
                      )}
                    {this.props.projectDetails &&
                      this.props.projectDetails.task_type === IMAGE_MASKING &&
                      itemOption === "MASKEDBKG" && (
                        <div>
                          <Form.Group
                            inline
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Form.Radio
                              label="Gray Background Mask"
                              value="greymask"
                              checked={color === "greymask"}
                              onChange={this.handlemask}
                            />
                            <Form.Radio
                              label="Black Background Mask"
                              value="blackmask"
                              checked={color === "blackmask"}
                              onChange={this.handlemask}
                            />
                            <Form.Radio
                              label="GrayScale Mask"
                              value="grayscale"
                              checked={color === "grayscale"}
                              onChange={this.handlemask}
                            />
                          </Form.Group>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button primary size="mini" onClick={this.downloadFile}>
                        Download
                      </Button>
                    </div>
                    <br />

                    <div style={{ height: "100px" }} />
                    <br />
                    <br />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer.user,
    currentPathOrg: state.projectReducer.currentPathOrg,
    currentPathProject: state.projectReducer.currentPathProject,
    currentProject: state.projectReducer.currentProject,
    projectDetails: state.projectReducer.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goBack,
      downloadfile,
      downloadfilecsv,
      setloaderMenu,
      getProjectDetails,
      getHitsDetails,
      setCurrentProject,
      pushState: push,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExportProjects);
