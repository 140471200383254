import React, { PropTypes } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Helmet from 'react-helmet';
import {SUCCESS, COMMENTS_SAVED_SUCCESS, COMMENTS_UPDATED_SUCCESS, COMMENTS_DELETED_SUCCESS} from '../../helpers/Constants'
import { Table } from 'antd';
import { updateProjectDetails,setCurrentHit,setCurrentProject,getProjectDetails,updatePrevPageDeatails } from "../../redux/reducer/projectReducer";
import { saveactiveTab } from "../../helpers/commonFunctions";
import { fetchHits,updateNewLabelName,updateDicomListHitStatus,updateHitStatus,addHitEvaluation,fetchNotDoneHits } from "../../Services/TaggerApis";
import { getUidToken,logEvent,refreshUidToken,showAlert } from "../../helpers/commonFunctions";
import { downloadfile,saveComments,updateComments,deleteComments,downloadImageUrl,getComments } from "../../Services/commonApis";
import CommentComponents from "../CommentComponent/CommentComponent";
import SidebarTools from "../DicomSidebarTools.js/SidebarTools";
import { MentionsInput, Mention } from 'react-mentions';
import _uniqBy from 'lodash/uniqBy'
import { push, goBack } from "react-router-redux";
import {
  createDocEntityColorMap,
  POS_TAGGING,
  DICOM_SEGMENTATION,
  ENTITY_COLORS,
  createEntitiesJson,
  getDetaultShortcuts,
  HIT_STATE_DONE,
  HIT_STATE_NOT_DONE,
  convertKeyToString,
  NEW_LABEL_UPDATE,
  hitStateNameMap,
  dateTimeConverter,
  HIT_EVALUATION_CORRECT,
  HIT_STATE_ALL,
  HIT_EVALUATION_ALL,
  HIT_EVALUATION_INCORRECT,
  HIT_EVALUATION_NE,
  HIT_EVALUATION_TYPE_ALL,
  HIT_ENTITIES_ALL,
  HIT_CONTRIBUTORS_ALL,
  HIT_ENTITY_ALL,
  DONE_SCREEN,
  PENDING_SCREEN,
  TOTAL_SCREEN,
  captureException,
  fromDateTimestamp,
  toDateTimestamp,
  projectTypePath,
  breadcrumbSeperate,
  hitsBreadCrumbTitle,
  displaySettingsByRole,
  ROLES_TYPES
} from "../../helpers/Utils";
import * as utilities from "../../helpers/DicomUtils"
import Fullscreen from "react-fullscreen-crossbrowser";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneMath from "cornerstone-math";
import Hammer from "hammerjs";
import cloneDeep from 'lodash/cloneDeep';
import { Button, Label, Dropdown,  Icon, Input, Segment } from 'semantic-ui-react';
import Modal from 'react-awesome-modal';
import AnnotationList from "../DicomAnnotationList/DicomAnnotationList";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as dicomParser from "dicom-parser";
import { Modal as AntModal, LocaleProvider, Icon as AntIcon, Spin,  Tooltip as AntTooltip } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import { DatePicker,AutoComplete } from 'antd';
import { setloaderMenu } from "../../redux/reducer/projectReducer";
import moment from 'moment';
import { updateImage } from 'cornerstone-core';
import LEFTARROW from '../../images/left.svg';
import RIGHTARROW from '../../images/right.svg';
import BACK from '../../images/Arrow_Simple_left1.svg';
import FILTER from '../../images/filter.svg';
import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'
import '../../Pages/TaggerPage/mentions.css'
import "./TaggerViewer.css"
import helper from "./helper";
import Panner from '../../helpers/PinchZoomPan/CenteredPanZoom';
const Mousetrap = require("mousetrap");
var flubber = require("flubber");
const FileSaver = require('file-saver');

class TaggerViewer extends React.Component {
  static propTypes = {
    projectDetails: PropTypes.object,
    currentProject: PropTypes.string,
    updateProjectDetails: PropTypes.func,
    getProjectDetails: PropTypes.func,
    pushState: PropTypes.func,
    currentHit: PropTypes.object,
    setCurrentHit: PropTypes.func,
    goBack: PropTypes.func,
    params: PropTypes.object,
    orgName: PropTypes.string,
    projectName: PropTypes.string,
    setCurrentProject: PropTypes.func,
    location: PropTypes.object,
    query: PropTypes.object,
    type: PropTypes.string,
    btnClick: PropTypes.string,
    label: PropTypes.string,
    contributorId: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string
  };

  constructor(props) {

    super(props);
    let evaluationType = undefined;
    let contributorId = undefined;
    let fromLeaderBoard = false;
    let fromHitList = false;
    let errorMessage = false;
    let currentStart = 0;
    this.getCurrentResult = this.getCurrentResult.bind(this);
    this.hitAddCallback = this.hitAddCallback.bind(this);
    this.saveTagAndNextRow = this.saveTagAndNextRow.bind(this);
    this.drawHandle = this.drawHandle.bind(this);
    this.getCurrentProjectDetails = this.getCurrentProjectDetails.bind(this);
    this.receivedProjectDetails = this.receivedProjectDetails.bind(this);
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
    this.downloadFilterResult = this.downloadFilterResult.bind(this);
    this.downloadCallback = this.downloadCallback.bind(this);
    this.renderKeyBoardShortCuts = this.renderKeyBoardShortCuts.bind(this);
     this.swapTags = this.swapTags.bind(this);
     this.getUsersFromTags = this.getUsersFromTags.bind(this);
    this.projectDetailFetched = this.projectDetailFetched.bind(this);
    let type = "notDone";
    let label = undefined;
    let btnClick = undefined;
    let contributorName = undefined;
    let displayScreen = undefined;
    let displayScreenType = undefined;

    if (props.location && props.location.query) {
    	if (props.location.query.screenType){
    		displayScreen = props.location.query.screenType;
    		if(displayScreen === 'pendingScreen' )
    			displayScreenType = HIT_STATE_NOT_DONE
    		else if(displayScreen === 'doneScreen')
    			displayScreenType = HIT_STATE_DONE
        	else if(displayScreen === 'totalScreen')
        			displayScreenType = "all"
    	}
    	if (props.location.query.type)
          type = props.location.query.type;
        if (props.location.query.btnClick)
        	btnClick = props.location.query.btnClick;
        if (props.prevPageDetails === "leaderBoard") {
          fromLeaderBoard = true;
          props.updatePrevPageDeatails("")
        }
        if (props.location.query.label)
          label = props.location.query.label;

        if (props.location.query.evaluationType)
          evaluationType = props.location.query.evaluationType;

        if (props.location.query.contributorId)
          contributorId = props.location.query.contributorId;
      }

    const ch = this.props.currentHit;
    this.state = {
      type,
      btnClick: btnClick,
      contributorId,
      label,
      evaluationType,
      drag:{
        marginTop: '1%',
        width:'100%',
        textAlign:'-webkit-center',
        height: 512,
        position: "relative",
        display: "inline-block",
        color: "white"
      },
      isFullScreen: false,
      currentHit: ch,
      hits: [],
      projectDetails: this.props.projectDetails,
      currentStart: 0,
      currentCount: 10,
      DEFAULT_COUNT: 10,
      currentIndex: -1,
      contrast: 1.0, //
      brightness: 1.0,  //
      saturation: 1.0, //
      opacity: 0.4, //
      translate: {
        x: 0,
        y: 0
      },
      images: [],
      startTime: new Date().getTime(),
      open: false,
      textValue: null,
      currentImageIndex: 0,
      historyArray: [],
      undoredoHistory: 0,
      count: 0,
      collapse: false,
      drawregions: [],
      activeFlag: false,
      indexvalue: 0,
      changesInSession: 0,
      interpolationDone: [],
      interpolationNext: [],
      labelExists: false,
      labelText: null,
      searchQuery: '',
      showOrHideEyeMap: {},
      defaultClass: '',
      interpolationStartIndex: 0,
      showOrHideEye: true,
      hitUserName: '',
      hitEvaluatedBy: '',
      hitUserEmail: '',
      hitFileName: '',
      hitStatus: '',
      hitEvaluation: '',
      hitUpdatedTime: '',
      updateAnnotatorInfo: true,
      fromLeaderBoard: fromLeaderBoard,
      pageSortBy: "NEW_FIRST",
      fromHitList: fromHitList,
      downloadFormat: 'json',
      itemOption: 'ALL',
      confirmDownloadModalVisible: false,
      contributorName,
      drawingNotCompleted : false,
      displayScreen,
      displayScreenType,
      errorMessage,
      startValue: null,
      endValue: null,
      rulerdrawregions:[],
      AnnotationsDrawn: false,
      comments: '',
      mentionedUsers: [],
      editCommentsButtonEnable: false,
      commentsType: false,
      mentionData: null,
      users: [
        {
          _id: '123',
          name: { first: 'John', last: 'Reynolds' }
        },
        {
          _id: '234',
          name: { first: 'Holly', last: 'Reynolds' }
        },
        {
          _id: '345',
          name: { first: 'Ryan', last: 'Williams' }
        }
      ]
      };

    this.props.setCurrentHit(undefined);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
  }
  toolDataArr = null; // Temporary save for Tooldata
  pasteCount = 0;// Paste Count
  drawAnnotationColor = null; // Annotation Color
  isToolActive  = false;

  toggleMenu() {
    this.setState({visible: !this.state.visible})
}

toggleLeftMenu() {
  this.setState({sideVisible: !this.state.sideVisible})
}

  componentWillMount() {
    this.setState({loading: true});
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneTools.init();

    document.addEventListener('keydown', this.handleMouseMove);
    document.removeEventListener('contextmenu', this.deleteContextMenu);

    let rules = '';
    if ( this.state.type === "notDone") {
    	if (this.props.projectDetails !== null) {
            rules = JSON.parse(this.props.projectDetails.taskRules);
    	}
    	this.setState({rules: rules});
    }
  }


  componentDidMount() {

    this.props.setloaderMenu(true);
    if (this.props.selectedHitsDetails) {
        this.props.setCurrentProject(
          {
            orgName: this.props.params.orgName,
            projectName: this.props.params.projectName,
            dateFrom: this.props.params.from,
            dateTo: this.props.params.to
          },
          getUidToken()
        );

      let totalHits;
      if (this.state.fromLeaderBoard !== true) {
        this.state.redirectContributionDetails = this.props.selectedHitsDetails.redirectContributionDetails;
        this.state.searchHitUrl = this.props.selectedHitsDetails.searchHitUrl;
        this.state.selecteddate = this.props.selectedHitsDetails.selectedDate;
        this.state.dateFrom = this.props.selectedHitsDetails.from;
        this.state.dateTo = this.props.selectedHitsDetails.to;
        this.state.label = this.props.selectedHitsDetails.label;
        this.state.evaluationType = this.props.selectedHitsDetails.evaluationType;
        if (this.props.selectedHitsDetails.contributorId)
          this.state.contributorId = this.props.selectedHitsDetails.contributorId[0];
          this.setState({ type: this.props.selectedHitsDetails.status[0], contributorId: this.props.selectedHitsDetails.contributorId });
          totalHits = this.props.selectedHitsDetails.totalHits;
      }
      this.loadProjectDetails(this.props.currentProject, this.props.selectedHitsDetails.pageStart, totalHits)
    }else {
    	if (
            (this.props.params.orgName &&
              this.props.params.projectName &&
              (!this.props.projectDetails ||
                (this.props.projectDetails.name !== this.props.params.projectName ||
                  this.props.projectDetails.orgName !==
                    this.props.params.orgName))) ||
            !this.props.currentProject
    		    ) {
    		      this.props.setCurrentProject(
    		        {
    		          orgName: this.props.params.orgName,
    		          projectName: this.props.params.projectName
    		        },
    		        getUidToken()
    		      );
            }
      else if (!this.props.currentHit) {
          this.loadProjectDetails(this.props.currentProject);
        }
    if (this.state.type !== "notDone" && this.props.currentProject) {
      this.props.getProjectDetails(this.props.currentProject, getUidToken());
    }
    document.addEventListener('contextmenu', this.deleteContextMenu);
    }
  }

  componentWillReceiveProps(props) {
    if (
      (!this.state.hits || this.state.hits.length === 0) &&
      !this.state.currentHit &&
      props.currentProject &&
      this.props.currentProject !== props.currentProject
    ) {

      if (!props.selectedHitsDetails)

        this.loadProjectDetails(props.currentProject);
        this.props.getProjectDetails(props.currentProject, getUidToken());
    } else if (this.props.menuHidden !== props.menuHidden) {

      this.setState({ menuHidden: props.menuHidden });
      this.loadProjectDetails(props.currentProject);
    }
    if (this.props.location !== props.location) {
      if (props.location && props.location.query) {
        if (props.location.query.type)
          this.state.type = props.location.query.type;
        if (props.location.query.btnClick)
        	this.state.btnClick = props.location.query.btnClick;
        if (props.location.query.label)
          this.state.label = props.location.query.label;
        if (props.location.query.evaluationType)
          this.state.evaluationType = props.location.query.evaluationType;
        if (props.location.query.contributorId)
          this.state.contributorId = props.location.query.contributorId;
        if (props.location.query.date)
          this.state.selecteddate = props.location.query.date;
      }
      if (props.selectedHitsDetails) {
        this.setState({ contributer: props.selectedHitsDetails.contributorId ? props.selectedHitsDetails.contributorId[0] : '', type: props.selectedHitsDetails.status, selecteddate: props.selectedHitsDetails.selectedDate ? props.selectedHitsDetails.selectedDate : null })
      }

      this.state.hitsCompleted = false;
      this.loadProjectDetails(
        props.currentProject,
        this.state.start,
        this.state.count
      );
    }
    if (!this.props.projectDetails && props.projectDetails) {
      this.props.getProjectDetails(props.currentProject, getUidToken());
      this.setState({ projectDetails: props.projectDetails });
    }
    if (props.projectDetails && props.projectDetails.contributorDetails) {
      this.setState({
        contributorDetails: props.projectDetails.contributorDetails,
        contributorDetailsMap: this.getContributorDetailsMap(
          props.projectDetails.contributorDetails
        )
      });
    }
  }

  componentDidUpdate() {
	    if ( this.state.currentHit && this.state.updateAnnotatorInfo ) {
	        let hit = this.state.hits[this.state.currentIndex];
	        this.setState({updateAnnotatorInfo: false}, this.getAnnotatorInfo( hit ));
        }
        // Auto Save Check
        if(this.state.changesInSession  > 0 && !this.state.drawingNotCompleted){
          this.saveTagAndNextRow('autoSave');
        }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    this.setState({ hits: undefined, selecteddate: null});
  }

  dicomImageRef = el => {
    this.dicomImage = el;
  };

  loadProjectDetailsFilter(pid, start, count) {
    this.setState({ loading: true });
    // TODO_REPLACE
	  this.setState({updateAnnotatorInfo: true});
    let startP = start;
    if (startP === undefined) {
      startP = this.state.currentStart;
    }

    if (this.state.btnClick !== undefined && this.state.btnClick === "showCompletedHits") {
    	startP = 0;
    	count = 10;
    }
    fetchHits(
      pid ? pid : this.props.currentProject,
      startP,
      count ? count : this.state.currentCount,
      this.projectDetailsFetched,
      this.state.displayScreenType,
      this.state.label,
      this.state.contributorId,
      this.state.evaluationType,
      this.state.commentFilter,
      this.state.dateFrom,
      this.state.pageSortBy,
      this.state.dateTo
    );
  }

  

  loadProjectDetails(pid, start, count) {
      // TODO_REPLACE

		  this.setState({updateAnnotatorInfo: true});
	    let startP = start;
	    if (startP === undefined) {
	      startP = this.state.currentStart;
	    }

	    if (this.state.btnClick !== undefined && this.state.btnClick === "showCompletedHits") {
	    	startP = 0;
	    	count = 10;
	    }

	    let type = undefined;
	    if(this.state.displayScreen !== undefined && this.state.displayScreen === "doneScreen")
	    	type = "done";
	    else if(this.state.displayScreen !== undefined && this.state.displayScreen === "pendingScreen")
	    	type = "notDone";
	    else if(this.state.displayScreen !== undefined && this.state.displayScreen === "totalScreen")
	    	type = "all";
	    else
	    	type = this.state.type;
	    this.setState({ loading: true });
	    fetchHits(
	      pid ? pid : this.props.currentProject,
	      startP,
	      count ? count : this.state.currentCount,
	      this.projectDetailsFetched,
	      type,
	      this.state.label,
	      this.state.contributorId,
        this.state.evaluationType,
        this.state.commentFilter,
	      this.state.dateFrom,
	      this.state.pageSortBy,
	      this.state.dateTo
	    );
	  }

  getContributorDetailsMap(contributorDetails) {
	    let contributorDetailsMap = {};
	    for (let index = 0; index < contributorDetails.length; index++) {
	      contributorDetailsMap[contributorDetails[index].userDetails.uid] =
	        contributorDetails[index].userDetails;
	    }
	    return contributorDetailsMap;
    }

  projectDetailsFetched(error, response) {
    if (!error) {
        if (this.props.selectedHitsDetails) {
            let index = response.body.hits.findIndex(x => x.id === this.props.selectedHitsDetails.selectedImageId);
            response.body.hits.unshift(response.body.hits.splice(index, 1)[0]);
          }
          let notDoneHits = response.body.projectDetails.totalHits -
          (response.body.projectDetails.totalHitsDeleted + response.body.projectDetails.totalHitsDone + response.body.projectDetails.totalHitsSkipped);
          let totalFilteredHits = response.body.totalFilteredHits;
          let hitsNotAnnotatedCount = response.body.hitsNotAnnotatedCount;
          this.setState({ hitsNotAnnotatedCount: hitsNotAnnotatedCount, totalFilteredHits: totalFilteredHits, notDoneHits: notDoneHits })

      if (response.body.hits.length < this.state.currentCount) {
        this.setState({ hitScrollCompleted: true });
      }else {
        this.setState({ hitScrollCompleted: false });
      }
      let projectDetails = this.props.projectDetails;
      if (response.body.projectDetails) {
        this.props.updateProjectDetails(response.body.projectDetails);
        projectDetails = response.body.projectDetails;
      }

      const rules = JSON.parse(projectDetails.taskRules);
      const entitiesObject = createEntitiesJson(projectDetails.taskRules);
      const entities = entitiesObject.entities;
      const entityJson = entitiesObject.entityJson;
      let shortcuts = getDetaultShortcuts(projectDetails.task_type, entities);
      const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
      this.state.entityColorMap = entityColorMap;
      if ("shortcuts" in rules) {
        shortcuts = rules.shortcuts;
      }
      let currentHits = this.state.hits;
      let currentIndex = this.state.currentIndex;
      currentIndex = currentIndex + 1;
      currentHits = [...currentHits, ...response.body.hits];
      const currentHit = currentHits[currentIndex];
      if (response.body.hits.length === 0 || !currentHit) {
        this.setState({
          hits: currentHits,
          currentIndex: currentHits.length - 1,
          entityColorMap,
          entities,
          entityJson,
          startTime: new Date().getTime(),
          projectDetails,
          action: "",
          rules,
          loading: false,
          showOrHideEyeMap: {}
        });
        if ((!this.state.type || this.state.type === "notDone")) {
          this.setState({ hitsCompleted: true });
        }
      } else {

          let hideLabels = false;
          let keepEntitySelected = false;
          if ("hideLabels" in rules) {
            hideLabels = rules.hideLabels;
          }
          if ("keepEntitySelected" in rules) {
            keepEntitySelected = rules.keepEntitySelected;
          }

          if (response.body.hits.length > 0) {
            let boundingBoxMap = [];
            this.setState({
              boundingBoxMap,
              hideLabels,
              entityJson,
              newEntities: [],
              shortcuts,
              rules,
              entities,
              entityColorMap,
              keepEntitySelected,
              currentIndex,
              hits: currentHits,
              currentHit,
              projectDetails,
              action: "",
              showOrHideEyeMap: {}
            });

            this.loadImage();
          }else{
              	this.setState({ loading: false });
          }
      }
    } else {
      showAlert("Error in fetching project details, please try again", 'error');
      this.props.pushState("/projects/login");
      if (error && error.message) {
        logEvent("project_detail_fetch_error", error.message);
      } else {
        logEvent("project_detail_fetch_error", "Error");
      }
      this.setState({ loading: false });
    }
  }

  callDynamicLabelling = (event) => {
        this.setState({ changesInSession : this.state.changesInSession + 1, AnnotationsDrawn: true })
  }

  navigatesOnWheel = (event) => {
    if(event.detail.direction === 1 && !utilities.disableNextButton(this)){
      this.saveTagAndNextRow()
    }
    if(event.detail.direction === -1 && !(this.state.currentIndex <= 0)) {
      this.getBackTopreviousRow()
    }
  }

  loadImage = () => {
    this.showhideLabel = false;
    const currentObj = this;
    const element = this.dicomImage;
    // Listen for changes to the viewport so we can update the text overlays in the corner
      function onImageRendered(e) {
        cornerstoneTools.toolStyle.setToolWidth(2);
        var annotationlist = cornerstoneTools.getToolState(e.target, "FreehandRoi"); // getting tooldata for freehand
        var annotationlistCopy = cloneDeep(annotationlist); // copy of freehand data
        // For Annotation show hide
        if (currentObj.state.activeFlag === true) {
          cornerstoneTools.clearToolState(e.target, "FreehandRoi"); // removing the data to update the active for select
          var annotationlistarray = annotationlistCopy.data;
          annotationlistarray.map((item, index) => {
              var data = item;
              if (index === currentObj.state.indexvalue) {
                  if (data.visible === false) {
                      data.visible = true;
                      data.active = true;
                  } else {
                      data.visible = false;
                      data.active = false;
                  }
              } else data.active = false;
              cornerstoneTools.addToolState(e.target, "FreehandRoi", data);
              return true;
          });
          currentObj.setState({ activeFlag: false });
        }
        const imageIds = currentObj.state.images;
        // // Setting Color to Annotation
            var toolDataConfig = [];
            var cornersstoneObj = new cornerstoneTools.FreehandRoiTool
            var configuration = cornersstoneObj.configuration;
            toolDataConfig.push(configuration.currentTool, configuration.movingTextBox, currentObj.drawAnnotationColor, currentObj.annoindex);
            utilities.getDrawedRegions(currentObj, e, annotationlistarray, imageIds, toolDataConfig);
          }
          const {
            EVENTS
          } = cornerstoneTools;

          element.addEventListener(EVENTS.MEASUREMENT_COMPLETED, this.callDynamicLabelling);
          element.addEventListener(EVENTS.MOUSE_WHEEL, this.navigatesOnWheel);
          element.addEventListener("cornerstoneimagerendered", onImageRendered);
          // Drag
          element.addEventListener("mousemove", e => {
            cornerstone.enable(this.dicomImage);
            var toolData = cornerstoneTools.getToolState(this.dicomImage, "FreehandRoi");
            if (toolData) {
              var tooldataItem = toolData.data;
              this.index = -1;
              this.selected = false;
              // loop starts
              tooldataItem.map((item, k) => {
                if (item.area) {
                  this.handleAreaDrag(e, element, item, k);
                }
                return true;
              });
              // loop ends
            }
          });

    element.addEventListener("keypress", e => {
      this.handleMouseMove(e)
    });

    // When drag completed, calling autosave
    element.addEventListener("mouseup", e => {
      if(this.clonedata !== -1 || this.state.tooltype === 'FreehandRoiSculptor'){
        this.setState({changesInSession : this.state.changesInSession+1})
      }
    });


    // mousedown
    element.addEventListener("mousedown", e => {

      var toolData = cornerstoneTools.getToolState(this.dicomImage, "FreehandRoi");
      this.clonedata = -1;
      if (this.selected && toolData !== undefined && !this.isBubbleSelected
                        && this.state.defaultClass === ""    //When any label is selected disable the drag
                        && !this.isToolActive) {             //When tool is active
        this.clonedata = cloneDeep(this.index);
        element.addEventListener("mousemove", e => {
          this.dragRegions(e);
        });
      }
    });

    element.addEventListener("cornerstoneimagerendered", onImageRendered);
    const config = {
      minScale: 0.25,
      maxScale: 20.0,
      preventZoomOutsideImage: true
    };
    utilities.getImages(this, element);
    this.setState({ loading: false });
  };

  updateTheImage = (element, imageIds, imageIndex) => {
    var checkURL = imageIds[imageIndex].data.split(':')
    let ImageId
    if (checkURL.length === 1) {
      /** to fetch uid and token from dthelper file */
      downloadImageUrl((url, uid, token) => {
        /** to send the image request url to cornerstone library */
        const URL = `${url}${this.state.currentHit.data}?token=${token}&uid=${uid}`
        ImageId = "wadouri:" + URL
      })
    } else {
      ImageId =  imageIds[imageIndex].data
    }
    const that = this
    this.setState({ loading: false })
    ImageId = ImageId+"?frame="+imageIds[imageIndex].frameSet
    
    return cornerstone
      .loadImage(
        ImageId
      )
      .then(function(image) {
        that.props.setloaderMenu(false);
        cornerstone.enable(element);
        const viewport = cornerstone.getViewport(element);
        cornerstone.displayImage(element, image, viewport);
        const imageData = imageIds[imageIndex];
        var cornersstoneObj = new cornerstoneTools.FreehandRoiTool
        let config = cornersstoneObj.configuration;
        cornerstoneTools.clearToolState(element, "FreehandRoi");
        cornerstoneTools.clearToolState(element, "Length");
        cornerstoneTools.clearToolState(element, "Bidirectional");
        
        if (imageData.rulerpoints !== undefined && imageData.rulerpoints.length > 0) {
          const pointsData = imageData.rulerpoints;
            pointsData.map((item, index) => {
              if(item.toolType){
                cornerstoneTools.addToolState(element, item.toolType, item);
                if(item.toolType === "Length"){
                  const LengthTool = cornerstoneTools.LengthTool;
                  cornerstoneTools.addTool(LengthTool)
                  cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 })
                }
                else{
                  const BidirectionalTool = cornerstoneTools.BidirectionalTool;
                  cornerstoneTools.addTool(BidirectionalTool)
                  cornerstoneTools.setToolActive('Bidirectional', { mouseButtonMask: 1 })
                }
              }
              })
        }
        if (imageData.points !== undefined && imageData.points.length > 0) {
            const pointsData = imageData.points;
            pointsData.map((item, index) => {
              if(item.toolType){
                cornerstoneTools.addToolState(element, item.toolType, item);
                if(item.toolType === "Length"){
                  const LengthTool = cornerstoneTools.LengthTool;
                  cornerstoneTools.addTool(LengthTool)
                  cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 })
                }
                else{
                  const BidirectionalTool = cornerstoneTools.BidirectionalTool;
                  cornerstoneTools.addTool(BidirectionalTool)
                  cornerstoneTools.setToolActive('Bidirectional', { mouseButtonMask: 1 })
                }
              }
              else{
                cornerstoneTools.addToolState(element, "FreehandRoi", item);
                const FreehandRoi = cornerstoneTools.FreehandRoiTool;
                cornerstoneTools.addTool(FreehandRoi)
                cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: 1 })
              }
            });
            return true;
          }
      });
  };

  enableMouseClick = () => {
    const FreehandRoi = cornerstoneTools.FreehandRoiTool;
    cornerstoneTools.addTool(FreehandRoi)
    cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: 1 })
    this.isToolActive = true
  }

  // Exit Full screen
  goExitFullScreen = () => {
    this.setState({ isFullScreen: false });
  };

  zoom(event_offsetX, event_offsetY, deltaY, zoomF) {
    let zoomFactor;
    if (deltaY < 0 && !zoomF) {
      zoomFactor = 1.07;
    } else if (deltaY > 0 && !zoomF) {
      zoomFactor = 0.93;
    } else if (zoomF) {
      zoomFactor = zoomF;
    }
    const mouseHoverMap = this.state.mouseHoverMap;
    for (const k in mouseHoverMap) {
      if (mouseHoverMap.hasOwnProperty(k)) {
        mouseHoverMap[k] = false;
      }
    }
    this._panner.zoom(zoomFactor, {x: event_offsetX, y: event_offsetY});
    this._startX = event_offsetX;
    this._startY = event_offsetY;
    let newZoomF = this._panner.scale / this.state.scale;
    this.state.translate = {
      x: this._panner.viewport.x,
      y: this._panner.viewport.y
    };
    let { currentRect } = this.state;
    if (currentRect && currentRect.length > 0) {
      for (let jindex = 0; jindex < currentRect.length; jindex++) {
        currentRect[jindex][0] = currentRect[jindex][0] * zoomFactor;
        currentRect[jindex][1] = currentRect[jindex][1] * zoomFactor;
      }
    }
    this.setState({ zoomFactor: newZoomF, disableZoomAnim: true })
    this.setState({
      imgWidth: this.state.imgWidth * zoomFactor,
      imgHeight: this.state.imgHeight * zoomFactor,
      scale: 1,
      currentRect
    });
    clearTimeout(this.resetScale);
    setTimeout(this.resetScale, 10);
  }

  //  ----------Side Bar Tools Calling Methods --start-----------------------

  layoutSelect = (value) => {

  }
  // FullScreen
  goFullScreen = () => {
      this.setState({ isFullScreen: true });
  };

  // Exit Full screen
  goExitFullScreen = () => {
      this.setState({ isFullScreen: false });
  };

  // Interpolation
  interpolation = toolType => {
      this.disableAllTools();
      const toolData = cornerstoneTools.getToolState(this.dicomImage, toolType);
      let interpolationDone = false
      if (toolData !== undefined) {
          // Array to find drawn image index
          var drawnImages = [];
          for (var i = 0; i < this.state.images.length; i++) {
              if (this.state.images[i].points !== undefined) {
                  if (this.state.images[i].points.length > 0) {
                      drawnImages.push(i);
                  }
              }
          } //

          for (var j = 0; j < drawnImages.length; j++) {
              if (drawnImages[j + 1] !== undefined && drawnImages[j] + 1 !== drawnImages[j + 1]) {
                  // condition to check drawn images are not next to next
                  interpolationDone = true
                  this.calculateBetweenSlidePoints(drawnImages, i, j);
              }
          }
          this.callHistory()

      let interpolaDoneMessage = "Interpolation Completed"
      if (interpolationDone) {
        showAlert(interpolaDoneMessage, 'success');
        this.autoSaveInterpolation()
      }
    }
  };

  calculateBetweenSlidePoints = (drawnImages, i, j) => {
    const values = this.state.images;
    var betweenSlides = drawnImages[j + 1] - drawnImages[j]; // middle slides
    if (betweenSlides) {
        var interpolateValue = 1 / betweenSlides; // interpolate value to be add in each slides
        var interpolateValueToAdd = interpolateValue;
        const shape1Points = [];
        const shape2Points = [];
        let startToolData = values[drawnImages[j]].toolData;
        let endToolData = values[drawnImages[j + 1]].toolData;
        for (let i = 0; i < startToolData.handles.points.length; i++) {
            shape1Points.push([
                startToolData.handles.points[i].x,
                startToolData.handles.points[i].y
            ]);
        }
        for (let i = 0; i < endToolData.handles.points.length; i++) {
            shape2Points.push([
                endToolData.handles.points[i].x,
                endToolData.handles.points[i].y
            ]);
        }

        this.setState({interpolationStartIndex: drawnImages[j] })
        for (var k = drawnImages[j] + 1; k < drawnImages[j + 1]; k++) {
            this.doInterpolate(interpolateValue, shape1Points, shape2Points, startToolData, k);
            interpolateValue = interpolateValue + interpolateValueToAdd;
        }
    }
  }

  doInterpolate = (interpolateValue, shape1Points, shape2Points, startToolData, k) => {
      var newPoints = flubber.interpolate(shape1Points, shape2Points);
      var points = newPoints(interpolateValue);
      var pointArrays = utilities.svgToPointsConvert(points);
      pointArrays.push(pointArrays[0]);
      // try catch added,becz after resize, the toolData changed into circular structure,
      // to handle error
      try {
          utilities.getUpdatedToolData(this, JSON.parse(JSON.stringify(startToolData)), pointArrays, k);
      } catch (e) {
        utilities.getUpdatedToolData(this, JSON.parse(JSON.stringify(startToolData)), pointArrays, k);
      }
  }
  enableTool = (toolName, mouseButtonNumber, event1) => {
	  if (toolName.indexOf("#") > -1) {
		  this.setState({ labelExists: true });
		  const arr = toolName.split("#");
		  toolName = "FreehandRoi";
		  this.drawAnnotationColor = this.state.entityColorMap[arr[1]];
	  } else {
		  this.setState({ labelExists: false });
		  this.drawAnnotationColor = null;
	  }

    this.disableAllTools();
    utilities.toggleManager(this, 1);
    this.isContourSelected = true;
    this.activateTools('FreehandRoi')
    this.isToolActive = true
    this.setState({ tooltype: '' });
    };

    //AllTools
    activateTools = which => {
      let tool = null
      switch (which) {
        // no button
        case 'FreehandRoi':
          tool = cornerstoneTools.FreehandRoiTool;
          cornerstoneTools.addTool(tool);
          cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: 1 });
          return true;
        case 'FreehandRoiSculptor':
          tool = cornerstoneTools.FreehandRoiSculptorTool;
          cornerstoneTools.addTool(tool);
          cornerstoneTools.setToolActive('FreehandRoiSculptor', { mouseButtonMask: 1 });
          return true;
        case 'Wwwc':
        	tool = cornerstoneTools.WwwcTool;
        	cornerstoneTools.addTool(tool);
        	cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
        	return true;
      }
    };

  disableAllTools = () => {
      this.isToolActive = false
      cornerstoneTools.setToolPassive('FreehandRoi');
      cornerstoneTools.setToolPassive('Length');
      cornerstoneTools.setToolPassive('Bidirectional');
  };

  // Drag Function
  handleAreaDrag = (e, element, item, k) => {
        this.handlesItem = item.handles.points;
        var handlesItemlen = this.handlesItem.length - 1
        var j = handlesItemlen;
        var leftSideCheck = false
        var rightSideCheck = false
        var movex = cornerstone.pageToPixel(element, e.pageX, e.pageY).x;
        var movey = cornerstone.pageToPixel(element, e.pageX, e.pageY).y;
        for (var i = 0; i < this.handlesItem.length; j = i++) {
            // Condition to check whether the mouse pointer is between the region.
            // This also takes the left side region
            if (((this.handlesItem[i].y) > movey) !== ((this.handlesItem[j].y) > movey) &&
                (movex < ((this.handlesItem[j].x) - (this.handlesItem[i].x)) * (movey - (this.handlesItem[i].y)) /
                    ((this.handlesItem[j].y) - (this.handlesItem[i].y)) + (this.handlesItem[i].x))) {
                leftSideCheck = !leftSideCheck;
            }
            // Condition to check whether the mouse pointer is between the region.
            // this also takes the right side region
            if (((this.handlesItem[i].y) > movey) !== ((this.handlesItem[j].y) > movey) &&
                (movex > ((this.handlesItem[j].x) - (this.handlesItem[i].x)) * (movey - (this.handlesItem[i].y)) /
                    ((this.handlesItem[j].y) - (this.handlesItem[i].y)) + (this.handlesItem[i].x))) {
                rightSideCheck = !rightSideCheck // taking common area
            }
            j = i;
        }
        if (rightSideCheck) {
            this.selected = true
            this.index = k
            this.annoindex = k
        }
        if (e.buttons !== 1) {
            this.setState({
                movexProp: movex
            }); // before dragging getting the points of mouse place
            this.setState({
                moveyProp: movey
            });
        }
    };

  // Drag
  dragRegions = (e) => {
        if (this.handlesItem && this.clonedata !== -1) {
            var movex = cornerstone.pageToPixel(this.dicomImage, e.pageX, e.pageY).x
            var movey = cornerstone.pageToPixel(this.dicomImage, e.pageX, e.pageY).y
            var xpointToadd = this.state.movexProp - cornerstone.pageToPixel(this.dicomImage, e.pageX, e.pageY).x
            var ypointToadd = this.state.moveyProp - cornerstone.pageToPixel(this.dicomImage, e.pageX, e.pageY).y
            var toolData = cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi');
            if (toolData) {
                var toolDataClone = cloneDeep(toolData);
                cornerstoneTools.clearToolState(this.dicomImage, 'FreehandRoi')
                // deleting old points
                // try catch added,becz after resize, the toolData changed into circular structure,
                // to handle error
                try {
                    toolData = JSON.parse(JSON.stringify(toolDataClone.data))
                } catch (e) {
                    toolData = JSON.parse(JSON.stringify(toolDataClone.data))
                }
                for (var k = 0; k < toolData.length; k++) {
                    var data = toolData[k]
                    if (k === this.clonedata) {
                        var handlesData = toolData[this.clonedata].handles.points
                        handlesData.map((item, index) => {
                            item.x = item.x - xpointToadd;
                            item.y = item.y - ypointToadd;

                            item.lines[0].x = item.lines[0].x - xpointToadd;
                            item.lines[0].y = item.lines[0].y - ypointToadd;
                            return true
                        });
                        movex = cornerstone.pageToPixel(this.dicomImage, e.pageX, e.pageY).x
                        movey = cornerstone.pageToPixel(this.dicomImage, e.pageX, e.pageY).y
                        this.setState({ movexProp: movex }); // setting latest points
                        this.setState({ moveyProp: movey });
                    }
                    // add
                    cornerstoneTools.addToolState(this.dicomImage, 'FreehandRoi', data) // pushing new points
                }
            }
        }
    }
  // UNdo
      // Ctrl + Z && Ctrl + Y
      handleMouseMove = (event) => {
        if (event.ctrlKey && event.code === "KeyZ") {
            document.getElementById('funundo').click()
        }
        if (event.ctrlKey && event.code === "KeyY") {
            document.getElementById('funredo').click()
        }
        if (event.key === "Delete") {
            var toolData = cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi');
            var deleteindex = -1;
            if (toolData !== undefined) {
                toolData.data.map((item, index) => {
                    if (item.active) {
                        deleteindex = index
                    }
                    return true
                });
            }
            if (deleteindex !== -1) {
                toolData.data.splice(deleteindex, 1)
                cornerstone.enable(this.dicomImage);
            }
        }
    };



     // Undo Function
     callUndo = () => {
        if(this.state.historyArray.length > 0) {
          cornerstoneTools.clearToolState(this.dicomImage, "FreehandRoi");
          var index = this.state.undoredoHistory - 1;
          var tooldata = this.state.historyArray;
          for (var i = 0; i < tooldata.length; i++) {
              if (index === i) {
                  this.showhideLabel = true
                  var historyTooldata = tooldata[i]
                  var imageindex = historyTooldata[0]
                  var annotationlistarray = historyTooldata[1].data;;
                  this.setState({ images: historyTooldata[2] })
                  if (imageindex !== this.state.currentImageIndex) { // moving to that page
                      this.setState({ currentImageIndex: imageindex });
                      this.updateTheImage(this.dicomImage, this.state.images, imageindex);
                  }
                  var count = index;
                  this.setState({ count: count });
                  this.setState({ undoredoHistory: index });
                  annotationlistarray.map((item, index) => {
                      var data = item;
                         cornerstoneTools.addToolState(this.dicomImage, "FreehandRoi", data);
                         updateImage(this.dicomImage)
                        this.enableMouseClick();
                          if (this.state.tooltype === "FreehandRoiSculptor") {
                              cornerstoneTools["FreehandRoiSculptor"].deactivate(
                                  this.dicomImage,
                                  1
                              );
                              this.setState({ tooltype: "FreehandRoi" });
                          }
                      return true;
                  });
              }
          }

          if (index === -1) {
              count = index + 1;
              this.setState({ count: count });
              this.setState({ undoredoHistory: index });
              cornerstoneTools.clearToolState(this.dicomImage, "FreehandRoi")
              updateImage(this.dicomImage)
              this.enableMouseClick();
          }
          //Autosave
              this.autosaveforUndoRedo();
          //
        }
        this.retainSavedHit()
      };

      retainSavedHit = ( ) => {
        let toolData = cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi');

        if(toolData !== undefined && toolData.data.length === 0){
          toolData = undefined
        }
        if(toolData === undefined){
          let currentHit = this.state.currentHit.hitResults
          if(null != currentHit &&  currentHit.length > 0){
              let result = []
              result = JSON.parse(currentHit[0].result)
              if(result !== null){
                result.map((item) => {
                  cornerstoneTools.addToolState(this.dicomImage, "FreehandRoi", item);
              })
            }
          }
        }
      }

    // Redo Function
    callRedo = () => {
      if(this.state.historyArray.length > 0) {
          var index = this.state.undoredoHistory;
          index = index + 1;
          if (this.state.historyArray.length !== index) {
              cornerstoneTools.clearToolState(this.dicomImage, "FreehandRoi");
              var tooldata = this.state.historyArray;
              for (var i = 0; i < tooldata.length; i++) {
                  if (index === i) {
                      this.showhideLabel = true
                      var historyTooldata = tooldata[i]
                      var imageindex = historyTooldata[0]
                      if (imageindex !== this.state.currentImageIndex) { // moving to that
                          this.setState({ currentImageIndex: imageindex });
                          this.updateTheImage(this.dicomImage, this.state.images, imageindex);
                      }
                      this.setState({ images: historyTooldata[2] })
                      var annotationlistarray = historyTooldata[1].data;
                      var count = index;
                      this.setState({ count: count });
                      this.setState({ undoredoHistory: index });
                      annotationlistarray.map((item, iter) => {
                          cornerstoneTools.addToolState(this.dicomImage, "FreehandRoi", item);
                          updateImage(this.dicomImage)
                          this.enableMouseClick();

                          if (this.state.tooltype === "FreehandRoiSculptor") {
                              cornerstoneTools["FreehandRoiSculptor"].activate(
                                  this.dicomImage,
                                  1
                              );
                              this.setState({ tooltype: "FreehandRoi" });
                          }
                          return true;
                      });
                  }
              }
          }
          // Autosave
          this.autosaveforUndoRedo();
          //
      }
      this.retainSavedHit() //If save hit is removed, to retain
    };

  // Left & Right Rotate
  imageRotate = (side) => {
      const element = this.dicomImage;
      const viewport = cornerstone.getViewport(element);
      if (side === 'left') {
        viewport.rotation += 90;
      } else {
        viewport.rotation -= 90;
      }
      cornerstone.setViewport(element, viewport);
  };

  // Copy Annotation
  copy = toolType => {
      this.disableAllTools();
      const toolData = cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi');
      if (null === toolData || undefined === toolData) {
          return;
      }
      try {
          this.toolDataArr = JSON.parse(JSON.stringify(toolData));
      } catch (e) {
          this.toolDataArr = JSON.parse(JSON.stringify(toolData));
      }
  };

  // Paste Annotation
  paste = toolType => {
      this.disableAllTools();
      if (null === this.toolDataArr || undefined === this.toolDataArr) {
          return;
      }
      this.pasteCount++;
      for (var i = 0; i < this.toolDataArr.data.length; i++) {
          let measurementData
          try {
              measurementData = GetMeasurementData(JSON.parse(JSON.stringify(this.toolDataArr.data[i])));
          } catch (e) {
              measurementData = GetMeasurementData(JSON.parse(JSON.stringify(this.toolDataArr.data[i])));
          }
          measurementData = utilities.updateValues(this, measurementData);
          cornerstoneTools.addToolState(this.dicomImage, 'FreehandRoi', measurementData);
      }
      this.changesInSessionUpdate() // Autosave
    };

    // Invert Image
    invert = () => {
        utilities.toggleManager(this, 4);
        const element = this.dicomImage;
        const viewport = cornerstone.getViewport(element);
        viewport.pixelReplication = !viewport.pixelReplication;
        cornerstone.setViewport(element, viewport);
        this.disableAllTools();
        this.activateTools('Wwwc')
    };

    // Resizing bubble
    Resize = Resize => {
        utilities.toggleManager(this, 3);
        this.disableAllTools();
        const element = this.dicomImage;
        element.tabIndex = 0;
        element.focus();
        if (cornerstoneTools.getToolState(element, "FreehandRoi")) {
            cornerstone.enable(element);
            this.activateTools('FreehandRoiSculptor')
            element.focus();
            this.setState({ tooltype: "FreehandRoiSculptor" });
        }
    };

  // Flip Image
  flip = () => {
      const element = this.dicomImage;
      const viewport = cornerstone.getViewport(element);
      viewport.hflip = !viewport.hflip;
      cornerstone.setViewport(element, viewport);
  };

  checkToolDataInCurrentPage = () => {
    let isToolData = false
    if (cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi') ||
        cornerstoneTools.getToolState(this.dicomImage, 'Length') ||
        cornerstoneTools.getToolState(this.dicomImage, 'Bidirectional')) {
      isToolData =  true
    }
      return isToolData
  }

  // Clear Current Image Annotation
  clearAnnotations = () => {
    if (this.checkToolDataInCurrentPage()) {
      this.setState({ loading: true })
      this.clearTools()
      this.disableAllTools();
      utilities.toggleManager(this, 5);
      this.callHistory();
      this.setState({action: 'clear'})
      const resultString = this.getCurrentResult();
      if (this.state.displayScreen !== undefined && this.state.currentHit.status !== "notDone" && (this.state.displayScreen === "doneScreen") ){
        console.log("check hitstatus 1417");
        updateHitStatus(this.state.currentHit.id, this.props.currentProject, 'notDone', resultString, this.moveToDoneCallback.bind(this));
      }
      else{
        console.log("check hitstatus 1421");
    	  updateHitStatus(this.state.currentHit.id, this.props.currentProject, 'notDone', resultString, this.clearAnnotationsCallBack.bind(this));
        if(this.state.currentHit['points'].length>0){
          showAlert("Annotations are removed successfully.", 'success')
        }else{
          showAlert("There are no annotations in this image.", 'warning')
        }
      }

    } else {
      showAlert("There are no annotations in this image.", 'warning')
    }
  }

  // Clear Annotations in all Images
  clearAllAnnotations = () => {
      // alert
      let otherImageshaveAnnos = false
      // Checking other images has annotations or not
      if (this.state.images.length > 0) {
        this.state.images.map((image)=>{
          if (image.result && image.result !== null) {
            otherImageshaveAnnos = true
          }
          if (image.hitResults) {
            image.hitResults.map((result)=>{
              if (result.result !== "") {
                otherImageshaveAnnos = true
              }
            })
          }
        })
      }
      if (otherImageshaveAnnos) {
        const that = this
        const confirm = AntModal.confirm;
              confirm({
                  title: 'Confirmation',
                  content: 'Do you want clear all the annotations drawn from this project?',
                  okText: 'OK',
                  cancelText: 'CANCEL',
                  onOk() {that.ClearAllandSave();},
                    onCancel() {},
                });
      } else {
        showAlert("There is no annotations in the project.", 'warning')
      }
  }
  clearTools = () => {
    cornerstoneTools.clearToolState(this.dicomImage, 'FreehandRoi')
    cornerstoneTools.clearToolState(this.dicomImage, "Length");
    cornerstoneTools.clearToolState(this.dicomImage, "Bidirectional");
  }

  ClearAllandSave = () => {
      this.clearTools()
      var allAnnotations = this.state.images
      allAnnotations.map((item, index) => {
          item.toolData = []
          item.points = []
      });
      this.setState({action: 'clear', drawregions: []})
      this.setState({ images: allAnnotations })

      this.state.hits.map((hit)=>{
      	  delete hit['result']
      	  hit.status = "notDone";
      	  this.setState({currentIndex : 0 , currentImageIndex : -1 })
          console.log("check hitstatus 1491");
          updateHitStatus(
            hit.id,
            this.props.currentProject,
            'notDone',
            "",
            (this.state.displayScreen === "doneScreen" ? this.moveToDoneCallback.bind(this) : this.clearAnnotationsCallBack.bind(this))
          );

      })


      this.disableAllTools();
      utilities.toggleManager(this, 6);
  }

// Color Change for Annotations
chooseAnnoColor = (color) => {
    this.drawAnnotationColor = color.hex
  }
  getAnnoIndex = (index) => {
      this.annoindex = index - 1
  }
  changeAnnoColor = (color) => {
      if (color.source === 'rgb' && this.annoindex !== null) {
          var toolData = cornerstoneTools.getToolState(this.dicomImage, 'freehand').data;
          toolData[this.annoindex].color = color.hex
          cornerstoneTools["freehand"].activate(this.dicomImage, 1);
          this.isToolActive = true
      }
  }
  // History
  // saving history datas
  callHistory = () => {
    this.setState({ mouseRightClick: false });
    var toolData = cornerstoneTools.getToolState(this.dicomImage, "FreehandRoi"); // getting toodata value
      if (toolData !== undefined) {
          var annotationlistCopy = cloneDeep(toolData); // clone the object
          var stateImagesData = cloneDeep(this.state.images) // fro storing images data also for interpolation
          if (this.state.historyArray !== undefined) {
              this.showhideLabel = false
              if (this.state.historyArray.length === 0) {
                  var annotationlist = []
                  annotationlist.push(this.createHistoryData(this.state.currentImageIndex, annotationlistCopy, stateImagesData))
                  this.setState({ historyArray: annotationlist })
              } else {
                  var arr = this.state.historyArray;
                  if (this.state.historyArray.length !== this.state.count) {
                      var formatedDataarr = [];
                      for (var j = 0; j < this.state.count; j++) {
                          var formatedData = this.state.historyArray;
                          formatedDataarr.push(formatedData[j]);
                      }
                      arr = formatedDataarr;
                  }
                  var arrdata = [];
                  for (var i = 0; i < arr.length; i++) {
                      arrdata.push(arr[i]);
                  }
                  // pushing the pagename and historyarraylist
                  arrdata.push(this.createHistoryData(this.state.currentImageIndex, annotationlistCopy, stateImagesData));
                  this.setState({ historyArray: arrdata })
              }
              var count = this.state.count;
              this.setState({ undoredoHistory: count });
              count++;
              this.setState({ count: count });
          }
      }
  };

  // push data for history
    createHistoryData(currentImageIndex, annotationlistCopy, stateImagesData) {
        var annotationlistCopyobj = [];
        annotationlistCopyobj[0] = currentImageIndex
        annotationlistCopyobj[1] = annotationlistCopy
        annotationlistCopyobj[2] = stateImagesData
        return annotationlistCopyobj;
    }

  // Close Labels
  handleDialogClose = () => {
    const { tooltype } = this.state;
    let toolData = []
    let tool = ""
    if (tooltype === 'Length') {
      tool = 'Length'
    } else if (tooltype === 'Bidirectional') {
      tool = 'Bidirectional'
    } else {
      tool = 'FreehandRoi'
    }
    toolData = cornerstoneTools.getToolState(this.dicomImage, tool).data
	  toolData.splice(toolData.length - 1, 1);
    this.setState({ open: false, collapse: true });
    this.isContourSelected = false;
    this.setState({errorMessage : false, AnnotationsDrawn : false});
    this.isToolActive = false
    utilities.toggleManager(this, 0);
    this.disableAllTools();
  };

  clearAnnotationsCallBack = (err, response) => {
	    if (!err) {
	    	 let imagesValue = this.state.images;
		      let responseHits = response.body.hits
		      imagesValue.map((image,index)=>{
		        responseHits.map((hit)=>{
		        	hit.status = "notDone";
		          if(image.id === hit.id){
		            image.hitResults = hit.hitResults
		          }
		        })
		      })
	          this.state.currentHit.status = "notDone";
		        this.setState({
		        	images:imagesValue
		          });
		        this.setState({ loading: false })
	    }
	  }

  interpolationMoveDoneCallBack = (err, response) => {
    if (!err) {
      if(response.body !== null){
        let responseHits = response.body.hits
        let imagesValue = this.state.images
        imagesValue.map((image,index)=>{
          responseHits.map((hit)=>{
            if(image.id === hit.id){
              image.hitResults = hit.hitResults
            }
          })
        })
        this.setState({images:imagesValue})
      }
    }
  }

  moveToDoneCallback(err, response) {
    if (!err) {
      this.props.getProjectDetails(this.props.currentProject, getUidToken());
      this.hitAddCallback(undefined, "Hit moved to done", this.state.action);
    } else {
      if (response && response.body && response.body.code && response.body.code === 401) {
        refreshUidToken(() => {
          this.setState({ loading: false })});
      } else {
        captureException(err);
      }
    }
  }

   getMenuItems = () => {
      const arrs = [];
      let index1 = 0;
      let jindex = 0;
      const entityColorMap = this.state.entityColorMap;
      const { styles } = helper;
	let keys = Object.keys(entityColorMap);
      for (let i = 0; i < keys.length; i++) {
        if (!this.state.searchQuery || this.state.searchQuery.length === 0 || keys[i].toUpperCase().includes(this.state.searchQuery.toUpperCase())) {
          let bgC = 'white';
          if (this.state.defaultClass === keys[i]) {
            bgC = 'grey';
          }
          let combo = undefined;
          if (keys[i] in this.state.shortcuts) {
            combo = convertKeyToString(this.state.shortcuts[keys[i]]);
            Mousetrap.bind(combo, this.drawHandle.bind(this, keys[i]));
          }
          arrs.push(

            <div className="disable_text_highlighting text-center" onClick = {this.drawHandle.bind(this, keys[i])} tabIndex={jindex} key={keys[i]} id={keys[i]} style={{ cursor: 'pointer', backgroundColor: `${bgC}`, minHeight: '35px', marginBottom: '3px', padding: '3px', marginTop: '3px', display: 'flex', justifyContent: 'space-around', overflow: 'auto', width: '100%', alignItems: 'center', flexDirection: 'row-reverse' }} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId)  === ROLES_TYPES.QA) ? true : false}>
            { ((keys[i] in this.state.showOrHideEyeMap) && (this.state.showOrHideEyeMap[keys[i]] === false)) &&
                <div title="Show Label" style={styles.sInitial}>
                  <Button size="mini" icon style={styles.buttonBG} onClick={(event) => {this.showOrHideAnnotation(keys[i], event)}} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId)  === ROLES_TYPES.QA) ? true : false}>
                    <Icon name="low vision" style={{ color: `${this.state.entityColorMap[keys[i]]}`}} />
                  </Button>
                </div>
              }
                { ((!(keys[i] in this.state.showOrHideEyeMap)) || (keys[i] in this.state.showOrHideEyeMap && this.state.showOrHideEyeMap[keys[i]] === true)) &&
                <div title="Hide Label" style={styles.sInitial}>
                  <Button size="mini" icon style={styles.buttonBG} onClick={(event) => {this.showOrHideAnnotation(keys[i], event)}} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId)  === ROLES_TYPES.QA) ? true : false}>
                    <Icon name="eye" style={{ color: `${this.state.entityColorMap[keys[i]]}`}} />
                  </Button>
                </div>
                }
            <div style={styles.setContainer}>
            <div>
                    <Label id={keys[i]} size="mini" style={{ whiteSpace: 'inherit', boxShadow: '1px 1px 1px', color: 'white', backgroundColor: `${entityColorMap[keys[i]]}` }}>
                      {keys[i]}
                    </Label>
                  </div>
                  <div>
                  { combo && <p style={styles.comboStyle}> {combo}</p> }
                </div>
                </div>
              </div>);
          jindex = jindex + 1;
        }
        index1 = index1 + 1;
      }
      return ( <div
                  style={styles.flexCo}
                  bsStyle="primary"
                >
                <div>
                  <Input value={this.state.searchQuery} className="w-100" onChange={(event) => this.setState({searchQuery: event.target.value })} placeholder="Search..." />
                </div>
                <div className="m_labelsec">
                  {arrs}
                </div>
               </div>);
    }

   showOrHideAnnotation = (label, event) =>{
     if(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR){
	   event.stopPropagation();

	   if (label in this.state.showOrHideEyeMap && this.state.showOrHideEyeMap[label] === true) {
		   this.state.showOrHideEyeMap[label] = false;
	   }else if (label in this.state.showOrHideEyeMap && this.state.showOrHideEyeMap[label] === false) {
		   this.state.showOrHideEyeMap[label] = true;
	   }else {
		   this.state.showOrHideEyeMap[label] = false;
	   }
		  var toolData = cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi').data;
		  toolData.map(data => {
        	 if (data.label === label && this.state.showOrHideEyeMap[label] === false) {
        		 data.visible = false;
        	 }else if (data.label === label && this.state.showOrHideEyeMap[label] === true ) {
        		 data.visible = true;
        	 }
    		})
		  cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi').data = toolData;
		  this.setState({action: ''});
   }
  }

  drawHandle(label, event) {
    if(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.ANNOTATOR){
	  event.preventDefault();
      if (this.state.defaultClass !== label) {
          this.setState({ defaultClass: label });
    	  this.setState({ labelText: label });
    	  this.setState({ labelExists: true });
    	  let freehand = "FreehandRoi" + "#" + label;
    	  this.enableTool(freehand, 1);
        } else {
          this.setState({ defaultClass: '' });
        }
  }
}

  getAnnotatorInfo( hit ) {
	    if ( hit && hit.hitResults ) {
	      const updatedtime = hit.hitResults[0].updatedTimestamp || hit.hitResults[0].updated_timestamp;
	      let userName = hit.hitUserName;
        let userEmail = hit.hitUserEmail;
        let hitEvaluatedBy = '';
	      if ( this.state.contributorDetailsMap ) {
	        const hr = hit.hitResults[0];
	        userName = this.state.contributorDetailsMap[hr.userId] && this.state.contributorDetailsMap[hr.userId].firstName ? this.state.contributorDetailsMap[hr.userId].firstName : '';
	        userEmail = this.state.contributorDetailsMap[hr.userId] && this.state.contributorDetailsMap[hr.userId].email ? this.state.contributorDetailsMap[hr.userId].email : '';
        }
        if (hit.hitEvaluatedBy) {
          hitEvaluatedBy = hit.hitEvaluatedBy;
        }
	      this.setState({
	          hitUserName: userName,
	          hitUserEmail: userEmail,
	          hitFileName: hit.fileName,
	          hitStatus: hit.status,
	          hitEvaluation: hit.evaluation,
            hitUpdatedTime: dateTimeConverter(updatedtime),
            hitEvaluatedBy
	      });
	    }
	  }

  showButtons() {
    const disableNext = utilities.disableNextButton(this)
    const { styles } = helper;
    this.renderKeyBoardShortCuts(disableNext,"StartTagging")
    return (
      <div className="m_action_btn">
                <div title="Previous" className={this.state.currentIndex <= 0 ? 'eventnone' : ''}> <a className="m_imgaction_btn m-r-10" tilte="Previous" onClick={this.getBackTopreviousRow} disabled={this.state.currentIndex <= 0}><img src={LEFTARROW} /></a> </div>
                <div title="Next" className={disableNext ? 'eventnone' : ''}> <a className="m_imgaction_btn m-r-10" tilte="Next" onClick={this.saveTagAndNextRow} disabled={disableNext }><img src={RIGHTARROW} /></a> </div>
              </div>
        );
  }
  // Next Button Calls Start

  getBackTopreviousRow = () => {
    this.selected = false
    this.props.setloaderMenu(true);
    this.setState({ loading: true })
    if (this.state.currentImageIndex > 0) {
        const currentImgIndex = this.state.currentImageIndex - 1;
        const currentIndex = this.state.currentIndex - 1; // Added for Image index when next clicks
        this.setState({ currentImageIndex: currentImgIndex });
        this.setState({ currentIndex: currentIndex });// Added for Image index when next clicks
        this.setState({ currentHit: this.state.hits[currentIndex]
        }, () => {
          this.updateTheImage(this.dicomImage, this.state.images, currentImgIndex);
        });
    } else {
      this.props.setloaderMenu(false);
    	this.setState({ loading: false })
    }
  };

  autoSaveInterpolation = () => {
    let interpolationDoneImages  = this.state.interpolationDone
      if (interpolationDoneImages.length !== 0) {
        let saveValues = []
        interpolationDoneImages.map((value) => {
          let saveDataMap = {}
          saveDataMap.status = 'done'
          saveDataMap.hitID = value.id
          saveDataMap.result = JSON.stringify(value.points)
          saveValues.push(saveDataMap)
        })
        updateDicomListHitStatus(this.props.currentProject, saveValues, this.interpolationMoveDoneCallBack);
      }
  }
  // Routes Next Page and Save

  saveTagAndNextRow(action) {

    if(action !== 'autoSave'){
      this.props.setloaderMenu(true);
      this.setState({ loading:true })
      action = 'next'
    }
    logEvent("buttons", "Next hit");
    if (this.state.changesInSession > 0) {
      logEvent("buttons", "Labeled Data");
    }
    const resultString = this.getCurrentResult();
    this.state.currentHit.result = resultString;

    // If changes made
    if (this.state.changesInSession > 0) {
      this.setState({action: action, changesInSession: 0});
      console.log("check hitstatus 1839");
      updateHitStatus(this.state.currentHit.id, this.props.currentProject, 'done', resultString, this.moveToDoneCallback.bind(this));
    } else {    // If no changes made
      this.setState({ changesInSession: 0, action: action});
      this.hitAddCallback(undefined, "No Tag Found, moving to next", 'next');
    }
    return false;
  }

  hitAddCallback(error, response, action) {
    if (!error) {
      const chits = this.state.hits;
      let nextIndex = -1;
      let imgLoaded = false;
      let unProcessedCount = chits.length;
      this.state.unProcessedCount = (this.state.unProcessedCount) ? this.state.unProcessedCount : unProcessedCount;

      // next page start
      if ((action && action !== "autoSave") || this.state.action !== "autoSave") {
      if ((action && action === "next") || this.state.action === "next") {
        if (this.state.currentIndex + 1 < chits.length) {
          nextIndex = this.state.currentIndex + 1;
          this.selected = false
          if (this.state.currentImageIndex < this.state.images.length - 1) {
              const currentImgIndex = this.state.currentImageIndex + 1;
              const currentIndex = this.state.currentIndex + 1; // Added for Image index when next clicks
              this.setState({ currentImageIndex: currentImgIndex });
              this.setState({ currentIndex: currentIndex });// Added for Image index when next clicks
              this.setState({ currentHit: this.state.hits[currentIndex]
              }, () => {
                this.updateTheImage(this.dicomImage, this.state.images, currentImgIndex);
              });
          }
        }
      } else if ((action && action === "previous") || this.state.action === "previous") {
        if (this.state.currentIndex > 0) {
          nextIndex = this.state.currentIndex - 1;
        }
      } else if ((action && action === "clear") || this.state.action === "clear") {
        if (this.state.displayScreen)
          this.state.totalFilteredHits = this.state.totalFilteredHits - 1;

        nextIndex = this.state.currentIndex;
        this.state.currentImageIndex = this.state.currentImageIndex - 1;
        chits.splice(this.state.currentIndex, 1);
        if (nextIndex >= chits.length) {
          this.state.currentIndex = this.state.currentIndex - 1;
        }
        this.selected = false

        if (this.state.currentImageIndex < this.state.images.length - 1) {
            const currentImgIndex = this.state.currentImageIndex + 1;
            const currentIndex = this.state.currentIndex; // Added for Image index when next clicks
            this.setState({ currentImageIndex: currentImgIndex });
            this.setState({ currentIndex: currentIndex });// Added for Image index when next clicks
            this.setState({ currentHit: this.state.hits[currentIndex]
            }, () => {
              this.updateTheImage(this.dicomImage, this.state.images, currentImgIndex);
            });
        }
        // MOving to next image
      }

      let currentHit = this.state.currentHit;
      if (nextIndex > -1 &&  nextIndex < chits.length) {
        currentHit = chits[nextIndex];
        let currentTags = new Set();
        let currentNote = "";
        let textSummary = "";
        let boundingBoxMap = [];
        let classificationResponse = {};
        this.getAnnotatorInfo( currentHit );
        this.setState({
          startTime: new Date().getTime(),
          currentNote,
          classificationResponse,
          imgLoaded,
          currentTags,
          boundingBoxMap,
          hits: chits,
          action: "",
          textSummary,
          currentIndex: nextIndex,
          currentHit,
          selectIds: [],
          tagSelected: false,
          loading : false
        });
      }else {

        const { currentStart, currentCount } = this.state;
        let newCurrentStart = currentStart;
        let expectedCurrentStart = currentStart + currentCount;
        let overviewStatusList = ["done", "pending", "all"];
        if ( (this.state.type && this.state.type === overviewStatusList[0] && parseInt(this.state.projectDetails.totalHitsDone) > expectedCurrentStart ) ||
             (this.state.type && this.state.type === overviewStatusList[1] && (parseInt(this.state.projectDetails.totalHits) - parseInt(this.state.projectDetails.totalHitsDone)) > expectedCurrentStart ) ||
             ( this.state.type && this.state.type === overviewStatusList[2] && parseInt(this.state.projectDetails.totalHits) > expectedCurrentStart ) ||
             (!this.state.type || !(overviewStatusList.includes(this.state.type) )) ) {
              newCurrentStart = currentStart + currentCount
          this.setState({ currentStart: newCurrentStart });
        }
        if ((!this.state.type || this.state.type === "notDone") && this.state.fromLeaderBoard === true) {
          this.getCurrentProjectDetails(this.props.currentProject, newCurrentStart);
        } else {
          this.loadProjectDetails(
            this.props.currentProject,
            newCurrentStart,
            this.state.DEFAULT_COUNT
          );
          this.setState({
            currentStart: newCurrentStart,
            currentCount: this.state.DEFAULT_COUNT,
            currentHit: undefined
          });
        }
      }
      }
    }

    else {
      if (response && response.body && response.body.code && response.body.code === 401) {
        refreshUidToken(() => {
          this.setState({ loading: false })});
      } else {
        showAlert(error.message, 'error');
        this.setState({ loading: false, error: error.message});
      }
    }
  }

  getCurrentProjectDetails(pid, currentStart) {
    this.currentStart = currentStart;
	    fetchNotDoneHits(
	      pid ? pid : this.props.currentProject,
	      this.receivedProjectDetails,
	      this.state.type,
	      this.state.contributorId,
	      this.state.pageSortBy);
	  }

  receivedProjectDetails(error, response) {
	    if (!error) {
	      let projectDetails = this.props.projectDetails;
	      if (response.body.projectDetails) {
	        this.props.updateProjectDetails(response.body.projectDetails);
	        projectDetails = response.body.projectDetails;
	      }
	      let totalNotDoneHits = projectDetails.totalHits - (projectDetails.totalHitsDeleted +
	        projectDetails.totalHitsDone +  projectDetails.totalHitsSkipped );
	      if (this.state.unProcessedCount <  this.state.hits.length ||  this.state.unProcessedCount !== 0) {
	        this.state.currentStart =  this.state.unProcessedCount;
	      }
        const { currentStart, currentCount } = this.state;
        let startCount = (totalNotDoneHits > 10 ? this.currentStart : 0 );
	      this.loadProjectDetails(this.props.currentProject, startCount, currentCount);
	    }
    }

  overAllToolData = () => {
    let toolResult = ['Length','Bidirectional','FreehandRoi']
    let Toolsdata = []
    toolResult.map((tool) => {
      if(cornerstoneTools.getToolState(this.dicomImage, tool) !== undefined){
        let toolData = null
        toolData = cornerstoneTools.getToolState(this.dicomImage, tool).data
        if(toolData !== null) {
          toolData.map((tools)=>{
            Toolsdata.push(tools)
          })
        }
      }
    })
    return Toolsdata
  }


  getCurrentResult() {

    let totalTooldata = []
    let Toolsdata = this.overAllToolData()
    //end
    try {
      totalTooldata = JSON.stringify(Toolsdata)
    } catch (e) {
      var seen = [];
      totalTooldata = JSON.stringify(Toolsdata, function(key, val) {
                          if (val != null && typeof val == "object") {
                          if (seen.indexOf(val) >= 0) {
                          return;
                          }
                          seen.push(val);
                          }
                          return val;
                          });

    }
    if (totalTooldata === "[]") totalTooldata = "";
    return totalTooldata;

  }

  handleDialogClickEvent = event => {
	  const {entities,textValue,labelIndex,images,currentImageIndex,currentHit} = this.state;
	  let labelvalue = {};
	  let entry = entities;
	  if (this.state.textValue === "") {
		  this.setState({errorMessage : true});
      } else {
    	  this.setState({errorMessage : false});
    	  if(!entities.includes(textValue)){
        	  entry.push(textValue);
        	  this.setState({entities: entry})
    	  }

        const entityColorMap = createDocEntityColorMap(entities, ENTITY_COLORS);
        this.setState({entityColorMap: entityColorMap })

          let toolData = []
          let index
          if(this.state.tooltype  === 'Length' || this.state.tooltype  === 'Bidirectional'){
            let toolDatas = ['Length','Bidirectional']
            let undirectionalLength = null
            let bidirectionalLength = null
            toolDatas.map((tool)=>{
              if(cornerstoneTools.getToolState(this.dicomImage, tool)){
                let tools = cornerstoneTools.getToolState(this.dicomImage, tool).data;
                tools.map((toolVal)=>{
                  toolData.push(toolVal)
                  if(this.state.tooltype === 'Length' && tool === 'Length') {
                    undirectionalLength = undirectionalLength + 1
                  }
                    bidirectionalLength = bidirectionalLength + 1
                })
              }
            })
            index = labelIndex === undefined ? (this.state.tooltype === 'Length' ? undirectionalLength : bidirectionalLength) : labelIndex;
            utilities.setLabelValue(this, textValue, index, 'ruler');
          }
          else {
            toolData = cornerstoneTools.getToolState(this.dicomImage, 'FreehandRoi').data;
            index = labelIndex === undefined ? images[currentImageIndex].points.length : labelIndex;
            utilities.setLabelValue(this, textValue, index, 'freehand');
          }
         toolData[index - 1].color = entityColorMap[textValue];
         labelvalue = { "labelName": textValue}
         this.activateTools('FreehandRoi')
         this.isToolActive = true
         this.setState({ textValue: "" });
         this.setState({ open: false, collapse: true, AnnotationsDrawn : false});
         utilities.toggleManager(this, 0);
         this.disableAllTools();
         updateNewLabelName(currentHit.id, this.props.currentProject, labelvalue, NEW_LABEL_UPDATE, this.labelUpdatedCallback.bind(this, () => {})) // Saving new Label Name
      }
    };

  // set Label Value
    setTextValue = value => {
    	this.setState({ textValue: value, errorMessage : false});
    };
  // Lebel List
    toggle = () => {
      this.setState({ collapse: !this.state.collapse });
  }

    labelUpdatedCallback(callback, err, response) {
        this.stopAutoSave = false;
        if (!err) {
          this.setState({ loading: false, changesInSession : this.state.changesInSession + 1 });
          const responseData = response.body;
          const taskLabels = JSON.parse(responseData.taskRules)
          // For displaying entity with color in right side list
          const enitity = taskLabels.tags
          let entityColorMap = null;
          let tagsEntitiy = enitity.toString();
          if (enitity !== "") {
            tagsEntitiy = enitity.toString().split(",")
          }
          this.setState({ entities: tagsEntitiy }, callback(true))
          entityColorMap = createDocEntityColorMap(tagsEntitiy, ENTITY_COLORS);
          // For displaying keyboardshortcuts below the label
          let shortcuts = getDetaultShortcuts(responseData.task_type, tagsEntitiy);
          this.setState({ entityColorMap: entityColorMap, entities: tagsEntitiy, shortcuts: shortcuts})
        } else {
          if (response && response.body && response.body.code && response.body.code === 401) {
            refreshUidToken(() => {
              this.setState({ loading: false }) });
          } else {
            captureException(err);
          }
        }
      }

    callActive = (activeindex) => {
      var index = activeindex - 1;
      this.setState({ activeFlag: true });
      this.setState({ indexvalue: index });
      this.activateTools('FreehandRoi');
      this.isToolActive = true
  }

  deleteContextMenu = (event) => {
    if (this.selected) {
        event.preventDefault();
        this.setState({ mouseRightClick: true });
        const clickX = event.clientX;
        const clickY = event.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const rootW = this.root.offsetWidth;
        const rootH = this.root.offsetHeight;
        const right = (screenW - clickX) > rootW;
        const left = !right;
        const top = (screenH - clickY) > rootH;
        const bottom = !top;
        if (right) {
            this.root.style.left = `${clickX + 5}px`;
        }
        if (left) {
            this.root.style.left = `${clickX - rootW - 5}px`;
        }
        if (top) {
            this.root.style.top = `${clickY + 5}px`;
        }
        if (bottom) {
            this.root.style.top = `${clickY - rootH - 5}px`;
        }
    }
};

removeSelected = (index) => {

    let overAllToolDatas = this.overAllToolData()
    let deleteTool = "" // which tool to get removed
    let deleteParticularToolindex = -1 // which index should be removed
    let deleteindex = 0

    overAllToolDatas.map((removeTool, overAllindex)=>{
      deleteParticularToolindex ++
      if(overAllindex === index-1){
        if(overAllindex !== 0 && overAllToolDatas[overAllindex-1] !== overAllToolDatas[overAllindex]){
          deleteParticularToolindex = 0
        }
        deleteindex = deleteParticularToolindex
        if(overAllindex === 0){
          deleteParticularToolindex = 0
          deleteindex = 0
        }
        if(removeTool.toolType){
          deleteTool = removeTool.toolType
        }
        else{
          deleteTool = "FreehandRoi"
        }
      }
    })
    let toolData = cornerstoneTools.getToolState(this.dicomImage, deleteTool);
    toolData.data.splice(deleteindex, 1)

    cornerstone.enable(this.dicomImage);
    this.setState({changesInSession : this.state.changesInSession + 1})
}

getHitDetails(hit) {
    const { styles } = helper;
  if (hit && hit.hitResults && this.state.hitUserEmail) {
    return (
      <div className="status_area m-b-10">
         <h5>Annotator Info</h5>
                 {this.state.hitUserName && (<p><b>Name: </b>{this.state.hitUserName}</p>)}
                 <p><b>Email:</b> {this.state.hitUserEmail}</p>
                 {this.state.hitFileName && <p>
                      <b> File Name: </b>{(this.state.hitFileName).replaceAll("__-__-"," ")}  </p>}
                      <p >
                 <b>Last Updated: </b>
                      {this.state.hitUpdatedTime}
                    </p>
                    {this.state.hitEvaluatedBy !== '' &&
             <p><b>Evaluated By:</b> {this.state.hitEvaluatedBy}</p>
          }
              <div className="m-t-5 evaluate-label">
              {this.state.hitStatus && <span className="status_btn m-b-5">{hitStateNameMap[this.state.hitStatus]}</span>}
                {this.state.hitEvaluation && <Label style={styles.textC} className="tv"color={null != this.state.hitEvaluation && this.state.hitEvaluation.toLowerCase()=="correct"?"green":"red"} size="mini">{this.state.hitEvaluation}</Label>}
              </div>
            </div>

    );
  }
}

nextRow(action) {
	let confirmed = true;
    logEvent("buttons", "Next Row");
    logEvent("navigation", "Next");
    this.setState({updateAnnotatorInfo: true, loading:true});

    if (confirmed) {
      if (action === 'next') {
        this.saveTagAndNextRow()
      } else {
        this.getBackTopreviousRow();
      }
    } else {
      this.setState({ changesInSession: 0 });
      this.hitAddCallback(undefined, "Ok", action);
    }
    return false;
  }

renderKeyBoardShortCuts (nextBtnDisable, funType) {

  if ("shortcuts" in this.state) {
    const shortcuts = this.state.shortcuts;
    if ("next" in shortcuts) {
      const combo = convertKeyToString(shortcuts.next);
      if (!nextBtnDisable) {
        Mousetrap.bind(combo, funType === "StartTagging" ? this.saveTagAndNextRow.bind(this) : this.nextRow.bind(this, 'next'));
      } else {
        Mousetrap.unbind(combo);
      }
    }
    if ("previous" in shortcuts) {
      const combo = convertKeyToString(shortcuts.previous);
      if (this.state.currentIndex > 0) {
        Mousetrap.bind(combo, funType === "StartTagging" ? this.getBackTopreviousRow.bind(this) : this.nextRow.bind(this, 'previous'));
      } else {
        Mousetrap.unbind(combo);
      }
    }
  }
}

showButtonsMini() {
  const nextButtonDisabled = utilities.disableNextButton(this)
  this.renderKeyBoardShortCuts(nextButtonDisabled, "DoneHits")
  return (
     <div>
       <div className="m_titlebtn">
         <Button className="m_btn"
           title="Previous Element"
           size="mini"
           color="grey"
           icon
           loading={this.state.loading}
           onClick={this.nextRow.bind(this, 'previous')}
           disabled={this.state.currentIndex <= 0}
         >
           <Icon name="left arrow" />
         </Button>
         <Button className="m_btn"
           title="Next Element"
           size="mini"
           color="blue"
           icon
           loading={this.state.loading}
           onClick={this.nextRow.bind(this, 'next')}
           disabled={nextButtonDisabled}
         >
           <Icon name="right arrow" />
         </Button>
       </div>
     </div>
  );
}

evaluationCallback(value, err, response) {
  if (!err) {
    const { currentHit, hits, currentIndex } = this.state;
    currentHit.evaluation = value;
    hits[currentIndex].evaluation = value;
    if (response && response.body) {
      const evaluationResult = response.body;
      currentHit.hitEvaluatedBy = evaluationResult.evaluatedBy;
      hits[currentIndex].hitEvaluatedBy = evaluationResult.evaluatedBy;
    }
    this.setState({ loading: false, currentHit, hits, hitEvaluation: value.toUpperCase() }, () => {
        this.getAnnotatorInfo(currentHit);
      });
  } else {
    this.setState({ loading: false });
    captureException(err);
  }
}

evaluateHit(value) {
  logEvent("evaluation", value);
  const { currentHit } = this.state;
  this.setState({ loding: true });
  addHitEvaluation(currentHit.id, this.props.currentProject, value, this.evaluationCallback.bind(this, value));
}

evaluationCallback(value, err, response) {
  if (!err) {
    this.props.setloaderMenu(false);
    const { currentHit, hits, currentIndex } = this.state;
    currentHit.evaluation = value;
    hits[currentIndex].evaluation = value;

    if (response && response.body) {
      const evaluationResult = response.body;
      currentHit.hitEvaluatedBy = evaluationResult.evaluatedBy;
      hits[currentIndex].hitEvaluatedBy = evaluationResult.evaluatedBy;
    }

    this.setState({ loading: false, currentHit, hits, hitEvaluation: value.toUpperCase() }, () => {
      this.getAnnotatorInfo(currentHit);
    });
  } else {
    this.setState({ loading: false });
    captureException(err);
  }
}

showEvaluationButtons() {
  this.state.searchHitUrl = null;
  return (
    <div className="eva_btn">
            <button href="#" className="btn_base btn_incorrect custom_btn_complete" onClick={this.evaluateHit.bind(this, 'INCORRECT')}> <AntIcon  className="m-r-5" type="close" /> INCORRECT</button>
            <button href="#" className="btn_base btn_correct m-l-10 custom_btn_complete" onClick={this.evaluateHit.bind(this, 'CORRECT')}> <AntIcon  className="m-r-5" type="check" /> CORRECT</button>
    </div>
  );
}

routeToHome = () => {
  if (getUidToken().roleId === "2") {
    this.props.pushState("/workspace")
  } else {
    let roleId = getUidToken().roleId
    this.props.pushState("/projects/"+roleId+"/list")
  }
}

redirectBackImage(e) {
  if (undefined !== e)
    e.preventDefault();
  const { type, projectDetails} = this.state;
    this.props.pushState({
          pathname:
            projectTypePath(projectDetails) +
            this.props.params.orgName +
            "/" +
            this.props.params.projectName +
            "/" +
            this.props.params.projectId
        });
}

getHitStates() {
  this.setState({loading: true});
  const options = [];
  let selected = this.state.displayScreenType;
  options.push({
    text: "Done HITs",
    value: HIT_STATE_DONE,
    onClick: () => {
      this.resetFilterState();
      logEvent("buttons", "Select Done");
      this.state.displayScreenType = HIT_STATE_DONE;
      this.setState({searchHitUrl: null}, () => {
        this.loadProjectDetailsFilter(this.props.currentProject);
      });
    }
  });
  options.push({
    text: "Pending HITs",
    value: HIT_STATE_NOT_DONE,
    // callback
    onClick: () => {
      this.resetFilterState();
      logEvent("buttons", "Select Not Done");
      this.state.displayScreenType = HIT_STATE_NOT_DONE;
      this.setState({searchHitUrl: null}, () => {
        this.loadProjectDetailsFilter(this.props.currentProject);
      });
    }
  });
  options.push({
	    text: "All",
	    value: HIT_STATE_ALL,
	    // callback
	    onClick: () => {
	      this.resetFilterState();
	      logEvent("buttons", "All");
	      this.state.displayScreenType = HIT_STATE_ALL;
	      this.setState({searchHitUrl: null}, () => {
	        this.loadProjectDetailsFilter(this.props.currentProject);
	      });
	    }
	  });
  return (
    <Dropdown
      value={selected}
      placeholder="Select State"
      selection
      options={options}
    />
  );
}

evaluationOnClick(evaluationType) {
  this.resetFilterState();
  this.state.label = undefined;
  this.state.contributorId = undefined;
  this.state.commentFilter = undefined;
  this.state.currentStart = 0;
    if (evaluationType === HIT_EVALUATION_NE) {
      this.state.evaluationType = HIT_EVALUATION_NE;
      this.loadProjectDetailsFilter(this.props.currentProject);
    }else if (evaluationType === HIT_EVALUATION_TYPE_ALL) {
      this.state.evaluationType = undefined;
      this.loadProjectDetailsFilter(this.props.currentProject);
    } else {
      let screen = DONE_SCREEN;
    	if(this.state.displayScreenType === HIT_STATE_NOT_DONE)
			screen = PENDING_SCREEN
		else if(this.state.displayScreenType === HIT_STATE_ALL)
			screen = TOTAL_SCREEN;

    	this.props.pushState(
        this.getUrl(this.state.type, undefined, undefined, evaluationType, undefined)
      );
    }
  }

getUrl(type, screen, contributorId, entity, evaluationType, undefined, from, to) {
    let url =
      "/projects/" +
      this.props.params.orgName +
      "/" +
      this.props.params.projectName +
      "/" +
      this.props.params.projectId +
      "/view?type=done";
    if (screen) {
      url = url + "&screenType=" + screen;
    }
    if (contributorId) {
      url = url + "&contributorId=" + contributorId;
    }
    if (entity) {
      url = url + "&label=" + entity;
    }
    if (evaluationType) {
      url = url + "&evaluationType=" + evaluationType;
    }
    if (from) {
      url = url + "&from=" + from;
    }
    if (to) {
      url = url + "&to=" + to;
    }
    return url;
  }

getEvaluations() {
  const options = [];
  let selected = this.state.evaluationType;
  if(!this.state.evaluationType){
    selected = HIT_EVALUATION_ALL
  }
  options.push({
    text: "Correct",
    value: HIT_EVALUATION_CORRECT,
    onClick: () => {
      logEvent("buttons", "Select Correct");
      this.evaluationOnClick( HIT_EVALUATION_CORRECT);
    }
  });
  options.push({
    text: "Incorrect",
    value: HIT_EVALUATION_INCORRECT,
    onClick: () => {
      logEvent("buttons", "Select InCorrect");
      this.evaluationOnClick(HIT_EVALUATION_INCORRECT);
    }
  });
  options.push({
    text: "Not Evaluated",
    value: HIT_EVALUATION_NE,
    onClick: () => {
      logEvent("buttons", "Select None");
      this.evaluationOnClick( HIT_EVALUATION_NE);
    }
  });
  options.push({
    text: "All",
    value: HIT_STATE_ALL,
    onClick: () => {
      logEvent("buttons", "Select All Evaluation");
      this.evaluationOnClick(HIT_EVALUATION_ALL );
    }
  });
  return (
    <div className="w-100">
      <label>EVALUATION STATUS</label>
        <Dropdown className="f_evaluation"
        compact
        value={selected}
        placeholder="Filter by Evaluation Status"
        selection
        options={options}
      />
    </div>
  );
}

resetFilterState(label, contributor, callback) {
  this.setState({
    hits: [],
    currentIndex: -1,
    currentImageIndex: 0,
    currentStart: 0,
    start: 0,
    currentHit: undefined,
    currentCount: this.state.DEFAULT_COUNT,
    hitsCompleted: false,
    changesInSession: 0,
    selecteddate: undefined,
    searchHitUrl: null,
    label: label,
    contributorId: contributor,
    fromHitList: false,
    loading: true
  }, callback ? callback :  null);
}

showExtra = extra => {
    const arrs = [];
    for (const k1 of Object.keys(extra)) {
      arrs.push(
        <Table.Row>
          <Table.Cell textAlign="left" className="h5 bold">
            {k1}
          </Table.Cell>
          <Table.Cell className="h6">{extra[k1]}</Table.Cell>
        </Table.Row>
      );
    }

    return (
      <Table
        celled
        color="blue"
        key="blue"
        size="small"
        compact="very"
        collapsing
      >
        <Table.Body>{arrs}</Table.Body>
      </Table>
    );
  };

getContributors(contributorDetails, selectedHits) {
  const options = [];
  let selected = this.state.contributer ? this.state.contributer : HIT_CONTRIBUTORS_ALL;
  if (contributorDetails) {
    for (
      let index = 0;
      index < contributorDetails.length && index < 50;
      index++
    ) {
      if (
        this.state.contributorId === contributorDetails[index].userDetails.uid
      ) {
        selected = contributorDetails[index].userDetails.uid;
      }
      options.push({
        text: contributorDetails[index].userDetails.firstName ? contributorDetails[index].userDetails.firstName : '',
        value: contributorDetails[index].userDetails.uid ? contributorDetails[index].userDetails.uid : '',
        image: {
          avatar: true,
          src: contributorDetails[index].userDetails.profilePic ? contributorDetails[index].userDetails.profilePic : ''
        },
        onClick: () => {
          logEvent("buttons", "Select Contributor");
          this.setState({ contributorName: contributorDetails[index].userDetails.firstName });
          this.resetFilterState(undefined, contributorDetails[index].userDetails.uid, this.filterCallBack.bind(this));
        }
      });
    }
    options.push({
      text: 'All',
      value: HIT_CONTRIBUTORS_ALL,
      onClick: () => {
        this.setState({ contributorName: undefined });
        this.resetFilterState(undefined, undefined, this.filterCallBack.bind(this));
      }
    });
    return (
      <div className="w-100">
        <label>CONTRIBUTORS</label>
        <Dropdown
        value={selected}
        placeholder="Filter by Contributor"
        selection
        options={options}
      />
      </div>
    );
  }
}

filterCallBack() {
    this.loadProjectDetailsFilter(this.props.currentProject);
  }

getEntities = (entities) => {
  const options = [];
  let selected = HIT_ENTITIES_ALL;
    if (entities) {
      for (let index = 0; index < entities.length; index++) {
        if (this.state.label === entities[index]) {
          selected = entities[index];
        }
        options.push({
          text: entities[index],
          value: entities[index],
          onClick: () => {
            this.resetFilterState(entities[index], undefined, this.filterCallBack.bind(this));
            logEvent("buttons", "Select Entity");
          }
        });
      }
      options.push({
        text: 'All',
        value: HIT_ENTITY_ALL,
        onClick: () => {
          this.resetFilterState(undefined, undefined, this.filterCallBack.bind(this));
        }
      });
      return (
        <div className="w-100">
          <label>ENTITY</label>
          <Dropdown
          value={selected}
          placeholder="Filter by Entity"
          selection
          options={options}
        />
        </div>
      );
    }
  }

setSelectedDate(selecteddate, cDate) {
  this.resetFilterState();
  let startDate = null;
  let endDate = null;
  this.clearFromDisable = false;
if (selecteddate === undefined) {
  this.clearFromDisable = true;
    startDate = undefined
       endDate = undefined
      this.state.selecteddate = undefined;
      this.state.dateFrom = startDate;
      this.state.dateTo = endDate;
} else {
     startDate = fromDateTimestamp(cDate)
     endDate = toDateTimestamp(cDate)
    this.state.selecteddate = selecteddate;
    this.state.dateFrom = startDate;
    this.state.dateTo = endDate;
}
  this.loadProjectDetails(this.props.currentProject);
}

onDateChange = (field, value) => {
	  this.resetFilterState();
	    let startDate = undefined;
	    let endDate = undefined;

	    if(field === 'startValue' && value !== null){
	    	startDate = value.toDate().getTime();
			this.state.dateFrom = startDate;
			this.state.dateTo = this.state.dateTo !== undefined ? this.state.dateTo : null;
	    }else if(field === 'endValue' && value !== null){
	    	endDate = value.toDate().getTime();
			this.state.dateTo = endDate;
			this.state.dateFrom = this.state.dateFrom !== undefined ? this.state.dateFrom : null;
	    }else if(field === 'startValue' && value === null){
	    	this.state.dateFrom = startDate;
	    }else if(field === 'endValue' && value === null){
	    	this.state.dateTo = endDate;
	    }else{
			    this.state.dateFrom = startDate;
			    this.state.dateTo = endDate;
	    }

	    this.loadProjectDetailsFilter(this.props.currentProject);
	  };

	onStartChange = value => {
		this.setState({
			startValue: value
		    });
		if(value === null){
			this.onDateChange('startValue', value);
		}
	  };

	onEndChange = value => {
		this.setState({
			endValue: value
		    });
		if(value === null){
			this.onDateChange('endValue', value);
		}
	};

	handleStartOK = value => {
		if(value === undefined){
			value = moment();
			this.setState({
				startValue: moment()
			    });
		}
		this.onDateChange('startValue', value);
	}

	handleEndOK = value => {
		if(value === undefined){
			value = moment();
			this.setState({
				endValue: moment()
			    });
		}
		this.onDateChange('endValue', value);
	}

	disabledStartDate = startValue => {
	    const { endValue } = this.state;
	    if (!startValue || !endValue) {
	      return false;
	    }
	    return startValue.valueOf() > endValue.valueOf();
	  };

	  disabledEndDate = endValue => {
	    const { startValue } = this.state;
	    if (!endValue || !startValue) {
	      return false;
	    }
	    return endValue.valueOf() <= startValue.valueOf();
	  };

handleClick = (event, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

showTaggingInstructions() {
  const { activeIndex } = this.state;
  return (
    <div className="project_nameinfo">
      <div className="proj_sec">
        <label>Project Guidelines</label>
        <p className="file_wrap" title={this.state.rules.instructions}>{this.state.rules.instructions}</p>
      </div>
    </div>
  );
}

getHitInfo(hit) {
  if (hit) {
    const fileName = hit.fileName;
    const { styles } = helper;
    let status = hit.status
    if (hit.status === 'reQueued') { status = hit.status }
    else if (hit.result && JSON.parse(hit.result).length === 0) { status = 'notDone' }
    else if (hit.result && JSON.parse(hit.result).labels && JSON.parse(hit.result).labels.length === 0) { status = 'notDone' }
    else { status = hit.status }
    return (
      <div style={{ display: "flex"}}>
        <div className="project_nameinfo">
          <div className="proj_sec">
            {fileName && <label>File Name</label>}
            <p style={styles.fSize} className="file_wrap" title={fileName}>{fileName.replaceAll("__-__-"," ")}</p>
          </div>
        </div>
        <div className="project_nameinfo">
          <div className="proj_sec">
            {status && <label style={{ textTransform: 'capitalize' }}> HIT status </label>}
            <p style={styles.fSize}>{status === "notDone" ? hitStateNameMap["pending"] : hitStateNameMap[status] }</p>
          </div>
        </div>
        </div>
    );
  }
}

redirectCompletedStatusClick(orgName, projectName) {
  this.setState({ start: 0, count: 10, fromLeaderBoard: false, currentStart: 0}, () => {
    this.props.pushState(
      "/projects/" +
        orgName +
        "/" +
        projectName +
        "/" +
      this.props.params.projectId +
        "/view?type=done"
    )
  });
}

downloadCallback = (error, response) => {
  if (!error) {
    this.setState({ loading: false });
   const blob = response.xhr.response;
    if (this.props.projectDetails) {
        if (this.state.downloadFormat === 'json') {
          FileSaver.saveAs(blob, this.props.projectDetails.name + '.json');
        } else if (this.state.downloadFormat === 'stanford') {
          FileSaver.saveAs(blob, this.props.projectDetails.name + '.tsv');
        } else {
          FileSaver.saveAs(blob, this.props.projectDetails.name + '.txt');
        }
    } else {
      FileSaver.saveAs(blob, this.props.projectDetails.name);
    }
  } else {
    showAlert(response.body.message, 'info');
  }
}
downloadFilterResult() {
  let param = null;
  if(this.state.type === HIT_STATE_ALL){
  param = '&filterDownload=' + false;
  } else{
    param = '&filterDownload=' + true;
  }
    this.setState({ downloadFormat: 'json' });
    this.setState({ itemOption: 'ALL', loading: true });
    if (this.state.type && this.state.type !== null)
      param += "&type=" + this.state.type;
    if (this.state.evaluationType && this.state.evaluationType !== null)
      param += "&evaluationType=" + this.state.evaluationType;
    if (this.state.contributorId && this.state.contributorId !== null)
      param += "&userId=" + this.state.contributorId;
    if (this.state.label && this.state.label !== null)
      param += "&label=" + this.state.label;
    if (this.state.dateFrom && this.state.dateFrom !== null)
      param += "&fromDate="  + this.state.dateFrom;
    if (this.state.dateTo && this.state.dateTo !== null)
      param += "&toDate=" + this.state.dateTo;

    downloadfile(this.props.currentProject, this.state.itemOption, this.downloadCallback, this.state.downloadFormat, param);
}
handleDownloadOk = () => {
  this.setState({
    confirmDownloadModalVisible: false,
  });
  this.downloadFilterResult();
};

handleDownloadCancel = () => {
  this.setState({
    confirmDownloadModalVisible: false,
  });
};
summaryContent() {
  let fromDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.state.dateFrom);
  let toDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.state.dateTo);
  return (
    <div>
        <ul className="m_summary">
        {(this.state.type && this.state.type !== null) && (
          <li>
            <label>{"Hits Status"}</label>
            <span>{this.state.type}</span>
          </li>
          )}
          {(this.state.evaluationType && this.state.evaluationType !== null) && (
            <li>
            <label>{"Evaluation Status"}</label>
            <span>{this.state.evaluationType}</span>
          </li>
          )}
          {(this.state.contributorId && this.state.contributorId !== null) && (
            <li>
            <label>{"Contributor"}</label>
            <span>{this.state.contributorName}</span>
          </li>
          )}
          {(this.state.label && this.state.label !== null) && (
            <li>
            <label>{"Entity"}</label>
            <span>{this.state.label}</span>
          </li>
          )}
          {(this.state.dateFrom && this.state.dateFrom !== null && this.state.dateTo && this.state.dateTo !== null) && (
            <li>
            <label>{"Date"}</label>
            <span>{ fromDate + ' to ' + toDate }</span>
          </li>
          )}
          {(this.state.hitsNotAnnotatedCount !== null) && (
              <li>
              <label>{"Pages without annotation"}</label>
              <span>{this.state.hitsNotAnnotatedCount}</span>
            </li>
            )}
          {(this.state.totalFilteredHits !== null) && (
            <li>
            <label>Number of Files</label>
            <span>{this.state.totalFilteredHits}</span>
          </li>
          )}
        </ul>
    </div>
  );
}
showSummaryPage() {
  return (
    <div className="text-center">
      {this.state.confirmDownloadModalVisible && (
        <LocaleProvider locale={en_US}>
          <AntModal className="s_model"
            title="Summary"
            visible={this.state.confirmDownloadModalVisible}
            onOk={this.handleDownloadOk}
            onCancel={this.handleDownloadCancel}
            footer={[
              <div className="txt-center">
                <Button className="b-r-20" key="submit" type="primary" onClick={this.handleDownloadOk}> <AntIcon type="download" className="m-r-5" />
                Download
              </Button>
              </div>
              ]}
          >
            {this.summaryContent()}
          </AntModal>
        </LocaleProvider>
      )}
    </div>
  );
}


getUsersFromTags = (text) => {
  let displayText = _clone(text)
  const tags = text.match(/@\{\{[^\}]+\}\}/gi) || []
  const allUserIds = tags.map(myTag => {
    const tagData = myTag.slice(3, -2)
    const tagDataArray = tagData.split('||')
    return {_id: tagDataArray[1], name: tagDataArray[2]}
  })
  return _uniqBy(allUserIds, myUser => myUser._id)
}

swapTags = (text) => {
  let displayText = _clone(text)
  const tags = text.match(/@\{\{[^\}]+\}\}/gi) || []
  tags.map(myTag => {
    const tagData = myTag.slice(3, -2)
    const tagDataArray = tagData.split('||')
    const tagDisplayValue = tagDataArray[2]
    displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), 'gi'), tagDisplayValue)
  })
  return displayText
}

projectDetailFetched(error, response) {
  console.log(" project details fetched ", error, response);
  if (!error) {
    
    const userlist = response.body.contributorDetails;
  
  } else {
    const userId = window.sessionStorage.getItem("uid")
    if (this.props.user) {
      showAlert(`invalid url - ${response.body.message}.`, 'error');
    }
    if (response && response.body && response.body.message) {
      this.props.setloaderMenu(false);
      this.setState({ loading: false, projectDetailsError: response.body.message });
    } else {
      this.props.setloaderMenu(false);
      this.setState({ loading: false, projectDetailsError: "Error in fetching data" });
    }
    if (this.props.user) {
      this.props.pushState('/workspace');
      saveactiveTab("home");
    } else if (!userId) {
      this.props.pushState('/projects/login');
    }
  }
}

getComments = () => {  
  const userMentionData = this.props.projectDetails.contributorDetails.map(myUser => ({
    id: myUser.userDetails.uid,
    display: `${myUser.userDetails.firstName} ${myUser.userDetails.secondName}`
  }))
  return (
    <div className="CommentSec">
      <label style={{color : "white"}}>Comments</label>
       <MentionsInput
          value={this.state.comments}
          onChange={this.handleChange}
          markup="@{{__type__||__id__||__display__}}"
          placeholder="Write your comments here..."
          className="mentions"
        >
          <Mention
            type="user"
            trigger="@"
            data={userMentionData}
            className="mentions__mention"
          />         
        </MentionsInput>
        <Button className="btn_base btn_correct" onClick={() => this.saveComments()} > Save  </Button>
        
    </div>
  )
}

handleChange = (event, newValue, newPlainTextValue, mentions) => {
  console.log(newValue, newPlainTextValue, mentions)
  this.setState({
    comments: newValue,
    mentionData: {newValue, newPlainTextValue, mentions}
  })
}


getCommentDetails = () => {
  const { currentHit } = this.state;
  getComments(currentHit.id, this.getCommentDetailsCallBack.bind(this))
}
getCommentDetailsCallBack = (err, res) => {
  console.log(res);
  if (!err) {
    let arrayItems = [];
    if (res.body && res.body.length > 0) {
      let response = res.body;

      response.map((comment) => {
        arrayItems.push(
          <div>
            <span>{comment.commentText}</span>
            {comment.handleAction &&
              <span>DELETE</span>
            }
          </div>
        )
      });
      return (
        <div>{arrayItems}</div>
      )
    }
  }
}

saveComments = () => {
  const { currentHit } = this.state;
  if (!this.state.comments || this.state.comments.length === 0) {
    showAlert("Please enter the comments", 'error');
    return false;
  }
  let data = {};
  data.comments = this.state.comments;  
  data.displayText = this.swapTags(this.state.comments);
  const usersList = this.getUsersFromTags(this.state.comments);
  data.mentionedUsers = JSON.stringify(usersList);
 
  this.props.setloaderMenu(true);
  saveComments(this.props.currentProject, currentHit.id, data, this.saveCommentCallBack.bind(this));
}

editComments = () => {
  this.setState({ editCommentsButtonEnable: false })
}

handleCommentsChange = (event) => {
  this.setState({ comments: event.target.value })
}

saveCommentCallBack(err, res) {
  this.props.setloaderMenu(false);
  if (!err) {
    if (res.body && res.body.length > 0) {
      let hitsList = [];
      this.state.hits.map((hit) => {
        if (hit.id === this.state.currentHit.id) {
          hit.comments = res.body;
        }
        hitsList.push(hit);
      });
      this.state.currentHit.comments = res.body;
      this.setState({ editCommentsButtonEnable: true, hits: hitsList, comments: '' });
    }
    showAlert(COMMENTS_SAVED_SUCCESS, SUCCESS);
  }
}

deleteComments = (id) => {
  const { currentHit } = this.state;
  this.props.setloaderMenu(true);
  deleteComments(currentHit.id, id, this.deleteCommentCallBack.bind(this));
}

updateComments = (id, text) => {
  const { currentHit } = this.state;
  let data = {};
  data.comments = text;
  data.displayText = this.swapTags(text);
  const usersList = this.getUsersFromTags(text);

 data.mentionedUsers = JSON.stringify(usersList);
  this.props.setloaderMenu(true);
  updateComments(currentHit.id, id,  data, this.updateCommentCallBack.bind(this));
}

commentAction = (res, type) => {
  let hitsList = [];
  this.state.hits.map((hit) => {
    if (hit.id === this.state.currentHit.id) {
      hit.comments = res.body;
    }
    hitsList.push(hit);
  });
  this.state.currentHit.comments = res.body;
  this.setState({ hits: hitsList, comments: '' });
  if (type === "update")
    showAlert(COMMENTS_UPDATED_SUCCESS, SUCCESS);
  else
    showAlert(COMMENTS_DELETED_SUCCESS, SUCCESS);
    this.props.setloaderMenu(false);

}

deleteCommentCallBack = (err, res) => {
  if (!err) {
    this.commentAction(res, "delete");
  }
}

updateCommentCallBack = (err, res) => {
  if (!err) {
    this.commentAction(res, "update");
  }
}

closeComments = () => {
  this.setState({comments: ''})
}

changesInSessionUpdate = () =>{
  this.setState({changesInSession : this.state.changesInSession + 1 })
}

  autosaveforUndoRedo = () => {
    if(!utilities.toolDataCompleted(this)){
      this.changesInSessionUpdate()
    }
  }

  downloadImage = () => {
    showAlert('download image', 'info');
  }

  ruler = (direction) => {

    if(direction === 'unidirection'){
      utilities.toggleManager(this, 7);
      const LengthTool = cornerstoneTools.LengthTool;
      cornerstoneTools.addTool(LengthTool)
      cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 })
      this.setState({tooltype: 'Length'})
    }
    else{
      utilities.toggleManager(this, 8);
      const BidirectionalTool = cornerstoneTools.BidirectionalTool;
      cornerstoneTools.addTool(BidirectionalTool)
      cornerstoneTools.setToolActive('Bidirectional', { mouseButtonMask: 1 })
      this.setState({tooltype: 'Bidirectional'})
    }
  }

  render() {


    const showCommentsDetails = () => {
      const { currentHit } = this.state;
      let arrayItems = [];
      currentHit.comments && currentHit.comments.map((comment) => {
        arrayItems.push(
          <CommentComponents
           comment={comment}
            deleteComments={this.deleteComments}
            updateComments={this.updateComments}
            closeComments={this.closeComments}
            contributionDetails={this.props.projectDetails.contributorDetails}
          />
        )
      })
      return (
        <div>
          {arrayItems && arrayItems.length > 0 &&
          <div style={{padding:"50px"}}>
            <label>Comments</label>
            <div className="comment_section">
              {
                arrayItems
              }
            </div>
          </div>
          }
        </div>
      )
    }


    let pageTitle = this.props.params.projectName;
    const userRoleId = getUidToken().roleId;
    let pageDetails = '';
    const { styles } = helper;
    if (this.state.totalFilteredHits)
      pageDetails = pageDetails.concat("( ", this.state.currentIndex + 1, " of ", this.state.totalFilteredHits, " )");
    if (this.state.hits && this.state.hitsCompleted)
      return (
                  <div className="redirect_btn">
                    {this.state.type === "notDone" && (
                      <Segment color="green" className="marginTopExtra" style={styles.mBase}>
                        {this.state.type &&
                          this.state.type !== "skipped" && (
                            <h4 className="m-b-20">
                              {" "}
                              All rows are tagged, You can check the project
                              stats.{" "}
                            </h4>
                          )}
                        {!this.state.type && (
                          <h4 className="m-b-20">
                            {" "}
                            All items are tagged, You can check the project
                            stats.{" "}
                          </h4>
                        )}
                        <div className="redirect_btn">
                        <Button
                          onClick={() =>
                            this.props.pushState(
                              "/projects/" +
                                this.props.params.orgName +
                                "/" +
                                this.props.params.projectName +
                                "/" +
                                this.props.params.projectId
                            )
                          }
                        >
                          Show Project Stats
                        </Button>
                        <Button
                          onClick={() =>
                            this.redirectCompletedStatusClick(this.props.params.orgName, this.props.params.projectName)
                          }
                        >
                          Show Completed HITs
                        </Button>
                        </div>
                      </Segment>
                    )}
                  </div>
                );


    // If Completed Hits in All Images  END

    let canvasStyle;
    if (this.state.isFullScreen) {
      canvasStyle = {
        width: 512,
        height: 512,
        top: "187px",
        left: "414px",
        position: "absolute"
      };
    } else {
      canvasStyle = {
        width: 512,
        height: 512,
        top: 0,
        left: 0,
        position: "absolute"
      };
    }

    const changeImageProp = (name, event) => {
      if (name === 'scale') {
        this.zoom(250, 250, this._panner.scale - event.target.value);
      } else {
        this.setState({ [name]: event.target.value });
      }
    };

    let classes = "content-container noselect";
    if (this.state.disableZoomAnim) {
      classes = "content-container noselect leaflet-zoom-anim";
    }
    let classes2 = "no-flickr leaflet-zoom-animated";
    if (this.state.disableZoomAnim) {
      classes2 = "no-flickr leaflet-zoom-animated1";
    }

    let windowHeight = (window.innerHeight * 75) / 100;
    let windowWidth = (window.innerWidth * 80) / 100;
    if (!this.props.space) {
      windowHeight = (window.innerHeight * 70) / 100;
      windowWidth = (window.innerWidth * 60) / 100;
    }
    if (this.props.fullScreen) {
      windowHeight = (window.innerHeight * 95) / 100;
    }
    if (this.state.toolbarHidden && this.props.fullScreen) {
      windowWidth = (window.innerWidth * 80) / 100;
    }

    const onImgLoad = ({target: img}) => {
      let imgWidth = img.offsetWidth;
      let imgHeight = img.offsetHeight;

      this._panner = new Panner({
        screenWidth: imgWidth,
        screenHeight: imgHeight
      });
      this.setState({imgHeight, imgWidth, imageNaturalWidth: img.naturalWidth, imageNaturalHeight: img.naturalHeight, imgObject: img, imgLoad: true});
    };

    // -------------Label List Functions Start----------------------
   let drawRegions = []
   if(this.state.rulerdrawregions.length > 0 ) {
    this.state.rulerdrawregions.map((value)=>{
      drawRegions.push(value)
    })
   }
   if(this.state.drawregions.length > 0 ) {
    this.state.drawregions.map((value)=>{
      drawRegions.push(value)
    })
   }

   var listItems = drawRegions.map((item, index) => {
    item.index = index + 1;
    return (
      <AnnotationList
          key={index}
          item={item}
          index={index}
          images={this.state.images}
          currentImageIndex={this.state.currentImageIndex}
          callActive={this.callActive}
          getlabelValue={utilities.getlabelValue(this, index)}
          editLabel={this.editLabel}
          changeAnnoColor={this.changeAnnoColor}
          getAnnoIndex={this.getAnnoIndex}
      	  removeSelected = {this.removeSelected}
      	  entityColorMap = {this.state.entityColorMap}
          changesInSessionUpdate = {this.changesInSessionUpdate}>
      </AnnotationList>
    );
});

      return (
        <div>
        <Helmet title={pageTitle}/>
          {this.props.projectDetails && (
        		  <div>
        		  <div className="filter_fix" style={styles.filterF}>
                  {this.state.type !== HIT_STATE_NOT_DONE && (
                		  <div className="project_title f-column t_titleback">
                          <div className="f-center">
                          <a href="#" onClick={this.redirectBackImage.bind(this)}>
                          <span className="btn_back">
                              <img src={BACK} />
                            </span>
                              </a>
                          </div>
                          <div className="breadcrum-sec">
                        <div className="sec_holder">
                        <a href="#" className="tilte_display fw-400" onClick={this.redirectBackImage.bind(this)}> {this.state.projectDetails ? this.state.projectDetails.name:"Dashboard"} </a>
                        {breadcrumbSeperate()}
                        {hitsBreadCrumbTitle(this.state.displayScreenType)}&nbsp; {pageDetails}
                      </div>
                      </div>
                        </div>
                        )} 
                        {this.state.type !== HIT_STATE_NOT_DONE && (
                          <div className="project_set medical_view">
                            {this.showSummaryPage()}
                            {(this.state.type !== HIT_STATE_ALL || this.state.type === HIT_STATE_ALL) && 
                            <ul className="tools" style={styles.dFlex}>
                            {this.state.hits.length > 0 &&
                                <li>
                            <button className="downloadIcon" title="Download" onClick={() => { this.setState({ confirmDownloadModalVisible: true });}}>
                              <AntIcon type="download" />
                            </button>
                              	</li>
                            }
                              <li>
                                <button className="h_filtericon" title="Filter" onClick={this.toggleMenu}>
                                    <img alt="filter" src={FILTER} />
                                </button>
                              </li>
                            </ul>
                             } 
                          </div>
                          )}

                          { /* FILTERS TAB */}
                          {this.state.type !== HIT_STATE_NOT_DONE && this.state.visible && (
                            <div className="hits_filter" >
                              <div className="dropBox_set">
                                <div className="f_between">
                                  <h3 className="sec_tilte"><span>Filter By</span></h3>
                                  <AntIcon className="f_close" type="close" onClick={this.toggleMenu}/>
                                </div>
                                {this.state.fromLeaderBoard === false && (this.props.projectDetails.task_type !== POS_TAGGING) && (
                                  <div className="custom_radius">
                                    <div className="hold_sec">
                                      <label>HITs</label>
                                      {this.getHitStates()}
                                    </div>
                                    <div className="hold_sec">
                                      {this.state.type === HIT_STATE_DONE &&
                                        this.getEvaluations()
                                      }
                                    </div>
                                    <div className="hold_sec">
                                      {this.state.type === HIT_STATE_DONE &&
                                      this.getContributors(this.state.contributorDetails, this.props.selectedHitsDetails)}
                                    </div>
                                    <div className="hold_sec">
                                      {this.state.type === HIT_STATE_DONE &&
                                      this.state.entities &&
                                      this.state.entities.length > 0 &&
                                      this.getEntities(this.state.entities)
                                      }
                                    </div>
                                  </div>
                                )}
                                <div className="hold_sec">
                                  {this.state.type === HIT_STATE_DONE
                                    && (
                                    <div className="input-uniform">
                                      <label>DATE</label>
          						<div className="f_datesec">
          							<LocaleProvider locale={en_US}>
          							<DatePicker
          							disabledDate={this.disabledStartDate}
          							showTime
          							format="YYYY-MM-DD HH:mm:ss"
          							value={this.state.startValue}
          							placeholder="Start"
          							onChange={this.onStartChange}
          							onOk = {this.handleStartOK}
          							/>
          							</LocaleProvider>
          							<LocaleProvider locale={en_US}>
          							<DatePicker
          							disabledDate={this.disabledEndDate}
          							showTime
          							format="YYYY-MM-DD HH:mm:ss"
          							value={this.state.endValue}
          							placeholder="End"
          							onChange={this.onEndChange}
          							onOk = {this.handleEndOK}
          							/>
          							</LocaleProvider>
          							</div>
          							</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                  </div>

            <div style={styles.flexR} className="m-t-10">
              { /* HITS OVERVIEW TAB */ }
              <div className={"seg_w80 " + ((this.state.type === HIT_STATE_NOT_DONE || this.state.hits.length === 0) ? 'w-100' : 'w-80')}>
                <div
                  style={styles.fBetween}
                >
                  <div style={styles.fWrap}>
                      <div style={styles.dFlex}>
                      {this.state.type === HIT_STATE_NOT_DONE &&
                      <div style={styles.flexC}>
                      {this.state.type === HIT_STATE_NOT_DONE  && (
                        <div>
                          <Button
                            icon
                            title="Back"
                            className="pull-left back_btn"
                            onClick={this.redirectBackImage.bind(this)}
                            compact
                          >
                            <Icon name="arrow left" />
                          </Button>
                          <Button
                            icon
                            title="Home"
                            className="pull-left back_btn"
                            onClick={() => this.routeToHome()}
                            compact
                          >
                            <Icon size="large" name="home" />
                          </Button>
                        </div>
                      )}

                        {(this.state.type === HIT_STATE_NOT_DONE) &&
                                <div className="">{this.showTaggingInstructions()}</div>
                        }
                      </div>
                  }
                  {this.state.type === HIT_STATE_NOT_DONE && this.state.currentHit &&
                            	 <div style={styles.flexC}>
                            	 {this.getHitInfo(this.state.currentHit)}
                            	 </div>
                            }
                      </div>


                  </div>

                </div>
                {this.state.type === HIT_STATE_NOT_DONE &&
                <br />
                }

                { /* CONTENT TAB */}
                {!this.state.loading && this.props.projectDetails && (this.state.hits.length === 0 || (this.state.hits && this.state.hits[0] === undefined) ||
                        (this.state.fromLeaderBoard === false && this.state.hitsCompleted) || this.state.images.length === 0) && (
                  <div className="text-center">
                    <h2> No items to display here </h2>
                    </div>
                )}
                {this.state.hits &&
                  this.state.currentHit && (
                    <div id="targetdiv"
                      style={styles.divContainer}
                      loading={this.state.loading}
                    >
                      {this.props.projectDetails.task_type ===
                        DICOM_SEGMENTATION && (
                          <div className="pr_medical_annotate">
                            <div className="m_header">
                              <div className="m_control">
                                <label className="range_label m-r-10">
                                  <span>Contrast : <b> {Math.round(this.state.contrast * 100)}%</b> </span>
                                  <input type="range" className="ui range range_slider" step="0.01" min="0" max="10" value={this.state.contrast} onChange={changeImageProp.bind(this, 'contrast')} />
                                </label>
                                <label className="range_label m-r-10">
                                  <span>Saturation : <b> {Math.round(this.state.saturation * 100)}% </b> </span>
                                  <input type="range" className="range_slider" step="0.01" min="0" max="4" value={this.state.saturation} onChange={changeImageProp.bind(this, 'saturation')}/>
                                </label>
                                <label className="range_label m-r-10">
                                  <span>Brightness : <b> {Math.round(this.state.brightness * 100)}% </b></span>
                                  <input type="range" className="range_slider" step="0.01" min="0" max="4" value={this.state.brightness} onChange={changeImageProp.bind(this, 'brightness')} />
                                </label>
                                { this._panner && this._panner.scale &&
                                  <label className="range_label m-r-10">
                                  <span>Zoom : <b> {Math.round(this._panner.scale * 100)}%</b> </span>

                                  <input type="range" className="range_slider" step="0.01" min="0" max="20" value={this._panner.scale} onChange={changeImageProp.bind(this, 'scale')} />
                                  </label>
                                }
                                <label className="range_label">
                                  <span>Box Opacity : <b> {Math.round(this.state.opacity * 100)}%</b> </span>
                                  <input type="range" className="ui range range_slider" step="0.01" min="0" max="1" value={this.state.opacity} onChange={changeImageProp.bind(this, 'opacity')} />
                                </label>
                              </div>
                              <div style={styles.flexC}>
                              {this.state.type === HIT_STATE_NOT_DONE && this.showButtons()}
                              <div title="Show Entities">
                              <span style={styles.mCenter}>
                              <Icon name="bars" style={styles.tMenu} onClick={this.toggleLeftMenu} />
                              </span>
                              </div>
                              </div>
                            </div>
                            <div className="m_holder">
                            {(this.props.projectDetails.status !== null && this.props.projectDetails.status === "NONE") &&
                              <div className="m_left">
                                  <SidebarTools
                                    changeStyle={styles}
                                    layoutSelect={this.layoutSelect}
                                    isContourSelected={this.isContourSelected}
                                    isBubbleSelected={this.isBubbleSelected}
                                    isInvertSelected={this.isInvertSelected}
                                    isClearSelected={this.isClearSelected}
                                    isClearAllSelected={this.isClearAllSelected}
                                    isUnidirectionSelected = {this.isUnidirectionSelected}
                                    isBidirectionSelected = {this.isBidirectionSelected}
                                    goFullScreen={this.goFullScreen}
                                    callUndo={this.callUndo}
                                    callRedo={this.callRedo}
                                    imageRotate={this.imageRotate}
                                    copy={this.copy}
                                    paste={this.paste}
                                    erase={this.erase}
                                    invert={this.invert}
                                    Resize={this.Resize}
                                    flip={this.flip}
                                    interpolation={this.interpolation}
                                    enableTool={this.enableTool}
                                    disableAllTools={this.disableAllTools}
                                    clearAnnotations={this.clearAnnotations}
                                    clearAllAnnotations={this.clearAllAnnotations}
                                    chooseAnnoColor={this.chooseAnnoColor}
                                    ruler={this.ruler}>
                                  </SidebarTools>
                              </div>
                            }
                              <div className={"m_right pr_h_filter " + (this.state.type === HIT_STATE_NOT_DONE ? 'w-100' : 'w-97')}>
                                <div className="seg_new w-100" id={this.state.type === HIT_STATE_NOT_DONE ? '' : 'box_fix'}>
                                    <div ref={(parentDiv) => this.parentDiv = parentDiv} className={"test_b " + ((this.props.projectDetails.status !== null && this.props.projectDetails.status === "COMPLETED") ? 'card_disabled' : '')} style={styles.linebHeight}>
                                        <div ref={(parentDiv) => this.parentDiv2 = parentDiv} className="pan-zoom-element" style={styles.zoomPan}>
                                          <div ref={(parentDiv) => this.parentDiv3 = parentDiv} className={classes} style={styles.posR}>
                                              <div
                                                style={this.state.drag}
                                                onContextMenu={this.contextMenu}
                                                className="cornerstone-enabled-image"
                                                unselectable="on"
                                              >
                                                <Fullscreen
                                                  enabled={this.state.isFullScreen}
                                                  onChange={isFullScreen => this.setState({ isFullScreen })}
                                                >

                                                  <div style={{width:'512px',height:'512px',position:'relative',
                                                        color: 'white',display:'inline-block',borderStyle:'solid',borderColor:'black',
                                                        filter: `contrast(${this.state.contrast})
                                                            brightness(${this.state.brightness})
                                                            saturate(${this.state.saturation})`,
                                                            transform: `translate3d(${this.state.translate.x}px, ${this.state.translate.y}px, 0px) scale(1)`}}
                                                    >
                                                      <div  ref={this.dicomImageRef}
                                                      onMouseUp={() => {
                                                        this.callHistory();
                                                      }}
                                                          style={{width:'512px',height:'512px',top:'0px',left:'0px',position:'absolute'}}>
                                                      </div>
                                                  </div>
                                                </Fullscreen>
                                              </div>

                                          </div>
                                        </div>
                                    </div>
                                    <div className={"test_b " + ((this.props.projectDetails.status !== null && this.props.projectDetails.status === "COMPLETED") ? 'card_disabled' : '')} style={styles.cardB}>
                                        <div style={styles.boxWidth}>
                                            <div style={styles.boxSize}>
                                                    {listItems}
                                                </div>
                                          </div>
                                    </div>

                                    <Modal
                               visible={this.state.open}
                               width="400"
                               effect="fadeInUp"
                               onClickAway={() => this.handleDialogClose} style={styles.modalHeight}>
                               <div
                                 align="center"
                                 style={styles.hPadding}
                               >
                                 <h1>Annotation Label</h1>
                                 <p className="m-t-5 m-b-10">Please enter your Annotation Label.</p>
                                   <div>
                                   <AutoComplete className="m_input"
                                   dataSource={this.state.entities}
                                   placeholder="Label"
                                   value ={this.state.textValue}
                                   onSelect={this.setTextValue}
                                   onSearch={this.setTextValue}
                                   dropdownClassName="d_labeling"
                                   style={styles.dLabel}
                                   filterOption={(inputValue, option) =>
                                     option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                   }
                                 />
                                   </div>
                                   {this.state.errorMessage &&
                                	   <label style={styles.sLabel}>* Label Name Required</label>
                                   }

                                   <div className="model_submit m-b-10">
                                   <Button className="btn_cancel" onClick={this.handleDialogClose}>Cancel</Button>
                                   <Button className="btn_apply"onClick={this.handleDialogClickEvent}> Apply</Button>
                                   </div>
                               </div>
                               </Modal>
                                </div>
                                {this.state.sideVisible &&
                                <div style={styles.rightBox} className="w-20" style = {{display: displaySettingsByRole(getUidToken().roleId)}} disabled={(parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId)  === ROLES_TYPES.QA) ? true : false}>
                                  {(this.state.entities && this.props.projectDetails.status !== null && this.props.projectDetails.status === "NONE") &&
                                    <div style={styles.rHolder}>
                                      <p style={styles.pTextColor}>
                                         Select Entity
                                      </p>
                                      {this.getMenuItems()}
                                      <div>{this.state.currentImgIndex}</div>
                                    </div>
                                   }
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                      )}
                      <br />
                    </div>
                )}
              </div>
              {this.state.hits.length > 0 && this.state.type !== HIT_STATE_NOT_DONE && this.props.projectDetails.task_type !== POS_TAGGING && (
                <Segment raised style={styles.segRaised}>
                  <div className="pr_hviews">
                  <div className="r_box m-b-10">
                          <h4 className="sec_tilte">
                            <span>HITs Overview</span>
                          </h4>
                          {this.showButtonsMini()}
                  </div>
                  <div style={styles.bPadding}>
                  {(this.state.currentHit && this.state.currentHit.status === "done") &&
                  this.getHitDetails(this.state.currentHit)}

                  {(parseInt(getUidToken().roleId) === ROLES_TYPES.ADMIN || parseInt(getUidToken().roleId) === ROLES_TYPES.GUEST || parseInt(getUidToken().roleId) === ROLES_TYPES.QA) && ((this.state.type === HIT_STATE_DONE || this.state.type === HIT_STATE_ALL) && this.props.projectDetails && this.props.projectDetails.status === "NONE"
                   && this.state.currentHit && this.state.currentHit.status === "done") && (
                    <div className="sec_bottom m-t-10" >
                    <h3>Evaluate Tagging</h3>
                        {this.showEvaluationButtons()}
                    </div>
                  )}
                  {this.state.currentHit && this.state.currentHit.status === "done" && this.props.projectDetails.contributorDetails && this.getComments()}
                  </div>
                  </div>
                </Segment>
              )}
            </div>
            </div>
          )}
          {this.state.currentHit && this.state.currentIndex >= 0 && showCommentsDetails()}
        </div>

    );
  }
}

const GetMeasurementData = toolDataItem => {
  toolDataItem.invalidated = true;
  return toolDataItem;
};


const mapStateToProps = (state) => {
  return {
    currentProject: state.projectReducer.currentProject,
    projectDetails: state.projectReducer.projectDetails,
    currentHit: state.projectReducer.currentHit,
    menuHidden: state.projectReducer.menuHidden,
    selectedHitsDetails: state.projectReducer.selectedHitsDetails,
    prevPageDetails: state.projectReducer.prevPageDetails

  };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        getProjectDetails,
        goBack,
        setCurrentProject,
        setCurrentHit,
        updateProjectDetails,
        updatePrevPageDeatails,
        pushState: push,
        downloadfile,
        setloaderMenu
      },
      dispatch
    );

export default connect(
    mapStateToProps,
     mapDispatchToProps
    )(TaggerViewer);