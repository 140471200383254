// Sample Data
import React from 'react';
import {
    VIDEO_BOUNDING_BOX,
    POS_TAGGING_GENERIC,
    VIDEO_CLASSIFICATION,
    IMAGE_CLASSIFICATION,
    DOCUMENT_ANNOTATION,
    IMAGE_POLYGON_BOUNDING_BOX,
    IMAGE_POLYGON_BOUNDING_BOX_V2,
    IMAGE_SEGMENTATION,
    IMAGE_MASKING,
    IMAGE_BOUNDING_BOX,
    TEXT_CLASSIFICATION,
    POS_TAGGING,
    TEXT_MODERATION,
    TEXT_SUMMARIZATION,
  } from "../../helpers/Utils";
  import WCHART from '../../images/Chart-7-1.svg';
  import STATUS from '../../images/sample_data.svg';
  import ALEFT from '../../images/left.svg';
  import ARIGHT from '../../images/right.svg';
class SampleData extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  render() {
    const {
        propsState,propsData,projectDetails,hitsDetails,extra,
        entities,showExtra,showTags,showPolygonV2Images,nextElement,previousElement
        ,showSummaries,showClassifications,showPosTags,showBoundedImages,showPolygonImages,showVideoAnnotation,
        showClassificationImages,showDocs
    } = this.props
    console.log("sample data props",projectDetails,hitsDetails)
    console.log("props for sample data",propsState.start);

    return (
      <div>
        { projectDetails &&
                hitsDetails &&
                hitsDetails.length >= 0 &&
                (projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 ||
                  projectDetails.task_type === IMAGE_SEGMENTATION ||
                  projectDetails.task_type === IMAGE_MASKING) && (
                  <div className="p_sampleview ">
                    <div className="p_title">
                      <img alt="status" src={STATUS} />
                      <h3>Sample Data</h3>
                    </div>
                    {propsState.start < 0 ||
                    propsState.start <= propsState.hitsDetails.length - 1 ? (
                      <div className="p_det_box">
                        <div className="ent_title">
                          <h3 className="sec_tilte">
                            <span>Entities</span>
                          </h3>
                          <div className="m_action_btn">
                            <button
                              className={
                                "action_btn m-r-10 " +
                                (propsState.start === 0 ? "eventnone" : "")
                              }
                              tilte="Previous"
                            onClick={previousElement.bind(this,"pervious")}
                            disabled={propsState.start === 0}
                            >
                              <img alt="left" src={ALEFT} />
                            </button>
                            <button
                              className={
                                "action_btn " +
                                (propsState.start < 0 ||
                                propsState.start >=
                                  propsState.hitsDetails.length - 1
                                  ? "eventnone"
                                  : "")
                              }
                              tilte="Next"
                              onClick={nextElement.bind(this, "right")}
                              disabled={
                                propsState.start < 0 ||
                                propsState.start >=
                                  propsState.hitsDetails.length - 1
                              }
                            >
                              <img alt="right" src={ARIGHT} />
                            </button>
                          </div>
                        </div>
                        <div className="entity_sec">
                          {showTags(entities)}
                          {extra && <div>{showExtra(extra)}</div>}
                        </div>
                        {showPolygonV2Images(hitsDetails)}
                      </div>
                    ) : (
                      <div className="p_det_box">
                        {" "}
                        <div className="ent_title">
                          <h3 className="sec_tilte">
                            <span>Entities</span>
                          </h3>
                        </div>
                        <div className="entity_sec">
                          {showTags(entities)}
                          {extra && <div>{showExtra(extra)}</div>}
                        </div>
                        <div>
                          <h4>No Sample Hits</h4>
                        </div>
                      </div>
                    )}
                  </div>
                )}

        {projectDetails &&
          hitsDetails &&
          hitsDetails.length > 0 &&
          projectDetails.task_type === TEXT_SUMMARIZATION && (
            <div className="p_sampleview">
              <div className="p_title">
                <img alt="wchart" src={WCHART} />
                <h3>Sample Data</h3>
              </div>
              {propsState.start < 0 ||
                (propsState.start <= propsState.hitsDetails.length - 1 && (
                  <div className="p_det_box">
                    <div className="entity_sec">
                      <h3 className="sec_tilte">
                        <span>Entities</span>
                      </h3>
                      {extra && <div>{showExtra(extra)}</div>}
                      {showSummaries(hitsDetails, TEXT_SUMMARIZATION)}
                    </div>
                  </div>
                ))}
            </div>
          )}

        {projectDetails &&
          hitsDetails &&
          hitsDetails.length >= 0 &&
          projectDetails.task_type === TEXT_MODERATION && (
            <div className="p_sampleview">
              <div className="p_title">
                <img alt="wchart" src={WCHART} />
                <h3>Sample Data</h3>
              </div>
              {propsState.start < 0 ||
                (propsState.start <= propsState.hitsDetails.length - 1 && (
                  <div className="p_det_box">
                    <div className="entity_sec">
                      <h3 className="sec_tilte">
                        <span>Entities</span>
                      </h3>
                      {extra && <div>{showExtra(extra)}</div>}
                      {showSummaries(hitsDetails, TEXT_MODERATION)}
                    </div>
                  </div>
                ))}
            </div>
          )}

        {projectDetails &&
          hitsDetails &&
          hitsDetails.length >= 0 &&
          projectDetails.task_type === TEXT_CLASSIFICATION && (
            <div className="p_sampleview">
              <div className="p_title">
                <img alt="wchart" src={WCHART} />
                <h3>Sample Data</h3>
              </div>
              {propsState.start < 0 ||
                (propsState.start <= propsState.hitsDetails.length - 1 && (
                  <div className="p_det_box">
                    <div className="entity_sec">
                      <h3 className="sec_tilte">
                        <span>Entities</span>
                      </h3>
                      {showTags(entities)}
                      <br />
                      {extra && <div>{showExtra(extra)}</div>}
                      <br />
                      {showClassifications(hitsDetails)}
                    </div>
                  </div>
                ))}
            </div>
          )}

        {projectDetails &&
                hitsDetails &&
                hitsDetails.length >= 0 &&
                (projectDetails.task_type === IMAGE_CLASSIFICATION ||
                  projectDetails.task_type === VIDEO_CLASSIFICATION) && (
                  <div className="p_sampleview">
                    <div className="p_title">
                      <img alt={"status"} src={STATUS} />
                      <h3>Sample Data</h3>
                    </div>
                    {propsState.start < 0 ||
                    propsState.start <= propsState.hitsDetails.length - 1 ? (
                      <div className="p_det_box">
                        <div className="ent_title">
                          <h3 className="sec_tilte">
                            <span>Entities</span>
                          </h3>
                          <div className="m_action_btn">
                            <button
                              className={
                                "action_btn m-r-10 " +
                                (propsState.start === 0 ? "eventnone" : "")
                              }
                            tilte="Previous"
                            onClick={previousElement.bind(this,"pervious")}
                            disabled={propsState.start === 0}
                            >
                              <img alt="left" src={ALEFT} />
                            </button>
                            <button
                              className={
                                "action_btn " +
                                (propsState.start < 0 ||
                                propsState.start >=
                                  propsState.hitsDetails.length - 1
                                  ? "eventnone"
                                  : "")
                              }
                              tilte="Next"
                              onClick={nextElement.bind(this, "right")}
                              disabled={
                                propsState.start < 0 ||
                                propsState.start >=
                                  propsState.hitsDetails.length - 1
                              }
                            >
                              <img alt="right" src={ARIGHT} />
                            </button>
                          </div>
                        </div>
                        <div className="entity_sec">
                          {showTags(entities)}
                          {extra && <div>{this.showExtra(extra)}</div>}
                        </div>
                        {showClassificationImages(hitsDetails)}
                      </div>
                    ) : (
                      <div className="p_det_box">
                        {" "}
                        <div className="ent_title">
                          <h3 className="sec_tilte">
                            <span>Entities</span>
                          </h3>
                        </div>
                        <div className="entity_sec">
                          {showTags(entities)}
                          {extra && <div>{this.showExtra(extra)}</div>}
                        </div>
                        <div>
                          <h4>No Sample Hits</h4>
                        </div>
                      </div>
                    )}
                  </div>
                )}

        {projectDetails &&
          hitsDetails &&
          hitsDetails.length >= 0 &&
          projectDetails.task_type === POS_TAGGING && (
            <div className="p_sampleview">
              <div className="p_title">
                <img alt="wchart" src={WCHART} />
                <h3>Sample Data</h3>
              </div>
              {propsState.start < 0 ||
                (propsState.start <= propsState.hitsDetails.length - 1 && (
                  <div className="p_det_box">
                    <div className="entity_sec">
                      <h3 className="sec_tilte">
                        <span>Entities</span>
                      </h3>
                      {showTags(entities)}
                      <br />
                      {extra && <div>{showExtra(extra)}</div>}
                      <br />
                      {showPosTags(hitsDetails)}
                    </div>
                  </div>
                ))}
            </div>
          )}

        {
        projectDetails &&
                hitsDetails &&
                hitsDetails.length >= 0 &&
                projectDetails.task_type === IMAGE_BOUNDING_BOX && (
                  <div className="p_sampleview">
                    <div className="p_title">
                      <img alt="wchart" src={WCHART} />
                      <h3>Sample Data</h3>
                    </div>
                    {propsState.start < 0 ||
                      (propsState.start <=
                        propsState.hitsDetails.length - 1 && (
                        <div className="p_det_box">
                          <div className="entity_sec">
                            <h3 className="sec_tilte">
                              <span>Entities</span>
                            </h3>
                            {showTags(entities)}
                            <br />
                            {extra && <div>{this.showExtra(extra)}</div>}
                            <br />
                            {this.showBoundedImages(hitsDetails)}
                          </div>
                        </div>
                      ))}
                  </div>
                )}

        
        {projectDetails &&
                hitsDetails &&
                hitsDetails.length >= 0 &&
                projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX && (
                  <div className="p_sampleview">
                    <div className="p_title">
                      <img alt='WCHART' src={WCHART} />
                      <h3>Sample Data</h3>
                    </div>
                    {propsState.start < 0 ||
                      (propsState.start <=
                        propsState.hitsDetails.length - 1 && (
                        <div className="p_det_box">
                          <div className="entity_sec">
                            <h3 className="sec_tilte">
                              <span>Entities</span>
                            </h3>
                            {showTags(entities)}
                            <br />
                            {extra && <div>{this.showExtra(extra)}</div>}
                            <br />
                            {this.showPolygonImages(hitsDetails)}
                          </div>
                        </div>
                      ))}
                  </div>
                )}

        {projectDetails &&
                hitsDetails &&
                hitsDetails.length >= 0 &&
                projectDetails.task_type === VIDEO_BOUNDING_BOX && (
                  <div className="p_sampleview">
                    <div className="p_title">
                      <img alt='WCHART' src={WCHART} />
                      <h3>Sample Data</h3>
                    </div>
                    {propsState.start < 0 ||
                      (propsState.start <=
                        propsState.hitsDetails.length - 1 ? (
                        <div className="p_det_box">
                          <div className="entity_sec">
                            <h3 className="sec_tilte">
                              <span>Entities</span>
                            </h3>
                            {showTags(entities)}
                            <br />
                            {extra && <div>{this.showExtra(extra)}</div>}
                            <br />
                            {showVideoAnnotation(hitsDetails)}
                          </div>
                        </div>
                      ): (
                        <div className="p_det_box">
                          {" "}
                          <div className="ent_title">
                            <h3 className="sec_tilte">
                              <span>Entities</span>
                            </h3>
                          </div>
                          <div className="entity_sec">
                            {showTags(entities)}
                            {extra && <div>{this.showExtra(extra)}</div>}
                          </div>
                          <div>
                            <h4>No Sample Hits</h4>
                          </div>
                        </div>
                      )
                    
                    )
                      
                      
                      }
                  </div>
                )}

        {projectDetails &&
                hitsDetails &&
                hitsDetails.length >= 0 &&
                (projectDetails.task_type === DOCUMENT_ANNOTATION ||
                  projectDetails.task_type === POS_TAGGING_GENERIC) && (
                  <div className="p_sampleview">
                    <div className="p_title">
                      <img alt='status' src={STATUS} />
                      <h3>Sample Data</h3>
                    </div>
                    <div className="p_det_box">
                      <div className="ent_title">
                        <h3 className="sec_tilte">
                          <span>Entities</span>
                        </h3>
                        {propsState.start < 0 ||
                          (propsState.start <=
                            propsState.hitsDetails.length - 1 && (
                            <div className="m_action_btn">
                              <button
                                className={
                                  "action_btn m-r-10 " +
                                  (propsState.start === 0 ? "eventnone" : "")
                                }
                                tilte="Previous"
                                onClick={previousElement.bind(this,"pervious")}
                                disabled={propsState.start === 0}
                              >
                                <img alt='ALEFT' src={ALEFT} />
                              </button>
                              <button
                                className={
                                  "action_btn " +
                                  (propsState.start < 0 ||
                                  propsState.start >=
                                    propsState.hitsDetails.length - 1
                                    ? "eventnone"
                                    : "")
                                }
                                tilte="Next"
                                onClick={nextElement.bind(this, "right")}
                                disabled={
                                  propsState.start < 0 ||
                                  propsState.start >=
                                    propsState.hitsDetails.length - 1
                                }
                              >
                                <img alt='ARIGHT' src={ARIGHT} />
                              </button>
                            </div>
                          ))}
                      </div>
                      <div className="entity_sec">
                        {extra && <div>{this.showExtra(extra)}</div>}
                      </div>
                      {showDocs(hitsDetails)}
                    </div>
                  </div>
                )}
      </div>
    );
  }
}

export default SampleData









