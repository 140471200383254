import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import { setCurrentProject } from '../../redux/reducer/projectReducer.js';
import { Statistic, Header, Table, Dropdown } from 'semantic-ui-react';
import { push, goBack } from 'react-router-redux';
import { fetchProjectStats, fetchProjectStatsFIlterbyDate, docLabelChart, fetchStats } from '../../Services/OverviewApis.js';
import { showAlert } from '../../helpers/commonFunctions.js';
import { POS_TAGGING, VIDEO_BOUNDING_BOX, VIDEO_CLASSIFICATION, DOCUMENT_ANNOTATION, IMAGE_POLYGON_BOUNDING_BOX_V2, IMAGE_SEGMENTATION ,
  TEXT_CLASSIFICATION, breadcrumbSeperate, POS_TAGGING_GENERIC, IMAGE_BOUNDING_BOX, IMAGE_POLYGON_BOUNDING_BOX,
  IMAGE_CLASSIFICATION, TEXT_SUMMARIZATION, TEXT_MODERATION, fromDateTimestamp, toDateTimestamp, DICOM_SEGMENTATION,
  SELECT_BY_HITS, SELECT_BY_LABELTYPE, DOC_ENTITY_COLORS,projectTypePath} from '../../helpers/Utils.js';
import { BarChart, Bar, Tooltip, YAxis, XAxis, Legend, CartesianGrid } from 'recharts';
import SingleDatePicker from '../SingleDatePicker.js/SingleDatePicker.js';
import { Popover, Tooltip as AntTooltip } from 'antd';
import BACK from '../../images/Arrow_Simple_left1.svg';
import SEARCH from '../../images/Searchn.svg';
import GROUPH from '../../images/Group.svg';
import WCHART from '../../images/Chart-7-1.svg';
import { setloaderMenu } from '../../redux/reducer/projectReducer.js';
import { PredictionInsights } from './PredectionInsight.js';

const TiltedAxisTick = (props) => {

  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <AntTooltip title={payload.value}>
      <text  className="bar_text" style={{zIndex: '999999'}}
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(0)" title={payload.value}>
          { (payload.value.toString().length>8 ? payload.value.toString().substring(0,8)+'...': payload.value.toString())}
      </text>
      </AntTooltip>
    </g>
  );
};

const statsLabel = { textTransform: 'initial', fontWeight: '300' };

class ProjectVisualize extends Component {
  static propTypes = {
    user: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    pushState: PropTypes.func,
    currentProject: PropTypes.string,
    projectDetails: PropTypes.object,
    goBack: PropTypes.func,
    params: PropTypes.object,
    orgName: PropTypes.string,
    projectName: PropTypes.string,
    setCurrentProject: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.projectDetailsFetched = this.projectDetailsFetched.bind(this);
    this.projectHitsDetailsFetched = this.projectHitsDetailsFetched.bind(this);
    this.setFromSelectedDate = this.setFromSelectedDate.bind(this);
    this.setToSelectedDate = this.setToSelectedDate.bind(this);
  }
  hasData = false;

  state = {
    projectStats: undefined,
    loading: false,
    taskType: undefined,
    projectDetails: undefined,
    enableToDate: true,
    showDateError: false,
    selectionType: SELECT_BY_HITS,
    showLabelType: false,
    labelDetails: undefined,
    projectDetIsFetched : false,
    showDocLabelChartDetails : undefined,
    docErrorOccurs : false
  };

  componentWillMount() {
  }

  componentDidMount() {
    console.log(this.props.currentProject);
    if ((this.props.params.orgName && this.props.params.projectName &&
      (!this.props.projectDetails || (this.props.projectDetails.name !== this.props.params.projectName || this.props.projectDetails.orgName !== this.props.params.orgName))) ||
      !this.props.currentProject) {
        this.props.currentProject = this.props.params.projectId;
    }
    if (this.props.currentProject && !this.state.projectStats) {
      this.loadProjectDetails(this.props.currentProject);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentProject !== nextProps.currentProject) {
      this.loadProjectDetails(nextProps.currentProject);
    }
  }

  getLabelsCountChart = (inData) => {
    const dataArray = [];
    if (inData) {
      Object.keys(inData).forEach(function(tag) {
        const data = {};
        const tagValue = inData[tag];
        if (tagValue.label) {
          data.name = tagValue.label;
        } else {
          data.name = tag;
        }
        data.Count = tagValue.count;
        dataArray.push(data);
      });
    }
    return (
      <div className="marginTop">
        {this.state.taskType !== IMAGE_CLASSIFICATION ?
          <div>
            <BarChart layout="vertical" width={1000} height={500} data={dataArray}>
              <Tooltip/>
              <Legend/>
              <XAxis type="number"/>
              <YAxis dataKey="name" type="category"/>
              <Bar dataKey="Count" stackId="a" fill="#8884d8"/>
            </BarChart>
          </div> :
          <PredictionInsights projectId={this.props.params.projectId} />
        }
      </div>

    );
  }

  getDocLabelsChart(inData) {
    const dataArray = [];
    if (inData) {
      Object.keys(inData).forEach(function(tag) {
        const data = {};
        const tagValue = inData[tag];
        data.name = tag;
        data.Count = tagValue.count;
        dataArray.push(data);
      });
    }

    if(dataArray.length === 0 && this.state.taskType === DOCUMENT_ANNOTATION){
        return (
          <div className="no_result" style={{paddingBottom : '50px'}}>No Data Available.</div>
        )
    }
    else
    {
      return (
        <div>
          {this.state.taskType === DOCUMENT_ANNOTATION && <h2  style={{marginTop : '50px'}}>By Classification</h2>}
          <div className="marginTop">
            <div>
              <BarChart layout="vertical" width={1000} height={500} data={dataArray}>
                <Tooltip />
                <Legend />
                <XAxis type="number"  />
                <YAxis dataKey="name" type="category"  tick={<TiltedAxisTick />} />
                <Bar dataKey="Count" stackId="a" fill="#8884d8" />
              </BarChart>
            </div>
          </div>
        </div>
      );
    }
  }

  getDocLabelsChart_Parents(details){
	 if(details === null){
		 return (
		          <div className="no_result" style={{paddingBottom : '50px'}}>No Data Available.</div>
	     );
	 }else{
		  const data = details.graphDataSet;
			 const labels = details.grapLabels;
			 let pushArr = [];
			  labels.map((value,index) => {
				  pushArr.push(
						  <Bar barSize={50} dataKey={value} stackId="a" fill={DOC_ENTITY_COLORS[index]}  />
				  );
			  })

			  return (
				    	<BarChart width={900} height={600} data={data}
				            margin={{top: 20, right: 30, left: 20, bottom: 5}}>
				       <CartesianGrid strokeDasharray="3 3"/>
				       <XAxis dataKey="parent" label={{ value: 'Label', position: 'insideBottomRight', offset: 0 }}/>
				       <YAxis allowDecimals = {false} label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
				       <Tooltip/>
				       <Legend />
				       {pushArr}
				      </BarChart>
				    );
	 }


  }


  getDocLabelsChartDetailsFetched = (error, response) =>{
	  if(!error){
		  this.setState({showDocLabelChartDetails : response.body, docErrorOccurs : false})
	  }else{
		  this.setState({docErrorOccurs : true})
	  }
  }

  getLabelsChart(inData) {
    const dataArray = [];
    if (inData) {
      Object.keys(inData).forEach(function(tag) {
        const data = {};
        const tagValue = inData[tag];
        data.name = tag;
        data['Total Words'] = tagValue.count;
        data['Unique Words'] = tagValue.numUniqWords;
        data['Word Frequency'] = (tagValue.count / tagValue.numUniqWords).toPrecision(2);
        dataArray.push(data);
      });
    }
    return (
      <div className="marginTop">
        <div>
          <BarChart layout="vertical" width={1000} height={500} data={dataArray}>
            <Tooltip />
            <Legend />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" />
            <Bar dataKey="Total Words" stackId="a" fill="#8884d8" />
            <Bar dataKey="Unique Words" stackId="a" fill="#82ca9d" />
          </BarChart>
        </div>
        <br />
        <br />

        <div>
          <BarChart layout="vertical" width={1000} height={500} data={dataArray}>
            <Tooltip />
            <XAxis type="number" />
            <YAxis type="catehory" dataKey="name" type="category" />
            <Legend />
            <Bar dataKey="Word Frequency" stackId="c" fill="#ffc658" />
          </BarChart>
        </div>
        <PredictionInsights projectId={this.props.params.projectId} />
      </div>

    );
  }

  getWordsChart(inData) {
    const renderArr = [];
    for (let index = 0; index < inData.length; index++) {
      renderArr.push(
        <tr>
          <td>{inData[index].phrase}</td>
          <td>{inData[index].count}</td>
        </tr>);
    }
    return (
      <Table striped bordered condensed hover responsive>
        <thead>
          <tr>
            <th>Word</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {renderArr}
        </tbody>
      </Table>
    );
  }
  // From date
  setFromSelectedDate(selectedFromDate, cDate) {
    this.clearFromDisable = false;
    if (selectedFromDate === undefined) {
      this.clearFromDisable = true;
    }
    this.setState({ fromDate: selectedFromDate })
    this.setState({ enableToDate: false })
    this.changeToDateVal = true
    this.setState({ fromcDate: cDate })
  }

  // To date
  setToSelectedDate(selectedToDate, cDate) {
    this.clearToDisable = false;
    if (selectedToDate === undefined) {
      this.clearToDisable = true;
    }

    this.changeToDateVal = false
    this.setState({ toDate: selectedToDate })
    this.setState({ tocDate: cDate })
  }

    // Search Function to get datas for from date to date
    searchInsights() {
      var fDate = new Date(this.state.fromDate);
      var tDate = new Date(this.state.toDate);
      // Checks for from date should be geater
      // When there is no from and to date, the error message should be "From Date and To Date cannot be empty" - Ramani
      if (this.state.fromDate === undefined && this.state.toDate === undefined) {
    	  showAlert('From Date and To Date cannot be empty', 'error');
    	  return false;
      }else if (this.state.fromDate === undefined && this.state.toDate !== undefined) {
    	  showAlert('From Date cannot be empty', 'error');
    	  return false;
      }else if (fDate > tDate) {
        // From date should not be greater than To date
        showAlert('To Date should be greater than From Date', 'error');
        return false;
      }else {
        // When user provides only the from date, it should fetch the results from the given date to current/till date - Ramani
        let fromDate = fromDateTimestamp(this.state.fromcDate);
        let toDate = this.state.toDate !== undefined  ? toDateTimestamp(new Date(this.state.tocDate)) : toDateTimestamp(new Date());
        fetchProjectStatsFIlterbyDate(this.props.currentProject, fromDate, toDate, this.state.selectionType, this.projectHitsDetailsFetched);
      }
    }

  getStatsInTable() {
    const hitsContributorDetails = (this.state.projectDetails) ? this.state.projectDetails.hitsContributorDetails : [];
    // UI Changes in Insight screen for displaying heading uniformly
    const headerRow = ['Name', 'HITs Done', 'Labels in HITs Done', 'HITs Skipped', 'Labels in HITs Skipped', 'HITs Deleted', 'Labels in HITs Deleted', 'Total HITs', 'Total Labels'];
    const recorIndex = hitsContributorDetails.findIndex(i => i.hitsStats.totalHits !== 0);
    const renderBodyRow = ({ userDetails, hitsStats }, i) => (
      hitsStats.totalHits !== 0 && {
      key: `row-${i}`,
      cells: [
        userDetails.firstName + ' ' + userDetails.secondName,
        { key: 'hitsDone', content: hitsStats.done.hits },
        { key: 'hitsDoneLabels', content: hitsStats.done.labels },
        { key: 'hitsSkipped', content: hitsStats.skipped.hits },
        { key: 'hitsSkippedLabels', content: hitsStats.skipped.labels },
        { key: 'hitsDeleted', content: hitsStats.deleted.hits },
        { key: 'hitsDeletedLabels', content: hitsStats.deleted.labels },
        { key: 'hitsTotal', content: hitsStats.totalHits },
        { key: 'labelsTotal', content: hitsStats.totalLabels }
      ],
    });
    // When no results is found between the date given, it is better to display a message "No Results Found"
    if (recorIndex === -1) {
      this.hasData = false
      return <div className="no_result">No Results Found.</div>;
    }else {
      this.hasData = true
      return (
        <Table striped celled bordered condensed hover responsive headerRow={headerRow} renderBodyRow={renderBodyRow} tableData={hitsContributorDetails}>
        </Table>);
    }
  }

  getLabelListTable = () => {
    const hitsLabelDetails = (this.state.labelDetails) ? this.state.labelDetails : [];
    const headerRow = ['Label Name', 'Labels in Done HITs', 'Labels in Deleted HITs', 'Labels in Skipped HITs', 'Total HITs'];
    const recorIndex = hitsLabelDetails ? hitsLabelDetails.findIndex(i => i.hitsStats.totalLabelCount !== 0) : -1;
    if (recorIndex === -1) {
      this.hasData = false
      return <div className="no_result">No Results Found.</div>;
    } else {
      this.hasData = true
      return (
        <Table striped celled bordered condensed hover responsive>
          {this.renderLabelListHeader(headerRow)}
          {hitsLabelDetails && this.renderLabelListRows(hitsLabelDetails)}
        </Table>);
    }
  }

  renderLabelListHeader = (headerRow) => {
    const headers = [];
    headerRow.map((element, index) => {
      headers.push(<Table.HeaderCell>{element}</Table.HeaderCell>)
    });

    return (
    <Table.Header>
      <Table.Row>
        {headers}
      </Table.Row>
    </Table.Header> )
  }

  getPopOverData = (hitsCount, type) => {
    let popOverData;

    // To show popover for non zero value
    let labelCount = hitsCount[hitsCount.length - 1];
    if (labelCount !== 0) {
      let hitsUserData = []
      hitsCount.map((done, index) => {
        if (index !== hitsCount.length - 1 && done.labelCount !== 0)  {
          hitsUserData.push(<p style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '1em'}}><span>{done.user}</span> <span>{done.labelCount}</span></p>)
        }
      })
      const popoverContent = (<div> {hitsUserData} </div> )
      popOverData = ( <Popover content={popoverContent} title={type}>
                        <a>{labelCount}</a>
                      </Popover>
                      )
    } else {
      popOverData = labelCount
    }
    return (
      <div>
        {popOverData}
     </div>
    )
  }


  renderLabelListRows = ( hitsStats ) => {
    let arrs = []
    hitsStats.map((element, index) =>  {
      let doneCount = element.hitsStats.labelList[0].done
      let deleteCount = element.hitsStats.labelList[2].deleted
      let skippedCount = element.hitsStats.labelList[1].skipped

      arrs.push(
        <Table.Row key={index}>
                    <Table.Cell>
                      {element.hitsStats.labelName}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getPopOverData(doneCount, "Hit's Done")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getPopOverData(deleteCount, "Hit's Deleted")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getPopOverData(skippedCount, "Hit's Skipped")}
                    </Table.Cell>
                    <Table.Cell>
                        {element.hitsStats.totalLabelCount}
                    </Table.Cell>
        </Table.Row>
      )
    });
    return (
      <Table.Body>
        {arrs}
      </Table.Body> );
  }

  //
  loadDropDown = (projectDetails) => {
    if (projectDetails.task_type === DOCUMENT_ANNOTATION) {
      let tasks = JSON.parse(projectDetails.taskRules)
      if (tasks.classification) {
        this.setState({ showLabelType: true });
      }
    }

    if (projectDetails.task_type === IMAGE_CLASSIFICATION
      || projectDetails.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2
      || projectDetails.task_type === IMAGE_SEGMENTATION
      || projectDetails.task_type === DICOM_SEGMENTATION) {
          this.setState({ showLabelType: true });
      }
  }

  loadProjectDetails(pid, cache) {
    // TODO_REPLACE
    this.props.setloaderMenu(true);
    this.setState({ loading: true, projectDetails: this.props.projectDetails, projectDetIsFetched:true });
    if (window.sessionStorage.getItem("uid") === null) {
      this.props.pushState('/projects/login');
    }
    if (this.props.projectDetails !== null) {
      this.loadDropDown(this.props.projectDetails)
    }

    if (pid) {
      fetchStats(pid, this.projectDetailsFetched, cache);
      if (this.state.selectionType === SELECT_BY_LABELTYPE) {
        fetchProjectStatsFIlterbyDate(pid, "", "", SELECT_BY_LABELTYPE, this.projectHitsDetailsFetched);
      }else {
        fetchProjectStats(pid, this.projectHitsDetailsFetched);
      }
    } else {
      fetchStats(this.props.currentProject, this.projectDetailsFetched, cache);
      if (this.state.selectionType === SELECT_BY_LABELTYPE) {
        fetchProjectStatsFIlterbyDate(this.props.currentProject, "", "", SELECT_BY_LABELTYPE, this.projectHitsDetailsFetched);
      }else {
        fetchProjectStats(this.props.currentProject, this.projectHitsDetailsFetched);
      }
    }
  }

  projectDetailsFetched(error, response) {
    if (!error) {
      let projectStats = {};
      let taskType = undefined;
      if (response.body.details.task_type === POS_TAGGING) {
        projectStats = response.body.posTaggingStats;
        taskType = response.body.details.task_type;
      } else if (response.body.details.task_type === TEXT_SUMMARIZATION || response.body.details.task_type === TEXT_MODERATION) {
        projectStats = response.body.textSummarizationStats;
        taskType = response.body.details.task_type;
      } else if (response.body.details.task_type === TEXT_CLASSIFICATION) {
        projectStats = response.body.textClassificationStats;
        taskType = TEXT_CLASSIFICATION;
      } else if (response.body.details.task_type === IMAGE_CLASSIFICATION) {
        projectStats = response.body.imageClassificationStats;
        taskType = IMAGE_CLASSIFICATION;
      } else if (response.body.details.task_type === VIDEO_CLASSIFICATION) {
        projectStats = response.body.videoClassificationStats;
        taskType = VIDEO_CLASSIFICATION;
      } else if (response.body.details.task_type === IMAGE_BOUNDING_BOX) {
        projectStats = response.body.imageBoundingBoxStats;
        taskType = IMAGE_BOUNDING_BOX;
      } else if (response.body.details.task_type === IMAGE_POLYGON_BOUNDING_BOX || response.body.details.task_type === IMAGE_POLYGON_BOUNDING_BOX_V2 || response.body.details.task_type === IMAGE_SEGMENTATION) {
        projectStats = response.body.imageBoundingBoxStats;
        taskType = response.body.details.task_type;
      } else if (response.body.details.task_type === DOCUMENT_ANNOTATION) {
        projectStats = response.body.documentTaggingStats;
        taskType = DOCUMENT_ANNOTATION;
        if(this.state.showDocLabelChartDetails === undefined)
        	docLabelChart(this.props.currentProject, this.getDocLabelsChartDetailsFetched);
      } else if (response.body.details.task_type === POS_TAGGING_GENERIC) {
        projectStats = response.body.documentTaggingStats;
        taskType = POS_TAGGING_GENERIC;
      } else if (response.body.details.task_type === VIDEO_BOUNDING_BOX) {
        projectStats = response.body.videoBoundingBoxStats;
        taskType = VIDEO_BOUNDING_BOX;
      }
      else if (response.body.details.task_type === DICOM_SEGMENTATION) {
        projectStats = response.body.dicomSegmentationStats;
        taskType = response.body.details.task_type;
      }
      this.props.setloaderMenu(false);
      this.setState({ projectStats, taskType, loading: false, projectDetIsFetched: false });
    }
  }

  projectHitsDetailsFetched(error, response) {
    if (!error) {
      this.props.setloaderMenu(false);
      let responseData = response.body;
      this.loadDropDown(responseData)
      if (this.state.selectionType === SELECT_BY_LABELTYPE) {
        this.setState({loading: false, labelDetails: responseData.hitsLabelDetails, projectDetIsFetched: false })
      }
      this.setState({ projectDetails: responseData, loading: false});
    }
  }

  handleSubmit = (response) => {
    if (response && response.profileObj) {
      this.props.login(response.profileObj.name, response.profileObj.imageUrl);
    }
  }

  getResults = () => {
    const options = [];
    let selected = this.state.selectionType;

    options.push({
      text: SELECT_BY_HITS,
      value: SELECT_BY_HITS,
      onClick: () => {
        this.setState({selectionType: SELECT_BY_HITS}, () => {
          this.loadProjectDetails(this.props.currentProject);
          this.getResultBySelection( SELECT_BY_HITS );
        });
      }
    });
    options.push({
      text: SELECT_BY_LABELTYPE,
      value: SELECT_BY_LABELTYPE,
      onClick: () => {
        this.setState({selectionType: SELECT_BY_LABELTYPE}, () => {
          this.getResultBySelection( SELECT_BY_LABELTYPE );
        });
      }
    });
    return (
      <Dropdown className="custom_label"
        compact
        value = {selected}
        selection
        options={options}
      />
    );
  }

  getResultBySelection = (selectionType) => {
    this.hasData = false
    this.setState({ selectionType: selectionType })
    if (selectionType === SELECT_BY_LABELTYPE ) {
      this.props.setloaderMenu(true);
      this.setState({loading: true, projectDetIsFetched: true})
      fetchProjectStatsFIlterbyDate(this.props.currentProject, "", "", selectionType, this.projectHitsDetailsFetched);
    }else {
      this.loadProjectDetails(this.props.currentProject);
    }
  }

  clearFilters() {
    this.setFromSelectedDate(undefined, null);
    this.setToSelectedDate(undefined, null);
    this.loadProjectDetails(this.props.currentProject, false);
  }

  render() {
    const { projectStats, taskType, fromcDate, tocDate } = this.state;
    const { projectDetails } = this.props;
    let pageTitle = 'Visualize';
    let pageDescription = 'Just upload your data, invite your team members and start tagging. The best way to tag training/evaluation data for your machine learning projects.';
    if (projectDetails) {
      pageTitle = projectDetails.name + ' ' + pageTitle;
      if (projectDetails.shortDescription) {
        pageDescription = projectDetails.shortDescription;
      }
      if (projectDetails.subtitle) {
        pageTitle = projectDetails.subtitle + ' ' + pageTitle;
      }
    }

    return (

      <div className="taggerPages pr_insiteview">
        <Helmet title={pageTitle}>
          <meta property="og:title" content={pageTitle} />
          <meta name="description" content={pageDescription} />
          <meta property="og:description" content={pageDescription} />
        </Helmet>
        {
          <div>
            {
              <div>
                <div>
                  <div className="project_sec">
                    <div className="project_title f-column">
                    <div className="f-center">
                      <span className="btn_back" onClick={() => this.props.pushState(projectTypePath(projectDetails) + this.props.params.orgName + '/' + this.props.params.projectName+'/'+this.props.params.projectId)}>
                          <img alt='back' src={BACK} />
                      </span>
                    </div>
                    <div className="breadcrum-sec">
                        <div className="sec_holder">
                        <a className={"tilte_display fw-400 " + (this.props.params.orgName.length > 15 ? 'pro_width' : '')} 
                        title={this.props.params.orgName}>{this.props.params.orgName} </a>
                        {breadcrumbSeperate()}
                        <a className={"tilte_display fw-400 " + (projectDetails.name.length > 15 ? 'pro_width' : '')} onClick={() => this.props.pushState('/projects/' + this.props.params.orgName + '/' + projectDetails.name + '/' + this.props.params.projectId)} title={projectDetails.name}> {projectDetails.name}</a>
                        </div>
                        </div>
                    </div>

                    <div className="project_set">
                      {this.state.showLabelType &&
                      <div className="hits_dropdown m-r-10">
                        <label>HITs</label>
                        {this.getResults()}
                      </div>
                      }
                      <div className="site_date">
                        <label>From Date</label>
                        <SingleDatePicker
                          setSelectedDate={this.setFromSelectedDate}
                          selectedDate = {fromcDate}
                          placeholder="From date"
                          convedDate={this.convedDate}
                          clearDisable={this.clearFromDisable}
                        />
                      </div>
                      <div className="site_date">
                        <label>To Date</label>
                        <SingleDatePicker
                          setSelectedDate={this.setToSelectedDate}
                          selectedDate={tocDate}
                          placeholder="To date"
                          isDisabled={this.state.enableToDate}
                          changeToDateVal={this.changeToDateVal}
                          convedDate={this.convedDate}
                          clearDisable={this.clearToDisable}
                        />
                      </div>
                      <a className="action_btn m-t-20 m-h-35" onClick={() => this.searchInsights()}><img src={SEARCH} /><span>SEARCH</span></a>
                      <a className="action_btn m-l-10 m-t-20 m-h-35" onClick={() => this.clearFilters()}><img src={GROUPH} /><span>REFRESH</span></a>
                    </div>
                  </div>
                </div>
              </div>
            }
            <br />
            <br />
            {
              <div className="m-t-25">
                <div className="p_title">
                <img alt="wchart" src={WCHART} />
                <h3>Leaderboard</h3>
                </div>
                {
                      <div>
                        {this.state.selectionType === SELECT_BY_HITS && this.state.projectDetails &&
                          !this.state.projectDetIsFetched && !this.state.loading && this.getStatsInTable()}
                        {this.state.selectionType === SELECT_BY_LABELTYPE  &&
                          !this.state.projectDetIsFetched && !this.state.loading && this.state.labelDetails && this.getLabelListTable()}
                      </div>
                }
              </div>
            }
            <br />
            {projectStats && taskType !== DOCUMENT_ANNOTATION && taskType !== VIDEO_CLASSIFICATION && taskType !== DICOM_SEGMENTATION
                && taskType !== POS_TAGGING_GENERIC && taskType !== IMAGE_CLASSIFICATION
                && taskType !== IMAGE_BOUNDING_BOX && taskType !== IMAGE_POLYGON_BOUNDING_BOX
                && taskType !== VIDEO_BOUNDING_BOX && taskType !== IMAGE_POLYGON_BOUNDING_BOX_V2 && taskType !== IMAGE_SEGMENTATION &&
              <div className="text-center col-md-12 card_shade">
                  <Header as="h2">Project Insights</Header>
                  <div>
                    <br />
                    <br />
                    <Statistic.Group size="mini" widths="three">
                      <Statistic color="green">
                        <Statistic.Value>{projectStats.totalUniqWords}</Statistic.Value>
                        <Statistic.Label style={statsLabel}>Unique Words</Statistic.Label>
                      </Statistic>
                      <Statistic color="green">
                        <Statistic.Value>{(projectStats.totalWords / projectStats.totalUniqWords).toPrecision(2)}</Statistic.Value>
                        <Statistic.Label style={statsLabel}>Word Frequency</Statistic.Label>
                      </Statistic>
                      <Statistic color="green">
                        <Statistic.Value>
                          {projectStats.totalWords}
                        </Statistic.Value>
                        <Statistic.Label style={statsLabel}>Total Words</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </div>
                  <br />
                  <br />
                  <div className="marginTopExtra col-md-12">
                    <div className="col-md-6">
                      {projectStats.leastFrequentWords && projectStats.leastFrequentWords.length > 0 &&
                        <div className="marginTop">
                          <h4> Least frequent words </h4>
                          {this.getWordsChart(projectStats.leastFrequentWords)}
                        </div>
                      }
                    </div>
                    <div className="col-md-6">
                      {projectStats.mostFrequentWords && projectStats.mostFrequentWords.length > 0 &&
                        <div className="marginTop">
                          <h4> Most frequent words </h4>
                          {this.getWordsChart(projectStats.mostFrequentWords)}
                        </div>
                      }
                    </div>
                  </div>
              </div>
            }
            <br />

           {(!this.state.projectDetIsFetched && !this.state.loading && this.hasData) &&
            <div>
            {projectStats && (taskType === POS_TAGGING) &&
              <div className="text-center col-md-12 card_shade">
                  <Header as="h2">Insights from completed HITs</Header>
                  <div>
                    <br />
                    <br />
                    <Statistic.Group size="mini" widths="three">
                      <Statistic color="green">
                        <Statistic.Value>{projectStats.totalUniqWordsWithLabels}</Statistic.Value>
                        <Statistic.Label style={statsLabel}>Unique Words</Statistic.Label>
                      </Statistic>
                      <Statistic color="green">
                        <Statistic.Value>{(projectStats.totalWordsWithLabels / projectStats.totalUniqWordsWithLabels).toPrecision(2)}</Statistic.Value>
                        <Statistic.Label style={statsLabel}>Word Frequency</Statistic.Label>
                      </Statistic>
                      <Statistic color="green">
                        <Statistic.Value>
                          {projectStats.totalWordsWithLabels}
                        </Statistic.Value>
                        <Statistic.Label style={statsLabel}>Total Words</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </div>
              </div>
            }
            {(projectStats && taskType === TEXT_SUMMARIZATION || taskType === TEXT_MODERATION) &&
              <div className="text-center col-md-12 card_shade">
                  <Header as="h2">Insights from completed HITs</Header>
                  <div>
                    <br />
                    <br />
                    <Statistic.Group size="mini" widths="two">
                      <Statistic color="green">
                        <Statistic.Value>{projectStats.avrWordsInHits}</Statistic.Value>
                        <Statistic.Label style={statsLabel}>Average Words in HITs</Statistic.Label>
                      </Statistic>
                      <Statistic color="green">
                        <Statistic.Value>
                          {projectStats.avrWordsInHitResults}
                        </Statistic.Value>
                        <Statistic.Label style={statsLabel}>Average Words in HIT Results</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </div>
              </div>
            }

            {projectStats && (taskType === POS_TAGGING) &&
              <div className="text-center col-md-12 card_shade">
                  <div>
                    {this.getLabelsChart(projectStats.perLabelStat)}
                  </div>
              </div>
            }

            {projectStats && taskType === TEXT_CLASSIFICATION &&
              <div className="text-center col-md-12 card_shade">
                  <div>
                    {this.getLabelsCountChart(projectStats.labelCounts)}
                  </div>
              </div>
            }
            {projectStats && (taskType === IMAGE_CLASSIFICATION || taskType === VIDEO_CLASSIFICATION ||
            taskType === IMAGE_BOUNDING_BOX || taskType === VIDEO_BOUNDING_BOX || taskType === IMAGE_POLYGON_BOUNDING_BOX
            || taskType === IMAGE_POLYGON_BOUNDING_BOX_V2 || taskType === IMAGE_SEGMENTATION || taskType === DICOM_SEGMENTATION) &&
              <div className="text-center col-md-12 card_shade">
                  <div>
                    {this.getLabelsCountChart(projectStats.laeblStats)}
                  </div>
              </div>
            }

            {(( taskType === POS_TAGGING_GENERIC) &&
             (!this.state.projectDetIsFetched && !this.state.loading && this.hasData)) &&
              <div>
                {(projectStats && projectStats.perLabelStat && !this.state.projectDetIsFetched) &&
                  <div className="text-center col-md-12 card_shade">
                    {this.getDocLabelsChart(projectStats.perLabelStat)}
                  </div>
                }
              </div>
            }

            {((taskType === DOCUMENT_ANNOTATION) &&
                    (!this.state.projectDetIsFetched && !this.state.loading && this.hasData)) &&
                     <div>
                         <div className="card_shade c_flex">
                           <div className="c_holder">
                                {(this.state.selectionType === SELECT_BY_HITS && this.state.showDocLabelChartDetails !== undefined) &&
                                this.getDocLabelsChart_Parents(this.state.showDocLabelChartDetails)}
                                {this.state.selectionType === SELECT_BY_LABELTYPE &&
                                  this.getDocLabelsChart(projectStats.perLabelStat)}
                           </div>
                         </div>
                     </div>
                   }

            {projectStats && (taskType === TEXT_SUMMARIZATION || taskType === TEXT_MODERATION) &&
              <div className="text-center col-md-12 card_shade">
                  <div className="marginTop" style={{ paddingLeft: '20%', paddingRight: '20%' }}>
                    <h4> Most frequent Excluded Words </h4>
                    {this.getWordsChart(projectStats.mostFrequentExcludedWords)}
                  </div>
              </div>
            }
            </div>
            }
          </div>
        }

      </div>

    );
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.loginReducer.user, 
      currentProject: state.projectReducer.currentProject,
      projectDetails: state.projectReducer.projectDetails
    };
  };
  
  
  const mapDispatchToProps = dispatch => bindActionCreators({
    pushState: push,
    goBack, 
    setCurrentProject,
    setloaderMenu
  },
   dispatch);
  
  export default connect( mapStateToProps, mapDispatchToProps)(ProjectVisualize);